import {Component, OnDestroy, OnInit} from '@angular/core';
import { AbstractModelValidator, GaMessagingService, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { UsersService } from 'src/app/services/autogen/Users';
import {filter, take} from 'rxjs/operators';
import { FreebetsService } from '../../../../services/autogen/Events';

@Component({
  selector: 'app-user-cancel-freebet',
  templateUrl: './user-cancel-freebet.component.html',
  styleUrls: ['./user-cancel-freebet.component.css']
})

export class UserCancelFreebetComponent implements OnInit, OnDestroy {

  public userId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();

  constructor(private readonly messaging: GaMessagingService,
              private readonly service: FreebetsService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  public getUserIdHasValue(): boolean {
    return !this.userId.hasStrictValue;
  }

  public reset(): void {
    this.service.cancelByUserId(this.userId.strictValue)
      .pipe(
        take(1),
        filter(err => !this.messaging.tryShowError(err)))
      .subscribe(() => this.messaging.showMessage('Фрибеты пользователя аннулированы')
      );
  }
}
