<div class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action" style="border-top: none;">
    <div class="row">
      <div class="col-3">
        <label>Batch id:</label>
        <span class="long-description">
          {{viewModel.batch.id}}
        </span>
      </div>
      <div class="col-2">
        <label>Аккаунт id:</label>
        <span class="long-description">
          {{viewModel.batch.accountId}}
        </span>
      </div>
      <div class="col-3">
        <label>Телефон:</label>
        <span class="long-description">
          {{viewModel.phoneNumber}}
        </span>
      </div>
    </div>
  </div>
  <div class="list-group-item list-group-item-action" style="border-top: none;">
    <div class="row">
      <div class="col-3">
        <label>Дата отправки:</label>
        <span class="long-description">
          {{viewModel.batch.dataTime | moscowDateTime}}
        </span>
      </div>
      <div class="col-3">
        <label>Статус отправки:</label>
        <span class="long-description">
          {{sentCodes.format(viewModel.batch.errorCode)}}
        </span>
      </div>
      <div class="col-2">

        <span class="long-description">
          {{viewModel.batch.requestDeviceIP}}
        </span>
      </div>
    </div>
  </div>
  <div class="list-group-item list-group-item-action">

    <h3 class="mb-1">Текст: {{viewModel.textMessage}}</h3>
  </div>
</div>
