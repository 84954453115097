<h1 style="padding: inherit">Последовательности</h1>
<div *ngIf="loading" class="loading">
  <app-loading></app-loading>
</div>

<div class="container-fluid" *ngIf="!loading">

  <table class="table">
    <tr class="row table-info">
      <td class="col-1">
        Id
      </td>
      <td class="col-1">
        Id задачи
      </td>
      <td class="col-2">
        Id факта подключения механики
      </td>
      <td class="col-2">
        Id пользователя
      </td>
      <td class="col-2">
        Id скрипта
      </td>
      <td class="col-2">
        Время удаления из списка на обзвон
      </td>
    </tr>

    <tr *ngFor="let sequence of model.results" class="row text-info">
      <td class="col-1">
        {{sequence.id}}
      </td>
      <td class="col-1">
        {{sequence.jobId}}
      </td>
      <td class="col-2">
        {{sequence.mechanicsConnectFactId}}
      </td>
      <td class="col-2">
        {{sequence.userId}}
      </td>
      <td class="col-2">
        {{sequence.callScriptId}}
      </td>
      <td class="col-2">
        {{sequence.removeTime | dateTime}}
      </td>
    </tr>
  </table>

  <ga-paging [pagedResult]="model" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
