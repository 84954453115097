import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class SearchNavigationService {
    constructor(private readonly router: Router) {}

    search(basicUrl: ActivatedRoute, params: Params, replaceUrl?: boolean): Promise<boolean> {
        return this.router.navigate([], {
            relativeTo: basicUrl,
            replaceUrl: replaceUrl,
            queryParams: params,
        });
    }
}
