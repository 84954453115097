import {Component, OnDestroy, OnInit} from '@angular/core';
import {LocaleService} from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-media-actions',
  templateUrl: './media-actions.html',
  styleUrls: ['./media-actions.css'],
  providers: [LocaleService]
})
export class MediaActionsComponent implements OnInit, OnDestroy {

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
