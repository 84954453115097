import { WlBattlePassRewardLiteModel } from 'src/app/services/autogen/BattlePass';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import {filter, map, take} from 'rxjs/operators';
import {DropdownItem, IDropdownStrategy, isNullOrUndefined} from '@koddington/ga-common';
import {BehaviorSubject, Observable} from 'rxjs';

export class WlBattlePassRewardsStrategy implements IDropdownStrategy<WlBattlePassRewardLiteModel> {
  private _source = new BehaviorSubject<WlBattlePassRewardLiteModel[]>([]);

  constructor(private readonly _battlePassService: BattlePassService) {
    this.load();
  }

  public getEntities(): Observable<Array<WlBattlePassRewardLiteModel>> {
    return this._source;
  }

  public map(model: WlBattlePassRewardLiteModel): DropdownItem<WlBattlePassRewardLiteModel> {
    if (isNullOrUndefined(model)) {
      return {
        entity: model,
        title: '',
        id: null,
      };
    }
    return {
      entity: model,
      title: model.name,
      id: model.id,
    };
  }

  private load(): void {
    this._battlePassService
      .fullList()
      .pipe(
        take(1),
        filter((res) => !isNullOrUndefined(res) && res.isCorrect),
        map((result) => result.result)
      )
      .subscribe((u) => this._source.next(u));
  }
}
