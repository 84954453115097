import {Component, OnInit} from '@angular/core';
import {WlResult} from '../../../../../models/common/results/wl-result';
import {DropdownOptions} from '../../../../../models/common/enum-dropdown/dropdown-options';
import {AddMechanicViewModel} from '../models/add-mechanic-view-model';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddEnabledMechanicForm, EventsService } from 'src/app/services/autogen/Events';
import { WlMechanicEnabledReasons } from 'src/app/services/autogen/Segmentation';
import {
  MechanicEnabledReasonsDropdownStrategy
} from '../../../../shared/common/enum-dropdown/strategies/mechanic-enabled-reasons-dropdown-strategy';
import {EnabledMehcanicValidator} from '../../../validators/enabled-mehcanic-validator.service';
import { WlSegmentationMechanicsTypes } from 'src/app/services/autogen/Events';
import {
  WlSegmentationMechanicsTypesStrategy
} from '../../../../shared/common/enum-dropdown/strategies/wl-segmentation-mechanics-types-strategy';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import { Dayjs } from 'dayjs';


@UntilDestroy()
@Component({
  selector: 'app-mechanics-changes',
  templateUrl: './mechanics-changes.component.html',
  styleUrls: ['./mechanics-changes.component.css']
})
export class MechanicsChangesComponent implements OnInit {

  private result: WlResult<boolean>;
  private readonly model: AddMechanicViewModel = new AddMechanicViewModel();
  private readonly _validator: EnabledMehcanicValidator = new EnabledMehcanicValidator();

  constructor(private readonly service: EventsService,
              private readonly _messaging: GaMessagingService,
              public readonly _mechanicTypeStrategy: WlSegmentationMechanicsTypesStrategy,
              public readonly _mechanicEnabledReasonId: MechanicEnabledReasonsDropdownStrategy) {
  }

  ngOnInit(): void {
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get mechanicEnabledReason(): StrictFormControl<WlMechanicEnabledReasons> {
    return this.model.mechanicEnabledReason;
  }

  get mechanicType(): StrictFormControl<WlSegmentationMechanicsTypes> {
    return this.model.mechanicType;
  }

  get depositWaitingDate(): StrictFormControl<Dayjs> {
    return this.model.depositWaitingDate;
  }

  get freebetId(): StrictFormControl<number> {
    return this.model.freebetId;
  }

  get sumForMechanicTriggering(): StrictFormControl<number> {
    return this.model.sumForMechanicTriggering;
  }

  get freebetSum(): StrictFormControl<number> {
    return this.model.freebetSum;
  }
  get freebetLifetimeDays(): StrictFormControl<number> {
    return this.model.freebetLifetimeDays;
  }

  public isUnconditionalFreebet(): boolean {
    return this.model.mechanicType.strictValue === WlSegmentationMechanicsTypes.UnconditionalFreebet;
  }

  get options(): DropdownOptions {
    const dropdownOptions = new DropdownOptions();
    dropdownOptions.placeholder = 'Не выбрано';

    return dropdownOptions;
  }
  addEnabledMechanic(): void {

    const errors = this._validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }

    const form = new AddEnabledMechanicForm();

    form.userId = this.userId.strictValue;
    form.mechanicEnabledReason = this.mechanicEnabledReason.strictValue;
    form.mechanicType = this.mechanicType.strictValue;
    form.depositWaitingDate = this.depositWaitingDate.hasStrictValue ? this.depositWaitingDate.strictValue : null;
    form.freebetId = this.freebetId.hasStrictValue ? this.freebetId.strictValue : 0;
    form.sumForMechanicTriggering = this.sumForMechanicTriggering.hasStrictValue ? this.sumForMechanicTriggering.strictValue : 0;
    form.freebetLifetimeDays = this.freebetLifetimeDays.hasStrictValue ? this.freebetLifetimeDays.strictValue : 0;
    form.freebetSum = this.freebetSum.hasStrictValue ? this.freebetSum.strictValue : 0;


    this.service.addEnabledMechanics(form)
      .pipe(
        take(1),
        filter(u => !this._messaging.tryShowError(u)),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.result = value;
        this._messaging.showMessage('Механика успешно добавлена');
      });
  }

}
