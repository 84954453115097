import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { WlResult } from 'src/app/models/common/results/wl-result';
import { AddErrorByTokenForm } from '../components/push-mock-error-handle/models/push-mock-add-error-token-form';
import { MockErrorDictionaryModel } from '../components/push-mock-error-handle/models/push-mock-error-code';
import { PUSH_PREFIX_URL_API } from '../consts/const-urls';
import { MockPushSevice } from './push-mock-apns-list.api-client';
import {GaPagedResult} from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class MockPushApnsErrorHandlerSevice {

 constructor(private readonly _http: MockPushSevice<MockErrorDictionaryModel>) {}

  public getList(count: number, offset: number, token: string): Observable<GaPagedResult<MockErrorDictionaryModel>> {
    return this._http.getList(PUSH_PREFIX_URL_API.ApnsListErrorCodeByToken, count, offset, token);
  }

  public addErrorByToken(paramForm: AddErrorByTokenForm): Observable<WlResult<boolean>> {
    return this._http.addErrorByToken(PUSH_PREFIX_URL_API.ApnsAddErrorCodeByToken, paramForm);
  }

  public deleteErrorById(id: number): Observable<WlResult<boolean>> {
    return this._http.deleteErrorById(PUSH_PREFIX_URL_API.ApnsDeleteErrorById, id);
  }
}

