<h3>Очередь запросов на обработку кастомных турниров</h3>
<div *ngIf="loading">
  <app-loading></app-loading>
</div>
<div *ngIf="!loading">
  <table class="table table-hover mt-3">
    <thead>
    <tr class="row table-info">
      <td class="col-1">
        Id
      </td>
      <td class="col-1">
        Дата запроса
      </td>
      <td class="col-2">
        Обработан винлайном
      </td>
      <td class="col-2">
        Обработан пуш сервером
      </td>
    </tr>
    </thead>

    <tbody>
    <tr
        *ngFor="let sequence of pagedResult.results" class="row">
      <td class="col-1">
        {{sequence.id}}
      </td>
      <td class="col-1">
        {{sequence.creationDate | dateTime}}
      </td>
      <td class="col-2">
        {{sequence.processedByWinline}}
      </td>
      <td class="col-2">
        {{sequence.processedByPushServer}}
      </td>
    </tr>
    </tbody>
  </table>
  <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
