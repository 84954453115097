import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDateTime, GaMessagingService, GaPanelGridConfig, GaTableCellTemplate, GaTableData } from '@koddington/ga-common';
import { filter, take } from 'rxjs/operators';
import {
    DailyTasksErpService,
    WlErpDtPickemPromotionApiModel
} from '../../../../services/autogen/DailyTasks';
import { PickemErpViewModel } from './view-models/pickem-erp.view-model';
import { PickemErpValidator } from './validators/pickem-erp.validator';

@Component({
    selector: 'app-pickem-erp',
    templateUrl: './pickem-erp.component.html',
    styleUrls: ['./pickem-erp.component.scss']
})
export class PickemErpComponent implements OnInit {
    @ViewChild('promoTaskShow') promoTaskShow: TemplateRef<any>;
    @ViewChild('daysShow') daysShow: TemplateRef<any>;

    protected filterViewModel = new PickemErpViewModel();
    protected filterInputGrid = new GaPanelGridConfig();

    public tableData: GaTableData<WlErpDtPickemPromotionApiModel>;

    constructor(private readonly _service: DailyTasksErpService,
                private readonly _messaging: GaMessagingService,
                private readonly _validator: PickemErpValidator) {
    }

    public ngOnInit(): void {
        this.filterInputGrid
            .addNumbers(this.filterViewModel.userId, 'ID юзера')
            .addNumbers(this.filterViewModel.promoId, 'ID акции');
    }

    public load(): void {
        if (this._validator.validate(this.filterViewModel).length > 0) {
            return;
        }

        this._service.getPickemProfile(this.filterViewModel.userId.strictValue, this.filterViewModel.promoId.strictValue).pipe(
            take(1),
            filter(res => !this._messaging.tryShowError(res))
        ).subscribe(res => {
            this.mapToTable(res.result);
        });
    }

    private mapToTable(result: WlErpDtPickemPromotionApiModel): void {
        this.tableData = new GaTableData<WlErpDtPickemPromotionApiModel>()
            .setData([result])
            .addSimpleColumn((elem) => elem.id, {title: 'Id', widthSize: 60})
            .addSimpleColumn((elem) => elem.name, {title: 'Название', widthSize: 160})
            .addSimpleColumn((elem) => formatDateTime(elem.startDate), {title: 'Дата начала', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem.technicalEndDate), {title: 'Дата тех.завершения', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem.endDate), {title: 'Дата завершения', widthSize: 150})
            .addSimpleColumn((elem) => elem.currentPoints, {title: 'Очки', widthSize: 80})
            .addTemplateColumn(new GaTableCellTemplate(this.promoTaskShow, (elem) => {
                return {
                    promoTask: elem.promoTaskInfo
                };
            }), {title: 'Задание акции', widthSize: 200})
            .addTemplateColumn(new GaTableCellTemplate(this.daysShow, (elem) => {
                return {
                    days: elem.days
                };
            }), {title: 'Дни', widthSize: 900});
    }
}
