import { Routes } from '@angular/router';
import { LogoServiceItemsListComponent } from './components/logo-service-items-list/logo-service-items-list.component';
import { LogoServiceItemCrudComponent } from './components/logo-service-item-crud/logo-service-item-crud.component';
import { LogoServiceLayoutComponent } from './components/logo-service-layout/logo-service-layout.component';

const ChildrenMockRoute: Routes = [
    {
        path: 'list',
        component: LogoServiceItemsListComponent
    },
    {
        path: 'create',
        component: LogoServiceItemCrudComponent
    },
    {
        path: 'update/:id',
        component: LogoServiceItemCrudComponent
    }
];

export const LogoServiceRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'main',
                component: LogoServiceLayoutComponent,
                children: ChildrenMockRoute
            }
        ]
    }
];
