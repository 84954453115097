import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {finalize, take} from 'rxjs/operators';
import { WlRemoveUserCallApiModel } from 'src/app/services/autogen/CallCenter';
import { RemoveUserCallService } from 'src/app/services/autogen/CallCenter';
import {GaPagedResult, GaPagingForm, StrictFormControl} from '@koddington/ga-common';

@Component({
  selector: 'app-remove-call-sequences',
  templateUrl: './remove-call-sequences.component.html',
  styleUrls: ['./remove-call-sequences.component.css']
})

export class RemoveCallSequencesComponent implements OnInit, OnDestroy {

  @Input()
  public offset: StrictFormControl<number> = new StrictFormControl<number>(0);
  public loading: boolean;
  protected model: GaPagedResult<WlRemoveUserCallApiModel>;

  constructor(private readonly service: RemoveUserCallService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.load(this.offset?.strictValue);
  }

  load(offset: number): void {
    this.loading = true;
    this.service.list(offset).pipe(
      take(1),
      finalize(() => this.loading = false)
    )
      .subscribe(value => this.model = value.result);
  }

  public pageChanged(form: GaPagingForm): void {
    this.offset.strictValue = form.offset;
  }

}


