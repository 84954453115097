import {AbstractModelValidator, ValidationLevel} from '@koddington/ga-common';
import {UserViewModel} from '../../../models/users/user-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class UserValidator extends AbstractModelValidator<UserViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.name, ValidationLevel.OnSave).required().minLength(3);
    this.ruleForControl(m => m.userId, ValidationLevel.OnSave).requiredAndNotZero().min(1).maxLength(9);
    this.ruleForControl(m => m.phone, ValidationLevel.OnSave).required().minLength(3);
    this.ruleForControl((m) => m.monetaryClass, ValidationLevel.OnSave).required();
    this.ruleForControl((m) => m.stage, ValidationLevel.OnSave).required();
  }
}
