import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {GaCommonModule} from '@koddington/ga-common';
import {RateRaceLayoutComponent} from './components/rate-race-layout/rate-race-layout.component';
import {RateRaceExchangeSequencesComponent} from './components/rate-race-exchange-sequences/rate-race-exchange-sequences.component';
import {RateRaceRoutes} from './rate-race-routes';
import {RateRaceExchangeRequestsComponent} from './components/rate-race-exchange-requests/rate-race-exchange-requests.component';
import { RateRaceUsersHistoriesComponent } from './components/rate-race-users-histories/rate-race-users-histories.component';
import { RateRaceResultsListComponent } from './components/rate-race-results-list/rate-race-results-list.component';
import { RateRaceResultsCrudComponent } from './components/rate-race-results-crud/rate-race-results-crud.component';
import { BattlePassAreaCodegenAppModule } from '../../services/autogen/BattlePass';


@NgModule({
    declarations: [
        RateRaceExchangeSequencesComponent,
        RateRaceLayoutComponent,
        RateRaceExchangeRequestsComponent,
        RateRaceUsersHistoriesComponent,
        RateRaceResultsListComponent,
        RateRaceResultsCrudComponent
    ],
  exports: [
    RateRaceExchangeSequencesComponent,
    RateRaceLayoutComponent,
    RateRaceExchangeRequestsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(RateRaceRoutes),
    SharedModule,
    GaCommonModule,
    BattlePassAreaCodegenAppModule
  ],
  providers: []
})
export class RateRaceModule {
}
