<div>
    <h3>Таблицы результатов Rate Race</h3>
    <div *ngIf="loading">
        <app-loading></app-loading>
    </div>
    <button class="btn btn-primary mx-2" (click)="create()">Добавить таблицу</button>
    <div class="row">
        <app-strict-dropdown class="col-3"
                             [control]="pagingViewModel.isPublished"
                             [strategy]="isPublishedDropdownStrategy"
                             [options]="getPublishedStrictOptions"
                             [title]="'Опубликованные'"
        ></app-strict-dropdown>
        <app-strict-dropdown class="col-3"
                             [control]="pagingViewModel.isHandled"
                             [strategy]="isHandledDropdownStrategy"
                             [options]="getHandledStrictOptions"
                             [title]="'Обработанные'"
        ></app-strict-dropdown>
        <app-strict-input-number class="col-3"
                                 [control]="pagingViewModel.battlePassSeasonId"
                                 [placeholder]="'ID сезона БП'"
                                 [min]="1"
                                 [step]="1"
        ></app-strict-input-number>
        <span class="col-2"><button class="btn btn-primary" (click)="search()">Поиск</button></span>
    </div>
    <div *ngIf="!loading">
        <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
        <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
    </div>
</div>

<ng-template #elemActions let-id="id" let-canPublish="canPublish">
    <button *ngIf="canPublish"
            class="btn btn-success mx-2"
            (click)="edit(id)">
        Изменить
    </button>
    <button *ngIf="canPublish"
            class="btn btn-primary mx-2"
            (click)="publish(id)">
        Опубликовать
    </button>
    <button class="btn btn-warning mx-2"
            (click)="repeat(id)">
        Повторить
    </button>
</ng-template>
