<div class="card card-body border-info">
  <div class="row">
    <h5 class="col-2">Идентификатор пользователя</h5>
    <div class="col-4">
      <input type="number"
             class="form-control"
             [matAutocomplete]="autoUserIds"
             [formControl]="viewModel.userId"
             [class.is-invalid]="viewModel.userId.shouldDisplayErrors | async"
             placeholder='Id пользователя'/>

      <div class="invalid-feedback">
        <li *ngFor="let error of viewModel.userId.strictErrors | async">
          {{error.message}}
        </li>
      </div>
    </div>
    <div class="row">
      <h5 class="col-6">Порядок дат ставок</h5>
      <div>
      <app-enum-dropdown [entityContainer]="dateStatus" [strategy]="dateOrderStrategy" [options]="options"></app-enum-dropdown>
      </div>
    </div>
    <div class="col-3">
      <button class="btn btn-primary" style="background-color: #BEE5EB; color: black" (click)="search()">
        Поиск
      </button>
    </div>
  </div>
  <div class="col-4">
    <mat-autocomplete class="wl-test-templates-autocomplete" #autoUserIds="matAutocomplete"
                      (optionSelected)="userIdSelected($event.option.value)">
      <mat-option *ngFor="let userId of userIdsForAutocomplete" [value]="userId">
        {{userId}}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
<hr/>

