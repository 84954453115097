import {Component, OnInit} from '@angular/core';
import {WlResult} from '../../../../models/common/results/wl-result';
import {AddBattlePassQuestProgressValidator} from '../../../events/validators/add-battle-pass-quest-progress-validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {filter, take} from 'rxjs/operators';
import {AddBattlePassQuestProgressViewModel} from '../../../../models/events/components-models/add-battle-pass-quest-progress-view-model';
import { AddBattlePassQuestProgressForm } from 'src/app/services/autogen/BattlePass';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-add-battle-pass-quest-progress',
  templateUrl: './add-battle-pass-quest-progress.component.html',
  styleUrls: ['./add-battle-pass-quest-progress.component.css']
})

export class AddBattlePassQuestProgressComponent implements OnInit {

  public readonly model: AddBattlePassQuestProgressViewModel = new AddBattlePassQuestProgressViewModel();
  private result: WlResult<boolean>;

  constructor(private readonly service: BattlePassService,
              private readonly validator: AddBattlePassQuestProgressValidator,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
  }

  public add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new AddBattlePassQuestProgressForm();
    form.subscriptionId = this.model.subscriptionId.strictValue;
    form.currentProgress = this.model.currentProgress.hasStrictValue ? this.model.currentProgress.strictValue : null;
    form.operationDate = this.model.operationDate.strictValue;

    this.service
      .addUserQuestProgress(form)
      .pipe(
        take(1),
        filter(u => !this.messaging.tryShowError(u)),
        untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Прогресс успешно добавлен');
      });
  }
}
