import { Component, OnInit, Injectable, ViewChild, TemplateRef } from '@angular/core';
import {Observable} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {take, finalize, map, switchMap} from 'rxjs/operators';
import { MockPushHistoryListForm, MockApnsPushModel } from '../../../../services/autogen/PushGate';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from 'src/app/modules/shared/services/search-navigation.service';
import {PushListViewModel} from './models/push-mock-list.viewmodel';
import { formatDateTime, GaPagedResult, GaPagingForm, GaTableCellTemplate, GaTableData, isNullOrUndefined } from '@koddington/ga-common';
import { MockApnsPushService } from '../../../../services/autogen/PushGate';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-push-mock-list',
  templateUrl: './push-mock-list.component.html',
  styleUrls: ['./push-mock-list.component.css']
})
export class PushMockListComponent implements OnInit {
  @ViewChild('showPayload') showPayload: TemplateRef<any>;
  public tableData: GaTableData<MockApnsPushModel>;
  public pagedResult: GaPagedResult<MockApnsPushModel> = new GaPagedResult<MockApnsPushModel>();
  public loading: boolean;
  public model: PushListViewModel = new PushListViewModel();
  private defaultCount = 25;
  private defaultOffset = 0;

  constructor(private readonly _httpClient: MockApnsPushService,
              private readonly _navigation: SearchNavigationService,
              private readonly _activeRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._activeRoute.queryParams.pipe(
      map((params) => this.initModel(params)),
      map(() => this.createForm()),
      switchMap((paging) => this.load(paging)),
      untilDestroyed(this)
    ).subscribe(data => {
      this.pagedResult = data;
      this.tableData = this.mapTable(data.results);
    });
  }

  public pageChanged(form: GaPagingForm): void {
    this.model.offset.strictValue = form.offset;
    this.search();
  }

  private load(form: MockPushHistoryListForm): Observable<GaPagedResult<MockApnsPushModel>> {
    this.loading = true;
    return this._httpClient.getList(form).pipe(
      take(1),
      finalize(() => this.loading = false),
      untilDestroyed(this)
    );
  }

  private createForm(): MockPushHistoryListForm {
    const form = new MockPushHistoryListForm();
    form.offset = this.model.offset.hasStrictValue ? this.model.offset.strictValue : this.defaultOffset;
    form.count = this.model.count.hasStrictValue ? this.model.count.strictValue : this.defaultCount;
    form.token = this.model.token.hasStrictValue ? this.model.token.strictValue : null;
    return form;
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : null;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : null;
    this.model.token.strictValue = !isNullOrUndefined(params['token']) ?
      params['token'] : null;
  }

  public search(): void {
    const params: Params = {
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : this.defaultOffset,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : this.defaultCount,
      token: this.model.token.hasStrictValue ? this.model.token.strictValue : null,
    };
    this._navigation.search(this._activeRoute, params);
  }

  private mapTable(data: MockApnsPushModel[]): GaTableData<MockApnsPushModel> {
    return  new GaTableData<MockApnsPushModel>()
        .addSimpleColumn((elem) => elem.id, { title: 'Id' })
        .addSimpleColumn((elem) => elem.title, { title: 'Title' })
        .addSimpleColumn((elem) => elem.message, { title: 'Message' })
        .addSimpleColumn((elem) => elem.bage, { title: 'Bage' })
        .addSimpleColumn((elem) => elem.sound, { title: 'Sound' })
        .addSimpleColumn((elem) => elem.mutableContent, { title: 'MutableContent' })
        .addSimpleColumn((elem) => elem.contentAvailable, { title: 'ContentAvailable' })
        .addSimpleColumn((elem) => elem.token, { title: 'Token' })
        .addSimpleColumn((elem) => formatDateTime(elem.dateTimeCreate), { title: 'Create date time' })
        .addTemplateColumn(new GaTableCellTemplate(this.showPayload, elem => {
          return {
            payload: JSON.parse(elem.payload)
          };
        }), {title: 'Payloads', widthSize: 300})
        .setData(data);
  }
}


