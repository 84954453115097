<div class="col-4">
  <h1>Ошибки верификации документов</h1>

  <div class="row">
    <app-strict-input-number class="col-6" [control]="model.userId"
                             [placeholder]="'Id пользователя'"></app-strict-input-number>
    <span class="col-6">
      <button class="btn btn-primary" (click)="search(model.userId.strictValue)">Поиск</button>
    </span>
  </div>
  <table class="table mt-3">
    <thead>
    <tr>
      <th scope="col">User id</th>
      <th scope="col">Ошибки загрузки паспорта</th>
      <th scope="col">Ошибки загрузки селфи</th>
      <th scope="col">Ошибок всего</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="!!errors">
      <th>{{errors.userId}}</th>
      <td>{{errors.passportErrorsCount}}</td>
      <td>{{errors.selfiErrorsCount}}</td>
      <td>{{errors.passportErrorsCount + errors.selfiErrorsCount}}</td>
    </tr>
    </tbody>
  </table>
</div>
