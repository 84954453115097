import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlResult } from '../../../../models/common/results/wl-result';
import { AddUserScoreForm, EventsService } from 'src/app/services/autogen/Events';
import {GaMessagingService} from '@koddington/ga-common';
import { AddScoreUpdateViewModel } from '../../../../models/events/components-models/add-score-update-view-model';
import { AddScoreUpdateValidator } from '../../validators/add-score-update-validator';

@UntilDestroy()
@Component({
  selector: 'app-add-user-score-update',
  templateUrl: './add-user-score-update.component.html',
  styleUrls: ['./add-user-score-update.component.css']
})

export class AddUserScoreUpdateComponent implements OnInit {

  public readonly model: AddScoreUpdateViewModel = new AddScoreUpdateViewModel();
  private result: WlResult<boolean>;

  constructor(private readonly service: EventsService,
              private readonly validator: AddScoreUpdateValidator,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
  }

  public add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new AddUserScoreForm();
    form.userId = this.model.userId.strictValue;
    form.operationDate = this.model.operationDate.strictValue;
    form.score = this.model.score.hasStrictValue ? this.model.score.strictValue : null;
    form.comment = this.model.comment.hasStrictValue ? this.model.comment.strictValue : null;

    this.service.addUserScoreUpdateEvent(form).pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Значение Score успешно обновленно');
      });
  }

}
