import {IDropdownStrategy} from './i-dropdown-strategy';
import {WlXfiftyStates, WlXfiftyStatesExtensions } from '../../../../../services/autogen/Events';
import {Observable, of} from 'rxjs';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Injectable} from '@angular/core';
import {EnumExtensions} from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class X50TourStatesDropdownStrategy implements IDropdownStrategy<WlXfiftyStates> {

  convert(model: WlXfiftyStates): DropdownItem<WlXfiftyStates> {
    const entity = new DropdownItem<WlXfiftyStates>();
    entity.entity = model;
    entity.id = Number(model).toString();
    entity.title = WlXfiftyStatesExtensions.format(model);

    return entity;
  }

  getEntities(): Observable<Array<WlXfiftyStates>> {
    return of(EnumExtensions.toArray(WlXfiftyStates));
  }

}
