<div>
  <h3>Список событий обзовна</h3>
  <div>
    <app-strict-input-number [control]="model.userId" [placeholder]="'Id пользователя'"></app-strict-input-number>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </div>
  <div *ngIf="result && result.results">
    <div class="row mt-3">
      <div class="col-1">Id пользователя</div>
      <div class="col-1">Дата изменения статуса</div>
      <div class="col-1">Операция колл-центра</div>
      <div class="col-1">Результат обзвона</div>
      <div class="col-1">Очередь КЦ</div>
    </div>
    <hr>
    <div class="row" *ngFor="let item of result.results">
      <div class="col-1">
        {{item.userId}}
      </div>
      <div class="col-1">
        {{item.eventChangeDate | moscowDateTime}}
      </div>
      <div class="col-1">
        {{item.callOperation | callOperationTypesTransform}}
      </div>
      <div class="col-1">
        {{item.callResult | callResultsTypesTransform}}
      </div>
      <div class="col-1">
        {{item.callQueue | callQueueTypesTransform}}
      </div>
    </div>
  </div>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
