import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {UserListViewModel} from '../../../../models/users/user-list-view-model';
import {BonusClubRewardService } from '../../../../services/autogen/Users';
import { WlBonusClubReward } from 'src/app/services/autogen/Users';
import { UsersWithRewardsForm } from 'src/app/services/autogen/Users';
import { GaPagedResult, GaPagingForm, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'app-user-bonus-club-rewards',
  templateUrl: './user-bonus-club-rewards.component.html',
  styleUrls: ['./user-bonus-club-rewards.component.css']
})

export class UserBonusClubRewardsComponent implements OnInit {

  public model: UserListViewModel = new UserListViewModel();
  public result: GaPagedResult<WlBonusClubReward>;
  public bonusClubRewards: WlBonusClubReward[] = [];
  public issueDateForms: IssueDateForm[] = [];

  constructor(private readonly service: BonusClubRewardService) {
  }

  ngOnInit(): void {
  }

  search(): void {
    const form = this.createForm();
    if (this.model.userId.hasStrictValue) {
      this.load(form);
    }
  }

  load(form: UsersWithRewardsForm): void {
    this.service.list(form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.createControls(this.result.results);
        this.bonusClubRewards = this.result.results;
      });
  }

  createForm(): UsersWithRewardsForm {
    const form = new UsersWithRewardsForm();
    form.userId = this.model.userId.strictValue;
    form.count = this.model.count.strictValue;
    form.offset = this.model.offset.strictValue;

    return form;
  }

  pageChanged(form: GaPagingForm): void {
    this.model.offset.strictValue = form.offset;
    this.load(this.createForm());
  }

  update(id: number): void {
    const issueDate = this.issueDateForms.filter(u => u.id === id)[0];
    if (!issueDate.issueDate.hasStrictValue) {
      return;
    }

    const bonusClubReward = this.bonusClubRewards.filter(u => u.id === id)[0];
    bonusClubReward.issueDate = issueDate.issueDate.strictValue;

    this.service.update(bonusClubReward)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.search();
      });
  }

  remove(id: number): void {
    this.service.remove(id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.search();
      });
  }

  getControl(id: number): StrictFormControl<Dayjs> {
    return this.issueDateForms.filter(value => value.id === id)[0]?.issueDate;
  }

  private createControls(rewards: WlBonusClubReward[]): void {
    this.issueDateForms = [];
    rewards.forEach(value => {
      const issueDateForm = new IssueDateForm();
      issueDateForm.id = value.id;
      issueDateForm.issueDate = StrictFormControlBuilder.buildNullDayjs();
      this.issueDateForms.push(issueDateForm);
    });
  }
}

@UntilDestroy()
export class IssueDateForm {
  id: number;
  issueDate: StrictFormControl<Dayjs>;
}
