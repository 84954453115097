import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class CupisChangesEventViewModel {
  public userId = StrictFormControlBuilder.buildNullNumber();
  public state = StrictFormControlBuilder.buildNullNumber();
  public withdrawalApprovedState = StrictFormControlBuilder.buildNullNumber();
  public hasSnils = StrictFormControlBuilder.buildNullNumber();
  public eventDate = StrictFormControlBuilder.buildNullDayjs();
  public registrationStateChangedAt = StrictFormControlBuilder.buildNullDayjs();
}
