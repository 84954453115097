import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDateTime, GaMessagingService, GaTableCellTemplate, GaTableData, StrictFormControl } from '@koddington/ga-common';
import { filter, take } from 'rxjs/operators';
import { WlErpBattlePassApiModel, WlErpBattlePassSeasonApiModel } from 'src/app/services/autogen/BattlePass';
import { BattlePassErpService } from '../../../../services/autogen/BattlePass';

@Component({
    selector: 'app-battle-pass-erp',
    templateUrl: './battle-pass-erp.component.html'
})
export class BattlePassErpComponent implements OnInit {
    @ViewChild('levelsShow') levelsShow: TemplateRef<any>;

    public userId: StrictFormControl<number>;
    public tableData: GaTableData<WlErpBattlePassSeasonApiModel>;

    constructor(private readonly _service: BattlePassErpService,
                private readonly _messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.userId = new StrictFormControl<number>(0);
    }

    public load(): void {
        this._service.getProfile(this.userId.strictValue).pipe(
            take(1),
            filter(res => !this._messaging.tryShowError(res))
        ).subscribe(res => {
            this.mapToTable(res.result);
        });
    }

    private mapToTable(result: WlErpBattlePassApiModel) {
        this.tableData = new GaTableData<WlErpBattlePassSeasonApiModel>()
            .setData(result.seasons)
            .addSimpleColumn((elem) => elem.id, { title: 'Id', widthSize: 60 })
            .addSimpleColumn((elem) => elem.name, { title: 'Название', widthSize: 160 })
            .addSimpleColumn((elem) => elem.description, { title: 'Описание', widthSize: 280 })
            .addSimpleColumn((elem) => elem.is_active ? 'Да' : 'Нет', { title: 'Активен', widthSize: 100 })
            .addSimpleColumn((elem) => formatDateTime(elem.start_date), { title: 'Дата начала', widthSize: 150 })
            .addSimpleColumn((elem) => formatDateTime(elem.registration_date), { title: 'Дата регистрации', widthSize: 150 })
            .addSimpleColumn((elem) => formatDateTime(elem.end_date), {title: 'Тех.завершение', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem.payout_date), {title: 'Факт.завершение', widthSize: 150})
            .addSimpleColumn((elem) => elem.current_level, { title: 'Уровень', widthSize: 100 })
            .addTemplateColumn(new GaTableCellTemplate(this.levelsShow, (elem) => {
                return {
                    levels: elem.levels
                };
            }), {title: 'Уровни', widthSize: 1600});
    }
}
