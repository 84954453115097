import {Component, OnInit} from '@angular/core';
import {AddX50TourValidator} from '../validators/add-x50-tour-validator';
import {AddX50TourViewModel} from '../../../models/tours/add-x50-tour-view-model';
import { AddXfiftyTourForm } from 'src/app/services/autogen/Events';
import {filter, first, map} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {X50TourStatesDropdownStrategy} from '../../shared/common/enum-dropdown/strategies/x50-tour-states.dropdown-strategy';
import {GaMessagingService} from '@koddington/ga-common';
import { EventsService, ToursService } from '../../../services/autogen/Events';

@UntilDestroy()
@Component({
  selector: 'app-add-x50-tour',
  templateUrl: './add-x50-tour.component.html',
  styleUrls: ['./add-x50-tour.component.css']
})
export class AddX50TourComponent implements OnInit {
  private validator = new AddX50TourValidator();
  public viewModel = new AddX50TourViewModel();
  constructor(private readonly eventsService: EventsService,
              private readonly toursService: ToursService,
              private readonly messaging: GaMessagingService,
              readonly strategy: X50TourStatesDropdownStrategy) {
  }

  ngOnInit(): void {
    this.loadLastTourId();
  }

  save(): void {
    if (!this.validator.isValid(this.viewModel)) {
      return;
    }
    const form = new AddXfiftyTourForm();
    form.id = this.viewModel.id.strictValue;
    form.firstBetDate = this.viewModel.startDate.strictValue;
    form.name = this.viewModel.name.strictValue;
    form.results = this.viewModel.results.strictValue;
    form.status = this.viewModel.status.strictValue;

    this.eventsService.addOrUpdateXfiftyTour(form).pipe(
      filter(value => !this.messaging.tryShowError(value)),
      untilDestroyed(this))
      .subscribe(() => this.messaging.showMessage('Тур успешно добавлен'));
  }

  loadLastTourId(): void {

    this.toursService.getLastTourIdFromPushServer().pipe(
      first(),
      filter(value => !this.messaging.tryShowError(value)),
      map(value => this.viewModel.lastTourId.strictValue = value.result),
      untilDestroyed(this)
    ).subscribe();
  }
}
