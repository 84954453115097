export enum WlBattlePassRewardResponseErrorCodes {
    TooMuchRejects = 1,
    ItemNotFound = 2,
    UserBanned = 3,
    UserTradesClosed = 4,
    SteamUnavailable = 5,
    TradeTimeout = 6,
    BadTradeLink = 7,
    UnknownError = 8,
    BalanceIsOut = 9,
    ItemOutOfStock = 10,
    ItemRetry = 11,
    TradeTimeOutSeller = 12,
}

export namespace WlBattlePassRewardResponseErrorCodesExtensions {
    export function toRabbitMessage(code: WlBattlePassRewardResponseErrorCodes): string {
        switch (code) {
            case WlBattlePassRewardResponseErrorCodes.TooMuchRejects:
                return '1';
            case WlBattlePassRewardResponseErrorCodes.ItemNotFound:
                return 'ITEM_NOTFOUND';
            case WlBattlePassRewardResponseErrorCodes.UserBanned:
                return 'USER_BANNED';
            case WlBattlePassRewardResponseErrorCodes.UserTradesClosed:
                return 'USER_TRADES_CLOSED';
            case WlBattlePassRewardResponseErrorCodes.SteamUnavailable:
                return 'STEAM_UNAVAILABLE';
            case WlBattlePassRewardResponseErrorCodes.TradeTimeout:
                return 'TRADE_TIMEOUT';
            case WlBattlePassRewardResponseErrorCodes.BadTradeLink:
                return 'TRADELINK_BAD';
            case WlBattlePassRewardResponseErrorCodes.UnknownError:
                return 'UNKNOWN';
            case WlBattlePassRewardResponseErrorCodes.BalanceIsOut:
                return 'BALANCE_ISOUT';
            case WlBattlePassRewardResponseErrorCodes.ItemOutOfStock:
                return 'ITEM_OUTOFSTOCK';
            case WlBattlePassRewardResponseErrorCodes.ItemRetry:
                return 'ITEM_RETRY';
            case WlBattlePassRewardResponseErrorCodes.TradeTimeOutSeller:
                return 'TRADE_TIMEOUT_SELLER';
        }
    }

    export function toFriendlyString(code: WlBattlePassRewardResponseErrorCodes): string {
        switch (code) {
            case WlBattlePassRewardResponseErrorCodes.TooMuchRejects:
                return 'Слишком большое число отказов от предмета';
            case WlBattlePassRewardResponseErrorCodes.ItemNotFound:
                return 'Переданная вещь не найдена в базе';
            case WlBattlePassRewardResponseErrorCodes.UserBanned:
                return 'Юзер забанен на маркете';
            case WlBattlePassRewardResponseErrorCodes.UserTradesClosed:
                return 'У юзера закрыт инвентарь стима';
            case WlBattlePassRewardResponseErrorCodes.SteamUnavailable:
                return 'Steam недоступен в данный момент';
            case WlBattlePassRewardResponseErrorCodes.TradeTimeout:
                return 'Юзер не принял трейд вовремя';
            case WlBattlePassRewardResponseErrorCodes.BadTradeLink:
                return 'Переданный трейдлинк не существует';
            case WlBattlePassRewardResponseErrorCodes.UnknownError:
                return 'Неизвестная ошибка';
            case WlBattlePassRewardResponseErrorCodes.BalanceIsOut:
                return 'Закончился баланс в альфаскинах';
            case WlBattlePassRewardResponseErrorCodes.ItemOutOfStock:
                return 'Вещь закончилась на маркете';
            case WlBattlePassRewardResponseErrorCodes.ItemRetry:
                return 'При закупке возникла проблема маркета, нужно заново отправить на закупку';
            case WlBattlePassRewardResponseErrorCodes.TradeTimeOutSeller:
                return 'Селлер не отправил трейд вовремя';
        }
    }
}
