import {AbstractModelValidator} from '@koddington/ga-common';
import {ChangeBonusExpDateViewModel} from '../../../models/mechanics/change-bonus-exp-date-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class ChangeBonusExpirationDateValidator extends AbstractModelValidator<ChangeBonusExpDateViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.newDate).required();
  }
}

