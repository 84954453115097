<div>
  <h3>
    Туры
  </h3>
</div>
<div>
  <ul>
    <li (click)="selectTour('X50Events')">События тура</li>
    <li (click)="selectTour('X50Tours')">Список туров X50</li>
    <li (click)="selectTour('X50Bets')">Список ставок X50</li>
  </ul>
</div>
<div>
  <app-x50-events *ngIf="isX50EventsAllowed"></app-x50-events>
  <app-x50-tours-list *ngIf="isX50ToursAllowed"></app-x50-tours-list>
  <app-x50-bets-list *ngIf="isX50BetsAllowed"></app-x50-bets-list>
</div>
