import { WlSmsErrorCodes } from 'src/app/services/autogen/SmsGate';
import { WlSmsTrafficConversionCodes } from 'src/app/services/autogen/SmsGate';
import { WlMtsCommunicatorConversionCodes } from 'src/app/services/autogen/SmsGate';
import { WlMockPhone } from 'src/app/services/autogen/SmsGate';
import { Dayjs } from 'dayjs';
import { WlMockSmsGateAccount } from 'src/app/services/autogen/SmsGate';
import { WlMockSmsDetails } from 'src/app/services/autogen/SmsGate';
import {StrictFormControl} from '@koddington/ga-common';

export class SentMessageStateViewModel {
  public id: number;
  public text: string;
  public createdAt: Dayjs;
  public phone: WlMockPhone;
  public account: WlMockSmsGateAccount;

  public sendingCode: WlSmsErrorCodes;
  public smsTrafficConversionCode: StrictFormControl<WlSmsTrafficConversionCodes>;
  public mtsCommunicatorConversionCode: StrictFormControl<WlMtsCommunicatorConversionCodes>;

  constructor(sms: WlMockSmsDetails) {
    this.id = sms.id;
    this.text = sms.text;
    this.createdAt = sms.createdAt;
    this.phone = sms.phone;
    this.account = sms.account;
    this.sendingCode = sms.state.sendingCode;

    this.mtsCommunicatorConversionCode = new StrictFormControl<WlMtsCommunicatorConversionCodes>(sms.state.mtsCommunicatorConversionCode);
    this.smsTrafficConversionCode = new StrictFormControl<WlSmsTrafficConversionCodes>(sms.state.smsTrafficConversionCode);
  }
}
