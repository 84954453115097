import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class ChannelChangeViewModel {
  userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  emailRestricted: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  smsRestricted: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();

  emailRestrictedDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  smsRestrictedDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
}

