import {Component, OnInit} from '@angular/core';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import {GaMessagingService, GaPagedResult, GaPagingForm} from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlBattlePassRewardListModel } from 'src/app/services/autogen/BattlePass';
import {WlBattlePassChangeRewardViewModel} from '../../../../models/battle-pass/wl-battle-pass-change-reward-view-model';
import { ChangeRewardDictionaryForm } from 'src/app/services/autogen/BattlePass';
import {filter} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-battle-pass-reward-dictionary-list',
  templateUrl: './battle-pass-reward-dictionary-list.component.html',
  styleUrls: ['./battle-pass-reward-dictionary-list.component.css']
})
export class BattlePassRewardDictionaryListComponent implements OnInit {

  public result: GaPagedResult<WlBattlePassRewardListModel>;
  public viewModel = new WlBattlePassChangeRewardViewModel;
  constructor(private readonly _battlePassService: BattlePassService,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
    this.load();
  }

  public pageChanged(form: GaPagingForm): void {
    this.load(form.offset);
  }

  public change(rewardId: number): void {
    const reward = this.result.results
      .filter((value) => value.id === rewardId)[0];

    const changeModel = new WlBattlePassChangeRewardViewModel;

    changeModel.id.strictValue = reward.id;
    changeModel.name.strictValue = reward.name;
    changeModel.description.strictValue = reward.description;
    changeModel.marketPrice.strictValue = reward.marketPrice;
    changeModel.hashName.strictValue = reward.hashName;
    changeModel.imageUrl.strictValue = reward.imageUrl;
    changeModel.game.strictValue = reward.game;
    changeModel.type.strictValue = reward.type;
    changeModel.group.strictValue = reward.group;
    changeModel.category.strictValue = reward.category;
    changeModel.scarcity.strictValue = reward.scarcity;
    changeModel.scarValue.strictValue = reward.scarValue;
    changeModel.quality.strictValue = reward.quality;

    this.viewModel = changeModel;
  }

  public send(): void {
    this._battlePassService.changeReward(this.createForm())
      .pipe(
        filter(u => !this.messaging.tryShowError(u)),
        untilDestroyed(this))
      .subscribe(u => this.messaging.showMessage('Изменение отправленно'));
  }

  private load(offset: number = 0): void {
    const paging = new GaPagingForm();
    paging.offset = offset;

    this._battlePassService.listRewards(paging)
      .pipe(untilDestroyed(this))
      .subscribe(res => this.result = res);
  }

  private createForm(): ChangeRewardDictionaryForm {
    const form = new ChangeRewardDictionaryForm();

    form.id = this.viewModel.id.strictValue;
    form.name = this.viewModel.name.strictValue;
    form.description = this.viewModel.description.strictValue;
    form.marketPrice = this.viewModel.marketPrice.strictValue;
    form.hashName = this.viewModel.hashName.strictValue;
    form.imageUrl = this.viewModel.imageUrl.strictValue;
    form.game = this.viewModel.game.strictValue;
    form.type = this.viewModel.type.strictValue;
    form.group = this.viewModel.group.strictValue;
    form.category = this.viewModel.category.strictValue;
    form.scarcity = this.viewModel.scarcity.strictValue;
    form.scarValue = this.viewModel.scarValue.strictValue;
    form.quality = this.viewModel.quality.strictValue;

    return form;
  }
}
