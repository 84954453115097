import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { WlBetTypes } from 'src/app/services/autogen/Events';
import {WlBetEventViewModel} from './models/wl-bet-event-view-model';
import {BetPeriodsStrategy} from '../enum-dropdown/strategies/bet-periods.strategy';
import {StrictError, StrictFormControl} from '@koddington/ga-common';

@Component({
  selector: 'app-wl-bet-event',
  templateUrl: './wl-bet-event.component.html',
  styleUrls: ['./wl-bet-event.component.css']
})
export class WlBetEventComponent implements OnInit, OnDestroy {
  @Input() controlEvent: WlBetEventViewModel;
  @Input() betType: StrictFormControl<WlBetTypes>;
  @Input() length: number;
  @Input() index: number;
  @Output() changeRate = new EventEmitter<void>();
  @Output() add = new EventEmitter<void>();
  @Output() del = new EventEmitter<void>();
  public errors: StrictError[];

  constructor(public readonly _betPeriodStrategy: BetPeriodsStrategy) {
  }

  ngOnInit() {
  }

  get showPanelButton(): boolean {
    if (!this.betType.hasStrictValue) {
      return false;
    }

    if (this.betType.strictValue === WlBetTypes.Ordinar) {
      return false;
    }

    return true;
  }

  get showAddButton(): boolean {
    if (this.length <= 1) {
      return true;
    }

    if (this.length - 1 <= this.index) {
      return true;
    }

    return false;
  }

  get showDelButton(): boolean {
    if (this.length > 1) {
      return true;
    }

    return false;
  }

  addClick() {
    this.add.emit();
  }

  delClick() {
    this.del.emit();
  }

  ngOnDestroy() {
  }
}
