import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LogoServiceItemsListComponent } from './components/logo-service-items-list/logo-service-items-list.component';
import { LogoServiceItemCrudComponent } from './components/logo-service-item-crud/logo-service-item-crud.component';
import { LogoServiceRoutes } from './logo-service-routes';
import { LogoServiceLayoutComponent } from './components/logo-service-layout/logo-service-layout.component';
import { SharedModule } from '../shared/shared.module';
import { GaCommonModule } from '@koddington/ga-common';
import { LogoServiceAreaCodegenAppModule } from '../../services/autogen/LogoService';


@NgModule({
  declarations: [
    LogoServiceItemsListComponent,
    LogoServiceItemCrudComponent,
    LogoServiceLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(LogoServiceRoutes),
    SharedModule,
    GaCommonModule,
    LogoServiceAreaCodegenAppModule
  ]
})
export class LogoServiceModule { }
