<div>
  <label class="mt-4">Добавление события "Загрузка селфи"</label>

  <div>
    <div class="row mt-2">
      <div class="col-2">
        Id пользователя
      </div>
      <div class="col-3">
        Укажите дату загрузки селфи
      </div>
      <div class="col-3">
        Укажите дату загрузки документа
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <app-strict-input-number [control]="userId" [placeholder]="'Id пользователя'">
        </app-strict-input-number>
      </div>
      <div class="col-3">
        <strict-date-time-picker [control]="selfieDate"></strict-date-time-picker>
      </div>
      <div class="col-3">
        <strict-date-time-picker [control]="docDate"></strict-date-time-picker>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
  </div>
</div>
