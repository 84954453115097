import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';


export class MechanicsListViewModel {
  mechanicId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  userId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  offset: StrictFormControl<number> = new StrictFormControl<number>(0);
  count: StrictFormControl<number> = new StrictFormControl<number>(30);

}
