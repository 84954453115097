import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {finalize, take} from 'rxjs/operators';
import { CallSequencesService } from 'src/app/services/autogen/CallCenter';
import { WlCallSequencesInternalModel } from 'src/app/services/autogen/CallCenter';
import { WlCallSequencesType } from 'src/app/services/autogen/CallCenter';
import {GaPagedResult, GaPagingForm, StrictFormControl} from '@koddington/ga-common';

@Component({
  selector: 'app-call-sequences',
  templateUrl: './call-sequences.component.html',
  styleUrls: ['./call-sequences.component.css']
})
export class CallSequencesComponent implements OnInit, OnDestroy {

  @Input()
  public offset: StrictFormControl<number> = new StrictFormControl<number>(0);
  @Input()
  public sequencesType: WlCallSequencesType;

  public loading: boolean;
  protected model: GaPagedResult<WlCallSequencesInternalModel>;

  constructor(private readonly service: CallSequencesService) {
  }

  ngOnInit(): void {
    this.load(0);
  }

  load(offset: number): void {
    this.loading = true;
    this.service.list(offset, this.sequencesType).pipe(
      take(1),
      finalize(() => this.loading = false)
    )
      .subscribe(value => this.model = value.result);
  }

  ngOnDestroy(): void {
  }

  public pageChanged(form: GaPagingForm): void {
    this.offset.strictValue = form.offset;
  }

  get listRoute(): string {
    switch (this.sequencesType) {
      case WlCallSequencesType.AddUserInCallQueue:
        return '/dashboard/calls/record-user-call-list';
      case WlCallSequencesType.RemoveUserFromCallQueue:
        return '/dashboard/calls/remove-user-call-list';
      case WlCallSequencesType.UserResponseQueue:
        return '/dashboard/calls/response-call-list';
    }
  }
}
