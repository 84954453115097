import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {finalize, take} from 'rxjs/operators';
import { UserCallResponseService } from 'src/app/services/autogen/CallCenter';
import { WlUserCallRecordApiModel } from 'src/app/services/autogen/CallCenter';
import {GaPagedResult, GaPagingForm, StrictFormControl} from '@koddington/ga-common';

@Component({
  selector: 'app-response-user-call-list',
  templateUrl: './response-user-call-list.component.html',
  styleUrls: ['./response-user-call-list.component.css']
})

export class ResponseUserCallListComponent implements OnInit, OnDestroy {

  @Input()
  public offset: StrictFormControl<number> = new StrictFormControl<number>(0);
  public loading = true;
  public items: GaPagedResult<WlUserCallRecordApiModel>;

  constructor(private _location: Location,
              private readonly _api: UserCallResponseService,
              private readonly _route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.load(this.offset?.strictValue);
  }

  load(offset = 0): void {
    this.loading = true;
    this._api.list(offset).pipe(
      take(1),
      finalize(() => this.loading = false)
    ).subscribe(value => this.items = value);
  }

  ngOnDestroy(): void {
  }


  backClicked() {
    this._location.back();
  }

  public pageChanged(form: GaPagingForm): void {
    this.offset.strictValue = form.offset;
  }
}
