import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { WlCouponsTypes } from 'src/app/services/autogen/Dictionaries';

export class CouponViewModel {
  couponId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  beginDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  daysUntilExpired: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  expirationDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  type: StrictFormControl<WlCouponsTypes> = StrictFormControlBuilder.buildNullNumber<WlCouponsTypes>();
}
