import {AbstractModelValidator} from '@koddington/ga-common';
import {MechanicBonusUsingRequestStrictViewModel} from '../../../models/mechanics/mechanic-bonus-using-request-strict-view.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MechanicBonusUsingRequestViewModelValidator extends AbstractModelValidator<MechanicBonusUsingRequestStrictViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.newStatus).required();
  }
}
