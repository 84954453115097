import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-tours-layout',
  templateUrl: './tours-layout.component.html',
  styleUrls: ['./tours-layout.component.css']
})
export class ToursLayoutComponent implements OnInit, OnDestroy {

  private tourName: string;
  constructor() { }

  ngOnInit(): void {
  }
  ngOnDestroy() {
  }
  selectTour(name: string): void {
    this.tourName = name;
  }
  get isX50EventsAllowed(): boolean {
    return this.tourName === 'X50Events';
  }
  get isX50ToursAllowed(): boolean {
    return this.tourName === 'X50Tours';
  }
  get isX50BetsAllowed(): boolean {
    return this.tourName === 'X50Bets';
  }
}
