import { Component, OnInit } from '@angular/core';
import { UserMechanicDetailsViewModels } from 'src/app/services/autogen/UserMechanics';
import { UserMechanicsService } from 'src/app/services/autogen/UserMechanics';
import { ChangeMechExpDateViewModel } from '../../../models/mechanics/change-mech-exp-date-view-model';
import { ChangeMechanicsExpDateValidator } from '../validators/change-mechanics-exp-date-validator';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeBonusExpDateViewModel } from '../../../models/mechanics/change-bonus-exp-date-view-model';
import { ChangeBonusExpirationDateValidator } from '../validators/change-bonus-expiration-date-validator';
import { ChangeUserMechanicDateForm } from 'src/app/services/autogen/UserMechanics';
import { ActiveUserTriggersService } from 'src/app/services/autogen/Triggers';
import { ActivatedRoute, Router } from '@angular/router';
import { GaMessagingService } from '@koddington/ga-common';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
    selector: 'app-user-mechanic-details',
    templateUrl: './user-mechanic-details.component.html',
    styleUrls: ['./user-mechanic-details.component.css']
})
export class UserMechanicDetailsComponent implements OnInit {

    public model: UserMechanicDetailsViewModels = new UserMechanicDetailsViewModels();
    protected readonly changeExpirationBonusDateModel: ChangeBonusExpDateViewModel = new ChangeBonusExpDateViewModel();
    protected readonly changeExpirationDateModel: ChangeMechExpDateViewModel = new ChangeMechExpDateViewModel();

    private _id: number;

    constructor(private readonly service: UserMechanicsService,
                private readonly changeMechValidator: ChangeMechanicsExpDateValidator,
                private readonly changeBonusValidator: ChangeBonusExpirationDateValidator,
                private readonly router: Router,
                private readonly activatedRoute: ActivatedRoute,
                private readonly messaging: GaMessagingService) {
    }

    ngOnInit(): void {
        const routeParams = this.activatedRoute.snapshot.paramMap;
        this._id = Number(routeParams.get('id'));
        this.loadMechanic();
    }

    changeMechanicExpDate(): void {
        const errors = this.changeMechValidator.validate(this.changeExpirationDateModel);
        if (errors.length > 0) {
            return;
        }
        const form = new ChangeUserMechanicDateForm();
        form.id = this._id;
        form.date = this.changeExpirationDateModel.newDate.strictValue;

        this.service.changeMechanicExpirationDate(form).pipe(
            take(1),
            filter(res => !this.messaging.tryShowError(res)),
            untilDestroyed(this)
        )
            .subscribe(() => this.loadMechanic());
    }

    changeBonusExpDate(): void {
        const errors = this.changeBonusValidator.validate(this.changeExpirationBonusDateModel);
        if (errors.length > 0) {
            return;
        }
        const form = new ChangeUserMechanicDateForm();
        form.id = this._id;
        form.date = this.changeExpirationBonusDateModel.newDate.strictValue;

        this.service.changeBonusExpirationDate(form).pipe(
            take(1),
            filter(res => !this.messaging.tryShowError(res)),
            untilDestroyed(this)
        )
            .subscribe(() => this.loadMechanic());
    }

    moveToActiveTriggerHistory(): void {
        this.router.navigate(['/dashboard/triggers/history'], {
            queryParams: {
                activeTriggerId: this.model.userMechanic.activeUserTriggerId,
                userId: this.model.userMechanic.userId,
                userTriggerId: this.model.userMechanic.userTriggerId
            }
        });
    }

    private loadMechanic(): void {
        this.service.details(this._id).pipe(
            take(1),
            filter(value => !this.messaging.tryShowError(value)),
            untilDestroyed(this)
        ).subscribe(value => {
            this.model = value.result;

            if (!!this.model.userMechanic.mechanicExpirationDate)
                this.changeExpirationDateModel.newDate.strictValue = dayjs(this.model.userMechanic.mechanicExpirationDate);

            if (!!this.model.userMechanic.bonusExpirationDate)
                this.changeExpirationBonusDateModel.newDate.strictValue = dayjs(this.model.userMechanic.bonusExpirationDate);
        });
    }
}
