<div>
  <h1>Зарегистрированные пользователи</h1>
  <hr/>
  <div>
    <app-strict-input-number class="col-6" [control]="model.userId" [placeholder]="'Id пользователя'"></app-strict-input-number>
    <button (click)="search()" class="btn btn-primary">Поиск</button>
  </div>
  <div *ngIf="result && result.results">
  <div class="row mt-3 font-weight-bold">
    <div class="col-1">User id</div>
    <div class="col-1">ОС</div>
    <div class="col-1">Версия</div>
    <div class="col-5">Токен</div>
    <div class="col-1">Логин</div>
    <div class="col-2">Дата</div>
  </div>
    <hr>
  <div class="row mt-3" *ngFor="let item of result.results">
    <div class="col-1">
      {{item.userId}}
    </div>
    <div class="col-1">
      {{item.deviceKind | wlDeviceKindsTransform}}
    </div>
    <div class="col-1">
      {{item.version}}
    </div>
    <div class="col-5 text-break">
      {{item.pushToken}}
    </div>
    <div class="col-1">
      {{item.isLogin}}
    </div>
    <div class="col-2">
      {{item.eventDateTime | dateTime}}
    </div>
  </div>
</div>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
