<div class="card" style="width: 30rem;">
  <div class="card-body">
    <h5 class="card-title">Состояние последней сегментации</h5>
    <h6 class="card-subtitle mb-2 {{stateClass}}">{{state}}</h6>
    <ng-container *ngIf="lastState">
      <p class="card-text">Запущена: {{lastState.startedAt | moscowDateTime}}</p>
      <p class="card-text">Завершена: {{lastState.completedAt | moscowDateTime}}</p>
      <p class="card-text">Длительность (минут): {{lastState.elapsedMinutes}}</p>
      <p class="card-text">Хост: {{lastState.hostName}}</p>
    </ng-container>
  </div>
</div>
