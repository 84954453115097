import { Component, OnInit } from '@angular/core';
import { GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlRateRaceExchangeSequenceViewModel } from 'src/app/services/autogen/BattlePass';
import { RateRaceMoneyService } from 'src/app/services/autogen/BattlePass';
import {Router} from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-rate-race-exchange-sequences',
    templateUrl: './rate-race-exchange-sequences.component.html',
    styleUrls: ['./rate-race-exchange-sequences.component.scss']
})
export class RateRaceExchangeSequencesComponent implements OnInit {
    public loading = true;
    public pagedResult: GaPagedResult<WlRateRaceExchangeSequenceViewModel>;

    private filter = new GaPagingForm();

    constructor(private readonly service: RateRaceMoneyService,
                private readonly router: Router) {
    }

    public ngOnInit(): void {
        this.load();
    }

  public navigateToProcessingRequest(id: number): void {
    this.router.navigate(['/dashboard/rate-race/main/exchange-requests', id]);
  }

    private load(): void {
        this.loading = true;

        this.service.listSequences(this.filter).pipe(
            take(1),
            untilDestroyed(this)
        ).subscribe(u => {
                this.pagedResult = u;
                this.filter = u.paging;

                this.loading = false;
            }
        );
    }

    public pageChanged(form: GaPagingForm): void {
        this.filter = form;
        this.load();
    }
}
