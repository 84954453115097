<h1>Лог ошибок винлайна для операций с механиками</h1>
<div>
  <div class="form-group col-3">
    <label>От</label>
    <strict-date-time-picker [control]="startDate"></strict-date-time-picker>
  </div>
  <div class="form-group col-3">
    <label>До</label>
    <strict-date-time-picker [control]="endDate"></strict-date-time-picker>
  </div>
  <div class="form-group col-3">
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </div>
</div>
<table class="table mt-3">
  <tr class="d-flex">
    <td class="col-1">
      UserMechanicId
    </td>
    <td class="col-1">
      QueueNumber
    </td>
    <td class="col-1">
      SequenceId
    </td>
    <td class="col-1">
      MechanicId
    </td>
    <td class="col-1">
      UserId
    </td>
    <td class="col-1">
      UserTriggerId
    </td>
    <td class="col-1">
      ActiveUserTriggerId
    </td>
    <td class="col-1">
      EventDate
    </td>
    <td class="col-1">
      Message
    </td>
    <td class="col-1">
      ErrorCode
    </td>
  </tr>
  <tr *ngFor="let error of errorsResult.errors" class="d-flex">
    <td class="col-1">
      {{error.userMechanicId}}
    </td>
    <td class="col-1">
      {{error.queueNumber}}
    </td>
    <td class="col-1">
      {{error.sequenceId}}
    </td>
    <td class="col-1">
      {{error.mechanicId}}
    </td>
    <td class="col-1">
      {{error.userId}}
    </td>
    <td class="col-1">
      {{error.userTriggerId}}
    </td>
    <td class="col-1">
      {{error.activeUserTriggerId}}
    </td>
    <td class="col-1">
      {{error.eventTime | moscowDateTime}}
    </td>
    <td class="col-1">
      {{error.message}}
    </td>
    <td class="col-1">
      {{error.errorCode}}
    </td>
  </tr>
</table>
