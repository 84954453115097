import { AbstractModelValidator } from '@koddington/ga-common';
import { ClientRegistrationViewModel } from '../../../models/events/components-models/client-registration-view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class ClientRegistrationValidator extends AbstractModelValidator<ClientRegistrationViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.userId)
            .requiredAndNotZero();
        this.ruleForControl(m => m.registrationDate)
            .required();
        this.ruleForControl(m => m.groupId)
            .required('Поле обязательно');
        this.ruleForControl(m => m.registrationState)
            .required('Статус обязателен');
    }
}
