import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GaMessagingService, isNullOrUndefined} from '@koddington/ga-common';
import { MockAllRegistredSmsService, WlMockSmsGateSendingCodesExtensions } from 'src/app/services/autogen/SmsGate';
import { WlMockAllRegistredSmsDetails } from 'src/app/services/autogen/SmsGate';

@Component({
  selector: 'app-attempts-message-info',
  templateUrl: './attempts-message-info.component.html',
  styleUrls: ['./attempts-message-info.component.css']
})
export class AttemptsMessageInfoComponent implements OnInit, OnDestroy {

  @Input()
  public sms: WlMockAllRegistredSmsDetails;

  public viewModel: WlMockAllRegistredSmsDetails;
  public sentCodes = WlMockSmsGateSendingCodesExtensions;


  constructor(private readonly _apiClient: MockAllRegistredSmsService,
              private readonly _messages: GaMessagingService) {
  }


  public get loading(): boolean {
    return isNullOrUndefined(this.viewModel);
  }

  public ngOnInit(): void {
    this.viewModel = new WlMockAllRegistredSmsDetails(this.sms);
  }

  public ngOnDestroy(): void {
    this.viewModel = null;

  }

}

