<div class="tabs container-fluid">
    <ul class="container-fluid">
        <li class="nav-item battle-pass-li">
            <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/logo-service/main/list']">
                Список изображений
            </a>
        </li>
        <li class="nav-item battle-pass-li">
            <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/logo-service/main/create']">
                Добавить изображение
            </a>
        </li>
    </ul>
    <router-outlet></router-outlet>
</div>
