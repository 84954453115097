<div class="row mt-3">
  <div class="col-1">
    <app-strict-input-number [control]="viewModel.type" placeholder="Тип"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="viewModel.subtype" placeholder="Подтип"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="viewModel.matchId" placeholder="Id матча"></app-strict-input-number>
  </div>
  <div class="col-1">
    <strict-date-time-picker [control]="viewModel.eventDate" placeholder="Дата события"></strict-date-time-picker>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="viewModel.currentScore" placeholder="Текущий счет"></app-strict-input-text>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="viewModel.currentPeriodScore" placeholder="Счет периода"></app-strict-input-text>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="viewModel.winner" placeholder="Победитель в периоде"></app-strict-input-number>
  </div>
</div>
<div class="row">
  <div class="col-1">
    <app-strict-input-number [control]="viewModel.sportId" placeholder="Id вида спорта"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="viewModel.sportName" placeholder="Вид спорта"></app-strict-input-text>
  </div>
  <div class="col-1">
    <app-strict-input-number [control]="viewModel.countryId" placeholder="Id страны"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="viewModel.countryName" placeholder="Страна"></app-strict-input-text>
  </div>
</div>
<div class="row mt-3">
  <div class="col-1">
    <app-strict-input-number [control]="viewModel.championshipId" placeholder="Id чемпионата"></app-strict-input-number>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="viewModel.championshipName" placeholder="Чемпионат"></app-strict-input-text>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="viewModel.firstPlayer" placeholder="Первый игрок"></app-strict-input-text>
  </div>
  <div class="col-1">
    <app-strict-input-text [control]="viewModel.secondPlayer" placeholder="Второй игрок"></app-strict-input-text>
  </div>
</div>
<div class="row mt-3">
  <div class="col-2">
    <app-strict-input-text [control]="viewModel.eventId" placeholder="Id отправки события"></app-strict-input-text>
  </div>
  <div class="col-1">
    <strict-date-time-picker [control]="viewModel.rabbitSentDate" placeholder="Дата отправки в рэббит"></strict-date-time-picker >
  </div>
</div>
<div class="row mt-3">
  <div class="col-1">
    <button class="btn btn-primary" (click)="save()">Сохранить</button>
  </div>
</div>
