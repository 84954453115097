import { AbstractModelValidator } from '@koddington/ga-common';
import { MockLogoServiceCrudViewModel } from '../mock-logo-service-crud-view-model';
import { StrictValidationRules } from '../../../modules/shared/validation-rules/strict-validation-rules';
import isGuid = StrictValidationRules.isGuid;
import isInteger = StrictValidationRules.isInteger;

export class MockLogoServiceCrudValidator extends AbstractModelValidator<MockLogoServiceCrudViewModel> {
    constructor() {
        super();

        this.ruleForControl(u => u.entityType)
            .required('Тип сущности лого-сервиса обязателен');

        this.ruleForControl(u => u.itemId)
            .required('Id сущности обязателен')
            .min(1)
            .customRule(u => isInteger(u));
    }
}
