import {Component, OnInit} from '@angular/core';
import { MediaService } from 'src/app/services/autogen/Shared';
import {filter, finalize, first} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddMediaSourceForm } from 'src/app/services/autogen/Shared';
import {AddSourceViewModel} from '../../../../models/media/view/add-source-view-model';
import {AddSourceValidator} from '../../../../models/media/view/validators/add-source-validator';
import {AppDatePickerOptions, GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-media-add-source',
  templateUrl: './media-add-source.component.html',
  styleUrls: ['./media-add-source.component.scss']
})
export class MediaAddSourceComponent implements OnInit {

  public viewModel: AddSourceViewModel = new AddSourceViewModel();

  private validator: AddSourceValidator = new AddSourceValidator();

  constructor(private _service: MediaService,
              private _messaging: GaMessagingService) { }

  ngOnInit(): void {
  }

  public getOptions(): AppDatePickerOptions {
    const options = new AppDatePickerOptions();
    options.placeholder = 'Дата изменения источника медиа';

    return options;
  }

  public send(): void {
    const errors = this.validator.validate(this.viewModel);

    if (errors.length > 0) {
      return;
    }

    const form = new AddMediaSourceForm();
    form.sourceId = this.viewModel.sourceId.strictValue;
    form.name = this.viewModel.name.strictValue;
    form.changeDate = this.viewModel.changeDate.strictValue;

    this._service.addSource(form).pipe(
      first(),
      filter(value => !this._messaging.tryShowError(value)),
      finalize(() => this._messaging.showMessage('Источник добавлен')),
      untilDestroyed(this)
    ).subscribe();
  }

}
