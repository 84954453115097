import { AbstractModelValidator } from '@koddington/ga-common';
import { AddSportMatchEventViewModel } from '../view-models/add-sport-match-event-view-model';

export class AddSportMatchEventValidator extends AbstractModelValidator<AddSportMatchEventViewModel> {
    constructor() {
        super();

        this.ruleForControl(u => u.matchId)
            .required();

        this.ruleForControl(u => u.type)
            .required();

        this.ruleForControl(u => u.subtype)
            .required();

        this.ruleForControl(u => u.eventDate)
            .required();

        this.ruleForControl(u => u.countryId)
            .required();

        this.ruleForControl(u => u.championshipId)
            .required();

        this.ruleForControl(u => u.championshipName)
            .required()
            .minLength(1);

        this.ruleForControl(u => u.winner)
            .required();

        this.ruleForControl(u => u.sportId)
            .required();

        this.ruleForControl(u => u.sportName)
            .required()
            .minLength(1);
    }
}
