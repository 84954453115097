import { Injectable } from "@angular/core";
import { AbstractModelValidator } from "@koddington/ga-common";
import { BetExpressLineViewModel } from "src/app/models/events/components-models/bet-express-line-calculation-view-model";

@Injectable()
export class ExpressLineRateCalculationValidator extends AbstractModelValidator<BetExpressLineViewModel>{
    constructor() {
        super();
        this.ruleForControl(m => m.userId).required();
        this.ruleForControl(m => m.betId).required();
        this.ruleForControl(m => m.operationDate).required();
        this.ruleForControl(m => m.winAmount).required();
        this.ruleForControl(m => m.lineRate).required();
        this.ruleForControl(m => m.betSum).required();
        this.ruleForControl(m => m.expressRate).required();
        this.ruleForControl(m => m.balls).required();
        this.ruleForControl(m => m.lineId).required();
        this.ruleForControl(m => m.eventId).required();
        this.ruleForControl(m => m.betSum).required();
      }
}

