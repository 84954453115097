<div>
  <h3>Добавление события обзвона</h3>
  <div>
    <div class="row mt-2">
      <div class="col-2">
        Id пользователя
      </div>
      <div class="col-2">
        Дата изменения статуса
      </div>
      <div class="col-2">
        Операция колл-центра
      </div>
      <div *ngIf="isSaveInCallOrLeftByCall()" class="col-2">
        Результат обзвона
      </div>
      <div class="col-2">
        Очередь КЦ
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <app-strict-input-number [control]="userId" placeholder="Id пользователя">
        </app-strict-input-number>
      </div>
      <div class="col-2">
        <strict-date-time-picker placeholder="Дата изменения статуса" [control]="eventChangeDate">
        </strict-date-time-picker>
      </div>
      <div class="col-2">
        <app-enum-dropdown [entityContainer]="callOperation" [strategy]="_callOperationTypesStrategy" [options]="options" >
        </app-enum-dropdown>
      </div>
      <div *ngIf="isSaveInCallOrLeftByCall()" class="col-2">
        <app-enum-dropdown [entityContainer]="callResult" [strategy]="_callResultsTypesStrategy" [options]="options">
        </app-enum-dropdown>
      </div>
      <div class="col-2">
        <app-enum-dropdown [entityContainer]="callQueue" [strategy]="_callQueueTypesStrategy" [options]="options">
        </app-enum-dropdown>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="save()">Сохранить</button>
</div>
</div>
