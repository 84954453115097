<nav class="navbar navbar-expand-sm navbar-light bg-white mb-3 justify-content-lg-end">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries/balances/list']">
        Балансы
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries/media/layout']">
        Медиа
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries/teams/list']">
        Команды матчей и ЛК
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries/coupons']">
        Купоны
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries/security-groups/list']">
        Группы СБ
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries/sport-types/list']">
        Виды спорта
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries/markets/list']">
        Маркеты
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries/countries/list']">
        Страны
      </a>
    </li>
  </ul>
</nav>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
