import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { MockBetCalculationRedeemForm } from 'src/app/services/autogen/Events';

export class BetCalculationRedeemViewModel {
    betRedeemedRate: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    championshipName?: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();


    public toForm() {
        const form = new MockBetCalculationRedeemForm();

        form.betRedeemedRate = this.betRedeemedRate.strictValue;
        form.championshipName = this.championshipName.strictValue;

        return form;
    }
}

