<h3>Отзывы пользователей</h3>
<div *ngIf="loading">
    <app-loading></app-loading>
</div>
<div class="row mt-2">
    <app-strict-input-number class="col-3"
                             [control]="pagingViewModel.userId"
                             [title]="'Id пользователя'">
    </app-strict-input-number>
    <div class="col-2 mt-4">
        <button class="btn btn-primary mb-3" (click)="search()">Поиск</button>
    </div>
</div>
<div *ngIf="!loading">
    <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
    <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
