<div>
  <h1 class="mt-2">Купоны</h1>
  <div>
    <div class="row mt-2">
      <div class="col-4">
        Название купона
      </div>
      <div class="col-2">
        Дата регистрации купона
      </div>
      <div class="col-3">
        Время действия(дни)
      </div>
      <div class="col-2">
        Тип купона
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-4">
        <app-strict-input-text [control]="couponModel.name"></app-strict-input-text>
      </div>
      <div class="col-2">
        <strict-date-time-picker [control]="couponModel.beginDate"></strict-date-time-picker>
      </div>
      <div class="col-3">
        <app-strict-input-number [control]="couponModel.daysUntilExpired"></app-strict-input-number>
      </div>
      <div class="col-2">
        <app-enum-dropdown [options]="dropDownOptions" [entityContainer]="couponModel.type" [strategy]="strategy">
        </app-enum-dropdown>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="save()">Сохранить</button>
  </div>
  <div>
    <div class="row mt-2">
      <div class="col-2">
        Id купона
      </div>
      <div class="col-2">
        Дата истечения купона
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-2">
        <app-strict-input-number [control]="couponModel.couponId"></app-strict-input-number>
      </div>
      <div class="col-2">
        <strict-date-time-picker [control]="couponModel.expirationDate"></strict-date-time-picker>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="update()">Обновить</button>
  </div>
</div>
