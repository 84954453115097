<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/custom-tournaments/main/tournaments-data']">
        Статика турнира
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/custom-tournaments/main/tournaments-table']">
        Перемещение пользователя по турнирной таблице
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/custom-tournaments/main/tournaments-sequences']">
        Очереди кастомных турниров
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/custom-tournaments/main/tournaments-invitations']">
        Приглашения в турниры
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</div>
