<label class="mt-2">Ставка купона x50</label>
<div>
  <div class="row mt-2">
    <div class="col-1">
      Id пользователя
    </div>
    <div class="col-1">
      Id тура
    </div>
    <div class="col-1">
      Id ставки
    </div>
    <div class="col-2">
      Дата ставки
    </div>
    <div class="col-2">
      Сумма ставки
    </div>
    <div class="col-2">
      Сумма выплаты
    </div>
    <div class="col-1">
      Варианты исходов
    </div>
    <div class="col-1">
      Выбранные исходы
    </div>
    <div class="col-1">
      Топ %
    </div>
  </div>
  <div class="row">
    <div class="col-1">
      <app-strict-input-number [control]="viewModel.userId" [placeholder]="'Id пользователя'">
      </app-strict-input-number>
    </div>
    <div class="col-1">
      <app-strict-input-number [control]="viewModel.tourId" [placeholder]="'Id тура'">
      </app-strict-input-number>
    </div>
    <div class="col-1">
      <app-strict-input-number [control]="viewModel.betId" [placeholder]="'Id ставки'">
      </app-strict-input-number>
    </div>
    <div class="col-2">
      <strict-date-time-picker [control]="viewModel.betDate"></strict-date-time-picker>
    </div>
    <div class="col-2">
      <app-strict-input-number [control]="viewModel.betSum" placeholder="Сумма ставки">
      </app-strict-input-number>
    </div>
    <div class="col-2">
      <app-strict-input-number [control]="viewModel.betResultSum" placeholder="Сумма выплаты">
      </app-strict-input-number>
    </div>
    <div class="col-1">
      <app-strict-input-number [control]="viewModel.branches" placeholder="Число вариантов">
      </app-strict-input-number>
    </div>
    <div class="col-1">
      <app-strict-input-text [control]="viewModel.results" placeholder="Результаты">
      </app-strict-input-text>
    </div>
    <div class="col-1">
      <app-strict-input-number [control]="viewModel.topPercent" placeholder="Топ X%">
      </app-strict-input-number>
    </div>
  </div>
  <button class="btn btn-primary mt-2" (click)="save()">Сохранить</button>
</div>
