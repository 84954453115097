import {Component, OnInit} from '@angular/core';
import { EventsService } from 'src/app/services/autogen/Events';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {RegistrationStateChangeViewModel} from '../../../../models/events/components-models/registration-state-change.view-model';
import {RegistrationStateChangesValidator} from '../../validators/registration-state-changes.validator';
import { RegistrationStateForm } from 'src/app/services/autogen/Events';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-add-registration-state-changes',
  templateUrl: './add-registration-state-changes.component.html',
  styleUrls: ['./add-registration-state-changes.component.css']
})
export class AddRegistrationStateChangesComponent implements OnInit {

  public readonly model = new RegistrationStateChangeViewModel();

  constructor(private readonly service: EventsService,
              private readonly validator: RegistrationStateChangesValidator,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
  }

  public add(): void {
    const errors = this.validator.validate(this.model);

    if (errors.length !== 0) {
      return;
    }

    const form = new RegistrationStateForm();
    form.oldState = this.model.oldState.strictValue;
    form.newState = this.model.newState.strictValue;
    form.userId = this.model.userId.strictValue;
    form.operationDate = this.model.eventDate.strictValue;
    this.service
      .addStateChanges(form)
      .pipe(
        take(1),
        filter(u => !this.messaging.tryShowError(u)),
        untilDestroyed(this)
      )
      .subscribe(value => this.messaging.showMessage('Событие добавлено'));
  }
}
