<div>
  <div>
    <h1>Пользователи без логина</h1>
    <hr/>
    <div>
      <app-strict-input-number class="col-6" [control]="model.userId" [placeholder]="'Id пользователя'"></app-strict-input-number>
      <app-strict-input-text class="col-6" [control]="model.deviceToken" [placeholder]="'Token'"></app-strict-input-text>
      <button (click)="search()" class="btn btn-primary">Поиск</button>
    </div>
    <div *ngIf="result && result.results">
      <div class="row mt-3 font-weight-bold">
        <div class="col-1">Id</div>
        <div class="col-1">Device Kind</div>
        <div class="col-1">App version</div>
        <div class="col-5">Token</div>
        <div class="col-1">Last login</div>
      </div>
      <hr>
      <div class="row mt-3" *ngFor="let item of result.results">
        <div class="col-1">
          {{item.id}}
        </div>
        <div class="col-1">
          {{item.deviceKind | wlDeviceKindsTransform}}
        </div>
        <div class="col-1">
          {{item.appVersion}}
        </div>
        <div class="col-5 text-break">
          {{item.deviceToken}}
        </div>
        <div class="col-1">
          {{item.lastLoginTime | dateTime}}
        </div>
      </div>
    </div>
    <div *ngIf="result">
      <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
    </div>
  </div>
</div>
