<h1>Медиа события</h1>
<div class="tabs container-fluid mt-2">
  <div class="row">
    <ul class="container-fluid col-8">
      <li (click)="onTabChange('Actions')">События</li>
      <li (click)="onTabChange('SourcesList')">Список источников</li>
      <li (click)="onTabChange('ChannelsList')">Список каналов</li>
      <li (click)="onTabChange('BtagsList')">Список Btags</li>
      <li (click)="onTabChange('PromoList')">Список Promo</li>
      <li (click)="onTabChange('Control')">Управление</li>
    </ul>
    <div *ngIf="lastSyncInfo" class="col-4 {{isSyncProblem()}}">
      <label>Последняя синхронизация справочников: {{lastSyncInfo.dictionaryLastCompleteSync | moscowDateTime}}</label>
      <label>Последняя попытка синхронизации справочников: {{lastSyncInfo.dictionaryLastSyncAttempt | moscowDateTime}}</label>
      <label>Последняя синхронизация пользовательских данных: {{lastSyncInfo.userDataLastCompleteSync | moscowDateTime}}</label>
      <label>Последняя попытка синхронизации пользовательских данных: {{lastSyncInfo.userDataLastSyncAttempt | moscowDateTime}}</label>
    </div>
  </div>
  <div>
    <div *ngIf="isActions()">
      <app-media-actions></app-media-actions>
      <hr>
    </div>
    <div *ngIf="isSourcesList()" class="row media-list">
      <app-media-source-list class="col"></app-media-source-list>
    </div>
    <div *ngIf="isChannelsList()" class="row media-list">
      <app-media-channel-list class="col"></app-media-channel-list>
    </div>
    <div *ngIf="isBtagsList()" class="row media-list">
      <app-media-btag-list class="col"></app-media-btag-list>
    </div>
    <div *ngIf="isPromoList()" class="row media-list">
      <app-media-promo-list class="col"></app-media-promo-list>
    </div>
    <div *ngIf="isControl()" class="row media-list">
      <app-media-control class="col"></app-media-control>
    </div>
  </div>
</div>
