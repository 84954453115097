<div>
  <label class="mt-2">Изменение любимой команды(favteam_change)</label>
  <div>
    <div class="row mt-2">
      <div class="col-1">
        Введите Id пользователя
      </div>
      <div class="col-2">
        Любимая команда
      </div>
      <div class="col-2">
        Дата
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <app-strict-input-number [control]="viewModel.userId" placeholder="Id пользователя">
        </app-strict-input-number>
      </div>
      <div class="col-2">
        <app-strict-autocomplete [control]="viewModel.favouriteTeam"
                                 [strategy]="teamsStrategy">
        </app-strict-autocomplete>
      </div>
      <div class="col-2">
        <strict-date-time-picker placeholder="Дата смены" [control]="viewModel.chooseDate">
        </strict-date-time-picker>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="changeTeam()">Сохранить</button>
  </div>
</div>
