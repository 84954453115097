import {CouponViewModel} from '../../../../models/coupons/coupon-view-model';
import {Injectable} from '@angular/core';
import {AbstractModelValidator, ValidationLevel} from '@koddington/ga-common';

@Injectable()
export class AddCouponValidator extends AbstractModelValidator<CouponViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.type, ValidationLevel.OnSave).required('Введите тип купона');
    this.ruleForControl( m => m.daysUntilExpired,
      ValidationLevel.OnSave).required('Введите количество дней')
      .min(1, 'Некорректное число дней');
    this.ruleForControl(m => m.beginDate, ValidationLevel.OnSave).required('Введите дату начала');
    this.ruleForControl(m => m.name, ValidationLevel.OnSave).required('Введите имя купона')
      .minLength(3, 'Имя должно содержать от трёх символов');
  }
}
