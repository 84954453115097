import {Pipe, PipeTransform} from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: any): unknown {
    if (!value) {
      return 'Не определено';
    }

    return dayjs(value).format('DD.MM.YYYY');
  }
}
