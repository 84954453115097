import { WlMockSecurityGroup } from 'src/app/services/autogen/Dictionaries';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class UserSegmentationDataViewModel {
  userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  registrationDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  securityGroupId: StrictFormControl<WlMockSecurityGroup> = StrictFormControlBuilder.buildNullObject(WlMockSecurityGroup);
  clientName: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  phoneNumber: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  email: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  registrationSource: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  emailRestricted: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
  smsRestricted: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
  emailRestrictionDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  smsRestrictionDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  operationDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  videoIdentState: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
}

