<mat-checkbox class="{{className}}"
              (change)="onSelect($event)"
              [formControl]="control">
  {{label}}
</mat-checkbox>
<div class="errors-list invalid-feedback" *ngIf="this.control.shouldDisplayErrors | async">
  <li class="error-item" *ngFor="let error of this.control.strictErrors | async">
    {{error.message}}
  </li>
</div>
