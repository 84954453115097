import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CouponsListComponent} from './coupons-list/coupons-list.component';
import {CouponComponent} from './coupon/coupon.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {CouponsRoutes} from './coupons.routes';
import {AddCouponValidator} from './validators/add-coupon-validator';
import {GaCommonModule} from '@koddington/ga-common';
import { DictionariesAreaCodegenAppModule } from '../../../services/autogen/Dictionaries';


@NgModule({
  declarations: [CouponsListComponent, CouponComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(CouponsRoutes),
        ReactiveFormsModule,
        SharedModule,
        GaCommonModule,
        DictionariesAreaCodegenAppModule
    ],
  providers: [
    AddCouponValidator
  ]
})
export class CouponsModule {
}
