import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { formatDateTime, GaMessagingService, GaTableCellTemplate, GaTableData, StrictFormControl } from '@koddington/ga-common';
import { filter, take } from 'rxjs/operators';
import { DailyTasksErpService, WlErpDailyTasksApiModel, WlErpDailyTasksPromotionApiModel } from '../../../../services/autogen/DailyTasks';

@Component({
  selector: 'app-daily-tasks-erp',
  templateUrl: './daily-tasks-erp.component.html'
})
export class DailyTasksErpComponent implements OnInit {
    @ViewChild('daysShow') daysShow: TemplateRef<any>;

    public userId: StrictFormControl<number>;
    public tableData: GaTableData<WlErpDailyTasksPromotionApiModel>;

    constructor(private readonly _service: DailyTasksErpService,
                private readonly _messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.userId = new StrictFormControl<number>(0);
    }

    public load(): void {
        this._service.getDailyTasksProfile(this.userId.strictValue).pipe(
            take(1),
            filter(res => !this._messaging.tryShowError(res))
        ).subscribe(res => {
            this.mapToTable(res.result);
        });
    }

    private mapToTable(result: WlErpDailyTasksApiModel): void {
        this.tableData = new GaTableData<WlErpDailyTasksPromotionApiModel>()
            .setData(result.promotions)
            .addSimpleColumn((elem) => elem.promotionId, {title: 'Id', widthSize: 60})
            .addSimpleColumn((elem) => elem.promotionName, {title: 'Название', widthSize: 160})
            .addSimpleColumn((elem) => formatDateTime(elem.startDate), {title: 'Дата начала', widthSize: 150})
            .addSimpleColumn((elem) => formatDateTime(elem.endDate), {title: 'Дата регистрации', widthSize: 150})
            .addSimpleColumn((elem) => elem.freeBetTypeId, {title: 'Id фрибета', widthSize: 150})
            .addSimpleColumn((elem) => elem.betIds.join(', ') ?? '-', {title: 'Ставки', widthSize: 400})
            .addTemplateColumn(new GaTableCellTemplate(this.daysShow, (elem) => {
                return {
                    days: elem.daysStats
                };
            }), {title: 'Дни', widthSize: 1300});
    }
}
