import {AbstractModelValidator} from '@koddington/ga-common';
import {AddTemplateViewModel} from '../../../models/push/add-template-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class AddTemplateValidator extends AbstractModelValidator<AddTemplateViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.templateCode).requiredAndNotZero().min(1);
    this.ruleForControl(m => m.values).required();
  }
}
