import {Component, OnDestroy, OnInit} from '@angular/core';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../../shared/services/search-navigation.service';
import { isNullOrUndefined } from '@koddington/ga-common';


@Component({
  selector: 'app-lifetime-call-events-layout',
  templateUrl: './lifetime-call-events-layout.component.html',
  styleUrls: ['./lifetime-call-events-layout.component.css']
})

export class LifetimeCallEventsLayoutComponent implements OnInit, OnDestroy {
  private tabName = '';
  private tabsControl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();

  constructor(private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService) {
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(this.activeRoute.snapshot.queryParams.tab)) {
      this.tab.strictValue = this.activeRoute.snapshot.queryParams.tab;
    }
  }

  ngOnDestroy(): void {
  }

  onTabChange(tabName: string): void {
    const params: Params = {
      tab: tabName
    };

    this.navigation.search(this.activeRoute, params);
  }

  get tab(): StrictFormControl<string> {
    return this.tabsControl;
  }

  get isLifetimeCallEventsListAllow(): boolean {
    return this.tab.strictValue === 'LifetimeCallEventsListAllow';
  }

  get isLifetimeCallEventsChangesAllow(): boolean {
    return this.tab.strictValue === 'LifeTimeCallEventsChangesAllow';
  }
}
