import {AbstractModelValidator} from '@koddington/ga-common';
import {ActiveUserTriggerListViewModel} from '../../../models/triggers/active-user-trigger-list-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class ActiveUserTriggersListValidator extends AbstractModelValidator<ActiveUserTriggerListViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).min(1);
  }
}
