import {IDropdownStrategy} from './i-dropdown-strategy';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {WlGamePlatforms, WlGamePlatformsExtensions} from 'src/app/services/autogen/BattlePass';
import {EnumExtensions, isNullOrUndefined} from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})

export class GamePlatformsDropdownStrategy implements IDropdownStrategy<WlGamePlatforms> {

  convert(model: WlGamePlatforms): DropdownItem<WlGamePlatforms> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlGamePlatforms>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlGamePlatformsExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlGamePlatforms>> {
    const types = EnumExtensions.toArray(WlGamePlatforms);
    return of(types);
  }
}
