import { Component, OnDestroy, OnInit } from '@angular/core';
import { MockLogoServiceCrudViewModel } from '../../../../models/logo-service/mock-logo-service-crud-view-model';
import { LogoServiceService } from 'src/app/services/autogen/LogoService'
import { ActivatedRoute, Router } from '@angular/router';
import { GaMessagingService, isNullOrUndefined } from '@koddington/ga-common';
import { filter, take } from 'rxjs/operators';
import { MockLogoServiceCrudValidator } from '../../../../models/logo-service/validators/mock-logo-service-crud-validator';
import {
    MockLogoServiceEntityTypesStrategy
} from '../../../shared/common/enum-dropdown/strategies/mock-logo-service-entity-types-strategy';
import { DropdownOptions } from '../../../../models/common/enum-dropdown/dropdown-options';

@Component({
    selector: 'app-logo-service-item-crud',
    templateUrl: './logo-service-item-crud.component.html',
    styleUrls: ['./logo-service-item-crud.component.scss']
})
export class LogoServiceItemCrudComponent implements OnInit, OnDestroy {
    public viewModel: MockLogoServiceCrudViewModel;
    public strategy = new MockLogoServiceEntityTypesStrategy();

    private readonly _validator = new MockLogoServiceCrudValidator();
    private _editModelId: number;

    constructor(private readonly _service: LogoServiceService,
                private readonly _messaging: GaMessagingService,
                private readonly _router: Router,
                private readonly _route: ActivatedRoute) {
    }

    public ngOnDestroy(): void {
    }

    public ngOnInit(): void {
        this.init();
    }

    public save(): void {
        const errors = this._validator.validate(this.viewModel);
        if (errors.length > 0) {
            return;
        }

        const form = this.viewModel.toForm();
        this._service.createOrUpdateRequest(form)
            .pipe(
                take(1),
                filter(u => !this._messaging.tryShowError(u))
            ).subscribe(u => {
                this._messaging.showMessage('Успешно сохранено!');
                this._router.navigate(['dashboard/logo-service/main/update', u.result]);
            }
        );
    }

    private init() {
        this._editModelId = isNullOrUndefined(this._route.snapshot.params['id']) ? null : this._route.snapshot.params['id'];

        if (isNullOrUndefined(this._editModelId)) {
            this.viewModel = new MockLogoServiceCrudViewModel();
            return;
        }

        this._service.getForUpdate(this._editModelId).pipe(
            take(1),
            filter(u => !this._messaging.tryShowError(u))
        ).subscribe(u => {
            this.viewModel = MockLogoServiceCrudViewModel.fromForm(u.result);
            this.viewModel.id.disable();
        });
    }

    public get pageCaption(): string {
        if (isNullOrUndefined(this._editModelId)) {
            return 'Создание новой записи в лого-сервисе';
        }

        return `Редактирование записи ${this._editModelId} в лого-сервисе`;
    }

    public get dropDownOptions(): DropdownOptions {
        const options = new DropdownOptions();
        options.placeholder = 'Выберите тип';
        return options;
    }
}
