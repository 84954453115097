<div class="row">
  <div class="col-6">
    <app-sport-type-update (sportTypeChanged)="reload()">
    </app-sport-type-update>
  </div>
  <div class="col-6">
    <app-loading *ngIf="loading"></app-loading>
    <h1 class="mt-2">Виды спорта</h1>
    <table class="table" *ngIf="!loading" style="width: 100%">
      <tr class="table-info">
        <th>
          id Winline
        </th>
        <th>
          Имя
        </th>
      </tr>
      <tr *ngFor="let item of sportTypes">
        <td>
          {{item.id}}
        </td>
        <td>
          {{item.nameRus}}
        </td>
      </tr>
    </table>
  </div>
</div>
