<div>
  <label class="mt-2">Новое значение Score для пользователя</label>
  <div class="row">
    <div class="col-2">
      {{"Id пользователя"}}
    </div>
    <div class="col-2">
      {{"Дата операции"}}
    </div>
    <div class="col-2">
      {{"Значение Score"}}
    </div>
    <div class="col-2">
      {{"Комментарий"}}
    </div>
  </div>
  <div class="mt-2 row">
    <app-strict-input-number class="col-2"
                             placeholder="Id пользователя"
                             [control]="model.userId">
    </app-strict-input-number>
    <strict-date-time-picker class="col-2 timeline-marker"
                          placeholder="Дата операции"
                          [control]="model.operationDate">
    </strict-date-time-picker>
    <app-strict-input-number class="col-2"
                             placeholder="Значение Score"
                             [control]="model.score">
    </app-strict-input-number>
    <app-strict-input-text class="col-2"
                           placeholder="Комментарий"
                           [control]="model.comment">
    </app-strict-input-text>
  </div>
  <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
