import {AddSourceViewModel} from '../add-source-view-model';
import {AbstractModelValidator} from '@koddington/ga-common';

export class AddSourceValidator extends AbstractModelValidator<AddSourceViewModel> {
  constructor() {
    super();
    this.ruleForControl(input => input.sourceId).min(1).requiredAndNotZero();
  }
}

