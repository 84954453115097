import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {filter, finalize, first, map, switchMap, tap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaMessagingService, GaPagedResult, GaPagingForm, isNullOrUndefined} from '@koddington/ga-common';
import {Observable} from 'rxjs';
import { SecurityGroupsService } from 'src/app/services/autogen/Dictionaries';
import { WlMockSecurityGroup } from 'src/app/services/autogen/Dictionaries';

@UntilDestroy()
@Component({
  selector: 'app-security-groups-list',
  templateUrl: './security-groups-list.component.html',
  styleUrls: ['./security-groups-list.component.css']
})
export class SecurityGroupsListComponent implements OnInit {

  public loading = true;
  public currentFilter = new GaPagingForm();
  public list: GaPagedResult<WlMockSecurityGroup>;

  constructor(private readonly _apiClient: SecurityGroupsService,
              private readonly _activeRoute: ActivatedRoute,
              private readonly _messages: GaMessagingService) {
  }

  public ngOnInit(): void {
    this._activeRoute.queryParams
      .pipe(
        tap((params) => this.fillFilter(params)),
        switchMap(() => this.loadList()),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public refreshList(): void {
    this.currentFilter.offset = 0;
    this.loadList().subscribe();
  }

  public onPageChanged(form: GaPagingForm): void {
    this.currentFilter.offset = form.offset;

    this.loadList().subscribe();
  }

  private fillFilter(params: Params): void {
    this.currentFilter.count = 10;
    this.currentFilter.offset = isNullOrUndefined(params['offset']) ? 0 : Number(params['offset']);
  }

  private loadList(): Observable<void> {
    this.loading = true;

    const form = new GaPagingForm();
    form.offset = this.currentFilter.offset;
    form.count = this.currentFilter.count;

    return this._apiClient.getPagedList(form)
      .pipe(
        first(),
        filter((u) => !this._messages.tryShowError(u)),
        map(u => u.result),
        finalize(() => this.loading = false),
        map(res => {
          this.list = res;
        }),
        untilDestroyed(this)
      );
  }
}
