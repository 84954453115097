<div class="row">
  <div class="col-6">
    <app-update-security-group-form (groupChanged)="refreshList()">
    </app-update-security-group-form>
  </div>
  <div class="col-6">
    <app-loading *ngIf="loading"></app-loading>
    <h1 class="mt-2">Список групп СБ</h1>
    <table class="table" *ngIf="!loading" style="width: 100%">
      <tr class="table-info">
        <th>
          Id Winline (внешний)
        </th>
        <th>
          Имя
        </th>
        <th>
          Хорошая
        </th>
        <th>
          Тестовая
        </th>
      </tr>
      <tr *ngFor="let item of list.results">
        <td>
          {{item.id}}
        </td>
        <td>
          {{item.name}}
        </td>
        <td>
          {{item.isGood}}
        </td>
        <td>
          {{item.isTest}}
        </td>
      </tr>
    </table>
    <ga-paging *ngIf="!loading" (pageChangedEvent)="onPageChanged($event)" [pagedResult]="list">
    </ga-paging>
  </div>
</div>
