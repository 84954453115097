import { DropdownItem, IDropdownStrategy, isNullOrUndefined } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';

export class BooleanDropdownStrategy implements IDropdownStrategy<boolean> {
    getEntities(): Observable<Array<boolean>> {
        return of(new Array(false, true));
    }

    map(model: boolean): DropdownItem<boolean> {
        const item = new DropdownItem<boolean>();
        item.entity = model;
        item.id = model ? 1 : 0;
        item.title = model ? 'Да' : 'Нет';
        return item;
    }
}
