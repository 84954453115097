import {AbstractModelValidator, StrictError} from '@koddington/ga-common';
import {AddUserDataViewModel} from '../../../models/users/add-user-data-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class AddUserDataValidator extends AbstractModelValidator<AddUserDataViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).required('Поле обязательно').maxLength(9);
    this.ruleForControl(m => m.userContactInformationType).required('Поле обязательно');
    this.ruleForControl(m => m.contactInfo).required('Поле обязательно').minLength(1);
  }
}
