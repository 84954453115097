import { WlMonetaryClassTypes } from 'src/app/services/autogen/Users';
import { WlStageTypes } from 'src/app/services/autogen/Users';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class UserViewModel {
  userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  phone: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  monetaryClass: StrictFormControl<WlMonetaryClassTypes> = StrictFormControlBuilder.buildNullNumber<WlMonetaryClassTypes>();
  stage: StrictFormControl<WlStageTypes> = StrictFormControlBuilder.buildNullNumber<WlStageTypes>();
}
