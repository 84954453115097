import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActiveTriggerHistoryComponent} from './active-trigger-history/active-trigger-history.component';
import {ActiveUserTriggersListForUserComponent} from './active-user-triggers-list-for-user/active-user-triggers-list-for-user.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ActiveUserTriggersListValidator} from './validators/active-user-triggers-list-validator';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {TriggersRoutes} from './triggers.routes';
import { TriggerActionsComponent } from './trigger-actions/trigger-actions.component';
import {GaCommonModule} from '@koddington/ga-common';
import { TriggersAreaCodegenAppModule } from '../../services/autogen/Triggers';

@NgModule({
  declarations: [ActiveTriggerHistoryComponent, ActiveUserTriggersListForUserComponent, TriggerActionsComponent],
  imports: [
    ReactiveFormsModule,
    RouterModule.forChild(TriggersRoutes),
    RouterModule,
    SharedModule,
    CommonModule,
    GaCommonModule,
    TriggersAreaCodegenAppModule
  ],
  providers: [ActiveUserTriggersListValidator]
})
export class TriggersModule {
}
