import {Routes} from '@angular/router';
import { PushMockLayoutComponent } from './components/push-mock-layout/push-mock-layout.component';
import { PushMockAndroidListComponent } from './components/push-mock-android-list/push-mock-android-list.component';
import { PushMockListComponent } from './components/push-mock-list/push-mock-list.component';
import { PushMockErrorHandleComponent } from './components/push-mock-error-handle/push-mock-error-handle.component';
import { PushMockErrorHandleApnsComponent } from './components/push-mock-error-handle-apns/push-mock-error-handle-apns.component';

export const ChildrenMockRoute : Routes = [
  { 
    path: 'push-mock-list',
    component: PushMockListComponent
  },
  {
    path: 'push-mock-android-list',
    component: PushMockAndroidListComponent
  },
  {
    path: 'push-mock-error-handle',
    component: PushMockErrorHandleComponent
  },
  {
    path: 'app-push-mock-error-handle-apns',
    component: PushMockErrorHandleApnsComponent
  }  
];

export const PushGateRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'main',
        component: PushMockLayoutComponent,
        children: ChildrenMockRoute
      }
    ]
  }
];
