import { Component, OnDestroy, OnInit } from '@angular/core';
import { MockMarketsSearchForm, WlMockMarketsListModel } from 'src/app/services/autogen/Dictionaries';
import { MarketsService } from 'src/app/services/autogen/Dictionaries';
import { GaSearchManagementStateService } from '../../../../shared/services/ga-search-management-state.service';
import { formatBoolean, GaPagedResult, GaPagingForm, GaPanelGridConfig, GaTableData } from '@koddington/ga-common';
import { MarketsSearchModel } from '../view-model/markets.search-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-markets-list',
    templateUrl: './markets-list.component.html',
    providers: [GaSearchManagementStateService]
})
export class MarketsListComponent implements OnInit, OnDestroy {
    public pagingResult: GaPagedResult<WlMockMarketsListModel>;
    public tableData: GaTableData<WlMockMarketsListModel>;
    public searchPanelGrid = new GaPanelGridConfig();
    private searchViewModel = new MarketsSearchModel();

    constructor(private readonly _service: MarketsService,
                private readonly _searchService: GaSearchManagementStateService<GaPagedResult<WlMockMarketsListModel>>) {
    }

    ngOnInit(): void {
        this.init();
    }

    ngOnDestroy() {
    }

    protected pageChanged(form: GaPagingForm): void {
        this._searchService.nextSearch(form);
    }

    protected pageFiltered(): void {
        this._searchService.nextSearch(null, true);
    }

    private init(): void {
        this.searchPanelGrid
            .addNumbers(this.searchViewModel.id, 'Id')
            .addText(this.searchViewModel.term, 'Название')
            .addBoolean(this.searchViewModel.isDeleted, true, 'Удалено?');

        this._searchService.init(form => this._service.list(form as MockMarketsSearchForm), this.searchViewModel);
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe(res => {
            this.pagingResult = res;
            this.tableData = new GaTableData<WlMockMarketsListModel>()
                .addSimpleColumn(u => u.id, {title: 'Id маркета'})
                .addSimpleColumn(u => u.name, {title: 'Название маркета', widthSize: 300})
                .addSimpleColumn(u => formatBoolean(u.isDeleted), {title: 'Удалён?'})
                .setData(res.results);
            this._searchService.loadingStatus = false;
        });
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
