<nav class="navbar navbar-expand-sm navbar-light bg-white mb-3 justify-content-lg-end">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/sms-gate/attempts/list']">
        Попытки
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/sms-gate/sent-sms/list']">
        Сообщения
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/sms-gate/phones/list']">
        Телефоны
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/sms-gate/accounts/list']">
        Провайдеры
      </a>
    </li>
  </ul>
</nav>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
