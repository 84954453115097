<div class="row">
  <div class="col-6">
    <app-update-balance-form (balanceChanged)="refreshList()">
    </app-update-balance-form>
  </div>
  <div class="col-6">
    <app-loading *ngIf="loading"></app-loading>
    <h1 class="mt-2">Список балансов</h1>
    <div class="form-group">
      <label>Id пользователя</label>
      <app-strict-input-number [control]="this.currentFilter.userId">
      </app-strict-input-number>
    </div>
    <table class="table" *ngIf="!loading" style="width: 100%">
      <tr class="table-info">
        <th>
          Пользователь
        </th>
        <th>
          Баланс
        </th>
        <th>
          Мячи
        </th>
        <th>
          Баланс в игре
        </th>
      </tr>
      <tr *ngFor="let item of list.results">
        <td>
          {{item.userId}}
        </td>
        <td>
          {{item.balance}}
        </td>
        <td>
          {{item.bonusBalls}}
        </td>
        <td>
          {{item.inGameBetsSum}}
        </td>
      </tr>
    </table>
    <ga-paging *ngIf="!loading" (pageChangedEvent)="onPageChanged($event)" [pagedResult]="list">
    </ga-paging>
  </div>
</div>
