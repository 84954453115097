import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class AddScoreUpdateViewModel {
  userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  operationDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  score: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  comment: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
}

