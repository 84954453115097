import { Component, Input, OnInit } from '@angular/core';
import {StrictFormControl} from '@koddington/ga-common';

@Component({
  selector: 'app-strict-input-phone',
  templateUrl: './strict-input-phone.component.html',
  styleUrls: ['./strict-input-phone.component.css']
})
export class StrictInputPhoneComponent implements OnInit {

  @Input()
  public control: StrictFormControl<string>;
  @Input()
  public placeholder = '';

  constructor() {
  }

  ngOnInit(): void {
    var randomNumber = Math.floor(Math.random() * 10000000000);
    this.control.strictValue = `TestPhone8${randomNumber}`;
  }

}
