import {Component, OnInit} from '@angular/core';
import { MediaService } from 'src/app/services/autogen/Shared';
import {AddChannelViewModel} from '../../../../models/media/view/add-channel-view-model';
import { AddMediaChannelForm } from 'src/app/services/autogen/Shared';
import {filter, finalize, first} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {AddChannelValidator} from '../../../../models/media/view/validators/add-channel-validator';
import {AppDatePickerOptions, GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-media-add-channel',
  templateUrl: './media-add-channel.component.html',
  styleUrls: ['./media-add-channel.component.scss']
})
export class MediaAddChannelComponent implements OnInit {

  public viewModel: AddChannelViewModel = new AddChannelViewModel();

  private validator: AddChannelValidator = new AddChannelValidator();

  constructor(private _service: MediaService,
              private _messaging: GaMessagingService) { }

  ngOnInit(): void {
  }

  public getOptions(): AppDatePickerOptions {
    const options = new AppDatePickerOptions();
    options.placeholder = 'Дата изменения канала медиа';

    return options;
  }

  public send(): void {
    const errors = this.validator.validate(this.viewModel);

    if (errors.length > 0) {
      return;
    }

    const form = new AddMediaChannelForm();
    form.channelId = this.viewModel.channelId.strictValue;
    form.name = this.viewModel.name.strictValue;
    form.changeDate = this.viewModel.changeDate.strictValue;

    this._service.addChannel(form).pipe(
      first(),
      filter(value => !this._messaging.tryShowError(value)),
      finalize(() => this._messaging.showMessage('Канал добавлен')),
      untilDestroyed(this)
    ).subscribe();
  }
}
