import {Component, OnInit} from '@angular/core';
import { GaPagedResult, GaPagingForm, isNullOrUndefined, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlUsersRateRaceHistoriesListModel } from 'src/app/services/autogen/BattlePass';
import { UsersRateRaceService } from 'src/app/services/autogen/BattlePass';
import { WlUsersRateRaceHistoriesForm } from 'src/app/services/autogen/BattlePass';

@UntilDestroy()
@Component({
  selector: 'app-rate-race-users-histories',
  templateUrl: './rate-race-users-histories.component.html',
  styleUrls: ['./rate-race-users-histories.component.css']
})
export class RateRaceUsersHistoriesComponent implements OnInit {
  public loading = true;
  public pagedResult: GaPagedResult<WlUsersRateRaceHistoriesListModel>;
  public userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public seasonId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  private offset = 0;
  private count = 30;

  constructor(private readonly service: UsersRateRaceService) {
  }

  public ngOnInit(): void {
    this.load();
  }

  public search(): void {
    this.load();
  }

  public pageChanged(form: GaPagingForm): void {
    this.offset = form.offset;
    this.load();
  }

  private load(): void {
    this.loading = true;

    this.service.listUsersRateRace(this.createForm()).pipe(
      take(1),
      untilDestroyed(this)
    ).subscribe(result => {
        this.pagedResult = result;
        this.offset = result.paging.offset;
        this.count = result.paging.count;
        this.loading = false;
      }
    );
  }

  private createForm(): WlUsersRateRaceHistoriesForm {
    const form = new WlUsersRateRaceHistoriesForm;

    form.userId = this.userId.strictValue;
    form.seasonId = this.seasonId.strictValue;
    form.offset = this.offset;
    form.count = isNullOrUndefined(this.count) ? 30 : this.count;

    return form;
  }
}
