import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {filter, finalize, first} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SecurityGroupsService } from 'src/app/services/autogen/Dictionaries';
import {UpdateFormValidator} from './validators/update-form.validator';
import {UpdateSecurityGroupViewModel} from './models/update-security-group.view-model';
import { WlMockSecurityGroup } from 'src/app/services/autogen/Dictionaries';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-update-security-group-form',
  templateUrl: './update-security-group-form.component.html',
  styleUrls: ['./update-security-group-form.component.css']
})
export class UpdateSecurityGroupFormComponent implements OnInit {

  @Output()
  public groupChanged = new EventEmitter<void>();

  public loading = true;
  public viewModel = new UpdateSecurityGroupViewModel();

  constructor(private readonly _apiClient: SecurityGroupsService,
              private readonly _validator: UpdateFormValidator,
              private readonly _messages: GaMessagingService) {
  }

  public ngOnInit(): void {
  }

  public save(): void {
    const errors = this._validator.validate(this.viewModel);
    if (errors.length > 0) {
      return;
    }

    const form = new WlMockSecurityGroup();
    form.id = this.viewModel.id.strictValue;
    form.name = this.viewModel.name.strictValue;
    form.isGood = this.viewModel.isGood.strictValue;
    form.isTest = this.viewModel.isTest.strictValue;

    this.loading = true;
    this._apiClient.createOrUpdate(form)
      .pipe(
        first(),
        filter(u => !this._messages.tryShowError(u)),
        finalize(() => this.loading = false),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this._messages.showMessage('Группа обновлена');
        this.groupChanged.emit();
      });
  }

}
