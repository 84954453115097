import {Component, OnInit} from '@angular/core';
import {AddFreeBetViewModel} from '../../../../models/events/components-models/add-free-bet-view-model';
import { EventsService } from 'src/app/services/autogen/Events';
import {AddFreeBetValidator} from '../../validators/add-free-bet-validator';
import {WlResult} from '../../../../models/common/results/wl-result';
import { AddFreeBetForm } from 'src/app/services/autogen/Events';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaMessagingService, GaPanelGridConfig, GaTitlePositions} from '@koddington/ga-common';
import { FreebetsService } from '../../../../services/autogen/Events';

@UntilDestroy()
@Component({
  selector: 'app-add-free-bet',
  templateUrl: './add-free-bet.component.html',
  styleUrls: ['./add-free-bet.component.css']
})
export class AddFreeBetComponent implements OnInit {

  public readonly model: AddFreeBetViewModel = new AddFreeBetViewModel();
  private result: WlResult<number>;
  config = new GaPanelGridConfig();

  constructor(private readonly service: EventsService,
              private readonly freebetsService: FreebetsService,
              private readonly validator: AddFreeBetValidator,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
    this.buildGrid();
  }

  public add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }

    const form = new AddFreeBetForm();
    form.userId = this.model.userId.strictValue;
    form.endDate = this.model.endDate.strictValue;
    form.freeBetId = this.model.freeBetId.strictValue;
    form.beginDate = this.model.beginDate.strictValue;
    form.freeBetType = this.model.freeBetType.strictValue;
    form.issuedFreebetId = this.model.issuedFreebetId.strictValue;
    form.summ = this.model.summ.strictValue;
    form.typeForBonusClub = this.model.typeForBonusClub.strictValue;

    this.freebetsService.freebetAdd(form).pipe(
      take(1),
      filter(u => !this.messaging.tryShowError(u)),
      untilDestroyed(this)
    )
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Фрибет успешно добавлен');
      });
  }

  private buildGrid() {
    this.config.columnsCount = 5;
    this.config.titlePositions = GaTitlePositions.Top;
    this.config.addNumbers(this.model.userId, 'Id пользователя')
      .addNumbers(this.model.freeBetId, 'Id фрибета')
      .addDateTime(this.model.beginDate, 'Дата начала')
      .addDateTime(this.model.endDate, 'Дата окончания')
      .addNumbers(this.model.freeBetType, 'Тип фрибета (0 - фрибет, 1 - x5 купон, 4 - кэшбэк, 5 - регистрация). Смотри справочник купонов')
      .addNumbers(this.model.issuedFreebetId, 'Id конкретного экзепляра купона, выданного пользователю')
      .addNumbers(this.model.summ, 'Сумма')
      .addNumbers(this.model.typeForBonusClub, 'Тип для бонус клуба (Пусто для обычных фрибетов, заполняется для бонусов за достижения бонус клуба.)');
  }
}
