import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {debounceTime, distinctUntilChanged, filter, finalize, first, map, skip, switchMap, tap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaMessagingService, isNullOrUndefined} from '@koddington/ga-common';
import {Observable} from 'rxjs';
import {SentSmsFilterViewModel} from './models/sent-sms-filter.view-model';
import { MockSmsListFilter, WlIndexPagedResult, WlMockSmsDetails } from 'src/app/services/autogen/SmsGate';
import { MockSentSmsService } from 'src/app/services/autogen/SmsGate';
import {SearchNavigationService} from '../../../../shared/services/search-navigation.service';
import { GaIndexBasedPagingForm } from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-sent-list',
  templateUrl: './sent-list.component.html',
  styleUrls: ['./sent-list.component.css']
})
export class SentListComponent implements OnInit {

  public loading = true;
  public currentFilter = new SentSmsFilterViewModel();
  public list: WlIndexPagedResult<WlMockSmsDetails, MockSmsListFilter>;

  constructor(private readonly _apiClient: MockSentSmsService,
              private readonly _activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly _messages: GaMessagingService) {
  }

  public ngOnInit(): void {
    this._activeRoute.queryParams
      .pipe(
        tap((params) => this.fillFilter(params)),
        switchMap(() => this.loadList()),
        untilDestroyed(this)
      )
      .subscribe();

    this.currentFilter.phoneNumber.strictValueSource.pipe(
      skip(1),
      distinctUntilChanged(),
      debounceTime(400),
      untilDestroyed(this)
    ).subscribe(() => this.search());
  }

  public onPageChanged(form: GaIndexBasedPagingForm): void {
    this.currentFilter.lastId = form.index;
    this.currentFilter.direction = form.direction;

    this.search();
  }

  public search(): void {
    const params: Params = {
      direction: Number(this.currentFilter.direction),
      lastId: this.currentFilter.lastId,
      phoneNumber: this.currentFilter.phoneNumber.getStrictValueOrDefault(undefined)
    };

    this.navigation.search(this._activeRoute, params);
  }

  private loadList(): Observable<void> {
    this.loading = true;

    const form = new MockSmsListFilter();
    form.phoneNumber = this.currentFilter.phoneNumber.strictValue;
    form.index = this.currentFilter.lastId;
    form.count = this.currentFilter.count;
    form.direction = this.currentFilter.direction;

    return this._apiClient.getList(form)
      .pipe(
        first(),
        filter((u) => !this._messages.tryShowError(u)),
        map(u => u.result),
        map(res => {
          this.list = res;
        }),
        finalize(() => this.loading = false),
        untilDestroyed(this)
      );
  }

  private fillFilter(params: Params): void {
    this.currentFilter.count = 20;

    if (!isNullOrUndefined(params['direction'])) {
      this.currentFilter.direction = Number(params['direction']);
    }

    if (!isNullOrUndefined(params['lastId'])) {
      this.currentFilter.lastId = Number(params['lastId']);
    }

    if (!isNullOrUndefined(params['phoneNumber'])) {
      this.currentFilter.phoneNumber.strictValue = params['phoneNumber'];
    }
  }
}
