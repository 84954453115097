import { Component, OnInit } from '@angular/core';
import { AddBetViewModel } from '../../../../models/events/components-models/add-bet-view-model';
import { BetPeriods, EventsService, MockBetCalculationForm, WlBetPlatforms, WlBetTypes } from 'src/app/services/autogen/Events';
import { AddBetValidator } from '../../validators/add-bet-validator';
import { filter, first, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlResult } from '../../../../models/common/results/wl-result';
import { BetTypesStrategy } from '../../../shared/common/enum-dropdown/strategies/bet-types-strategy';
import { DropdownOptions } from '../../../../models/common/enum-dropdown/dropdown-options';
import { WlBetEventViewModel } from '../../../shared/common/wl-bet-event/models/wl-bet-event-view-model';
import { BetPlatformsStrategy } from '../../../shared/common/enum-dropdown/strategies/bet-platforms-strategy';
import { Subject } from 'rxjs';
import { GaConfirmationService, GaMessagingService, isNullOrUndefined } from '@koddington/ga-common';
import { NgClassConfig } from '../../../../models/common/configs/ng-class-config';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
    selector: 'app-add-bet',
    templateUrl: './add-bet.component.html',
    styleUrls: ['./add-bet.component.css'],
    providers: [BetPlatformsStrategy]
})
export class AddBetComponent implements OnInit {

    protected model: AddBetViewModel = new AddBetViewModel();
    protected readonly changeRate = new Subject<void>();
    private result: WlResult<number>;
    private readonly _minAllowedHardcodedBetId = 3_000_000_000;

    constructor(protected readonly typeStrategy: BetTypesStrategy,
                protected readonly platformStrategy: BetPlatformsStrategy,
                private readonly _service: EventsService,
                private readonly _validator: AddBetValidator,
                private readonly _messaging: GaMessagingService,
                private readonly _confirmation: GaConfirmationService) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public addEvent() {
        const control = new WlBetEventViewModel();
        this.model.events.strictValue.push(control);
    }

    public delEvent(eventSelectorNumber: number): void {
        if (this.model.events.strictValue.length <= 1) {
            return;
        }

        this.model.events.strictValue = this.model.events.strictValue.filter((_, k) => k !== eventSelectorNumber);
    }

    public tryAddBet(): void {
        const errors = this._validator.validate(this.model);

        if (errors.length > 0) {
            return;
        }

        if (this.model.betDate.hasStrictValue && this.model.betDate.strictValue >= dayjs()) {
            return this.saveBet();
        }

        const warningMessage = this.model.betDate.hasStrictValue
            ? 'Указанное время ставки раньше текущего времени. Всё равно добавить?'
            : 'Время ставки не указано и будет заменено на время добавления. Всё равно добавить?';
        this._confirmation.openDialog(warningMessage)
            .pipe(
                take(1),
                filter(u => u === true)
            )
            .subscribe(_ => this.saveBet());
    }

    public fillEmptyFields(): void {
        if (!this.model.sum.hasStrictValue)
            this.model.sum.strictValue = 1000;

        if (!this.model.platform.hasStrictValue)
            this.model.platform.strictValue = WlBetPlatforms.Android;

        if (!this.model.betType.hasStrictValue)
            this.model.betType.strictValue = WlBetTypes.Ordinar;

        this.model.events.strictValue.forEach((value, index) => {
            if (!value.eventId.hasStrictValue)
                value.eventId.strictValue = 1 + index;

            if (!value.marketId.hasStrictValue)
                value.marketId.strictValue = 1;

            if (!value.sportType.hasStrictValue)
                value.sportType.strictValue = 1;

            if (!value.championshipId.hasStrictValue)
                value.championshipId.strictValue = 1;

            if (!value.gameId.hasStrictValue)
                value.gameId.strictValue = 1;

            if (!value.rate.hasStrictValue)
                value.rate.strictValue = 1.3;

            if (!value.betPeriod.hasStrictValue)
                value.betPeriod.strictValue = BetPeriods.Prematch;

            if (!value.firstTeamId.hasStrictValue)
                value.firstTeamId.strictValue = 1;

            if (!value.secondTeamId.hasStrictValue)
                value.secondTeamId.strictValue = 2;
        });

        this.changeRate.next();
    }

    public calculateByDefaultValue(): void {
        if (!this.model.betId.hasStrictValue) {
            this._messaging.showMessage('Ставка не добавлена');
            return;
        }

        const form = new MockBetCalculationForm();
        form.betId = this.model.betId.strictValue;
        form.eventDate = this.model.betDate.getStrictValueOrDefault(dayjs()).add(30, 'seconds');
        form.calculationAmount = this.model.sum.getStrictValueOrDefault(1000) * this.model.totalRate.getStrictValueOrDefault(1.3);
        form.isWin = true;

        this._service.addBetCalculationEvent(form)
                     .pipe(
                         first(),
                         filter(u => !this._messaging.tryShowError(u)),
                         untilDestroyed(this)
                     )
                     .subscribe(_ => {
                         this._messaging.showMessage('Расчёт ставки успешно выполнен');
                     });
    }

    private init(): void {
        this.addEvent();

        this.changeRate.pipe(untilDestroyed(this))
            .subscribe(() => {
                const total = this.model.events.strictValue
                    .filter((val) => val.rate.hasStrictValue)
                    .reduce((acc, curr) => acc * curr.rate.strictValue, 1);

                this.model.totalRate.strictValue = Math.round(total * 100) / 100;
            });

        this.model.totalRate.disable({emitEvent: false});
    }

    private saveBet(): void {
        const form = this.model.toForm();
        this._service.clientBetAdd(form)
            .pipe(
                take(1),
                filter(u => !this._messaging.tryShowError(u)),
                untilDestroyed(this)
            )
            .subscribe(value => {
                this.result = value;
                this.model.betId.strictValue = value.result;
                this._messaging.showMessage('Ставка успешно добавлена');
            });
    }


    get options(): DropdownOptions {
        const dropdownOptions = new DropdownOptions();
        dropdownOptions.placeholder = 'Тип ставки';

        return dropdownOptions;
    }

    get addBetNgClass(): NgClassConfig {
        const hasResult = !isNullOrUndefined(this.result);
        const isResultCorrect = this.result?.isCorrect ?? false;

        return {
            'row': true,
            'alert': hasResult,
            'alert-success': hasResult && isResultCorrect,
            'alert-danger': hasResult && !isResultCorrect
        };
    }

    get warning(): string {
        return this.model.betId.hasStrictValue && this.model.betId.strictValue < this._minAllowedHardcodedBetId ?
            'Id ставки меньше 3.000.000.000 и будет заменён' : '';
    }
}
