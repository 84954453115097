<h1>Активные триггеры пользователя</h1>
<div>
  <div class="form-group col-3">
    <label>Id пользователя</label>
    <app-strict-input-number [control]="userId" placeholder="Введите значение">
    </app-strict-input-number>
  </div>
  <div class="form-group col-3">
    <button class="btn btn-primary" (click)="findByUserId()">Поиск</button>
  </div>
</div>
<div *ngIf="isLoading">
  <app-loading></app-loading>
</div>
<div *ngIf="!isLoading">
  <table class="table mt-3">
    <tr class="d-flex">
      <td class="col-1">
        ID
      </td>
      <td class="col-1">
        ID пользователя
      </td>
      <td class="col-1">
        ID рассылки
      </td>
      <td class="col-2">
        Триггер
      </td>
      <td class="col-1">
        Дата начала
      </td>
      <td class="col-1">
        Дата конца
      </td>
      <td class="col-2">
        Текущее событие
      </td>
      <td class="col-2">
        Следующее событие
      </td>
      <td class="col-1">
        Статус
      </td>
    </tr>
    <tr *ngFor="let trigger of resultModel.results" class="d-flex">
      <td class="col-1">
        <a [routerLink]="['/dashboard/triggers/history']"
           [queryParams]="{activeTriggerId: trigger.id,
            userId: trigger.userId,
             userTriggerId: trigger.userTrigger.id}">{{trigger.id}}</a>
      </td>
      <td class="col-1">
        {{trigger.userId}}
      </td>
      <td class="col-1">
        {{trigger.userTrigger.id}}
      </td>
      <td class="col-2">
        {{trigger.userTrigger.trigger.name}}
      </td>
      <td class="col-1">
        {{trigger.startDate | moscowDateTime}}
      </td>
      <td class="col-1">
        {{trigger.endDate | moscowDateTime}}
      </td>
      <td class="col-2">
        {{trigger.currentEvent?.name}}
      </td>
      <td class="col-2">
        {{trigger.nextEvent?.name}}
      </td>
      <td class="col-1">
        {{trigger.state | wlActiveUserTriggersStatesTransform}}
      </td>
    </tr>
  </table>
</div>
