<a class="mt-2 my-2 btn btn-primary" [routerLink]="['/dashboard/dictionaries/teams/add']">Добавить команду</a>
<app-loading *ngIf="loading"></app-loading>
<table class="table" *ngIf="!loading" style="width: 100%">
  <tr class="table-info">
    <th>
      Id команды
    </th>
    <th>
      Наименование команды
    </th>
    <th>
      Id ЛК
    </th>
    <th>
      Наименование ЛК
    </th>
    <th>
    </th>
  </tr>
  <tr *ngFor="let item of model.results">
    <td>
      {{item.id}}
    </td>
    <td>
      {{item.nameRus}}
    </td>
    <td>
      {{item.superId}}
    </td>
    <td>
      {{item.favouriteTeamName}}
    </td>
    <td>
      <button class="btn btn-danger" (click)="onDeleteClick(item.id)">Delete</button>
    </td>
  </tr>
</table>
<div *ngIf="!loading">
  <ga-paging (pageChangedEvent)="onPageChanged($event)" [pagedResult]="model"></ga-paging>
</div>
