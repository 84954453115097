  <div *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!isLoading">
    <label>Статус купона</label>
    <div class="row">
      <app-enum-dropdown [entityContainer]="couponStatus" [strategy]="couponDropdownStatusStrategy" [options]="options"></app-enum-dropdown>
    <div class="form-group col-1">
      <button class="btn btn-primary" (click)="findChosenCouponType()" >Поиск</button>
    </div>
      <div class="coupon-add ">
        <a class="mt-2 my-2 btn btn-primary" [routerLink]="['/dashboard/dictionaries/coupons/settings']">Панель купонов</a>
      </div>
    </div>
    <div>
    <table class="table">
      <tr class="row table-info">
        <td class="col-1">
          Id
        </td>
        <td class="col-2">
          Название
        </td>
        <td class="col-2">
          Дата создания
        </td>
        <td class="col-3">
          Дата окончания
        </td>
        <td class="col-4">
          Тип
        </td>
      </tr>
      <tr *ngFor="let item of viewModel.coupons.results" class="row {{isNewItem(item.beginDate)}}}">
        <td class="col-1">
          {{item.id}}
        </td>
        <td class="col-2">
          {{item.name}}
        </td>
        <td class="col-2">
          {{item.beginDate | moscowDateTime}}
        </td>
        <td class="col-3">
          {{item.endDate | moscowDateTime}}
        </td>
        <td class="col-4">
          <pre class="coupon-type">{{item.type | wlCouponsTypesTransform}}</pre>
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="!isLoading">
  <ga-paging *ngIf="isPagingNeeded" (pageChangedEvent)="pageChange($event)"
              [pagedResult]="viewModel.coupons"></ga-paging>
</div>
