<div class="row">
  <div class="col-6">
    <h1>Список фрибетов пользователя</h1>

    <div class="row">
      <app-strict-input-number class="col-6" [control]="model.userId"
                               [placeholder]="'Id пользователя'"></app-strict-input-number>
      <span class="col-6">
        <button class="btn btn-primary" (click)="search()">Поиск</button>
      </span>
    </div>
    <table class="table mt-3">
      <thead>
      <tr>
        <th scope="col">UserFreebetId</th>
        <th scope="col">Старт фрибета</th>
        <th scope="col">Окончание фрибета</th>
        <th scope="col">Id фрибета</th>
        <th scope="col">Тип фрибета</th>
        <th scope="col">Начисленно</th>
        <th scope="col">Статус</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let freebet of freebets">
        <th>{{freebet.id}}</th>
        <td>{{freebet.startDate | dateTime}}</td>
        <td>{{freebet.endDate | dateTime}}</td>
        <td>{{freebet.freebetId}}</td>
        <td>{{freebet.type | wlCouponsTypesTransform}}</td>
        <td>{{freebet.amount}}</td>
        <td>{{freebet.state | wlFreebetsStatesTransform}}</td>
      </tr>
      </tbody>
    </table>
    <div *ngIf="result">
      <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
    </div>
  </div>
  <div class="col-6">
    <app-user-cancel-freebet></app-user-cancel-freebet>
  </div>
</div>
