<select class="form-control" #items (change)="selectEntity(items.value)"
        [class.is-invalid]="entityContainer.shouldDisplayErrors | async"
        [style.disabled]="entityContainer.disabled">
  <option>{{options?.placeholder}}</option>
  <option [selected]="item.id === selectedItem?.id" *ngFor="let item of currentItemsSource" role="option"
          [value]="item.id">
    {{item.title}}
  </option>
</select>
<div class="invalid-feedback">
  <div *ngFor="let error of entityContainer.strictErrors | async">
    {{error.message}}
  </div>
</div>
