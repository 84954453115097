import { Component, Input, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { WlErpDailyTaskPromotionDayApiModel } from '../../../../../../services/autogen/DailyTasks';
import { Dayjs } from 'dayjs';

@Component({
    selector: 'app-daily-tasks-erp-days[days]',
    templateUrl: './daily-tasks-erp-days.component.html',
    styleUrls: ['./daily-tasks-erp-days.component.css']
})
export class DailyTasksErpDaysComponent implements OnInit {
    @Input()
    days: WlErpDailyTaskPromotionDayApiModel[];

    public daysText = new Map<Dayjs, DayInfo>();

    constructor() {
    }

    public ngOnInit(): void {
        this.init();
    }

    public clickText(elem: KeyValue<Dayjs, DayInfo>): string {
        return elem.value.show ? 'Скрыть' : 'Показать';
    }

    public onClick(elem: KeyValue<Dayjs, DayInfo>): void {
        elem.value.show = !elem.value.show;
    }

    private init(): void {
        this.days.forEach(u => {
            const text = JSON.stringify(u, null, 2);
            this.daysText.set(u.startDay, {id: u.dayId, text: text, show: false});
        });
    }
}

class DayInfo {
    id: number;
    text: string;
    show: boolean;
}
