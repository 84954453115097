<div>
  <label class="mt-2">Создать событие обновления прогресса/выполнения задания</label>
  <div class="row">
    <div class="col-2">
      {{"Id подписки"}}
    </div>
    <div class="col-2">
      {{"Текущий прогресс по заданию"}}
    </div>
    <div class="col-2">
      {{"Дата события"}}
    </div>
  </div>
  <div class="mt-2 row">
    <app-strict-input-number class="col-2"
                             placeholder="Id подписки"
                             [control]="model.subscriptionId">
    </app-strict-input-number>
    <app-strict-input-number class="col-2"
                             placeholder="Текущий прогресс"
                             [control]="model.currentProgress">
    </app-strict-input-number>
    <strict-date-time-picker class="col-2 timeline-marker"
                          placeholder="Дата события"
                          [control]="model.operationDate">
    </strict-date-time-picker>
  </div>
  <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
