<div>
  <label class="mt-2">Результат тура x5</label>
  <div>
    <div class="row mt-2">
      <div class="col-3">
        Id пользователя
      </div>
      <div class="col-3">
        Дата
      </div>
      <div class="col-4">
        Уровень купона (1 - новичок, 2 - игрок, 3 - вип)
      </div>
      <div class="col-2">
        Количество угаданных исходов
      </div>
    </div>
    <div class="row {{isError()}}">
      <div class="col-3">
        <app-strict-input-number [control]="userId" [placeholder]="'Id пользователя'">
        </app-strict-input-number>
      </div>
      <div class="col-3">
        <strict-date-time-picker [control]="userDate"></strict-date-time-picker>
      </div>
      <div class="col-4">
        <app-strict-input-number [control]="loyaltyLevel" [placeholder]="'Уровень купона'">
        </app-strict-input-number>
      </div>
      <div class="col-2">
        <app-strict-input-number [control]="winsCount" [placeholder]="'Количество угадано'">
        </app-strict-input-number>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
  </div>
</div>
