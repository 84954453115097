<div>
    <label class="mt-2">Добавление новой категории Cashback в справочник</label>

    <div class="row">
        <div class="col-2">
            {{"Название категории"}}
        </div>
        <div class="col-2">
            {{"Описание категории"}}
        </div>
    </div>

    <div class="mt-2 row">
        <app-strict-input-text class="col-2 timeline-marker"
                                 placeholder="Название категории"
                                 [control]="addModel.name">
        </app-strict-input-text>
        <app-strict-input-text class="col-2 timeline-marker"
                               placeholder="Описание категории"
                               [control]="addModel.tultip">
        </app-strict-input-text>
    </div>

    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>

<div *ngIf="loading">
    <app-loading></app-loading>
</div>
<hr>
<div class="mt-2 row">
    <app-strict-input-number class="col-3"
                             (keydown.enter)="search()"
                             [control]="searchModel.categoryId"
                             placeholder="Id категории"
                             [title]="'Id категории'">
    </app-strict-input-number>

    <app-strict-input-text class="col-3"
                             (keydown.enter)="search()"
                             [control]="searchModel.categoryName"
                             placeholder="Название"
                             [title]="'Название'">
    </app-strict-input-text>
</div>

<div *ngIf="!loading">
    <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
    <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
