import { Component, OnInit } from '@angular/core';
import { MockRateRaceResultPlaceViewModel, MockRateRaceResultsCrudViewModel } from '../../../../models/rate-race/mock-rate-race-results-crud-view-model';
import { ActivatedRoute, Router } from '@angular/router';
import { RateRaceResultsService } from 'src/app/services/autogen/BattlePass';
import { GaMessagingService, StrictError } from '@koddington/ga-common';
import { Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RateRaceResultsCrudValidator } from '../../validators/rate-race-results-crud-validator';
import { IRateRaceResultsCrudStrategy } from './strategies/i-rate-race-results-crud-strategy';
import { RateRaceResultCreateStrategy } from './strategies/rate-race-result-create-strategy';
import { RateRaceResultUpdateStrategy } from './strategies/rate-race-result-update-strategy';
import { RateRaceResultRepeatStrategy } from './strategies/rate-race-result-repeat-strategy';
import { UsersRateRaceService } from 'src/app/services/autogen/BattlePass';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-rate-race-results-crud',
    templateUrl: './rate-race-results-crud.component.html'
})
export class RateRaceResultsCrudComponent implements OnInit {
    public viewModel = new MockRateRaceResultsCrudViewModel();
    public readonly userManipulationsSource = new Subject<void>();
    public loading: boolean = true;

    private readonly validator = new RateRaceResultsCrudValidator();
    private errors: StrictError[] = [];

    private _strategy: IRateRaceResultsCrudStrategy;

    constructor(private readonly _activeRoute: ActivatedRoute,
                private readonly _router: Router,
                private readonly _service: RateRaceResultsService,
                private readonly _messaging: GaMessagingService,
                private readonly _registrations: UsersRateRaceService) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public save(): void {
        return this._strategy.save(this.viewModel);
    }

    public addAllRegisteredUsers(): void {
        if (!this.viewModel.battlePassSeasonId.hasStrictValue) {
            this._messaging.showMessage('Введите id сезона БП');
            return;
        }

        this._registrations.registeredByBattlePassSeasonId(this.viewModel.battlePassSeasonId.strictValue)
            .pipe(take(1))
            .subscribe(res => res.forEach(u => {
                if (this.viewModel.userPlaces.strictValue.some(v => v.userId.strictValue === u)) {
                    return;
                }

                const newVal = new MockRateRaceResultPlaceViewModel();
                newVal.userId.strictValue = u;

                this.viewModel.userPlaces.strictValue.push(newVal);
            }));
    }

    public addUser(index: number) {
        this.viewModel.userPlaces.strictValue.splice(index + 1, 0, new MockRateRaceResultPlaceViewModel());
        this.userManipulationsSource.next();
    }

    public deleteUser(index: number) {
        this.viewModel.userPlaces.strictValue.splice(index, 1);
        this.userManipulationsSource.next();
    }

    private init() {
        this._strategy = this.getStrategy();
        if (this._strategy === null) {
            this._messaging.showMessage('Страница не найдена');
            return;
        }

        this._strategy.loadViewModel().subscribe(res => {
            this.viewModel = res;
            this.subscribeToValidate();
            this.loading = false;
        });
    }

    private subscribeToValidate() {
        this.userManipulationsSource
            .pipe(untilDestroyed(this))
            .subscribe(res => this.errors = this.validator.validate(this.viewModel));

        this.userManipulationsSource.next();
    }

    get headerText(): string {
        return this._strategy.getHeaderText();
    }

    get canDelete(): boolean {
        return this.viewModel.userPlaces.strictValue?.length > 1;
    }

    get isValid(): boolean {
        return this.errors.length === 0;
    }

    private getStrategy(): IRateRaceResultsCrudStrategy {
        const url = this._activeRoute.snapshot.url;
        if (!url || url.length < 2) {
            return new RateRaceResultCreateStrategy(this._service, this._router, this._messaging);
        }

        const tableId = parseInt(url[1].path, 10);
        switch (url[0].path) {
            case 'repeat':
                return new RateRaceResultRepeatStrategy(this._service, this._router, this._messaging, tableId);
            case 'update':
                return new RateRaceResultUpdateStrategy(this._service, this._messaging, tableId);
        }
        return null;
    }
}
