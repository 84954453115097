import { DropdownItem, EnumExtensions, IDropdownStrategy } from '@koddington/ga-common';
import { WlUserEventType, WlUserEventTypeExtensions } from '../../../../../services/autogen/Events';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WlUserEventTypeStrategy implements IDropdownStrategy<WlUserEventType> {
    map(model: WlUserEventType): DropdownItem<WlUserEventType> {
        return {
            entity: model,
            title: WlUserEventTypeExtensions.format(model),
            id: Number(model)
        };
    }
    getEntities(): Observable<WlUserEventType[]> {
        return of(EnumExtensions.toArray(WlUserEventType));
    }
}
