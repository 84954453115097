import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { UsersBalancesService } from 'src/app/services/autogen/Dictionaries';
import {UpdateBalanceViewModel} from './models/update-balance.view-model';
import {UpdateFormValidator} from './validators/update-form.validator';
import { AddBalanceForm } from 'src/app/services/autogen/Dictionaries';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {filter, finalize, first} from 'rxjs/operators';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-update-balance-form',
  templateUrl: './update-balance-form.component.html',
  styleUrls: ['./update-balance-form.component.css']
})
export class UpdateBalanceFormComponent implements OnInit {

  @Output()
  public balanceChanged = new EventEmitter<void>();

  public loading = true;
  public viewModel = new UpdateBalanceViewModel();

  constructor(private readonly _apiClient: UsersBalancesService,
              private readonly _validator: UpdateFormValidator,
              private readonly _messages: GaMessagingService) {
  }

  public ngOnInit(): void {
  }

  public save(): void {
    const errors = this._validator.validate(this.viewModel);
    if (errors.length > 0) {
      return;
    }

    const form = new AddBalanceForm();
    form.userId = this.viewModel.userId.strictValue;
    form.balance = this.viewModel.amount.strictValue;
    form.bonusBalls = this.viewModel.balls.strictValue;
    form.inGameBetsSum = this.viewModel.inGameBetsSum.strictValue;

    this.loading = true;
    this._apiClient.update(form)
      .pipe(
        first(),
        filter(u => !this._messages.tryShowError(u)),
        finalize(() => this.loading = false),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this._messages.showMessage('Баланс обновлен');
        this.balanceChanged.emit();
      });
  }
}
