<app-custom-tournaments-table-item-crud></app-custom-tournaments-table-item-crud>
<div>
  <h3>Турнирные таблицы</h3>
  <div class="row">
    <app-strict-input-number class="col-3"
                             [control]="tournamentId"
                             [placeholder]="'Id турнира'"
    ></app-strict-input-number>
    <app-strict-input-boolean class="col-3"
                             [control]="onlyActiveEntries"
                             [label]="'Только активные'"
    ></app-strict-input-boolean>
    <span>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </span>
  </div>
  <div *ngIf="loading">
    <app-loading></app-loading>
  </div>
  <div class="col-6" *ngIf="!loading">
    <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
    <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
