import {AbstractModelValidator} from '@koddington/ga-common';
import {AddDocAndSelfieViewModel} from '../../../models/events/components-models/add-doc-and-selfie-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class AddSelfieAndDocValidator extends AbstractModelValidator<AddDocAndSelfieViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).requiredAndNotZero().min(1);
    this.ruleForControlIf(m => m.selfieDate, u => !u.documentLoadingDate.hasStrictValue)
      .required();

    this.ruleForControlIf(m => m.documentLoadingDate, u => !u.selfieDate.hasStrictValue)
      .required();
  }
}
