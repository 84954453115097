import { WlGamePlatforms } from 'src/app/services/autogen/BattlePass';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class BattlePassUserProfileBindingViewModel {
  secret: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  userId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  accountId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  avatarLink: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  nickname: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  platform: StrictFormControl<WlGamePlatforms> = StrictFormControlBuilder.buildNullNumber<WlGamePlatforms>();
}

