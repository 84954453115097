import { ApiStates } from 'src/app/services/autogen/ControlPanel';
import { StrictFormControlBuilder } from '@koddington/ga-common';

export class ApiStatesViewModel {
  public balanceByLoginsMethodState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public docsAndSelfiesMethodState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public clientsRegistrationsMethodState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public mechanicsEnablingQueueState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public mechanicsTriggeringQueueState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public mechanicsBonusUsingQueueState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public betsState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public mediaBtagsMethodState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public mediaPromoIdsMethodState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public mediaSourcesMethodState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public mediaChannelsMethodState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public mediaUserBtagsMethodState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public uniQueueForBroadcastState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public uniQueueBroadcastCsvDownloadState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public pushInfoState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public battlePassFreeBetQueueState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public logoServiceState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public customTournamentState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public rateRaceExchangeQueueState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public userRateRaceQueueState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public rateRaceResultsQueueState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
  public cashbackBonusState = StrictFormControlBuilder.buildNullNumber<ApiStates>();
}
