<div class="row">
    <label class="col-1 bt-search-token">Search by token:</label>
    <app-strict-input-text class="col-5" [control]="model.token" [placeholder]="'...Укажите токен устройства'"></app-strict-input-text>
    <div class="col-1 bt-search-token">
      <button class="btn btn-primary" (click)="search()">Поиск</button>
    </div>
</div>
<div class="container-fluid ios-div-container-table" *ngIf="!loading">
  <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
  <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>

<ng-template #showPayload let-payload="payload">
  <pre class="wl-payloads">{{payload | json}}</pre>
</ng-template>
