import {Component, OnInit} from '@angular/core';
import { UserSegmentationService, WlSyncUserProfile } from 'src/app/services/autogen/Segmentation';
import { WlFilteredPagedResult } from 'src/app/services/autogen/Shared';
import {finalize, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaPagingForm} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-sync-profiles',
  templateUrl: './user-sync-profiles.component.html',
  styleUrls: ['./user-sync-profiles.component.css']
})
export class UserSyncProfilesComponent implements OnInit {

  private loading = false;
  private result: WlFilteredPagedResult<WlSyncUserProfile, GaPagingForm>;
  constructor(private readonly service: UserSegmentationService) { }

  ngOnInit(): void {
    this.load(0);
  }

  load(offset: number): void {
    this.loading = true;
    this.service.userProfilesList(offset)
      .pipe(take(1), untilDestroyed(this),
        finalize(() => this.loading = false))
      .subscribe(value => this.result = value);
  }
  changePage(form: GaPagingForm): void {
    this.load(form.offset);
  }
  get profiles(): WlSyncUserProfile[] {
    return this.result.results;
  }
  get currentResult(): WlFilteredPagedResult<WlSyncUserProfile, GaPagingForm> {
    return this.result;
  }
  get isLoading(): boolean {
    return this.loading;
  }
}
