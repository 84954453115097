import { Routes } from '@angular/router';
import { MainLayoutComponent } from './modules/layouts/main-layout/main-layout.component';
import { BroadcastsModule } from './modules/broadcasts/broadcasts.module';
import { CouponsModule } from './modules/dictionaries/coupons/coupons.module';
import { EventsModule } from './modules/events/events.module';
import { SegmentsModule } from './modules/segments/segments.module';
import { UsersModule } from './modules/users/users.module';
import { PushModule } from './modules/push/push.module';
import { MechanicsModule } from './modules/mechanics/mechanics.module';
import { TriggersModule } from './modules/triggers/triggers.module';
import { CallsModule } from './modules/calls/calls.module';
import { DictionariesLayoutComponent } from './modules/layouts/dictionaries-layout/dictionaries-layout.component';
import {
    WinlineApiStatesComponent
} from './modules/control-panel/winline-server/components/winline-api-states/winline-api-states.component';
import { ControlPanelLayoutComponent } from './modules/layouts/control-panel-layout/control-panel-layout.component';
import { BalancesListComponent } from './modules/dictionaries/balances/components/balances-list/balances-list.component';
import {
    SecurityGroupsListComponent
} from './modules/dictionaries/security-groups/components/security-groups-list/security-groups-list.component';
import { SmsGateLayoutComponent } from './modules/layouts/sms-gate-layout/sms-gate-layout.component';
import { SentListComponent } from './modules/sms-gate/components/sms/sent-list/sent-list.component';
import { PhonesListComponent } from './modules/sms-gate/components/phones/phones-list/phones-list.component';
import { AccountsListComponent } from './modules/sms-gate/components/accounts/accounts-list/accounts-list.component';
import { AttemptsListComponent } from './modules/sms-gate/components/attempts/attempts-list/attempts-list.component';
import { SportTypesListComponent } from './modules/dictionaries/sport-types/components/sport-types-list/sport-types-list.component';
import { PushGateMockModule } from './modules/push-gate/push-gate.module';
import { EventsNotificationsModule } from './modules/events-notifications/events-notifications.module';
import { LifetimeCallEventsModule } from './modules/events/events-components/lifetime-call-events-changes/lifetime-call-events.module';
import {
    LifetimeCallEventsLayoutComponent
} from './modules/events/events-components/lifetime-call-events-changes/lifetime-call-events-layout/lifetime-call-events-layout.component';
import {
    LifetimeCallEventsListComponent
} from './modules/events/events-components/lifetime-call-events-changes/lifetime-call-events-list/lifetime-call-events-list.component';
import {
    LifetimeCallEventsChangesComponent
} from './modules/events/events-components/lifetime-call-events-changes/lifetime-call-events-changes.component';
import { CountriesListComponent } from './modules/dictionaries/countries/components/countries-list/countries-list.component';
import { MarketsListComponent } from './modules/dictionaries/markets/components/markets-list/markets-list.component';
import { MediaModule } from './modules/media/media.module';
import {
    AddMatchTeamComponent
} from './modules/dictionaries/match-teams/components/add-match-team/add-match-team.component';
import {
    MatchTeamsListComponent
} from './modules/dictionaries/match-teams/components/match-teams-list/match-teams-list.component';
import { BattlePassModule } from './modules/battle-pass/battle-pass.module';
import { LogoServiceModule } from './modules/logo-service/logo-service.module';
import { CustomTournamentsModule } from './modules/custom-tournaments/custom-tournaments.module';
import { RateRaceModule } from './modules/rate-race/rate-race.module';
import { FaceItModule } from './modules/face-it/face-it.module';
import { RateUsRoutes } from './modules/rate-us/rate-us.routes';
import { RateUsModule } from './modules/rate-us/rate-us.module';
import { DailyTasksModule } from './modules/daily-tasks/daily-tasks.module';

export const AppRoutes: Routes = [
    {
        path: 'dashboard',
        component: MainLayoutComponent,
        children: [
            {
                path: 'control-panel',
                component: ControlPanelLayoutComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'winline-api/states'
                    },
                    {
                        path: 'winline-api/states',
                        component: WinlineApiStatesComponent
                    }
                ]
            },
            {
                path: 'dictionaries',
                component: DictionariesLayoutComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'coupons'
                    },
                    {
                        path: 'coupons',
                        loadChildren: () => CouponsModule
                    },
                    {
                        path: 'media',
                        loadChildren: () => MediaModule
                    },
                    {
                        path: 'teams/list',
                        component: MatchTeamsListComponent
                    },
                    {
                        path: 'teams/add',
                        component: AddMatchTeamComponent
                    },
                    {
                        path: 'balances',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                redirectTo: 'list'
                            },
                            {
                                path: 'list',
                                component: BalancesListComponent
                            }
                        ]
                    },
                    {
                        path: 'security-groups',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                redirectTo: 'list'
                            },
                            {
                                path: 'list',
                                component: SecurityGroupsListComponent
                            }
                        ]
                    },
                    {
                        path: 'sport-types',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                redirectTo: 'list'
                            },
                            {
                                path: 'list',
                                component: SportTypesListComponent
                            }
                        ]
                    },
                    {
                        path: 'countries',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                redirectTo: 'list'
                            },
                            {
                                path: 'list',
                                component: CountriesListComponent
                            }
                        ]
                    },
                    {
                        path: 'markets',
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                redirectTo: 'list'
                            },
                            {
                                path: 'list',
                                component: MarketsListComponent
                            }
                        ]
                    }
                ]
            },
            {
                path: 'sms-gate',
                component: SmsGateLayoutComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'sent-sms/list'
                    },
                    {
                        path: 'sent-sms/list',
                        component: SentListComponent
                    },
                    {
                        path: 'phones/list',
                        component: PhonesListComponent
                    },
                    {
                        path: 'accounts/list',
                        component: AccountsListComponent
                    },
                    {
                        path: 'attempts/list',
                        component: AttemptsListComponent
                    }

                ]
            },
            {
                path: 'lifetime-call',
                component: LifetimeCallEventsLayoutComponent,
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'lifetime-call'
                    },
                    {
                        path: 'lifetime-call/list',
                        component: LifetimeCallEventsListComponent
                    },
                    {
                        path: 'lifetime-call/add',
                        component: LifetimeCallEventsChangesComponent
                    }
                ]
            },

            {
                path: 'push-gate',
                //component: PushMockListComponent
                loadChildren: () => PushGateMockModule
            },
            {
                path: 'broadcasts',
                loadChildren: () => BroadcastsModule
            },
            {
                path: 'events',
                loadChildren: () => EventsModule
            },
            {
                path: 'segments',
                loadChildren: () => SegmentsModule
            },
            {
                path: 'users',
                loadChildren: () => UsersModule
            },
            {
                path: 'push',
                loadChildren: () => PushModule
            },
            {
                path: 'mechanics',
                loadChildren: () => MechanicsModule
            },
            {
                path: 'triggers',
                loadChildren: () => TriggersModule
            },
            {
                path: 'calls',
                loadChildren: () => CallsModule
            },
            {
                path: 'lifetime-call',
                loadChildren: () => LifetimeCallEventsModule
            },
            {
                path: 'events-notifications',
                loadChildren: () => EventsNotificationsModule
            },
            {
                path: 'battle-pass',
                loadChildren: () => BattlePassModule
            },
            {
                path: 'logo-service',
                loadChildren: () => LogoServiceModule
            },
            {
                path: 'custom-tournaments',
                loadChildren: () => CustomTournamentsModule
            },
            {
                path: 'rate-race',
                loadChildren: () => RateRaceModule
            },
            {
                path: 'face-it',
                loadChildren: () => FaceItModule
            },
            {
                path: 'rate-us',
                loadChildren: () => RateUsModule
            },
            {
                path: 'daily-tasks',
                loadChildren: () => DailyTasksModule
            },
            {
                path: '**',
                redirectTo: '/dashboard/dictionaries/coupons/list'
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/dashboard/users/list'
    }
];
