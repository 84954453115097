import {
  WlDocsAndSelfieVerificationStatus,
  WlDocsAndSelfieVerificationStatusExtensions
} from 'src/app/services/autogen/Events';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Observable, of} from 'rxjs';
import {EnumExtensions, IDropdownStrategy} from '@koddington/ga-common';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocsAndSelfieVerificationStrategy implements IDropdownStrategy<WlDocsAndSelfieVerificationStatus> {
  getEntities(): Observable<Array<WlDocsAndSelfieVerificationStatus>> {
    return of(EnumExtensions.toArray(WlDocsAndSelfieVerificationStatus));
  }

  map(model: WlDocsAndSelfieVerificationStatus): DropdownItem<WlDocsAndSelfieVerificationStatus> {
    const item = new DropdownItem<WlDocsAndSelfieVerificationStatus>();
    item.entity = model;
    item.id = model.toString();
    item.title = WlDocsAndSelfieVerificationStatusExtensions.format(model);

    return item;
  }
}
