import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { PushHistoryAndroid } from '../components/push-mock-android-list/models/push-history-android';
import { PUSH_PREFIX_URL_API } from '../consts/const-urls';
import { MockPushSevice } from './push-mock-apns-list.api-client';
import {GaPagedResult} from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class MockPushGCMSevice {

 constructor(private readonly _http: MockPushSevice<PushHistoryAndroid>) {}

  public getList(count: number, offset: number, token: string): Observable<GaPagedResult<PushHistoryAndroid>> {
    return this._http.getList(PUSH_PREFIX_URL_API.AndroidList, count, offset, token);
  }
}

