<div class="col-5"><h1>Шаблоны</h1>
  <app-push-template-add (valueAdded)="valueAdded($event)"></app-push-template-add>
</div>
<div *ngIf="IsLoading">
  <app-loading></app-loading>
</div>
<div *ngIf="!IsLoading" class="col-6">
  <table class="table mt-3">
    <thead>
    <tr>
      <th scope="col">Код шаблона</th>
      <th scope="col">Имя</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let template of models">
      <th>{{template.templateCode}}</th>
      <td>{{template.valuesView}}</td>
    </tr>
    </tbody>
  </table>
  <div>
    <ga-paging *ngIf="pagingNeeded" [pagedResult]="getResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
