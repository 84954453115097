import {Component, OnInit} from '@angular/core';
import { UserMechanicsProcessingService, WinlineBonusUsingStatus } from 'src/app/services/autogen/UserMechanics';
import {UserMechanicsNavigationService} from '../services/user-mechanics-navigation.service';
import {ActivatedRoute} from '@angular/router';
import {DropdownOptions} from '../../../models/common/enum-dropdown/dropdown-options';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {MechanicBonusUsingRequestStatusDropdownStrategy} from '../../shared/common/enum-dropdown/strategies/mechanic-bonus-using-request-status.dropdown-strategy';
import {MechanicBonusUsingRequestViewModelValidator} from '../validators/mechanic-bonus-using-request-view-model.validator';
import {MechanicBonusUsingRequestStrictViewModel} from '../../../models/mechanics/mechanic-bonus-using-request-strict-view.model';
import { GaMessagingService, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-mechanics-bonus-using-requests',
  templateUrl: './user-mechanics-bonus-using-requests.component.html',
  styleUrls: ['./user-mechanics-bonus-using-requests.component.css']
})
export class UserMechanicsBonusUsingRequestsComponent implements OnInit {

  public models: MechanicBonusUsingRequestStrictViewModel[] = [];
  public allStatesControl = StrictFormControlBuilder.buildNullNumber<WinlineBonusUsingStatus>();

  private _sequenceId: number;

  constructor(public readonly strategy: MechanicBonusUsingRequestStatusDropdownStrategy,
              private readonly processingService: UserMechanicsProcessingService,
              private readonly validator: MechanicBonusUsingRequestViewModelValidator,
              private readonly navigation: UserMechanicsNavigationService,
              private readonly route: ActivatedRoute,
              private readonly messages: GaMessagingService) {
  }

  get dropDownOptions(): DropdownOptions {
    const options = new DropdownOptions();
    options.placeholder = 'Выберите тип';
    options.classname = 'enabling-request-type';
    return options;
  }

  public ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this._sequenceId = Number(routeParams.get('id'));
    this.load();
  }

  public setStatusForAll(): void {
    if (!this.allStatesControl.hasStrictValue) {
      this.messages.showMessage('Выберите статус');
      return;
    }

    this.processingService.rawSetStatusForAllBonusUsingRequest(this._sequenceId, this.allStatesControl.strictValue)
      .pipe(
        take(1),
        filter(res => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(() => this.load());
  }

  public saveNewStatus(model: MechanicBonusUsingRequestStrictViewModel): void {
    const errors = this.validator.validate(model);
    if (errors.length > 0) {
      return;
    }

    this.processingService.setStatusForBonusUsingRequest(model.request.id, model.newStatus.strictValue)
      .pipe(
        take(1),
        filter(res => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(() => this.load());
  }

  private load(): void {
    this.processingService.getBonusUsingRequests(this._sequenceId)
      .pipe(
        take(1),
        filter((res) => !this.messages.tryShowError(res)),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.models = value.result.map(u => {
          return {
            request: u,
            newStatus: StrictFormControlBuilder.buildNullNumber<WinlineBonusUsingStatus>().withValue(u.processingStatus)
          };
        });
      });
  }

}
