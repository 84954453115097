import {Component, OnInit} from '@angular/core';
import { UsersBalancesService } from 'src/app/services/autogen/Dictionaries';
import {Observable} from 'rxjs';
import {ActivatedRoute, Params} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {BalancesFilterViewModel} from './models/balances-filter.view-model';
import {GaMessagingService, GaPagingForm, isNullOrUndefined} from '@koddington/ga-common';
import {debounceTime, distinctUntilChanged, filter, finalize, first, map, switchMap, tap} from 'rxjs/operators';
import { UserBalancesFilterForm } from 'src/app/services/autogen/Dictionaries';
import { WlClientBalance } from 'src/app/services/autogen/Dictionaries';
import { WlFilteredPagedResult } from 'src/app/services/autogen/Shared';

@UntilDestroy()
@Component({
  selector: 'app-balances-list',
  templateUrl: './balances-list.component.html',
  styleUrls: ['./balances-list.component.css']
})
export class BalancesListComponent implements OnInit {

  public loading = true;
  public currentFilter = new BalancesFilterViewModel();
  public list: WlFilteredPagedResult<WlClientBalance, UserBalancesFilterForm>;

  constructor(private readonly _apiClient: UsersBalancesService,
              private readonly _activeRoute: ActivatedRoute,
              private readonly _messages: GaMessagingService) {
  }

  public ngOnInit(): void {
    this._activeRoute.queryParams
      .pipe(
        tap((params) => this.fillFilter(params)),
        switchMap(() => this.loadList()),
        untilDestroyed(this)
      )
      .subscribe();

    this.currentFilter.userId.strictValueSource.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap(() => this.loadList()),
      untilDestroyed(this)
    ).subscribe();
  }

  public refreshList(): void {
    this.currentFilter.offset = 0;
    this.loadList().subscribe();
  }

  public onPageChanged(form: GaPagingForm): void {
    this.currentFilter.offset = form.offset;

    this.loadList().subscribe();
  }

  private fillFilter(params: Params): void {
    this.currentFilter.count = 10;
    this.currentFilter.offset = isNullOrUndefined(params['offset']) ? 0 : Number(params['offset']);
    if (!isNullOrUndefined(params['userId'])) {
      this.currentFilter.userId.strictValue = Number(params['userId']);
    }
  }

  private loadList(): Observable<void> {
    this.loading = true;

    const form = new UserBalancesFilterForm();
    form.userId = this.currentFilter.userId.strictValue;
    form.offset = this.currentFilter.offset;
    form.count = this.currentFilter.count;

    return this._apiClient.list(form)
      .pipe(
        first(),
        filter((u) => !this._messages.tryShowError(u)),
        map(u => u.result),
        finalize(() => this.loading = false),
        map(res => {
          this.list = res;
        }),
        untilDestroyed(this)
      );
  }
}
