import {Component, OnInit} from '@angular/core';
import {AddFinanceOperationViewModel} from '../../../../models/events/components-models/add-finance-operation-view-model';
import {WlResult} from '../../../../models/common/results/wl-result';
import { EventsService } from 'src/app/services/autogen/Events';
import {AddFinanceOperationValidator} from '../../validators/add-finance-operation-validator';
import { AddFinanceOperationForm } from 'src/app/services/autogen/Events';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaMessagingService, GaPanelGridConfig, GaStrictModelFactory, GaTitlePositions, isNullOrUndefined} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-add-finance-operation',
  templateUrl: './add-finance-operation.component.html',
  styleUrls: ['./add-finance-operation.component.css']
})
export class AddFinanceOperationComponent implements OnInit {

  public readonly model: AddFinanceOperationViewModel = new AddFinanceOperationViewModel();
  config = new GaPanelGridConfig();
  private result: WlResult<boolean>;

  constructor(private readonly service: EventsService,
              private readonly validator: AddFinanceOperationValidator,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
    this.buildGrid();
  }

  public add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }

    const form = GaStrictModelFactory.buildModel(AddFinanceOperationForm, this.model);

    this.service.operationDateAdd(form).pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Финансовая операция успешно исполненна');
      });
  }

  private buildGrid() {
    this.config.columnsCount = 5;
    this.config.titlePositions = GaTitlePositions.Top;
    this.config.addNumbers(this.model.userId, 'Id пользователя')
      .addDateTime(this.model.operationDate, 'Дата операции')
      .addNumbers(this.model.operationSum, 'Сумма операции*')
      .addDateTime(this.model.paymentSystemConfirmationDate, 'Дата подтверждения')
      .addNumbers(this.model.documentId, 'Id документа (Для кэшбека за бонус клуб - 19)')
      .addNumbers(this.model.operationId, 'Id операции')
      .addNumbers(this.model.operationSourceId, 'Id источника')
      .addText(this.model.comment, 'Комментарий');
  }

  public resultMarker(): string {
    if (isNullOrUndefined(this.result)) {
      return;
    }
    return this.result.isCorrect ? 'alert alert-success' : 'alert alert-danger';
  }
}
