import { AbstractModelValidator, StrictError, ValidationLevel } from '@koddington/ga-common';
import { StartBroadcastViewModel } from '../../../models/broadcasts/start-broadcast-view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class StartBroadcastValidator extends AbstractModelValidator<StartBroadcastViewModel> {
    constructor() {
        super();
        this.ruleForControl((m) => m.date)
            .required();

        this.ruleForControl((m) => m.excludingMode)
            .required();

        this.ruleForControl((m) => m.file)
            .required()
            .customRule(u => u?.size === 0
                ? new StrictError('emptyFile', 'пустой файл')
                : null);

        this.ruleForControl((m) => m.templateCode)
            .requiredAndNotZero();
    }
}
