import {CouponViewModel} from '../../../../models/coupons/coupon-view-model';
import {AbstractModelValidator} from '@koddington/ga-common';

export class UpdateCouponValidator extends AbstractModelValidator<CouponViewModel> {
  constructor() {
    super();
    this.ruleForControl(u => u.couponId).requiredAndNotZero('Id купона введён неверно');
    this.ruleForControl(u => u.expirationDate).required('Введённая дата истечения купона неверна');
  }
}
