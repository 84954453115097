import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class XFiveResultViewModel {
  userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  userDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  winsCount: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  loyaltyLevel: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
}

