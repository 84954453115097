<div>
  <h4>Спортивные матчи</h4>

  <div>
    <h4>Смена любимой команды</h4>
    <app-favorite-team-change></app-favorite-team-change>
    <hr>
    <h4>Смена любимой сборной</h4>
    <app-add-favourite-national-team-changes></app-add-favourite-national-team-changes>
    <hr>
    <h5>Новый матч</h5>
    <div class="row">
      <div class="col">
        Id матча
      </div>
      <div class="col">
        Id чемпионата
      </div>
      <div class="col">
        Категория матча
      </div>
      <div class="col">
        Дата матча
      </div>
      <div class="col">
        Первый игрок
      </div>
      <div class="col">
        Второй игрок
      </div>
      <div class="col">
        Id спорта
      </div>
      <div class="col">
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-strict-input-number [control]="viewModel.matchId" placeholder="Id матча"></app-strict-input-number>
      </div>
      <div class="col">
        <app-strict-input-number [control]="viewModel.championshipId"
                                 placeholder="Id чемпионата"></app-strict-input-number>
      </div>
      <div class="col">
        <app-strict-input-number [control]="viewModel.category" placeholder="Категория матча"></app-strict-input-number>
      </div>
      <div class="col">
        <strict-date-time-picker [control]="viewModel.matchDate"></strict-date-time-picker>
      </div>
      <div class="col">
        <app-strict-autocomplete [control]="viewModel.firstPlayer"
                                 [strategy]="firstPlayerStrategy"
                                 placeholder="Первый игрок"></app-strict-autocomplete>
      </div>
      <div class="col">
        <app-strict-autocomplete [control]="viewModel.secondPlayer"
                                 [strategy]="secondPlayerStrategy"
                                 placeholder="Второй игрок"></app-strict-autocomplete>
      </div>
      <div class="col">
        <app-strict-input-number [control]="viewModel.sportId"
                                 placeholder="Id спорта"></app-strict-input-number>
      </div>
      <div class="col">
        <button class="btn btn-success" (click)="add()">Добавить</button>
      </div>
    </div>
    <hr>
    <div class="row">
      <app-strict-input-number class="col-2" [control]="pagingViewModel.matchId"
                               placeholder="Id матча"></app-strict-input-number>
      <button class="col-1 btn btn-primary" (click)="search()">Поиск</button>
    </div>
    <hr>
    <div class="row">
      <div class="col">
        Id матча
      </div>
      <div class="col">
        Id чемпионата
      </div>
      <div class="col">
        Категория матча
      </div>
      <div class="col">
        Дата матча
      </div>
      <div class="col">
        Id первого игрока
      </div>
      <div class="col">
        Id второго игрока
      </div>
      <div class="col">
        Id первой команды
      </div>
      <div class="col">
        Id второй команды
      </div>
      <div class="col">
        Id Спорта
      </div>
      <div class="col">
      </div>
    </div>
    <div *ngIf="matches">
      <div *ngFor="let match of matches.results" class="row mt-1">
        <div class="col">
          {{match.matchId}}
        </div>
        <div class="col">
          {{match.championshipId}}
        </div>
        <div class="col">
          {{match.category}}
        </div>
        <div class="col">
          {{match.matchDate | dateTime}}
        </div>
        <div class="col">
          {{match.firstPlayerId}}
        </div>
        <div class="col">
          {{match.secondPlayerId}}
        </div>
        <div class="col">
          {{match.firstTeamId}}
        </div>
        <div class="col">
          {{match.secondTeamId}}
        </div>
        <div class="col">
          {{match.sportId}}
        </div>
        <div class="col">
          <button class="btn btn-warning" (click)="delete(match.matchId)">Удалить</button>
        </div>
      </div>
    </div>
    <div *ngIf="matches">
      <ga-paging [pagedResult]="matches" (pageChangedEvent)="pageChanged($event)"></ga-paging>
    </div>
  </div>
</div>
