<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li class="nav-item push-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/push-gate/main/push-mock-list']">
        Ios уведомления
      </a>
    </li>
    <li class="nav-item push-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/push-gate/main/push-mock-android-list']">
        Android уведомления
      </a>
    </li>
    <li class="nav-item push-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/push-gate/main/push-mock-error-handle']">
        Обработчик Ошибок GCM (Android)
      </a>
    </li>
    <li class="nav-item push-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/push-gate/main/app-push-mock-error-handle-apns']">
        Обработчик Ошибок APNS (IOS)
      </a>
    </li> 
  </ul>
  <router-outlet></router-outlet>
</div>
