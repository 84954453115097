<h3>Выдача наград пользователям</h3>

<div class="row">
    <app-strict-input-number class="col-6"
                             [control]="requestIdControl"
                             [title]="'Id запроса'"
                             [placeholder]="'Id запроса'"></app-strict-input-number>
    <span>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </span>
</div>

<div class="row mt-3">
    <div class="col-1">
        Id
    </div>
    <div class="col-1">
        Id запроса
    </div>
    <div class="col-2">
        TradeLinkUrl
    </div>
    <div class="col-1">
        Id предмета
    </div>
    <div class="col-1">
        Название ассета
    </div>
    <div class="col-1">
        Операция
    </div>
    <div class="col-1">
        Статус
    </div>
    <div class="col-2">
        Код ответа
    </div>
    <div class="col-1">
        Ответ
    </div>
    <div class="col-1">
    </div>
</div>
<div *ngIf="result">
    <div *ngFor="let elem of result.results">
        <app-battle-pass-accrual-reward-item [item]="elem"></app-battle-pass-accrual-reward-item>
    </div>
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
