<div>
  <h4>
    Туры X50
  </h4>
</div>
<div>
  <div *ngIf="loading">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!loading">
    <table class="table">
      <tr class="row table-info">
        <td class="col-1">
          Id
        </td>
        <td class="col-2">
          Имя
        </td>
        <td class="col-2">
          Дата первой ставки
        </td>
        <td class="col-1">
          Результат
        </td>
        <td class="col-2">
          Статус
        </td>
      </tr>
      <tr class="row" *ngFor="let tour of tours">
        <td class="col-1">
          {{tour.id}}
        </td>
        <td class="col-2">
          {{tour.tourName}}
        </td>
        <td class="col-2">
          {{tour.startDate | moscowDateTime}}
        </td>
        <td class="col-1">
          {{tour.results}}
        </td>
        <td class="col-2">
          {{tour.status | wlXfiftyStatesTransform}}
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="!loading">
  <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
