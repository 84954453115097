import {Component, OnInit} from '@angular/core';
import {CustomTournamentViewModel} from '../../../../models/custom-tournaments/custom-tournament-view-model';
import {WlCustomTournamentTypesStrategy} from '../../../shared/common/enum-dropdown/strategies/wl-custom-tournament-types-strategy';
import { CustomTournamentService } from 'src/app/services/autogen/CustomTournaments';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaMessagingService} from '@koddington/ga-common';
import {
  AddOrUpdateCustomTournamentForm
} from '../../../../services/autogen/CustomTournaments';
import { WlTournamentPrizeFundTypesStrategy } from '../../../shared/common/enum-dropdown/strategies/wl-tournament-prize-fund-types-strategy';

@UntilDestroy()
@Component({
  selector: 'app-custom-tournaments-item-crud',
  templateUrl: './custom-tournaments-item-crud.component.html',
  styleUrls: ['./custom-tournaments-item-crud.component.scss']
})
export class CustomTournamentsItemCrudComponent implements OnInit {
  public readonly viewModel: CustomTournamentViewModel = new CustomTournamentViewModel();

  constructor(private readonly service: CustomTournamentService,
              private readonly messaging: GaMessagingService,
              public readonly tournamentTypesStrategy: WlCustomTournamentTypesStrategy,
              public readonly prizeFundTypesStrategy: WlTournamentPrizeFundTypesStrategy) {
  }

  public ngOnInit(): void {
  }

  public add(): void {
    const form = new AddOrUpdateCustomTournamentForm();
    form.tournamentId = this.viewModel.id.strictValue;
    form.name = this.viewModel.name.strictValue;
    form.isActive = this.viewModel.isActive.strictValue;
    form.tournamentType = this.viewModel.tournamentType.strictValue;
    form.prizeFund = this.viewModel.prizeFund.strictValue;
    form.prizeFundType = this.viewModel.prizeFundType.strictValue;
    form.winnersCount = this.viewModel.winnersCount.strictValue;
    form.usersCount = this.viewModel.usersCount.strictValue;
    form.registrationDate = this.viewModel.registrationDate.strictValue;
    form.startDate = this.viewModel.startDate.strictValue;
    form.endDate = this.viewModel.endDate.strictValue;

    this.service
      .addOrUpdateTournament(form)
      .pipe(
        take(1),
        filter(u => !this.messaging.tryShowError(u)),
        untilDestroyed(this))
      .subscribe(() => this.messaging.showMessage('Турнир успешно добавлен'));
  }
}
