import {Routes} from '@angular/router';
import {MainLayoutComponent} from '../layouts/main-layout/main-layout.component';
import {ActiveUserTriggersListForUserComponent} from './active-user-triggers-list-for-user/active-user-triggers-list-for-user.component';
import {ActiveTriggerHistoryComponent} from './active-trigger-history/active-trigger-history.component';
import {TriggerActionsComponent} from './trigger-actions/trigger-actions.component';

export const TriggersRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: ActiveUserTriggersListForUserComponent
      },
      {
        path: 'history',
        component: ActiveTriggerHistoryComponent
      },
      {
        path: 'actions',
        component: TriggerActionsComponent
      }
    ]
  }
];
