import {AbstractModelValidator} from '@koddington/ga-common';
import {AddFreeBetViewModel} from '../../../models/events/components-models/add-free-bet-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class AddFreeBetValidator extends AbstractModelValidator<AddFreeBetViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.beginDate).required();
    this.ruleForControl(m => m.endDate).required();
    this.ruleForControl(m => m.issuedFreebetId).min(1);
    this.ruleForControl(m => m.freeBetId).requiredAndNotZero().min(1);
    this.ruleForControl(m => m.userId).requiredAndNotZero().min(1);
    this.ruleForControl(m => m.summ).requiredAndNotZero().min(0);
  }
}
