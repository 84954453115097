import {Component, OnDestroy, OnInit} from '@angular/core';
import {FavoriteTeamViewModel} from '../../../../models/events/components-models/favorite-team-view-model';
import {FavoriteTeamValidator} from '../../validators/favorite-team-validator';
import { EventsService } from 'src/app/services/autogen/Events';
import { FavoriteTeamChangeForm } from 'src/app/services/autogen/Events';
import {filter, first, map} from 'rxjs/operators';
import {FavouriteTeamsStrategy} from '../../../shared/common/strict-autocomplete/strategies/favourite-teams-strategy';
import { FavouriteTeamsService } from 'src/app/services/autogen/Shared';
import {GaMessagingService} from '@koddington/ga-common';

@Component({
  selector: 'app-favorite-team-change',
  templateUrl: './favorite-team-change.component.html',
  styleUrls: ['./favorite-team-change.component.css']
})
export class FavoriteTeamChangeComponent implements OnInit, OnDestroy {

  public validator = new FavoriteTeamValidator();
  public viewModel = new FavoriteTeamViewModel();
  public readonly teamsStrategy: FavouriteTeamsStrategy;

  constructor(private _service: EventsService,
              private _messaging: GaMessagingService,
              private readonly _apiService: FavouriteTeamsService) {
    this.teamsStrategy = new FavouriteTeamsStrategy(_apiService);
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public changeTeam(): void {
    const errors = this.validator.validate(this.viewModel);
    if (errors.length > 0) {
      return;
    }

    const form = new FavoriteTeamChangeForm();
    form.userId = this.viewModel.userId.strictValue;
    form.chooseDate = this.viewModel.chooseDate.strictValue;
    form.teamId = this.viewModel.favouriteTeam.strictValue.id;

    this._service.favoriteTeamChange(form).pipe(
      first(),
      filter(value => !this._messaging.tryShowError(value)),
      map(value => this._messaging.showMessage('Любимая команда успешна изменена'))
    ).subscribe();
  }
}
