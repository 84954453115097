import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AddSportMatchEventViewModel } from './view-models/add-sport-match-event-view-model';
import { SportMatchesEventsService } from 'src/app/services/autogen/EventNotifications';
import { AddSportMatchEventForm } from 'src/app/services/autogen/EventNotifications';
import { take } from 'rxjs/operators';
import { AddSportMatchEventValidator } from './validators/add-sport-match-event.validator';

@Component({
    selector: 'app-sport-matches-events-add',
    templateUrl: './sport-matches-events-add.component.html'
})
export class SportMatchesEventsAddComponent implements OnInit {
    protected viewModel = new AddSportMatchEventViewModel();
    private validator = new AddSportMatchEventValidator();

    @Output()
    public eventAdded = new EventEmitter();

    constructor(private readonly _service: SportMatchesEventsService) {
    }

    ngOnInit(): void {
    }

    protected save(): void {
        if (this.validator.validate(this.viewModel).length > 0)
            return;

        const form = new AddSportMatchEventForm();
        form.sportId = this.viewModel.sportId.strictValue;
        form.sportName = this.viewModel.sportName.strictValue;
        form.matchId = this.viewModel.matchId.strictValue;
        form.eventDate = this.viewModel.eventDate.strictValue;
        form.date = this.viewModel.date.strictValue;
        form.championshipId = this.viewModel.championshipId.strictValue;
        form.championshipName = this.viewModel.championshipName.strictValue;
        form.countryId = this.viewModel.countryId.strictValue;
        form.countryName = this.viewModel.countryName.strictValue;
        form.type = this.viewModel.type.strictValue;
        form.subtype = this.viewModel.subtype.strictValue;
        form.firstPlayer = this.viewModel.firstPlayer.strictValue;
        form.secondPlayer = this.viewModel.secondPlayer.strictValue;
        form.currentScore = this.viewModel.currentScore.strictValue;
        form.currentPeriodScore = this.viewModel.currentPeriodScore.strictValue;
        form.eventId = this.viewModel.eventId.strictValue;
        form.rabbitSentDate = this.viewModel.rabbitSentDate.strictValue;
        form.winner = this.viewModel.winner.strictValue;

        this._service.addEvent(form).pipe(
            take(1)
        ).subscribe(() => {
            this.eventAdded.emit();
            this.viewModel = new AddSportMatchEventViewModel();
        });
    }
}
