<div class="col-10">
  <h1>Пользователи мок-сервера</h1>
  <div class="col-3">
    <app-users-add (userAdded)="userAdded($event)"></app-users-add>
  </div>
  <hr />
  <div class="row">
    <app-strict-input-number class="col-6" [control]="model.userId" [placeholder]="'Id пользователя'"></app-strict-input-number>
  </div>
  <div>
    <div class="row mt-14 font-weight-bold">
      <div class="col-1">Id</div>
      <div class="col-1">Имя</div>
      <div class="col-2">Номер телефона</div>
      <div class="col-1">Стадия</div>
      <div class="col-1">Монетарный класс</div>
      <div class="col-1">Дата последней ставки</div>
      <div class="col-1">Дата смены стадии</div>
    </div>
    <div *ngFor="let user of users" class="row mt-1">
      <div class="col-1">{{user.userId}}</div>
      <div class="col-1">{{user.name}}</div>
      <div class="col-2">{{user.phone}}</div>
      <div class="col-1">{{user.stageType | wlStageTypesTransform}}</div>
      <div class="col-1">{{user.monetaryClassType | wlMonetaryClassTypesTransform}}</div>
      <div class="col-1">{{user.lastBetDate | moscowDateTime}}</div>
      <div class="col-1">{{user.segmentDate | moscowDateTime}}</div>
      <div class="col-2">
        <a class="btn btn-success" [routerLink]="['/dashboard/users/userDetails', user.userId]">Редактировать</a>
      </div>
    </div>
  </div>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
