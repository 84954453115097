<div>
    <h3>Расчёт ставки</h3>
    <div>
        <div class="row mt-2">
            <div class="col-1">
                Id ставки
            </div>
            <div class="col-2">
                Укажите дату расчёта
            </div>
            <div class="col-1">
                Выиграла?
            </div>
            <div class="col-1">
                {{ calculationAmountText }}
            </div>
            <div class="col-1">
                Коэффициент экспресса
            </div>
            <div class="col-1">
                Количество бонусных мячей
            </div>
            <div class="col-1">
                Выкуплена?
            </div>
            <ng-container *ngIf="isRedeem">
                <div class="col-1">
                    Коэффициент выкупа
                </div>
                <div class="col-2">
                    Навание чемпионата
                </div>
            </ng-container>
            <div *ngIf="hasExpressLines" class="col-1">
                C возвратом?
            </div>
        </div>
        <div class="row">
            <div class="col-1">
                <app-strict-input-number [control]="model.betId" placeholder="Id ставки">
                </app-strict-input-number>
            </div>
            <div class="col-2">
                <strict-date-time-picker [control]="model.eventDate"></strict-date-time-picker>
            </div>
            <div class="col-1">
                <app-strict-input-boolean [control]="model.isWin">
                </app-strict-input-boolean>
            </div>
            <div class="col-1">
                <app-strict-input-number [control]="model.calculationAmount" [placeholder]="calculationAmountText">
                </app-strict-input-number>
            </div>
            <div class="col-1">
                <app-strict-input-number [control]="model.expressRate" placeholder="Коэф. экспресса">
                </app-strict-input-number>
            </div>
            <div class="col-1">
                <app-strict-input-number [control]="model.ballsCount" placeholder="Количество бонусных мячей?">
                </app-strict-input-number>
            </div>
            <div class="col-1">
                <app-strict-input-boolean [control]="model.isRedeem">
                </app-strict-input-boolean>
            </div>
            <ng-container *ngIf="isRedeem">
                <div class="col-1">
                    <app-strict-input-number [control]="model.redemptionInfo.betRedeemedRate" placeholder="Коэффициент выкупа">
                    </app-strict-input-number>
                </div>
                <div class="col-2">
                    <app-strict-input-text [control]="model.redemptionInfo.championshipName" placeholder="Название чемпионата">
                    </app-strict-input-text>
                </div>
            </ng-container>
            <div *ngIf="hasExpressLines" class="col-1">
                <app-strict-input-boolean [control]="model.isReturn">
                </app-strict-input-boolean>
            </div>
        </div>
        <ng-container *ngIf="isReturn">
            <div class="row mt-2">
                <div class="col-1">
                    Id линии
                </div>
                <div class="col-1">
                    Коэффициент линии
                </div>
                <div class="col-2">
                    <button class="btn btn-primary mt-2" (click)="recalculateExpress()">Посчитать автоматически</button>
                </div>
            </div>
            <div class="row" *ngFor="let line of model.expressLines.strictValue">
                <div class="col-1">
                    <app-strict-input-number [control]="line.id" placeholder="Id линии">
                    </app-strict-input-number>
                </div>
                <div class="col-2">
                    <app-strict-input-number [control]="line.lineRate" placeholder="Коэффициент">
                    </app-strict-input-number>
                </div>
                <div class="col-1">
                    <app-strict-input-boolean [control]="line.isReturn" (userSelect)="onReturnCheck(line, $event)" label="Возврат">
                    </app-strict-input-boolean>
                </div>
            </div>
        </ng-container>
        <button class="btn btn-primary mt-2" (click)="tryAddBetCalculation()">Сохранить</button>
    </div>
</div>
<hr>
