import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PushTemplatesListComponent} from './push-templates-list/push-templates-list.component';
import {PushTemplateAddComponent} from './push-template-add/push-template-add.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AddTemplateValidator} from './validators/add-template-validator';
import {RouterModule} from '@angular/router';
import {PushRoutes} from './push.routes';
import {SharedModule} from '../shared/shared.module';
import {GaCommonModule} from '@koddington/ga-common';
import { PushGateAreaCodegenAppModule } from '../../services/autogen/PushGate';


@NgModule({
  declarations: [PushTemplatesListComponent, PushTemplateAddComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(PushRoutes),
    SharedModule,
    GaCommonModule,
    PushGateAreaCodegenAppModule
  ],
  exports: [
    PushTemplatesListComponent
  ],
  providers: [
    AddTemplateValidator
  ]
})
export class PushModule {
}
