import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BroadcastsStartComponent} from './broadcasts-start/broadcasts-start.component';
import {ReactiveFormsModule} from '@angular/forms';
import {StartBroadcastValidator} from './validators/start-broadcast-validator';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {BroadcastsRoutes} from './broadcasts.routes';
import { BroadcastsPausingComponent } from './broadcasts-pausing/broadcasts-pausing.component';
import {BroadcastsLayoutComponent} from './broadcasts-layout/broadcasts-layout.component';
import {PushModule} from '../push/push.module';
import {BetsResultsSubscriptionsListComponent} from '../events-notifications/bets-results-subscriptions-list/bets-results-subscriptions-list.component';
import {SmsBroadcastingStatisticsListComponent} from './sms-broadcasting-statistics-list/sms-broadcasting-statistics-list.component';
import {PushBroadcastingStatisticsListComponent} from './push-broadcasting-statistics-list/push-broadcasting-statistics-list.component';
import {GaCommonModule} from '@koddington/ga-common';
import { SharedAreaCodegenAppModule } from '../../services/autogen/Shared';
import { BroadcastsAreaCodegenAppModule } from '../../services/autogen/Broadcasts';


@NgModule({
  declarations: [BroadcastsStartComponent,
    BroadcastsPausingComponent,
    BroadcastsLayoutComponent,
    BetsResultsSubscriptionsListComponent,
    SmsBroadcastingStatisticsListComponent,
    PushBroadcastingStatisticsListComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    RouterModule.forChild(BroadcastsRoutes),
    PushModule,
    GaCommonModule,
    SharedAreaCodegenAppModule,
    BroadcastsAreaCodegenAppModule
  ],
  providers: [
    StartBroadcastValidator
  ]
})
export class BroadcastsModule {
}
