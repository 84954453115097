import {AbstractModelValidator} from '@koddington/ga-common';
import {AddXFiveBetViewModel} from '../../../models/events/components-models/add-x-five-bet-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class AddXFiveBetValidator extends AbstractModelValidator<AddXFiveBetViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.couponId).required().min(1);
    this.ruleForControl(m => m.date).required();
    this.ruleForControl(m => m.userId).required().min(1);
  }
}
