import {Component, OnInit} from '@angular/core';
import { GaMessagingService, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ResetIssuedFreebet } from 'src/app/services/autogen/Events';
import { FreebetsService } from '../../../../services/autogen/Events';

@UntilDestroy()
@Component({
  selector: 'app-reset-freebet',
  templateUrl: './reset-freebet.component.html',
  styleUrls: ['./reset-freebet.component.css']
})
export class ResetFreebetComponent implements OnInit {

  public issuedFreebetId = StrictFormControlBuilder.buildNullNumber();
  public operationDate = StrictFormControlBuilder.buildNullDayjs();

  constructor(private readonly service: FreebetsService,
              private readonly messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  reset(): void {
    if (!this.issuedFreebetId.hasStrictValue) {
      this.messaging.showMessage('Укажите id выданного фрибета');
    }

    const form = new ResetIssuedFreebet();
    form.issuedFreebetId = this.issuedFreebetId.strictValue;
    form.operationDate = this.operationDate.strictValue;

    this.service.reset(form).pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.messaging.showMessage('Фрибет успешно анулирован');
      });
  }
}
