<div class="wl-container-express">
    <h3>Целевое событие в экспрессе</h3>
    <div class="row">
        <div class="col-1">
            <span >Bet Id</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.betId" placeholder="Bet Id"></app-strict-input-number>
        </div>
        <div class="col-1">
            <span>User Id</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.userId" placeholder="User Id"></app-strict-input-number>
        </div>
        <div class="col-1">
            <span>Дата расчёта</span>
        </div>
        <div class="col-2">
            <strict-date-time-picker [control]="viewModel.operationDate" placeholder="Дата расчёта"></strict-date-time-picker>
        </div>
        <div class="col-1">
            <span>Сумма ставки</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.betSum" placeholder="Сумма ставки"></app-strict-input-number>
        </div>
    </div>
    <div class="row wl-row">
        <div class="col-1">
            <span>Сумма выплаты</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.winAmount" placeholder="Сумма выплаты"></app-strict-input-number>
        </div>
        <div class="col-1">
            <span>Мячи</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.balls" placeholder="Мячи"></app-strict-input-number>
        </div>
        <div class="col-1">
            <span>Покупка</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.purchase" placeholder="Покупка"></app-strict-input-number>
        </div>
        <div class="col-1">
            <span>Коэф экспресса</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.expressRate" placeholder="Коэф экспресса"></app-strict-input-number>
        </div>
    </div>
    <div class="row">
        <div class="col-1">
            <span>Line Id</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.lineId" placeholder="Line Id"></app-strict-input-number>
        </div>
        <div class="col-1">
            <span>Коэф. выигрыша линии</span>
        </div>
        <div class="col-2">
            <app-strict-input-number [control]="viewModel.lineRate" placeholder="Коэф. выигрыша линии"></app-strict-input-number>
        </div>
        <div class="col-1">
            <span>События Id</span>
        </div>
        <div class="col-1">
            <app-strict-input-number [control]="viewModel.eventId" placeholder="События Id"></app-strict-input-number>
        </div>
    </div>
    <div class="row">
        <div class='col-1'>
            <button class='btn btn-primary mt-2' (click)="defaultFill()">Предзаполнить</button>
        </div>
        <div class='col-1'>
            <button class='btn btn-primary mt-2' (click)="save()">Сохранить</button>
        </div>
    </div>
</div>
