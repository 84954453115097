<div class="col-6">
  <h1>Анулировать фрибеты</h1>
  <div class="row">
    <app-strict-input-number class="col-6" [control]="userId"
                             [placeholder]="'Id пользователя'"></app-strict-input-number>
    <span class="col-6">
      <button class="btn btn-primary" (click)="reset()" [disabled]="getUserIdHasValue()">Сбросить</button>
    </span>
  </div>
</div>
