import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class MockRateRaceResultsCrudViewModel {
    id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    battlePassSeasonId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    userPlaces: StrictFormControl<MockRateRaceResultPlaceViewModel[]> = new StrictFormControl<MockRateRaceResultPlaceViewModel[]>([]);
}

export class MockRateRaceResultPlaceViewModel  {
    userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    place: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    drop: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
}
