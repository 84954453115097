<div>
    <label class="mt-2">Новая категория кэшбэка для пользователя</label>
    <div class="row">
        <div class="col-2">
            {{"Id пользователя"}}
        </div>
        <div class="col-2">
            {{"Дата выбора"}}
        </div>
        <div class="col-2">
            {{"Id категории"}}
        </div>
    </div>
    <div class="mt-2 row">
        <app-strict-input-number class="col-2"
                                 placeholder="Id пользователя"
                                 [control]="model.userId">
        </app-strict-input-number>
        <strict-date-time-picker class="col-2 timeline-marker"
                                 placeholder="Дата выбора"
                                 [control]="model.selectDate">
        </strict-date-time-picker>
        <app-strict-input-number class="col-2"
                                 placeholder="Категория кэшбэка"
                                 [control]="model.categoryId">
        </app-strict-input-number>
    </div>
    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
