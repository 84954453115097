import { MockLogoServiceEntityTypes } from 'src/app/services/autogen/LogoService';
import { StrictFormControlBuilder } from '@koddington/ga-common';
import { MockLogoServiceCrudForm } from 'src/app/services/autogen/LogoService';

export class MockLogoServiceCrudViewModel {
    id = StrictFormControlBuilder.buildNullNumber();
    entityType = StrictFormControlBuilder.buildNullNumber<MockLogoServiceEntityTypes>();
    itemId = StrictFormControlBuilder.buildNullNumber();
    imageUrl = StrictFormControlBuilder.buildNullString();

    public static fromForm(form: MockLogoServiceCrudForm): MockLogoServiceCrudViewModel {
        const viewModel = new MockLogoServiceCrudViewModel();
        viewModel.id.strictValue = form.id;
        viewModel.entityType.strictValue = form.entityType;
        viewModel.itemId.strictValue = form.itemId;
        viewModel.imageUrl.strictValue = form.imageUrl;

        return viewModel;
    }

    public toForm(): MockLogoServiceCrudForm {
        const form = new MockLogoServiceCrudForm();
        form.id = this.id.strictValue;
        form.entityType = this.entityType.strictValue;
        form.itemId = this.itemId.strictValue;
        form.imageUrl = this.imageUrl.strictValue;

        return form;
    }
}
