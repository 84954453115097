<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/rate-race/main/users-rate-race']">
        Пользователи попавшие в Rate Race
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/rate-race/main/results/list']">
        Таблицы результатов
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/rate-race/main/exchange-sequences']">
        Очередь обмена на деньги
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</div>
