import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class AddX50BetViewModel {
  userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  tourId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  betId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  betDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  betSum: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  betResultSum: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  branches: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  results: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  topPercent: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
}
