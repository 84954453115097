import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { IPagingViewModel } from '../../../../shared/services/ga-search-management-state.service';

export class MarketsSearchModel implements IPagingViewModel {
    offset: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    count: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    term: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    isDeleted: StrictFormControl<boolean> = StrictFormControlBuilder.buildNullBoolean(); // selector
}
