import { Component, OnInit } from '@angular/core';
import { BroadcastsService } from 'src/app/services/autogen/Broadcasts';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-broadcasts-pausing',
  templateUrl: './broadcasts-pausing.component.html',
  styleUrls: ['./broadcasts-pausing.component.css']
})
export class BroadcastsPausingComponent implements OnInit {

  isPaused: boolean;

  constructor(private readonly _broadcasts: BroadcastsService) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this._broadcasts.isBroadcastingPaused().pipe(
      take(1)
    ).subscribe(res => this.isPaused = res.result);
  }

  pause() {
    this._broadcasts.pauseBroadcasts().pipe(
      take(1)
    ).subscribe(() => this.isPaused = true);
  }

  resume() {
    this._broadcasts.resumeBroadcasting().pipe(
      take(1)
    ).subscribe(() => this.isPaused = false);
  }
}
