import { AddViewModel } from '../models/push-mock-add-view-model';
import {Injectable} from '@angular/core';
import {AbstractModelValidator, ValidationLevel} from '@koddington/ga-common';

@Injectable()
export class AddErrorByTokenValidator extends AbstractModelValidator<AddViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.errorCode, ValidationLevel.OnSave).required('Введите код ошибки');
    this.ruleForControl(m => m.token, ValidationLevel.OnSave).required('Введите токен устройства');
  }
}
