import { AbstractModelValidator, AddErrorsToControlMode, isNullOrUndefined, StrictError, ValidationLevel } from '@koddington/ga-common';
import { BetCalculationViewModel } from '../../../models/events/components-models/bet-calculation-view-model';
import { Injectable } from '@angular/core';
import { AddRedeemedBetValidator } from './add-redeemed-bet-validator';
import { BetCalculationLineValidator } from './bet-calculation-line-validator';

@Injectable()
export class BetCalculationValidator extends AbstractModelValidator<BetCalculationViewModel> {
    private readonly _redeemValidator: AddRedeemedBetValidator = new AddRedeemedBetValidator();
    private readonly _linesValidator: BetCalculationLineValidator = new BetCalculationLineValidator();

    constructor() {
        super();

        this.ruleForControl(m => m.betId)
            .required('Введите id ставки')
            .min(1, 'Id ставки должен быть не меньше 1')
            .customRule((f, m) => m.hasBet || isNullOrUndefined(f)
                ? null
                : new StrictError('betNotFound', 'Ставка с указанным id не найдена')
            );

        this.ruleForControl(m => m.calculationAmount)
            .min(0, 'Ставка не выиграла');

        const isWinOrRedeem = (m: BetCalculationViewModel) => m.isWin.strictValue || m.isRedeem.strictValue;

        this.ruleForControlIf(m => m.calculationAmount, m => isWinOrRedeem(m))
            .required('Введите сумму');

        this.ruleForControlIf(m => m.calculationAmount, m => !isWinOrRedeem(m))
            .max(0);

        this.ruleForControl(m => m.ballsCount)
            .required('Введите количество мячей')
            .min(0);

        this.ruleForControlIf(u => u.expressRate, u => u.isReturn.strictValue)
            .customRule((_, model) => {
                return model.expressLines.strictValue.some(u => u.lineRate.strictValue === 1)
                    ? null
                    : new StrictError('expressLinesError', 'Ни одна из линий не является возвратной');
                }
            );
    }

    public override validate(model: BetCalculationViewModel, validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        const errors = [
            ...super.validate(model, validationLevel, addErrorsToControls),
        ];

        if (model.isRedeem.strictValue) {
            errors.push(...this._redeemValidator.validate(model.redemptionInfo, validationLevel, addErrorsToControls));
        }

        if (model.isReturn.strictValue) {
            errors.push(...this._linesValidator.validateArray(model.expressLines.strictValue, validationLevel, addErrorsToControls));
        }

        return errors;
    }
}
