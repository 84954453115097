import { Component, OnInit } from '@angular/core';
import { EventsListViewModel } from 'src/app/services/autogen/Events';
import { filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserEvent } from '../../../models/events/user-event';
import { EventsListStrictViewModel } from '../../../models/events/events-list-strict-view-model';
import { ActivatedRoute, Params } from '@angular/router';
import { GaMessagingService, GaPagingForm, isNullOrUndefined } from '@koddington/ga-common';
import { EventsListForm } from 'src/app/services/autogen/Events';
import { SearchNavigationService } from '../../shared/services/search-navigation.service';
import { EventsListValidator } from '../validators/events-list-validator';
import { Observable } from 'rxjs';
import { WlResult } from '../../../models/common/results/wl-result';
import { WlUserEventType } from 'src/app/services/autogen/Events';
import { EventsService } from 'src/app/services/autogen/Events';
import { builderStrictToModel } from '../../shared/common/operation/builder-operation';
import { WlUserEventTypeStrategy } from '../../shared/common/enum-dropdown/strategies/wl-user-event-type.strategy';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
    selector: 'app-events-list',
    templateUrl: './events-list.component.html',
    styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit {

    private validator: EventsListValidator = new EventsListValidator();

    public result: EventsListViewModel;
    public loading: boolean;
    public model: EventsListStrictViewModel = new EventsListStrictViewModel();

    constructor(private readonly service: EventsService,
                private readonly activeRoute: ActivatedRoute,
                private readonly navigation: SearchNavigationService,
                private readonly messaging: GaMessagingService,
                protected readonly userEventStrategy: WlUserEventTypeStrategy) {
    }

    ngOnInit(): void {
        this.activeRoute.queryParams.pipe(
            map(value => this.initModel(value)),
            map(() => this.createForm()),
            switchMap(value => this.load(value)),
            untilDestroyed(this)
        ).subscribe(value => this.result = value.result);
    }

    public eventIsProcessed(event: UserEvent): boolean {
        if (event.eventType === WlUserEventType.TechInfo) {
            return dayjs(event.eventDate).diff(dayjs(this.result.lastSequenceSyncDate)) > 0;
        }

        return dayjs(event.eventDate).diff(dayjs(this.result.lastSyncDate)) > 0;
    }

    public deserialize(value: string): string {
        return JSON.parse(value);
    }

    public pageChanged(form: GaPagingForm): void {
        this.model.offset.strictValue = form.offset;
        this.search();
    }

    public search(): void {
        const errors = this.validator.validate(this.model);
        if (errors.length > 0) {
            return;
        }

        const params: Params = {
            userId: this.model.userId.strictValue ?? null,
            offset: this.model.offset.strictValue ?? 0,
            count: this.model.count.strictValue ?? 30,
            eventType: this.model.eventType.strictValue ?? null,
            onlyLastRecord: this.model.onlyLastRecord.strictValue ?? null
        };

        this.navigation.search(this.activeRoute, params);
    }

    private load(form: EventsListForm): Observable<WlResult<EventsListViewModel>> {
        this.loading = true;

        return this.service.eventsList(form).pipe(
            take(1),
            filter(value => !this.messaging.tryShowError(value)),
            finalize(() => this.loading = false),
            untilDestroyed(this)
        );
    }

    private initModel(params: Params): void {
        this.model.offset.strictValue = !isNullOrUndefined(params['offset'])
            ? Number(params['offset'])
            : null;
        this.model.count.strictValue = !isNullOrUndefined(params['count'])
            ? Number(params['count'])
            : null;
        this.model.userId.strictValue = !isNullOrUndefined(params['userId'])
            ? params['userId']
            : null;
        this.model.eventType.strictValue = !isNullOrUndefined(params['eventType'])
            ? Number(params['eventType'])
            : null;
        this.model.onlyLastRecord.strictValue = !!params['onlyLastRecord'] && params['onlyLastRecord'].toLowerCase() !== 'false';
    }

    private createForm(): EventsListForm {
        return builderStrictToModel(EventsListForm, this.model);
    }
}
