<h1>Сегментация механик пользователей</h1>
<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li (click)="onTabChange('MechanicsList')">Механики пользователей</li>
    <li (click)="onTabChange('MechanicChange')">Изменения механик пользователя</li>
  </ul>
  <div>
    <div *ngIf="isMechanicsListAllow">
      <app-mechanics-list></app-mechanics-list>
    </div>
    <div *ngIf="isMechanicChangeAllow" class="mechanic-operations">
      <app-mechanics-changes></app-mechanics-changes>
      <app-trigger-enabled-user-mechanic></app-trigger-enabled-user-mechanic>
    </div>
  </div>
</div>
