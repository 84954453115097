import {Component, OnInit} from '@angular/core';
import { SportMatchesService } from 'src/app/services/autogen/Events';
import { WlSportMatchApiModel } from 'src/app/services/autogen/Events';
import {filter, first, map, take} from 'rxjs/operators';
import { AddMatchForm } from 'src/app/services/autogen/Events';
import {AddSportMatchViewModel} from '../../../models/events/components-models/add-sport-match-view-model';
import {ActivatedRoute, Params} from '@angular/router';
import {GaMessagingService, GaPagedResult, isNullOrUndefined} from '@koddington/ga-common';
import {SearchNavigationService} from '../../shared/services/search-navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SportMatchesListForm } from 'src/app/services/autogen/Events';
import {WlSportMatchListViewModel} from '../../../models/events/wl-sport-match-list-view-model';
import {MatchTeamsStrategy} from '../../shared/common/strict-autocomplete/strategies/match-teams-strategy';
import { MatchTeamsService } from 'src/app/services/autogen/Dictionaries';

@UntilDestroy()
@Component({
  selector: 'app-sport-matches',
  templateUrl: './sport-matches.component.html',
  styleUrls: ['./sport-matches.component.css']
})
export class SportMatchesComponent implements OnInit {

  matches: GaPagedResult<WlSportMatchApiModel>;
  viewModel: AddSportMatchViewModel = new AddSportMatchViewModel();
  pagingViewModel: WlSportMatchListViewModel = new WlSportMatchListViewModel();
  public readonly firstPlayerStrategy: MatchTeamsStrategy;
  public readonly secondPlayerStrategy: MatchTeamsStrategy;

  constructor(private readonly messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly matchesService: SportMatchesService,
              private readonly _apiService: MatchTeamsService
              ) {
    this.firstPlayerStrategy = new MatchTeamsStrategy(_apiService);
    this.secondPlayerStrategy = new MatchTeamsStrategy(_apiService);
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.pipe(
      map(value =>  this.initModel(value)),
      map(() => this.createForm()),
      untilDestroyed(this)
    ).subscribe(value => this.load(value));
  }

  public add() {
    const form = new AddMatchForm();
    form.matchId = this.viewModel.matchId.strictValue;
    form.championshipId = this.viewModel.championshipId.strictValue;
    form.category = this.viewModel.category.strictValue;
    form.matchDate = this.viewModel.matchDate.strictValue;
    form.firstPlayerId = this.viewModel.firstPlayer.strictValue.id;
    form.secondPlayerId = this.viewModel.secondPlayer.strictValue.id;
    form.sportId = this.viewModel.sportId.strictValue;

    this.matchesService.addMatch(form).pipe(
      first(),
      filter(u => !this.messaging.tryShowError(u)),
        untilDestroyed(this)
       ).subscribe(() => {
      this.load(this.createForm());
       });
    }

  public delete(matchId: number) {
    this.matchesService.deleteMatch(matchId).pipe(
      take(1)
    ).subscribe(() => {
      this.load(this.createForm());
      this.navigateThisParam();
    });

  }

  public pageChanged(form: SportMatchesListForm): void {
    this.pagingViewModel.count.strictValue = form.count;
    this.pagingViewModel.offset.strictValue = form.offset;

    this.navigateThisParam();
  }

  public search(): void {
    this.pagingViewModel.offset.strictValue = 0;
    this.navigateThisParam();
  }

  private load(form: SportMatchesListForm) {
    this.matchesService.list(form).pipe(
      take(1)
    ).subscribe(res => this.matches = res);
  }

  private createForm(): SportMatchesListForm {
    const form = new SportMatchesListForm();
    form.matchId = this.pagingViewModel.matchId.strictValue;
    form.offset = this.pagingViewModel.offset.strictValue;
    form.count = this.pagingViewModel.count.strictValue;
    return form;
  }

  private initModel(params: Params): void {
    this.pagingViewModel.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : 0;
    this.pagingViewModel.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : 30;
    this.pagingViewModel.matchId.strictValue = !isNullOrUndefined(params['matchId']) ?
      params['matchId'] : null;
  }

  private navigateThisParam(): void {
    const params: Params = {
      matchId: this.pagingViewModel.matchId.hasStrictValue ? this.pagingViewModel.matchId.strictValue : null,
      offset: this.pagingViewModel.offset.hasStrictValue ? this.pagingViewModel.offset.strictValue : 0,
      count: this.pagingViewModel.count.hasStrictValue ? this.pagingViewModel.count.strictValue : 30,
      tab: 'SportMatches'
    };

    this.navigation.search(this.activeRoute, params);
  }
}
