import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToursLayoutComponent} from './tours-layout/tours-layout.component';
import {SharedModule} from '../shared/shared.module';
import {X50EventsComponent} from './x50-events/x50-events.component';
import {X50ToursListComponent} from './x50-tours-list/x50-tours-list.component';
import {X50BetsListComponent} from './x50-bets-list/x50-bets-list.component';
import {AddX50TourComponent} from './add-x50-tour/add-x50-tour.component';
import {AddX50BetComponent} from './add-x50-bet/add-x50-bet.component';
import {X50BetsSearchComponent} from './x50-bets-search/x50-bets-search.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import {GaCommonModule} from '@koddington/ga-common';
import { EventsAreaCodegenAppModule } from '../../services/autogen/Events';


@NgModule({
  declarations: [ToursLayoutComponent,
    X50EventsComponent,
    X50ToursListComponent,
    X50BetsListComponent,
    AddX50TourComponent,
    AddX50BetComponent,
    X50BetsSearchComponent],
  exports: [
    ToursLayoutComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    EventsAreaCodegenAppModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    GaCommonModule,
    EventsAreaCodegenAppModule
  ]
})
export class ToursModule {
}
