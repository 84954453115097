import {NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { PushMockAndroidListComponent } from './components/push-mock-android-list/push-mock-android-list.component';
import { PushMockListComponent } from './components/push-mock-list/push-mock-list.component';
import { PushGateRoutes } from './push-gate-routes';
import { PushMockLayoutComponent } from './components/push-mock-layout/push-mock-layout.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { PushMockErrorHandleComponent } from './components/push-mock-error-handle/push-mock-error-handle.component';
import { AddErrorByTokenValidator } from './components/push-mock-error-handle/validators/push-mock-add-error-token-validators';
import { PushMockErrorHandleApnsComponent } from './components/push-mock-error-handle-apns/push-mock-error-handle-apns.component';
import {GaCommonModule} from '@koddington/ga-common';
import { PushGateAreaCodegenAppModule } from '../../services/autogen/PushGate';


@NgModule({
  declarations: [
    PushMockListComponent,
    PushMockAndroidListComponent,
    PushMockLayoutComponent,
    PushMockErrorHandleComponent,
    PushMockErrorHandleApnsComponent
    ],
  imports: [
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    RouterModule.forChild(PushGateRoutes),
    GaCommonModule,
    PushGateAreaCodegenAppModule
  ],
  providers: [AddErrorByTokenValidator]
})
export class PushGateMockModule {
}
