import { Component } from '@angular/core';
import { AddUserCashbackViewModel } from '../../../../models/events/components-models/add-user-cashback-view-model';
import { AddUserScoreForm, EventsService, MockAddUserCashbackForm } from '../../../../services/autogen/Events';
import { AddScoreUpdateValidator } from '../../validators/add-score-update-validator';
import { GaMessagingService } from '@koddington/ga-common';
import { AddUserCashbackValidator } from '../../validators/add-user-cashback-validator';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-add-user-cashback',
  templateUrl: './add-user-cashback.component.html',
  styleUrls: ['./add-user-cashback.component.css']
})
export class AddUserCashbackComponent {
    public readonly model: AddUserCashbackViewModel = new AddUserCashbackViewModel();

    constructor(private readonly service: EventsService,
                private readonly validator: AddUserCashbackValidator,
                private readonly messaging: GaMessagingService) {
    }

    public add(): void {
        const errors = this.validator.validate(this.model);
        if (errors.length > 0) {
            return;
        }
        const form = new MockAddUserCashbackForm();
        form.userId = this.model.userId.strictValue;
        form.selectDate = this.model.selectDate.strictValue;
        form.categoryId = this.model.categoryId.strictValue;

        this.service.addUserCashback(form).pipe(take(1), untilDestroyed(this))
            .subscribe(value => {
                this.messaging.showMessage('Категория кэшбэка для пользователя выбрана');
            });
    }
}
