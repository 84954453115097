<div class="row">
  <div class="col-6">
    <app-countries-update (countiesChanged)="reload()">
    </app-countries-update>
  </div>
  <div class="col-6">
    <app-loading *ngIf="loading"></app-loading>
    <h1 class="mt-2">Страны</h1>
    <table class="table" *ngIf="!loading" style="width: 100%">
      <tr class="table-info">
        <th>
          id
        </th>
        <th>
          Название на русском
        </th>
        <th>
          Название на английском
        </th>
      </tr>
      <tr *ngFor="let item of countries">
        <td>
          {{item.id}}
        </td>
        <td>
          {{item.nameRu}}
        </td>
        <td>
          {{item.nameEn}}
        </td>
      </tr>
    </table>
  </div>
</div>

