import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class BetExpressLineViewModel {
    public userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public betId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public operationDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public winAmount: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public balls: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public betSum: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public purchase: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public expressRate: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public lineId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public lineRate: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public eventId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
}
