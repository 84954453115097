import {Component, OnInit} from '@angular/core';
import {
  formatDateTime,
  GaMessagingService,
  GaPagedResult,
  GaPagingForm,
  GaTableData,
  isNullOrUndefined
} from '@koddington/ga-common';
import {SearchNavigationService} from '../../shared/services/search-navigation.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FaceItSubscriptionsListViewModel} from '../../../models/face-it/subscriptions/face-it-subscriptions-list.view-model';
import {
  FaceItService,
  MockFaceItSubscription,
  WlExternalSubsResultsExtensions,
  WlExternalTaskResultsExtensions
} from 'src/app/services/autogen/FaceIt';
import {filter, map, take} from 'rxjs/operators';
import {builderStrictToModel} from '../../shared/common/operation/builder-operation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {FaceItCreateSubscriptionViewModel} from '../../../models/face-it/subscriptions/face-it-create-subscription.view-model';
import { MockFaceItCreateSubscriptionForm } from 'src/app/services/autogen/FaceIt';
import {IUsersListByIdForm, UsersListByIdForm} from '../../../services/autogen/Shared';

@UntilDestroy()
@Component({
  selector: 'app-face-it-subscriptions',
  templateUrl: './face-it-subscriptions.component.html',
  styleUrls: ['./face-it-subscriptions.component.css']
})
export class FaceItSubscriptionsComponent implements OnInit {
  public loading = true;
  public tableData: GaTableData<MockFaceItSubscription>;
  public pagedResult: GaPagedResult<MockFaceItSubscription>;

  public pagingViewModel = new FaceItSubscriptionsListViewModel();
  public newSubsModel = new FaceItCreateSubscriptionViewModel();

  constructor(private readonly _messaging: GaMessagingService,
              private readonly _navigation: SearchNavigationService,
              private readonly _activeRoute: ActivatedRoute,
              private readonly _router: Router,
              private readonly _faceItApi: FaceItService) {
  }


  public ngOnInit(): void {
    this.updateCurrentTableState();
  }

  public search(): void {
    this.pagingViewModel.offset.strictValue = 0;

    this.handleFilterChange();
  }

  public create(): void {
    this.loading = true;

    const model = new MockFaceItCreateSubscriptionForm();
    model.userId = this.newSubsModel.userId.strictValue;
    model.bkTaskId = this.newSubsModel.bkTaskId.strictValue;
    model.subscriptionId = this.newSubsModel.subscriptionId.strictValue;
    model.payload = this.newSubsModel.payload.strictValue;

    this._faceItApi.createSubscription(model)
      .pipe(
        take(1),
        filter(res => !this._messaging.tryShowError(res))
      )
      .subscribe(res => {
        this._messaging.showMessage(`Подписка ${res.result} создана.`);
        this.newSubsModel = new FaceItCreateSubscriptionViewModel();
        this.updateCurrentTableState();
      });
  }

  public pageChanged(form: GaPagingForm): void {
    this.pagingViewModel.offset.strictValue = form.offset;
    this.pagingViewModel.count.strictValue = form.count;

    this.handleFilterChange();
  }

  private updateCurrentTableState() {
    this._activeRoute.queryParams.pipe(
      map(value => this.initFindViewModel(value)),
      map(() => this.createForm()),
      take(1)
    ).subscribe((value) => this.load(value));
  }

  private initFindViewModel(params: Params) {
    this.pagingViewModel.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
    this.pagingViewModel.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
    this.pagingViewModel.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
  }

  private createForm(): UsersListByIdForm {
    const form = builderStrictToModel(UsersListByIdForm, this.pagingViewModel);
    return form;
  }

  private load(form: UsersListByIdForm): void {
    this._faceItApi.getList(form)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.pagedResult = res;
        this.mapTableData(res.results);

        this.loading = false;
      });
  }

  private mapTableData(results: MockFaceItSubscription[]) {
    this.tableData = new GaTableData<MockFaceItSubscription>()
      .addSimpleColumn(u => u.id, { title: 'Id', widthSize: 100 })
      .addSimpleColumn(u => u.subscriptionId, { title: 'Id подписки', widthSize: 100 })
      .addSimpleColumn(u => u.userId, { title: 'Id юзера', widthSize: 100 })
      .addSimpleColumn(u => u.bkTaskId, { title: 'Id задания', widthSize: 100 })
      .addSimpleColumn(u => u.payload, { title: 'Payload', widthSize: 400 })
      .addSimpleColumn(u => formatDateTime(u.requestConfirmedAt), { title: 'Дата подтверждения', widthSize: 150 })
      .addSimpleColumn(u => WlExternalSubsResultsExtensions.format(u.confirmationCode), { title: 'Код подтверждения', widthSize: 150 })
      .addSimpleColumn(u => formatDateTime(u.completedAt), { title: 'Дата завершения', widthSize: 150 })
      .addSimpleColumn(u => WlExternalTaskResultsExtensions.format(u.completionCode), { title: 'Код завершения', widthSize: 150 })
      .addSimpleColumn(u => formatDateTime(u.createdAt), { title: 'Дата создания', widthSize: 150 })
      .setData(results);
  }

  private handleFilterChange(): void {
    const params: Params = {
      userId: this.pagingViewModel.userId.hasStrictValue ? String(this.pagingViewModel.userId.strictValue) : null,
      offset: this.pagingViewModel.offset.hasStrictValue ? this.pagingViewModel.offset.strictValue : 0,
      count: this.pagingViewModel.count.hasStrictValue ? this.pagingViewModel.count.strictValue : 30
    };

    this._navigation.search(this._activeRoute, params);
  }
}
