<div class="form-group">
  <app-strict-input-number [control]="userId" [placeholder]="'Id'">
  </app-strict-input-number>
</div>
<div class="form-group">
  <app-strict-input-text [control]="name" [placeholder]="'Имя'">
  </app-strict-input-text>
</div>
<div class="form-group">
  <app-strict-input-phone [control]="phone" [placeholder]="'Номер телефона'">
  </app-strict-input-phone>
</div>
<div class="form-group">
  <app-enum-dropdown [entityContainer]="stageType"
                     [strategy]="_stageStrategy"
                     [options]="optionsStage">
  </app-enum-dropdown>
</div>
<div class="form-group">
  <app-enum-dropdown [entityContainer]="monetaryType"
                     [strategy]="_typeStrategy"
                     [options]="optionsMonetaryClass">
  </app-enum-dropdown>
</div>
<button class="btn btn-primary mt-2" (click)="save()">Сохранить</button>
