import {Component, OnInit} from '@angular/core';
import {AddDocsAndSelfieVerificationViewModel} from '../../../../models/events/components-models/add-docs-and-selfie-verification-view-model';
import {GaMessagingService, GaPanelGridConfig, GaTitlePositions, StrictFormControl} from '@koddington/ga-common';
import {AddDocsAndSelfieVerificationResultValidator} from '../../validators/add-docs-and-selfie-verification-result-validator';
import {WlResult} from '../../../../models/common/results/wl-result';
import { AddDocsVerificationResultForm } from 'src/app/services/autogen/Events';
import { EventsService } from 'src/app/services/autogen/Events';
import {filter, first, map} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {DocsAndSelfieVerificationStrategy} from '../../../shared/common/enum-dropdown/strategies/docs-and-selfie-verification-strategy';

@UntilDestroy()
@Component({
  selector: 'app-add-docs-and-selfie-verification-result',
  templateUrl: './add-docs-and-selfie-verification-result.component.html',
  styleUrls: ['./add-docs-and-selfie-verification-result.component.css']
})
export class AddDocsAndSelfieVerificationResultComponent implements OnInit {
  public viewModel: AddDocsAndSelfieVerificationViewModel = new AddDocsAndSelfieVerificationViewModel();
  public validator: AddDocsAndSelfieVerificationResultValidator = new AddDocsAndSelfieVerificationResultValidator();

  public result: WlResult<boolean>;
  config = new GaPanelGridConfig();

  constructor(private readonly service: EventsService,
              private readonly messaging: GaMessagingService,
              private readonly strategy: DocsAndSelfieVerificationStrategy) { }

  ngOnInit(): void {
    this.buildGrid();
  }

  isSuccessful(): string {
    if (!this.result) {
      return;
    }
    return this.result.isCorrect ? 'alert alert-success' : 'alert alert-danger';
  }

  add(): void {
    this.result = null;

    const errors = this.validator.validate(this.viewModel);
    if (errors.length > 0) {
      return;
    }
    const form = new AddDocsVerificationResultForm();
    form.userId = this.viewModel.userId.strictValue;
    form.status = this.viewModel.status.strictValue;
    form.eventDate = this.viewModel.eventDate.strictValue;

    this.service.addDocsAndSelfieVerificationResult(form).pipe(
      first(),
      filter(value => !this.messaging.tryShowError(value)),
      map(value => this.result = value),
      untilDestroyed(this)
    ).subscribe();
  }

  private buildGrid() {
    this.config.columnsCount = 5;
    this.config.titlePositions = GaTitlePositions.Top;
    this.config.addNumbers(this.viewModel.userId, 'Id пользователя')
      .addDateTime(this.viewModel.eventDate, 'Дата события')
      .addDropDown(this.viewModel.status, 'Тип ошибки', this.strategy, false);
  }
}
