import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from './common/enum-dropdown/enum-dropdown.component';
import {AddFinanceOperationComponent} from '../events/events-components/add-finance-operation/add-finance-operation.component';
import {MoscowDateTimePipe} from './pipes/moscow-timezone.pipe';
import {ReactiveFormsModule} from '@angular/forms';
import {LoadingComponent} from './common/loading/loading.component';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import {MatIconModule} from '@angular/material/icon';
import {DateTimePipe} from './pipes/date-time.pipe';
import {WlIndexPagingComponent} from './common/wl-index-paging/wl-index-paging.component';
import {DateOnlyPipe} from './pipes/date-only.pipe';
import {StrictInputPhoneComponent} from './common/strict-input-phone/strict-input-phone.component';
import {WlBetEventComponent} from './common/wl-bet-event/wl-bet-event.component';
import {GaCommonModule} from '@koddington/ga-common';
import {StrictInputBooleanCheckboxComponent} from './common/strict-input-boolean-checkbox/strict-input-boolean-checkbox.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { SharedAreaCodegenAppModule } from '../../services/autogen/Shared';
import { UsersAreaCodegenAppModule } from '../../services/autogen/Users';
import { DictionariesAreaCodegenAppModule } from '../../services/autogen/Dictionaries';
import { SmsGateAreaCodegenAppModule } from '../../services/autogen/SmsGate';

@NgModule({
  declarations: [
    DropdownComponent,
    AddFinanceOperationComponent,
    MoscowDateTimePipe,
    LoadingComponent,
    WlBetEventComponent,
    StrictInputBooleanCheckboxComponent,
    StrictInputPhoneComponent,
    DateTimePipe,
    DateOnlyPipe,
    WlIndexPagingComponent,
  ],
    exports: [
        DropdownComponent,
        AddFinanceOperationComponent,
        MoscowDateTimePipe,
        LoadingComponent,
        WlBetEventComponent,
        StrictInputPhoneComponent,
        DateTimePipe,
        WlIndexPagingComponent,
        DateOnlyPipe,
        StrictInputBooleanCheckboxComponent,
        SharedAreaCodegenAppModule
    ],
  imports: [
    MatSnackBarModule,
    MatInputModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCheckboxModule,
    NgxDaterangepickerMd,
    CommonModule,
    NgxMatDatetimePickerModule,
    MatIconModule,
    GaCommonModule,
    SharedAreaCodegenAppModule,
    UsersAreaCodegenAppModule,
    DictionariesAreaCodegenAppModule,
    SmsGateAreaCodegenAppModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
