<app-custom-tournaments-item-crud></app-custom-tournaments-item-crud>
<div>
  <h3>Кастомные турниры</h3>
  <div *ngIf="loading">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!loading">
    <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
    <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
