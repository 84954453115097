<nav class="navbar navbar-expand-sm navbar-light bg-white mb-3 justify-content-lg-end">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/mechanics/list']">
        Механики пользователей
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/mechanics/processing-requests']">
        Очередь обработки
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/mechanics/processing-errors']">
        Логи ошибок винлайна
      </a>
    </li>
  </ul>
</nav>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
