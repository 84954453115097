<h3>Профиль пользователя Pick'em</h3>

<div class="row">
    <ga-panel-grid [config]="filterInputGrid"/>
    <span>
        <button class="btn btn-primary mt-2" (click)="load()">Загрузить</button>
    </span>
</div>
<div *ngIf="tableData" class="row">
    <ga-table [data]="tableData"></ga-table>
</div>

<ng-template #promoTaskShow let-promoTask="promoTask">
    <div class="wl-task-text" *ngIf="!!promoTask">
        <div>Id задания: {{ promoTask.taskGroupId }}</div>
        <div>Прогресс: {{ promoTask.currentProgress }}</div>
        <div>Цель: {{ promoTask.targetProgress }}</div>
    </div>
</ng-template>

<ng-template #daysShow let-days="days">
    <app-pickem-erp-days [days]="days"></app-pickem-erp-days>
</ng-template>
