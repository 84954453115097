import { MockRateRaceResultPlaceViewModel, MockRateRaceResultsCrudViewModel } from '../../../../../models/rate-race/mock-rate-race-results-crud-view-model';
import { RateRaceResultsService } from 'src/app/services/autogen/BattlePass';
import { Router } from '@angular/router';
import { GaMessagingService } from '@koddington/ga-common';
import { RateRaceResultCreateStrategy } from './rate-race-result-create-strategy';
import { IRateRaceResultsCrudStrategy } from './i-rate-race-results-crud-strategy';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { MockRateRaceResultCreateForm } from 'src/app/services/autogen/BattlePass';
import { builderModelToStrict, gaMap } from '../../../../shared/common/operation/builder-operation';

export class RateRaceResultRepeatStrategy implements IRateRaceResultsCrudStrategy{

    private readonly _createStrategy: RateRaceResultCreateStrategy;

    constructor(protected readonly _service: RateRaceResultsService,
                protected readonly _router: Router,
                protected readonly _messaging: GaMessagingService,
                protected readonly id: number) {
        this._createStrategy = new RateRaceResultCreateStrategy(_service, _router, _messaging);
    }

    public loadViewModel(): Observable<MockRateRaceResultsCrudViewModel> {
        return this._service.getForRepeat(this.id)
            .pipe(
                take(1),
                filter(res => !this._messaging.tryShowError(res)),
                map(res => this.mapFormToViewModel(res.result))
            );
    }

    public save(model: MockRateRaceResultsCrudViewModel) {
        this._createStrategy.save(model);
    }

    public getHeaderText(): string {
        return `Повтор таблицы результатов RateRace №${this.id}`;
    }

    private mapFormToViewModel(result: MockRateRaceResultCreateForm): MockRateRaceResultsCrudViewModel {
        const viewModel = new MockRateRaceResultsCrudViewModel();
        builderModelToStrict(viewModel, result, [
            {
                sourceMapProperty: 'userPlaces',
                targetMapProperty: 'userPlaces',
                mapObject: gaMap(result.userPlaces, (value) => {
                    const mapped = new MockRateRaceResultPlaceViewModel();
                    builderModelToStrict(mapped, value, null, null);
                    return mapped;
                }),
            }
        ], null);

        return viewModel;
    }
}
