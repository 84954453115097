<div class="col-8">
  <h1>Cброс истории расчёта ставок</h1>
  <app-enum-dropdown class="row col-2"
                     [strategy]="strategy"
                     placeholder="Выберите тип"
                     [entityContainer]="resetResultBetType">
  </app-enum-dropdown>
  <div class="row">
    <app-strict-input-number class="col-4" [control]="userIdBet"
                             [placeholder]="'Id пользователя'"></app-strict-input-number>
    <span class="col-6">
      <button class="btn btn-primary" (click)="resetBet()" [disabled]="getUserIdBetHasValue()">Сбросить</button>
    </span>
  </div>
  <hr>
  <h1>Cброс истории депозитов</h1>
  <app-enum-dropdown class="row col-2"
                     [strategy]="strategy"
                     placeholder="Выберите тип"
                     [entityContainer]="resetResultDepositType">
  </app-enum-dropdown>
  <div class="row">
    <app-strict-input-number class="col-4" [control]="userIdDeposit"
                             [placeholder]="'Id пользователя'"></app-strict-input-number>
    <span class="col-6">
      <button class="btn btn-primary" (click)="resetDeposit()" [disabled]="getUserIdDepositHasValue()">Сбросить</button>
    </span>
  </div>
  <hr>
  <h1>Cброс истории ставок</h1>
  <div class="row">
    <app-strict-input-number class="col-4" [control]="userIdStat"
                             [placeholder]="'Id пользователя'"></app-strict-input-number>
    <span class="col-6">
      <button class="btn btn-primary" (click)="dropUserBetStat()" [disabled]="getUserIdStatsHasValue()">Сбросить</button>
    </span>
  </div>
</div>
