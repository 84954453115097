import { StrictFormControlBuilder } from '@koddington/ga-common';
import { WlBattlePassQuestSubscriptionStates } from '../../../enums/wl-battle-pass-quest-subscription-states';
import { Dayjs } from 'dayjs';

export class BattlePassUserQuestSubscriptionViewModel {
    subscriptionId: number;
    userId: number;
    questId: number;
    subscriptionDateTime: Dayjs;
    completeDateTime?: Dayjs;
    isManualCompletion?: boolean;
    currentProgress?: number;

    statusCode = StrictFormControlBuilder.buildNullNumber<WlBattlePassQuestSubscriptionStates>();
    status = StrictFormControlBuilder.buildNullString();
    message = StrictFormControlBuilder.buildNullString();
}
