<div class="form-group">
  <app-strict-input-number [control]="templateCode" [placeholder]="'Код шаблона'">
  </app-strict-input-number>
</div>
<div class="form-group">
  <app-strict-input-text [control]="values" placeholder="Значения">
  </app-strict-input-text>
</div>
<p class="mt-2">Значения указываются без пробелов, через запятую. Идут в том же порядке, что в шаблоне на
  Push-сервере.
  Возможные значения: "CLIENTNAME" - имя клиента, "Random" - случайное число, любое другое значение вставится без
  изменений</p>
<button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
