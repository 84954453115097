import { Component, Input, OnInit } from '@angular/core';
import { WlUserQuestSubscription } from 'src/app/services/autogen/BattlePass';
import { BattlePassUserQuestSubscriptionViewModel } from '../../../../models/events/components-models/battle-pass-user-quest-subscription-view-model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import { GaMessagingService, isNullOrUndefined } from '@koddington/ga-common';
import { WlBattlePassQuestSubscriptionStates, WlBattlePassQuestSubscriptionStatesExtensions } from '../../../../enums/wl-battle-pass-quest-subscription-states';
import { BattlePassQuestSubscriptionValidator } from '../../../events/validators/battle-pass-quest-subscription-validator';
import { WlBattlePassQuestSubscriptionStatesStrategy } from '../../../shared/common/enum-dropdown/strategies/wl-battle-pass-quest-subscription-states';
import { filter } from 'rxjs/operators';
import { AddConfirmationStatusEventForm } from 'src/app/services/autogen/BattlePass';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
    selector: 'app-battle-pass-subscription-item[item]',
    templateUrl: './battle-pass-subscription-item.component.html'
})
export class BattlePassSubscriptionItemComponent implements OnInit {

    @Input()
    public item: WlUserQuestSubscription;
    public viewModel = new BattlePassUserQuestSubscriptionViewModel();
    public responseCodeStrategy: WlBattlePassQuestSubscriptionStatesStrategy;
    private validator: BattlePassQuestSubscriptionValidator;

    constructor(private readonly battlePassService: BattlePassService,
                private readonly messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public sendResponse(): void {
        const errors = this.validator.validate(this.viewModel);
        if (errors.length > 0) {
            return;
        }

        const form = new AddConfirmationStatusEventForm();

        form.subscriptionId = this.viewModel.subscriptionId;
        form.status = this.viewModel.status.strictValue;
        form.message = this.viewModel.message.strictValue;
        form.eventDate = dayjs();

        this.battlePassService.subscriptionUpdateState(form)
            .pipe(
                filter(u => !this.messaging.tryShowError(u)),
                untilDestroyed(this))
            .subscribe();
    }

    private init(): void {
        this.responseCodeStrategy = new WlBattlePassQuestSubscriptionStatesStrategy();
        this.validator = new BattlePassQuestSubscriptionValidator();
        this.loadModel();

        this.viewModel.statusCode.valueChanges.pipe(untilDestroyed(this)).subscribe(res => this.onErrorCodeChange(res));
    }

    private loadModel(): void {
        this.viewModel.subscriptionId = this.item.subscriptionId;
        this.viewModel.userId = this.item.userId;
        this.viewModel.questId = this.item.questId;
        this.viewModel.subscriptionDateTime = this.item.subscriptionDateTime;
        this.viewModel.currentProgress = this.item.currentProgress;
        this.viewModel.completeDateTime = this.item.completeDateTime;
        this.viewModel.status.strictValue = this.item.status;
        this.viewModel.message.strictValue = this.item.message;
        this.viewModel.isManualCompletion = this.item.isManualCompletion;
    }

    private onErrorCodeChange(value: WlBattlePassQuestSubscriptionStates) {
        if (isNullOrUndefined(value)) {
            this.viewModel.status.strictValue = null;
            this.viewModel.status.enable();
            return;
        }

        this.viewModel.status.strictValue = WlBattlePassQuestSubscriptionStatesExtensions.toRabbitMessage(value);
        this.viewModel.status.disable();
    }
}
