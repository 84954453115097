import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class AddFinanceOperationViewModel {
  public userId = StrictFormControlBuilder.buildNullNumber();
  public operationDate = StrictFormControlBuilder.buildNullDayjs();
  public operationSum = StrictFormControlBuilder.buildNullNumber();
  public paymentSystemConfirmationDate = StrictFormControlBuilder.buildNullDayjs();
  public documentId = StrictFormControlBuilder.buildNullNumber();
  public operationSourceId = StrictFormControlBuilder.buildNullNumber();
  public operationId = StrictFormControlBuilder.buildNullNumber();
  public comment = StrictFormControlBuilder.buildNullString();
}
