<h3>{{ headerText }}</h3>
<div class="col-6">
    <app-strict-input-text [control]="viewModel.name"
                           [title]="'Название'"
                           (valueChange)="userManipulationsSource.next()"
    ></app-strict-input-text>
    <app-strict-input-number [control]="viewModel.battlePassSeasonId"
                             [title]="'Id сезона боевого пропуска'"
                             [step]="1"
                             (valueChange)="userManipulationsSource.next()"
    ></app-strict-input-number>
    <button class="btn btn-primary" (click)="addAllRegisteredUsers()">Добавить всех зарегистрированных</button>
    <div class="row" *ngFor="let placeInfo of viewModel.userPlaces.strictValue, let i = index">
        <app-strict-input-number class="col-3"
                                 [control]="placeInfo.place"
                                 [title]="'Место'"
                                 [step]="1"
                                 (valueChange)="userManipulationsSource.next()"
        ></app-strict-input-number>
        <app-strict-input-number class="col-3"
                                 [control]="placeInfo.userId"
                                 [title]="'Id пользователя'"
                                 [step]="1"
                                 (valueChange)="userManipulationsSource.next()"
        ></app-strict-input-number>
        <app-strict-input-number class="col-3"
                                 [control]="placeInfo.drop"
                                 [title]="'Дроп'"
                                 [step]="1"
                                 (valueChange)="userManipulationsSource.next()"
        ></app-strict-input-number>
        <div class="col-1">
            <div class="row">
                <a mat-menu-item class="wl-event-add-item mt-4 col-6" (click)="addUser(i)">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="36" height="36" rx="8" fill="#007BFF"/>
                        <line stroke-width="3" stroke-linecap="round" y2="18" x2="28" y1="18" x1="8" stroke="#FFFFFF"/>
                        <line stroke-width="3" stroke-linecap="round" y2="28" x2="18" y1="8" x1="18" stroke="#FFFFFF"/>
                    </svg>
                </a>
                <a *ngIf="canDelete" mat-menu-item class="wl-event-del-item mt-4 col-6" (click)="deleteUser(i)">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="36" height="36" rx="8" fill="#DC3545"/>
                        <line stroke-width="3" stroke-linecap="round" y2="18" x2="28" y1="18" x1="8" stroke="#FFFFFF"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
    <button class="btn btn-primary" (click)="save()" [disabled]="!isValid">Сохранить</button>
</div>
