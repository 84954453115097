import {Routes} from '@angular/router';
import {BattlePassLayoutComponent} from './components/battle-pass-layout/battle-pass-layout.component';
import {BattlePassSubscriptionsComponent} from './components/battle-pass-subscriptions/battle-pass-subscriptions.component';
import {BattlePassQuestsProgressComponent} from './components/battle-pass-quests-progress/battle-pass-quests-progress.component';
import {
  BattlePassUserProfileBindingComponent
} from './components/battle-pass-user-profile-binding/battle-pass-user-profile-binding.component';
import {BattlePassUsersProfilesComponent} from './components/battle-pass-users-profiles/battle-pass-users-profiles.component';
import {BattlePassAccrualRewardListComponent} from './components/battle-pass-accrual-reward-list/battle-pass-accrual-reward-list.component';
import {
  BattlePassRewardDictionaryListComponent
} from './components/battle-pass-reward-dictionary-list/battle-pass-reward-dictionary-list.component';
import {BattlePassFreeBetRequestsComponent} from './components/battle-pass-user-freebets/battle-pass-free-bet-requests.component';
import {BattlePassFreeBetSequencesComponent} from './components/battle-pass-freebet-sequences/battle-pass-free-bet-sequences.component';
import {
  BattlePassLootBoxDictionaryListComponent
} from './components/battle-pass-loot-box-dictionary-list/battle-pass-loot-box-dictionary-list.component';
import { BattlePassErpComponent } from './components/battle-pass-erp/battle-pass-erp.component';
import { BattlePassSeasonCopyComponent } from './components/battle-pass-season-copy/battle-pass-season-copy.component';

export const ChildrenMockRoute: Routes = [
  {
    path: 'user-quests-progress',
    component: BattlePassQuestsProgressComponent
  },
  {
    path: 'users-subscriptions',
    component: BattlePassSubscriptionsComponent
  },
  {
    path: 'user-profile-binding',
    component: BattlePassUserProfileBindingComponent
  },
  {
    path: 'profiles-list',
    component: BattlePassUsersProfilesComponent
  },
  {
    path: 'accrual-reward',
    component: BattlePassAccrualRewardListComponent
  },
  {
    path: 'reward-dictionary',
    component: BattlePassRewardDictionaryListComponent
  },
  {
    path: 'free-bets-sequences',
    component: BattlePassFreeBetSequencesComponent
  },
  {
    path: 'free-bets-requests/:id',
    component: BattlePassFreeBetRequestsComponent,
  },
  {
    path: 'loot-box-dictionary',
    component: BattlePassLootBoxDictionaryListComponent
  },
  {
    path: 'erp',
    component: BattlePassErpComponent
  },
  {
    path: 'season-copy',
    component: BattlePassSeasonCopyComponent
  },
];

export const BattlePassRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'main',
        component: BattlePassLayoutComponent,
        children: ChildrenMockRoute
      }
    ]
  }
];
