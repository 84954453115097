import {Component, OnInit} from '@angular/core';
import {AddUserBtagViewModel} from '../../../../models/media/view/add-user-btag-view-model';
import {AddUserBtagValidator} from '../../../../models/media/view/validators/add-user-btag-validator';
import { AddMediaUserBtagForm } from 'src/app/services/autogen/Shared';
import {filter, first, map} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MediaService } from 'src/app/services/autogen/Shared';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-media-add-user-btag',
  templateUrl: './media-add-user-btag.component.html',
  styleUrls: ['./media-add-user-btag.component.scss']
})
export class MediaAddUserBtagComponent implements OnInit {

  public viewModel: AddUserBtagViewModel = new AddUserBtagViewModel();
  public validator: AddUserBtagValidator = new AddUserBtagValidator();

  constructor(private _service: MediaService,
              private _messaging: GaMessagingService) { }

  ngOnInit(): void {
  }

  public send(): void {
    const errors = this.validator.validate(this.viewModel);

    if (errors.length > 0) {
      return;
    }

    const form = new AddMediaUserBtagForm();
    form.btagId = this.viewModel.btagId.strictValue;
    form.userId = this.viewModel.userId.strictValue;
    form.changeDate = this.viewModel.changeDate.strictValue;

    this._service.addBtagInfo(form).pipe(
      first(),
      filter(value => !this._messaging.tryShowError(value)),
      map((value) => {
        if (value.result) {
          this._messaging.showMessage('Btag для пользователя добавлен');
        } else {
          this._messaging.showMessage('Btag не добавлен, проверьте введённые данные');
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }
}
