import {Injectable} from '@angular/core';
import {AbstractModelValidator} from '@koddington/ga-common';
import {AddMechanicViewModel} from '../components/new-segmenatation/models/add-mechanic-view-model';
import { WlSegmentationMechanicsTypes } from 'src/app/services/autogen/Events';

@Injectable()
export class EnabledMehcanicValidator extends AbstractModelValidator<AddMechanicViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).required('Поле обязательно').min(1).maxLength(9);
    this.ruleForControl(m => m.mechanicEnabledReason).required('Поле обязательно');
    this.ruleForControl(m => m.mechanicType).required('Поле обязательно');

    this.ruleForControlIf(m => m.depositWaitingDate,
      c => c.mechanicType.strictValue === WlSegmentationMechanicsTypes.DepositFreebet ||
        c.mechanicType.strictValue === WlSegmentationMechanicsTypes.NextWillNotLose)
      .requiredAndNotZero('Поле обязательно');

    this.ruleForControlIf(m => m.freebetId,
      c => c.mechanicType.strictValue === WlSegmentationMechanicsTypes.DepositFreebet ||
        c.mechanicType.strictValue === WlSegmentationMechanicsTypes.UnconditionalFreebet)
      .requiredAndNotZero('Поле обязательно').maxLength(9);

    this.ruleForControlIf(m => m.sumForMechanicTriggering,
      c => c.mechanicType.strictValue === WlSegmentationMechanicsTypes.DepositFreebet)
      .requiredAndNotZero('Поле обязательно').maxLength(9);

    this.ruleForControlIf(m => m.freebetLifetimeDays,
      c => c.mechanicType.strictValue === WlSegmentationMechanicsTypes.DepositFreebet ||
        c.mechanicType.strictValue === WlSegmentationMechanicsTypes.UnconditionalFreebet)
      .requiredAndNotZero('Поле обязательно').maxLength(9);

    this.ruleForControlIf(m => m.freebetSum,
      c => c.mechanicType.strictValue === WlSegmentationMechanicsTypes.DepositFreebet ||
        c.mechanicType.strictValue === WlSegmentationMechanicsTypes.UnconditionalFreebet)
      .requiredAndNotZero('Поле обязательно').maxLength(9);
  }
}
