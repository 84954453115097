<div>
  <h3>Подключение механики для пользователя</h3>
  <div>
    <div class="row mt-2">
      <div class="col-1">
        Id пользователя
      </div>
      <div class="col-2">
        Идентификатор причины выдачи
      </div>
      <div class="col-2">
        Тип механики
      </div>
      <div *ngIf="!isUnconditionalFreebet()" class="col-2">
        Срок ожидания депозита
      </div>
      <div class="col-1">
        Id фрибета
      </div>
      <div class="col-2">
        Сумма депозита для срабатывания механики
      </div>
      <div class="col-1">
        Сумма фрибета
      </div>
      <div class="col-1">
        Срок жизни фрибета (дни)
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <app-strict-input-number [control]="userId" placeholder="Id пользователя">
        </app-strict-input-number>
      </div>
      <div class="col-2">
        <app-enum-dropdown [entityContainer]="mechanicEnabledReason" [strategy]="_mechanicEnabledReasonId" [options]="options" >
        </app-enum-dropdown>
      </div>
      <div class="col-2">
        <app-enum-dropdown [entityContainer]="mechanicType" [strategy]="_mechanicTypeStrategy" [options]="options">
        </app-enum-dropdown>
      </div>
      <div *ngIf="!isUnconditionalFreebet()" class="col-2">
        <strict-date-time-picker placeholder="Срок ожидания депозита" [control]="depositWaitingDate">
        </strict-date-time-picker>
      </div>
      <div class="col-1">
        <app-strict-input-number [control]="freebetId" placeholder="Id фрибета">
        </app-strict-input-number>
      </div>
      <div class="col-2">
        <app-strict-input-number [control]="sumForMechanicTriggering" placeholder="Сумма депозита для срабатывания механики">
        </app-strict-input-number>
      </div>
      <div class="col-1">
        <app-strict-input-number [control]="freebetSum" placeholder="Сумма фрибета">
        </app-strict-input-number>
      </div>
      <div class="col-1">
        <app-strict-input-number [control]="freebetLifetimeDays" placeholder="Срок жизни фрибета">
        </app-strict-input-number>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="addEnabledMechanic()">Сохранить</button>
  </div>
</div>
