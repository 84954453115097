import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-battle-pass-quests-progress',
  templateUrl: './battle-pass-quests-progress.component.html',
  styleUrls: ['./battle-pass-quests-progress.component.css']
})
export class BattlePassQuestsProgressComponent implements OnInit, OnDestroy {

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
