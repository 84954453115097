import {AbstractModelValidator, AddErrorsToControlMode, StrictError, ValidationLevel} from '@koddington/ga-common';
import { MockRateRaceResultsCrudViewModel } from '../../../models/rate-race/mock-rate-race-results-crud-view-model';
import { StrictValidationRules } from '../../shared/validation-rules/strict-validation-rules';
import { RateRaceResultsPlacesValidator } from './rate-race-results-places-validator';

export class RateRaceResultsCrudValidator extends AbstractModelValidator<MockRateRaceResultsCrudViewModel> {

    private readonly placeValidator = new RateRaceResultsPlacesValidator();

    constructor() {
        super();

        this.ruleForControl(u => u.name)
            .required('Не введено имя')
            .maxLength(2000, 'Имя превышает длину в 2000 символов');

        this.ruleForControl(u => u.battlePassSeasonId)
            .required('Сезон боевого пропуска обязателен')
            .min(1, 'Id сезона боевого пропуска должен быть больше нуля')
            .customRule(u => StrictValidationRules.isInteger(u));
    }

    override validate(model: MockRateRaceResultsCrudViewModel, validationLevel?: ValidationLevel, addErrorsToControls?: AddErrorsToControlMode): StrictError[] {
        return [
            ...super.validate(model, validationLevel, addErrorsToControls),
            ...this.placeValidator.validateArray(model.userPlaces.strictValue, validationLevel, addErrorsToControls)
        ];
    }
}
