import {Component, OnInit} from '@angular/core';
import {finalize, map, switchMap, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {UserListViewModel} from '../../../../models/users/user-list-view-model';
import { WlUserQuestProgress } from 'src/app/services/autogen/BattlePass';
import { UserQuestProgressForm } from 'src/app/services/autogen/BattlePass';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import {ActivatedRoute, Params} from '@angular/router';
import {Observable} from 'rxjs';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';
import {GaPagedResult, GaPagingForm, isNullOrUndefined} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-quests-progress',
  templateUrl: './user-quests-progress.component.html',
  styleUrls: ['./user-quests-progress.component.css']
})

export class UserQuestsProgressComponent implements OnInit {

  public model: UserListViewModel = new UserListViewModel();
  public pagedResult: GaPagedResult<WlUserQuestProgress>;
  public results: WlUserQuestProgress[] = [];
  public loading: boolean;

  constructor(private readonly service: BattlePassService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService) {
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.pipe(
      map(value => this.initModel(value)),
      map(() => this.createForm()),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe(value => {
      this.pagedResult = value;
      this.results = value.results;
    });
  }

  public search(): void {
    const params: Params = {
      userId: this.model.userId.hasStrictValue ? this.model.userId.strictValue : null,
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : 30
    };

    this.navigation.search(this.activeRoute, params);
  }

  public pageChanged(form: GaPagingForm): void {
    this.model.offset.strictValue = form.offset;
    this.search();
  }

  private load(form: UserQuestProgressForm): Observable<GaPagedResult<WlUserQuestProgress>> {
    this.loading = true;

    return this.service.questsProgressList(form).pipe(
      take(1),
      finalize(() => this.loading = false),
      untilDestroyed(this)
    );
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : null;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : null;
    this.model.userId.strictValue = !isNullOrUndefined(params['userId']) ?
      params['userId'] : null;
  }

  private createForm(): UserQuestProgressForm {
    const form = new UserQuestProgressForm();
    form.offset = this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0;
    form.count = this.model.count.hasStrictValue ? this.model.count.strictValue : 30;
    form.userId = this.model.userId.strictValue;

    return form;
  }
}
