import {AbstractModelValidator, StrictError} from '@koddington/ga-common';
import {UserSegmentationDataViewModel} from '../../../models/segments/user-segmentation-data-view-model';
import {Injectable} from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';

@Injectable()
export class UserSegmentationDataValidator extends AbstractModelValidator<UserSegmentationDataViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).required().min(1);
    this.ruleForControl(m => m.securityGroupId).required('Поле обязательно');
    this.ruleForControl(m => m.email).minLength(1);
    this.ruleForControl(m => m.registrationSource).min(0);
    this.ruleForControl(m => m.operationDate).required();
    this.ruleForControl(m => m.phoneNumber).customRule(fieldValue => {
      if (!isNullOrUndefined(fieldValue) && isNaN(Number(fieldValue))) {
        return new StrictError('phoneNumberProhibitedChar', 'Номер телефона может содержать только цифры');
      }
      return null;
    });
  }
}
