<div>
  <h3>Создание кастомного турнира</h3>
    <div class="row">
        <div class="col-1">
            {{"Id турнира"}}
        </div>
        <div class="col-2">
            {{"Наименование турнира"}}
        </div>
        <div class="col-1">
            {{"Тип турнира"}}
        </div>
        <div class="col-1">
            {{"Призовой фонд"}}
        </div>
        <div class="col-1">
            {{"Тип призового фонда"}}
        </div>
        <div class="col-1">
            {{"Число призёров"}}
        </div>
        <div class="col-1">
            {{"Число участников"}}
        </div>
        <div class="col-1">
            {{"Запущен"}}
        </div>
    </div>
    <div class="mt-2 row">
        <app-strict-input-number class="col-1"
                                 [control]="viewModel.id">
        </app-strict-input-number>
        <app-strict-input-text class="col-2"
                               [control]="viewModel.name">
        </app-strict-input-text>
        <app-enum-dropdown class="col-1"
                           [entityContainer]="viewModel.tournamentType"
                           [strategy]="tournamentTypesStrategy"
        ></app-enum-dropdown>
        <app-strict-input-number class="col-1"
                                 [control]="viewModel.prizeFund">
        </app-strict-input-number>
        <app-enum-dropdown class="col-1"
                           [entityContainer]="viewModel.prizeFundType"
                           [strategy]="prizeFundTypesStrategy"
        ></app-enum-dropdown>
        <app-strict-input-number class="col-1"
                                 [control]="viewModel.winnersCount">
        </app-strict-input-number>
        <app-strict-input-number class="col-1"
                                 [control]="viewModel.usersCount">
        </app-strict-input-number>
        <app-strict-input-boolean class="col-1"
                                  [control]="viewModel.isActive">
        </app-strict-input-boolean>
    </div>
    <div class="row">
        <div class="col-2">
            {{"Дата регистрации"}}
        </div>
        <div class="col-2">
            {{"Дата начала"}}
        </div>
        <div class="col-2">
            {{"Дата завершения"}}
        </div>
    </div>
    <div class="mt-2 row">
        <strict-date-time-picker class="col-2"
                                 [control]="viewModel.registrationDate">
        </strict-date-time-picker>
        <strict-date-time-picker class="col-2"
                                 [control]="viewModel.startDate">
        </strict-date-time-picker>
        <strict-date-time-picker class="col-2"
                                 [control]="viewModel.endDate">
        </strict-date-time-picker>
    </div>
    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
