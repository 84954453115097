import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {GaMessagingService, GaPagedResult, isNullOrUndefined} from '@koddington/ga-common';
import {map, switchMap, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PushBroadcastingStatisticsForm } from 'src/app/services/autogen/Broadcasts';
import {Observable} from 'rxjs';
import {PushBroadcastingStatisticsStrictViewModel} from '../../../models/broadcasts/push-broadcasting-statistics-strict-view-model';
import {SearchNavigationService} from '../../shared/services/search-navigation.service';
import { BroadcastsService } from 'src/app/services/autogen/Broadcasts';
import { PushBroadcastingStatisticsViewModel } from 'src/app/services/autogen/Broadcasts';

@UntilDestroy()
@Component({
  selector: 'app-push-broadcasting-statistics-list',
  templateUrl: './push-broadcasting-statistics-list.component.html',
  styleUrls: ['./push-broadcasting-statistics-list.component.css']
})
export class PushBroadcastingStatisticsListComponent implements OnInit {

  public model: PushBroadcastingStatisticsStrictViewModel = new PushBroadcastingStatisticsStrictViewModel();
  public result: GaPagedResult<PushBroadcastingStatisticsViewModel>;

  constructor(private readonly messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly service: BroadcastsService) {
  }

  ngOnInit() {

    this.activeRoute.queryParams.pipe(
      map(value =>  this.initModel(value)),
      map(() => this.createForm()),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe(value => this.result = value);
  }

  public search(): void {
    this.model.offset.strictValue = 0;
    this.navigateThisParam();
  }

  public pageChanged(form: PushBroadcastingStatisticsForm): void {
    this.model.count.strictValue = form.count;
    this.model.offset.strictValue = form.offset;

    this.navigateThisParam();
  }

  private load(form: PushBroadcastingStatisticsForm): Observable<GaPagedResult<PushBroadcastingStatisticsViewModel>> {
    return this.service.listPushBroadcastingStatistics(form).pipe(
      take(1),
      untilDestroyed(this)
    );
  }

  private createForm(): PushBroadcastingStatisticsForm {
    const form = new PushBroadcastingStatisticsForm();
    form.userId = this.model.userId.strictValue;
    form.offset = this.model.offset.strictValue;
    form.count = this.model.count.strictValue;
    return form;
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : 0;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : 30;
    this.model.userId.strictValue = !isNullOrUndefined(params['userId']) ?
      params['userId'] : null;
  }

  private navigateThisParam(): void {
    const params: Params = {
      userId: this.model.userId.hasStrictValue ? this.model.userId.strictValue : null,
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : 30,
      tab: 'PushBroadcastingStatistics'
    };

    this.navigation.search(this.activeRoute, params);
  }
}
