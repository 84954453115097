import {UpdateSecurityGroupViewModel} from '../models/update-security-group.view-model';
import {Injectable} from '@angular/core';
import {AbstractModelValidator} from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class UpdateFormValidator extends AbstractModelValidator<UpdateSecurityGroupViewModel> {
  constructor() {
    super();

    this.ruleForControl(u => u.id)
      .required();

    this.ruleForControl(u => u.name)
      .required()
      .maxLength(150);

    this.ruleForControl(u => u.isGood)
      .required();
  }
}
