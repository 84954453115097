<div class="row">
  <div class="col-6">
    <app-create-sms-account (accountCreated)="refreshList()"></app-create-sms-account>
  </div>
  <div class="col-6">
    <app-loading *ngIf="loading"></app-loading>
    <h1 class="mt-2">Список аккаунтов</h1>
    <table class="table" *ngIf="!loading" style="width: 100%">
      <tr class="table-info">
        <th>
          Логин
        </th>
        <th>
          Пароль
        </th>
        <th>
          Тип
        </th>
        <th>
          Дата создания
        </th>
        <th>
          Активен
        </th>
        <th>
        </th>
      </tr>
      <tr *ngFor="let item of list">
        <td>
          {{item.login}}
        </td>
        <td>
          {{item.password}}
        </td>
        <td>
          {{typeExtensions.format(item.type)}}
        </td>
        <td>
          {{item.createdAt | moscowDateTime}}
        </td>
        <td>
          {{item.isActive}}
        </td>
        <td>
          <button class="btn btn-warning" (click)="toggleState(item)">
            Переключить
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
