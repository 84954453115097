<div>
  <label class="mt-2">Добавление события смены статуса регистрации (state_change)</label>
  <div class="row">
    <div class="col-2">
      <app-strict-input-number placeholder="Id пользователя (0)"
                               [control]="model.userId">
      </app-strict-input-number>
    </div>
    <div class="col-2">
      <app-strict-input-number placeholder="Новый статус (1)"
                               [control]="model.newState">
      </app-strict-input-number>
    </div>
    <div class="col-2">
      <app-strict-input-number placeholder="Старый статус (2)"
                               [control]="model.oldState">
      </app-strict-input-number>
    </div>
    <div class="col-3">
      <strict-date-time-picker class="timeline-marker"
                            placeholder="Дата события (3)"
                            [control]="model.eventDate">
      </strict-date-time-picker>
    </div>
  </div>
  <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
