import {AddUserBtagViewModel} from '../add-user-btag-view-model';
import {AbstractModelValidator} from '@koddington/ga-common';

export class AddUserBtagValidator extends AbstractModelValidator<AddUserBtagViewModel> {
  constructor() {
    super();
    this.ruleForControl(input => input.userId).min(1).requiredAndNotZero();
  }
}

