import {AbstractModelValidator} from '@koddington/ga-common';
import {AddDocsAndSelfieVerificationViewModel} from '../../../models/events/components-models/add-docs-and-selfie-verification-view-model';

export class AddDocsAndSelfieVerificationResultValidator extends AbstractModelValidator<AddDocsAndSelfieVerificationViewModel> {
  constructor() {
    super();
    this.ruleForControl(u => u.userId).requiredAndNotZero();
    this.ruleForControl(u => u.eventDate).required();
    this.ruleForControl(u => u.status).required('Данное поле обязательно')
      .notEmptyString('Вводимые данные не могут быть пустыми');
  }
}
