import {Routes} from '@angular/router';
import {BroadcastsStartComponent} from './broadcasts-start/broadcasts-start.component';
import {BroadcastsLayoutComponent} from './broadcasts-layout/broadcasts-layout.component';
import {BetsResultsSubscriptionsListComponent} from '../events-notifications/bets-results-subscriptions-list/bets-results-subscriptions-list.component';

export const BroadcastsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'start',
        component: BroadcastsStartComponent
      },
      {
        path: 'layout',
        component: BroadcastsLayoutComponent
      }
    ]
  }
];
