import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import { isNullOrUndefined, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import {SearchNavigationService} from '../../shared/services/search-navigation.service';
import { MediaService } from 'src/app/services/autogen/Shared';
import { WlMockMediaSyncInfo } from 'src/app/services/autogen/Shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {first, map} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-media-layout',
  templateUrl: './media-layout.component.html',
  styleUrls: ['./media-layout.component.css']
})
export class MediaLayoutComponent implements OnInit {
  private tabsControl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public lastSyncInfo: WlMockMediaSyncInfo;

  constructor(private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly _service: MediaService) {
  }

  ngOnInit(): void {
    if (!isNullOrUndefined(this.activeRoute.snapshot.queryParams.tab)) {
      this.tab.strictValue = this.activeRoute.snapshot.queryParams.tab;
    }
    this._service.getLastSyncInfo().pipe(
      first(),
      map(value => this.lastSyncInfo = value),
      untilDestroyed(this)
    ).subscribe();
  }

  onTabChange(tabName: string): void {
    const params: Params = {
      tab: tabName
    };

    this.navigation.search(this.activeRoute, params);
  }

  get tab(): StrictFormControl<string> {
    return this.tabsControl;
  }

  public isSyncProblem(): string {
    return (this.lastSyncInfo.dictionaryLastSyncAttempt > this.lastSyncInfo.dictionaryLastCompleteSync) ||
    (this.lastSyncInfo.userDataLastSyncAttempt > this.lastSyncInfo.userDataLastCompleteSync) ? 'alert alert-danger' : '';
  }

  public isBtag(): boolean {
    return this.tab.strictValue === 'Btag';
  }

  public isPromo(): boolean {
    return this.tab.strictValue === 'Promo';
  }

  public isActions(): boolean {
    return this.tab.strictValue === 'Actions';
  }

  public isSourcesList(): boolean {
    return this.tab.strictValue === 'SourcesList';
  }

  public isChannelsList(): boolean {
    return this.tab.strictValue === 'ChannelsList';
  }

  public isBtagsList(): boolean {
    return this.tab.strictValue === 'BtagsList';
  }

  public isPromoList(): boolean {
    return this.tab.strictValue === 'PromoList';
  }

  public isControl(): boolean {
    return this.tab.strictValue === 'Control';
  }
}
