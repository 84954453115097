import {Component, OnInit} from '@angular/core';
import { MechanicsProcessingSequenceViewModel } from 'src/app/services/autogen/UserMechanics';
import { UserMechanicsProcessingService } from 'src/app/services/autogen/UserMechanics';
import {finalize, take, tap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {UserMechanicsNavigationService} from '../services/user-mechanics-navigation.service';
import {GaPagedResult, GaPagingForm} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-mechanics-processing-sequences',
  templateUrl: './user-mechanics-processing-sequences.component.html',
  styleUrls: ['./user-mechanics-processing-sequences.component.css']
})
export class UserMechanicsProcessingSequencesComponent implements OnInit {

  public loading = true;
  private result: GaPagedResult<MechanicsProcessingSequenceViewModel>;

  constructor(private readonly processingService: UserMechanicsProcessingService,
              private readonly navigation: UserMechanicsNavigationService) {
  }

  get resultModel(): GaPagedResult<MechanicsProcessingSequenceViewModel> {
    return this.result;
  }

  ngOnInit(): void {
    const form = new GaPagingForm();
    form.count = 30;
    form.offset = 0;

    this.load(form);
  }

  load(form: GaPagingForm): void {
    this.loading = true;
    this.processingService.getSequences(form).pipe(
      take(1),
      tap(value => this.result = value.result),
      finalize(() => this.loading = false),
      untilDestroyed(this)
    )
      .subscribe();
  }

  pageChanged(form: GaPagingForm) {
    this.load(form);
  }

  navigateToProcessingRequest(sequenceId: number, queueNumber: number): void {
    switch (queueNumber) {
      case 100:
        this.navigation.navigateToMechanicsEnablingRequest(sequenceId);
        break;
      case 101:
        this.navigation.navigateToTriggeringRequest(sequenceId);
        break;
      case 102:
        this.navigation.navigateToBonusUsingRequest(sequenceId);
        break;
      default:
        break;
    }
  }
}
