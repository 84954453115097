<div class="row">
    <div class="col-6">
        <app-markets-update (marketsChanged)="pageFiltered()">
        </app-markets-update>
    </div>
    <div class="col-6">
        <app-loading *ngIf="loading"></app-loading>
        <h1 class="mt-2">Маркеты</h1>
        <ga-panel-grid *ngIf="!!searchPanelGrid" [config]="searchPanelGrid"/>
        <button class="btn btn-primary mt-2" (click)="pageFiltered()">Поиск</button>
        <ga-table *ngIf="!!tableData" [data]="tableData"/>
        <ga-paging *ngIf="!!pagingResult" [pagedResult]="pagingResult" (pageChangedEvent)="pageChanged($event)"/>
    </div>
</div>

