import {Injectable} from '@angular/core';
import {AbstractModelValidator} from '@koddington/ga-common';
import {TriggerMechanicViewModel} from '../components/new-segmenatation/models/trigger-mechanic-view-model';


@Injectable()
export class TriggerEnabledMechanicValidator extends AbstractModelValidator<TriggerMechanicViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.mechanicId).required('Поле обязательно');
    this.ruleForControl(m => m.mechanicTriggeredTime).required('Поле обязательно');
  }
}
