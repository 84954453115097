import { Injectable } from "@angular/core";
import { IDropdownStrategy } from "./i-dropdown-strategy";
import { of, Observable } from "rxjs";
import { DropdownItem } from "src/app/models/common/enum-dropdown/dropdown-item";
import {EnumExtensions} from '@koddington/ga-common';
import { ApnsResponseStatus, ApnsResponseStatusExtensions } from "src/app/modules/push-gate/enums/push-mock-apns-response-enum";

@Injectable({
    providedIn: 'root'
  })
  export class ApnsResponseStatusDropdownStrategy implements IDropdownStrategy<ApnsResponseStatus> {
    public getEntities(): Observable<Array<ApnsResponseStatus>> {
      return of(EnumExtensions.toArray(ApnsResponseStatus));
    }
    public convert(model: ApnsResponseStatus): DropdownItem<ApnsResponseStatus> {
      return {
        id: Number(model).toString(),
        title: ApnsResponseStatusExtensions.toFriendlyString(model),
        entity: model
      };
    }
  }
