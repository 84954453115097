import {Component, OnInit} from '@angular/core';
import {UserSegmentationDataViewModel} from '../../../../models/segments/user-segmentation-data-view-model';
import { ClientInformationChangeForm } from 'src/app/services/autogen/Events';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {UserSegmentationDataValidator} from '../../../segments/validators/user-segmentation-data-validator';
import { EventsService } from 'src/app/services/autogen/Events';
import {SecurityGroupsStrategy} from '../../../shared/common/strict-autocomplete/strategies/security-groups-strategy';
import { SecurityGroupsService } from 'src/app/services/autogen/Dictionaries';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.css']
})
export class ClientInformationComponent implements OnInit {

  public readonly emitGroupEventModel = new UserSegmentationDataViewModel();
  public readonly securityGroupStrategy: SecurityGroupsStrategy;
  constructor(private readonly _validator: UserSegmentationDataValidator,
              private readonly _messaging: GaMessagingService,
              private readonly _eventsService: EventsService,
              private readonly _apiService: SecurityGroupsService) {
    this.securityGroupStrategy = new SecurityGroupsStrategy(_apiService);
  }

  ngOnInit(): void {
  }

  public emitChangeInformationEvent(): void {
    const errors = this._validator.validate(this.emitGroupEventModel);
    if (errors.length > 0) {
      return;
    }

    const form = new ClientInformationChangeForm();
    form.userId = this.emitGroupEventModel.userId.strictValue;
    form.securityGroupId = this.emitGroupEventModel.securityGroupId.strictValue.id;
    form.clientName = this.emitGroupEventModel.clientName.strictValue;
    form.emailRestrictionDate = this.emitGroupEventModel.emailRestrictionDate.strictValue;
    form.email = this.emitGroupEventModel.email.strictValue;
    form.emailRestricted = this.emitGroupEventModel.emailRestricted.strictValue;
    form.phoneNumber = this.emitGroupEventModel.phoneNumber.strictValue;
    form.registrationDate = this.emitGroupEventModel.registrationDate.strictValue;
    form.registrationSource = this.emitGroupEventModel.registrationSource.strictValue;
    form.smsRestricted = this.emitGroupEventModel.smsRestricted.strictValue;
    form.smsRestrictionDate = this.emitGroupEventModel.smsRestrictionDate.strictValue;
    form.videoIdentState = this.emitGroupEventModel.videoIdentState.strictValue;
    form.operationDate = this.emitGroupEventModel.operationDate.strictValue;

    this._eventsService.clientInformationChange(form)
      .pipe(
        take(1),
        filter(value => !this._messaging.tryShowError(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this._messaging.showMessage('Событие добавлено');
      });
  }
}
