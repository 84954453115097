import { Injectable } from '@angular/core';
import { AbstractModelValidator } from '@koddington/ga-common';
import { AddGuideCashbackViewModel } from '../models/add-guide-cashback-view-model';


@Injectable()
export class AddGuideCashbackValidator extends AbstractModelValidator<AddGuideCashbackViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.name).requiredAndNotZero();
        this.ruleForControl(m => m.tultip).requiredAndNotZero();
    }
}
