<h5>Пользовательская механика</h5>
<div *ngIf="model && model.userMechanic" class="row gutters-sm mt-3">
  <div class="col-md-5">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Id пользовательской механики</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.id}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">ID пользователя</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.userId}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Механика</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.mechanic.name}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Состояние механики</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.mechanicState | wlMechanicsStatesTransform}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Состояние бонуса</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.bonusState | wlMechanicsBonusStatesTransform }}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Триггер</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.enabledTrigger.name}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">ID события в цепочке</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.triggerSequenceEventId}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Выданный фрибет</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.issuedFreebetId}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Выданный купон х5</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.issuedCouponId}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">ID захваченной ставки</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.betIdUsedForActivation}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">ID запроса пуш сервера на обработку</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.currentProcessingRequestsId}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-5">
    <div class="card mb-3">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Дата создания</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.createdAt | moscowDateTime}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Дата подключения механики</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.enabledAt | moscowDateTime}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Дата отключения механики</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.disabledAt | moscowDateTime}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-6">
            <h6 class="mb-0">Дата истечения механики</h6>
          </div>
          <div class="col-sm-6">
            <div style="display: flex;">
              <strict-date-time-picker [control]="changeExpirationDateModel.newDate"></strict-date-time-picker>
              <button class="btn btn-primary ml-2" (click)="changeMechanicExpDate()">Сохранить</button>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-6">
            <h6 class="mb-0">Дата истечения бонуса</h6>
          </div>
          <div class="col-sm-6">
            <div style="display: flex;">
              <strict-date-time-picker [control]="changeExpirationBonusDateModel.newDate"></strict-date-time-picker>
              <button class="btn btn-primary ml-2" (click)="changeBonusExpDate()">Сохранить</button>
            </div>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Механика истекла</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.mechanicExpiredAt | moscowDateTime}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Бонус истек</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.bonusExpiredAt | moscowDateTime}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Бонус использован</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.bonusUsedAt | moscowDateTime}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">Бонус выдан</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.bonusIssuedAt | moscowDateTime}}
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">ID активного триггера пользователя</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            <a class="link" (click)="moveToActiveTriggerHistory()">
              {{model.userMechanic.activeUserTriggerId}}
            </a>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-sm-5">
            <h6 class="mb-0">ID рассылки</h6>
          </div>
          <div class="col-sm-7 text-secondary">
            {{model.userMechanic.userTriggerId}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr/>
<h5>История состояний</h5>
<div *ngIf="model && model.logs">
  <div *ngFor="let log of model.logs" class="row gutters-sm mt-3 log-row">
    <div class="col-4">
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID пользовательской механики</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.userMechanicId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID пользователя</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.userId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID механики</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.mechanicId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">Статус механики</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.mechanicState | wlMechanicsStatesTransform}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">Статус бонуса</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.bonusState | wlMechanicsBonusStatesTransform}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID рассылки</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.userTriggerId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">Дата создания</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.createdAt | moscowDateTime}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">Отключена в</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.disabledAt | moscowDateTime}}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row mt-2">
        <div class="col-sm-7">
          <h6 class="mb-0">Дата истечения механики</h6>
        </div>
        <div class="col-sm-5 text-secondary">
          {{log.mechanicExpirationDate | moscowDateTime}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-7">
          <h6 class="mb-0">Дата истечения бонуса</h6>
        </div>
        <div class="col-sm-5 text-secondary">
          {{log.bonusExpirationDate | moscowDateTime}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-7">
          <h6 class="mb-0">Бонус истек в</h6>
        </div>
        <div class="col-sm-5 text-secondary">
          {{log.bonusExpiredAt | moscowDateTime}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-7">
          <h6 class="mb-0">Бонус использован в</h6>
        </div>
        <div class="col-sm-5 text-secondary">
          {{log.bonusUsedAt | moscowDateTime}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-7">
          <h6 class="mb-0">Бонус выдан в</h6>
        </div>
        <div class="col-sm-5 text-secondary">
          {{log.bonusIssedAt | moscowDateTime}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-7">
          <h6 class="mb-0">Дата истечения бонуса</h6>
        </div>
        <div class="col-sm-5 text-secondary">
          {{log.bonusExpirationDate | moscowDateTime}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-7">
          <h6 class="mb-0">ID события в цепочке</h6>
        </div>
        <div class="col-sm-5 text-secondary">
          {{log.triggerSequenceEventId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-7">
          <h6 class="mb-0">Дата лога</h6>
        </div>
        <div class="col-sm-5 text-secondary">
          {{log.eventTime | moscowDateTime}}
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">Механика истекла в</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.mechanicExpiredAt | moscowDateTime}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID события для срабатывания</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.wlTriggerEventIdUsedForTriggering}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID выданного фрибета</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.issuedFreebetId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID выданного купона</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.issuedCouponId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID захваченной ставки</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.betIdUsedForActivation}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">Текущий ID запроса к винлайн</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.currentProcessingRequestsId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">ID активного триггера пользователя</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.activeUserTriggerId}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-sm-5">
          <h6 class="mb-0">Комментарий</h6>
        </div>
        <div class="col-sm-7 text-secondary">
          {{log.comment}}
        </div>
      </div>
    </div>
  </div>
</div>
<hr/>

