import { Component, OnInit } from '@angular/core';
import { ClientIdentificationViewModel } from '../../../../models/events/components-models/client-identification-view-model';
import { GaMessagingService, GaPanelGridConfig, GaTitlePositions, StrictFormControl } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { EventsService } from 'src/app/services/autogen/Events';
import { ClientIdentificationValidator } from '../../validators/client-identification-validator';
import { ClientIdentificationForm } from 'src/app/services/autogen/Events';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-add-complete-identification',
  templateUrl: './add-complete-identification.component.html',
  styleUrls: ['./add-complete-identification.component.css']
})
export class AddCompleteIdentificationComponent implements OnInit {

  private model: ClientIdentificationViewModel = new ClientIdentificationViewModel();
  config = new GaPanelGridConfig();

  constructor(private readonly service: EventsService,
              private readonly messaging: GaMessagingService,
              private readonly validator: ClientIdentificationValidator) {
  }
  ngOnInit(): void {
    this.buildGrid();
  }

  add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new ClientIdentificationForm();
    form.userId = this.model.userId.strictValue;
    form.identificationDate = this.model.identificationDate.strictValue;

    this.service.clientsIdentificationCompletedAddEvent(form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => this.messaging.showMessage('Событие идентификации успешнор добавленно'));
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get identDate(): StrictFormControl<Dayjs> {
    return this.model.identificationDate;
  }

  private buildGrid() {
    this.config.columnsCount = 5;
    this.config.titlePositions = GaTitlePositions.Top;
    this.config.addNumbers(this.model.userId, 'Id пользователя')
      .addDateTime(this.model.identificationDate, 'Укажите дату завершения идентификации');
  }
}
