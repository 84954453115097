<h2>Панель управления состоянием методов апи винлайна</h2>
<app-loading *ngIf="loading"></app-loading>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.clientsRegistrationsMethodState.strictValue !== apiStates.Ok">
    <label>Регистрации клиентов</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onClientsRegistrationsMethodChange()"
      [entityContainer]="viewModel.clientsRegistrationsMethodState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.balanceByLoginsMethodState.strictValue !== apiStates.Ok">
    <label>Балансы по id пользователей</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onBalanceByLoginsMethodChange()"
      [entityContainer]="viewModel.balanceByLoginsMethodState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.docsAndSelfiesMethodState.strictValue !== apiStates.Ok">
    <label>События загрузки селфи и документов</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onDocsAndSelfiesMethodChange()"
      [entityContainer]="viewModel.docsAndSelfiesMethodState">
    </app-enum-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mechanicsEnablingQueueState.strictValue !== apiStates.Ok">
    <label>Очередь подключения механик</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onMechanicEnablingChange()"
      [entityContainer]="viewModel.mechanicsEnablingQueueState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mechanicsTriggeringQueueState.strictValue !== apiStates.Ok">
    <label>Очередь срабатывания механик</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onMechanicTriggeringChange()"
      [entityContainer]="viewModel.mechanicsTriggeringQueueState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mechanicsBonusUsingQueueState.strictValue !== apiStates.Ok">
    <label>Очередь использования бонусов механик</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onMechanicBonusUsingChange()"
      [entityContainer]="viewModel.mechanicsBonusUsingQueueState">
    </app-enum-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.betsState.strictValue !== apiStates.Ok">
    <label>Ставки(stavki_date_st)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onBetsChange()"
      [entityContainer]="viewModel.betsState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaBtagsMethodState.strictValue !== apiStates.Ok">
    <label>Получение связей по медиа(media_btags_dt)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onMediaBtagsMethodChange()"
      [entityContainer]="viewModel.mediaBtagsMethodState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaPromoIdsMethodState.strictValue !== apiStates.Ok">
    <label>Получение связей по медиа(media_promo_dt)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onMediaPromoIdsMethodChange()"
      [entityContainer]="viewModel.mediaPromoIdsMethodState">
    </app-enum-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaSourcesMethodState.strictValue !== apiStates.Ok">
    <label>Медиа справочники источников(media_source_dt)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onMediaSourcesMethodChange()"
      [entityContainer]="viewModel.mediaSourcesMethodState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaChannelsMethodState.strictValue !== apiStates.Ok">
    <label>Медиа справочники каналов(media_channels_dt)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onMediaChannelsMethodChange()"
      [entityContainer]="viewModel.mediaChannelsMethodState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.mediaUserBtagsMethodState.strictValue !== apiStates.Ok">
    <label>Медиа получение ID btag(media_btag_cl_dt)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onMediaUserBtagsMethodChange()"
      [entityContainer]="viewModel.mediaUserBtagsMethodState">
    </app-enum-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.uniQueueForBroadcastState.strictValue !== apiStates.Ok">
    <label>Получение рассылок от бэка по исключающему списку(uni_queue)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onUniQueueChange()"
      [entityContainer]="viewModel.uniQueueForBroadcastState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.uniQueueBroadcastCsvDownloadState.strictValue !== apiStates.Ok">
    <label>Скачивание csv файла рассылки от бэка(uni)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onUniQueueBroadcastCsvDownloadState()"
      [entityContainer]="viewModel.uniQueueBroadcastCsvDownloadState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.pushInfoState.strictValue !== apiStates.Ok">
    <label>Получение рассылок от бэка по списку(push_info)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onPushInfoChange()"
      [entityContainer]="viewModel.pushInfoState">
    </app-enum-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.battlePassFreeBetQueueState.strictValue !== apiStates.Ok">
    <label>Обработка фрибетов по БП (uni_queue, очередь 105)</label>
    <app-enum-dropdown
            [strategy]="dropdownStrategy"
            (userChanges)="onBattlePassFreeBetQueueChange()"
            [entityContainer]="viewModel.battlePassFreeBetQueueState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.logoServiceState.strictValue !== apiStates.Ok">
    <label>Внешний доступ к лого-сервису</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onLogoServiceChange()"
      [entityContainer]="viewModel.logoServiceState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.customTournamentState.strictValue !== apiStates.Ok">
    <label>Кастомные турниры (300 очередь)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onCustomTournamentChange()"
      [entityContainer]="viewModel.customTournamentState">
    </app-enum-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.rateRaceExchangeQueueState.strictValue !== apiStates.Ok">
    <label>Обработка обмена по Rate Race (uni_queue 106)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onRateRaceExchangeQueueChange()"
      [entityContainer]="viewModel.rateRaceExchangeQueueState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.userRateRaceQueueState.strictValue !== apiStates.Ok">
    <label>Обработка пользователей в Rate Race (uni_queue 400)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onUserRateRaceQueueChange()"
      [entityContainer]="viewModel.userRateRaceQueueState">
    </app-enum-dropdown>
  </div>
  <div class="col-3 current-state"
       [class.not-ok]="viewModel.rateRaceResultsQueueState.strictValue !== apiStates.Ok">
    <label>Обработка результатов Rate Race (uni_queue 401)</label>
    <app-enum-dropdown
      [strategy]="dropdownStrategy"
      (userChanges)="onRateRaceResultsQueueChange()"
      [entityContainer]="viewModel.rateRaceResultsQueueState">
    </app-enum-dropdown>
  </div>
</div>
<div class="row" *ngIf="!loading">
    <div class="col-3 current-state"
         [class.not-ok]="viewModel.cashbackBonusState.strictValue !== apiStates.Ok">
        <label>Получение игроков, не забравших бонусы Cashback (clients_cashbackBonus)</label>
        <app-enum-dropdown
                [strategy]="dropdownStrategy"
                (userChanges)="onCashbackBonusQueueChange()"
                [entityContainer]="viewModel.cashbackBonusState">
        </app-enum-dropdown>
    </div>
</div>
