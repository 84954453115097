export enum GcmResponseStatus {
    Ok = 0,
    Error = 1,
    QuotaExceeded = 2,
    DeviceQuotaExceeded = 3,
    InvalidRegistration = 4,
    NotRegistered = 5,
    MessageTooBig = 6,
    MissingCollapseKey = 7,
    MissingRegistrationId = 8,
    Unavailable = 9,
    MismatchSenderId = 10,
    CanonicalRegistrationId = 11,
    InvalidDataKey = 12,
    InvalidTtl = 13,
    InternalServerError = 14,
    InvalidPackageName = 15
  }
  
  
  export namespace GcmResponseStatusExtensions {
    export function toFriendlyString(value: GcmResponseStatus) {
      switch (value) {
        case GcmResponseStatus.Ok:
          return 'Without error';
        case GcmResponseStatus.Error:
          return 'Error';
        case GcmResponseStatus.QuotaExceeded:
            return 'Quota exceeded';
        case GcmResponseStatus.DeviceQuotaExceeded:
            return 'Device quota exceeded';
        case GcmResponseStatus.InvalidRegistration:
            return 'Invalid registration';
        case GcmResponseStatus.NotRegistered:
            return 'Not registered';
        case GcmResponseStatus.MessageTooBig:
            return 'Message too big';
        case GcmResponseStatus.MissingCollapseKey:
            return 'Missing collapse key';
        case GcmResponseStatus.MissingRegistrationId:
            return 'Missing registration id';
        case GcmResponseStatus.Unavailable:
            return 'Unavailable';
        case GcmResponseStatus.MismatchSenderId:
            return 'Mismatch sender id';
        case GcmResponseStatus.CanonicalRegistrationId:
            return 'Canonical registration id';
        case GcmResponseStatus.InvalidDataKey:
            return 'Invalid data key';
        case GcmResponseStatus.InvalidTtl:
            return 'Invalid Ttl';
        case GcmResponseStatus.InternalServerError:
            return 'Internal server error';
        case GcmResponseStatus.InvalidPackageName:
            return 'Invalid package name';
        default:
          return '';
      }
    }
  }