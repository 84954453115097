<div>
  <app-sport-matches-events-add (eventAdded)="eventAdded()"></app-sport-matches-events-add>
</div>
<ng-container *ngIf="result">
  <div class="row mt-3">
    <div class="col-2">
      Id матча
    </div>
    <div class="col-1">
      Тип
    </div>
    <div class="col-1">
      Подтип
    </div>
    <div class="col-1">
      Событие произошло
    </div>
    <div class="col-1">
      Вид спорта
    </div>
    <div class="col-1">
      Страна
    </div>
    <div class="col-1">
      Чемпионат
    </div>
    <div class="col-1">
      Счет
    </div>
    <div class="col-1">
      Счет тайма
    </div>
    <div class="col-2">
      Id отправки
    </div>
  </div>
  <div *ngFor="let elem of result.results" class="row">
    <div class="col-2">
      {{elem.matchId}} ({{elem.firstPlayer}}-{{elem.secondPlayer}})
    </div>
    <div class="col-1">
      {{elem.type}}
    </div>
    <div class="col-1">
      {{elem.subtype}}
    </div>
    <div class="col-1">
      {{elem.eventDate | dateTime}}
    </div>
    <div class="col-1">
      {{elem.sportName}} ({{elem.sportId}})
    </div>
    <div class="col-1">
      {{elem.countryName}} ({{elem.countryId}})
    </div>
    <div class="col-1">
      {{elem.championshipName}} ({{elem.championshipId}})
    </div>
    <div class="col-1">
      {{elem.currentScore}}
    </div>
    <div class="col-1">
      {{elem.currentPeriodScore}}
    </div>
    <div class="col-2">
      {{elem.eventId}}
    </div>
  </div>
  <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</ng-container>
