import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import { WlMatchTeam } from 'src/app/services/autogen/Dictionaries';
import {DropdownItem, IAutocompleteItem, IAutocompleteStrategy, IDropdownStrategy, isNullOrUndefined} from '@koddington/ga-common';
import { MatchTeamsService } from 'src/app/services/autogen/Dictionaries';

export class MatchTeamsStrategy implements IAutocompleteStrategy<WlMatchTeam>, IDropdownStrategy<WlMatchTeam>  {
  private _termSource = new BehaviorSubject<string>('');
  private _source = new BehaviorSubject<WlMatchTeam[]>([]);

  constructor(private _apiService: MatchTeamsService) {
    this._termSource
      .pipe(
        switchMap((term) => {
          return this._apiService.getFilterMatchTeams(term).pipe(
            filter(v => v.isCorrect),
            map(result => result.result)
          );
        })
      ).subscribe((u) => this._source.next(u));
  }

  public convert(model: WlMatchTeam): IAutocompleteItem<WlMatchTeam> {
    return {
      entity: model,
      title: model.nameRus,
      key: model.id.toString()
    };
  }

  public getSource(): Observable<Array<WlMatchTeam>> {
    return this._source.asObservable();
  }

  public updateSource(term: string): void {
    this._termSource.next(term);
  }

  public getEntities(): Observable<Array<WlMatchTeam>> {
    return this._apiService.getFilterMatchTeams('').pipe(
      map(u => {
        if (isNullOrUndefined(u) || !u.isCorrect) {
          return [];
        }

        return u.result;
      })
    );
  }

  public map(model: WlMatchTeam): DropdownItem<WlMatchTeam> {
    if (isNullOrUndefined(model)) {
      return {
        entity: model,
        title: '',
        id: null
      };
    }

    return {
      entity: model,
      title: model.nameRus,
      id: model.id.toString()
    };
  }

}

