<ng-container *ngIf="result">
  <div class="row mt-3">
    <div class="col-1">
      Id пользователя
    </div>
    <div class="col-1">
      Twitch Id
    </div>
    <div class="col-1">
      Twitch nickname
    </div>
    <div class="col-1">
      Twitch avatar
    </div>
    <div class="col-1">
      Steam Id
    </div>
    <div class="col-1">
      Steam nickname
    </div>
    <div class="col-1">
      Steam avatar
    </div>
    <div class="col-1">
      Дата события
    </div>
    <div class="col-1">
      Текст ошибки
    </div>
    <div class="col-3">
      Id отправки
    </div>
  </div>
  <div *ngFor="let elem of result.results" class="row">
    <div class="col-1">
      {{elem.userId}}
    </div>
    <div class="col-1">
      {{elem.twitchId}}
    </div>
    <div class="col-1">
      {{elem.twitchNickname}}
    </div>
    <div class="col-1">
      {{elem.twitchAvatarLink}}
    </div>
    <div class="col-1">
      {{elem.steamId}}
    </div>
    <div class="col-1">
      {{elem.steamNickname}}
    </div>
    <div class="col-1">
      {{elem.steamAvatarLink}}
    </div>
    <div class="col-1">
      {{elem.eventDate | dateTime}}
    </div>
    <div class="col-1">
      {{elem.errorMessage}}
    </div>
    <div class="col-3">
      {{elem.eventId}}
    </div>
  </div>
  <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</ng-container>
