import {Routes} from '@angular/router';
import {EventsListComponent} from './events-list/events-list.component';
import {EventsLayoutComponent} from './events-layout/events-layout.component';
import {MainLayoutComponent} from '../layouts/main-layout/main-layout.component';

export const EventsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: EventsListComponent
      },
      {
        path: 'addEvent',
        component: EventsLayoutComponent
      }
    ]
  }
];
