import { isNullOrUndefined, StrictFormControl } from '@koddington/ga-common';

export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

type Get = {
    <O extends object, K extends keyof O>(o: O, k: K): O[K];
    <O extends object, K extends keyof O, K1 extends keyof O[K]>(o: O, k: K, k1: K1): O[K][K1];
    <O extends object, K extends keyof O, K1 extends keyof O[K], K2 extends keyof O[K][K1]>(o: O, k: K, k1: K1, k2: K2): O[K][K1][K2];
};
export const getValueByKey: Get = (object: any, ...keys: string[]) => {
    let result = object;
    keys.forEach((key) => (result = result[key]));
    return result;
};
export type Mapper = {
    <T extends object, U extends object>(array: T[], mapFn: (value: T) => U): U[];
};
export const gaMap: Mapper = (array: any[], mapFn: (v: any) => any): any => {
    const result = [];

    for (let i = 0; i < array.length; i++) {
        result[i] = mapFn(array[i]);
    }
    return result;
};
export type KeyMap = {
    sourceKey: string;
    targetKey: string;
    newValue: object | null | number | boolean | string;
};

export type MapExpression = {
    sourceMapProperty: string;
    targetMapProperty: string;
    mapObject: object;
};

type BuildForm = {
    <T extends object, S extends object>(type: { new (...args: any[]): T }, source: S, mapper?: MapExpression[] | null, keyMap?: KeyMap[] | null, ...exclude: string[] | null): T;
};

type MapStrictForm = {
    <T extends object, S extends object>(target: T, source: S, mapper?: MapExpression[] | null, keyMap?: KeyMap[] | null, ...exclude: string[] | null): void;
};

export const builderStrictToModel: BuildForm = (type: any, source: any, mapper: MapExpression[] | null = null, keyMaps: KeyMap[] | null = null, ...exclude: string[] | null) => {
    const resultModel = new type();

    const typedKeys = getKeys(source);
    if (typedKeys.length === 0) {
        return resultModel;
    }

    for (const _key of typedKeys) {
        let isExclude: boolean = false;
        if (!isNullOrUndefined(exclude)) {
            exclude.forEach((ex) => {
                if (_key === ex) {
                    isExclude = true;
                    return;
                }
            });
        }

        switch (typeof source[_key]) {
            case 'bigint':
            case 'boolean':
            case 'number':
            case 'string':
            case 'symbol':
                resultModel[_key] = source[_key];
                continue;
        }

        if (source[_key] instanceof StrictFormControl) {
            if (!isNullOrUndefined(mapper) && mapper.length > 0) {
                let isMap: boolean = false;
                mapper.forEach((map) => {
                    if (_key === map.sourceMapProperty) {
                        resultModel[map.targetMapProperty] = map.mapObject;
                        isMap = true;
                    }
                });

                if (isMap) {
                    continue;
                }
            }

            if (!isNullOrUndefined(keyMaps) && keyMaps.length > 0) {
                let isMapKey: boolean = false;
                keyMaps.forEach((keyMap) => {
                    if (_key === keyMap.sourceKey) {
                        resultModel[keyMap.targetKey] = isNullOrUndefined(keyMap.newValue) ? source[keyMap.sourceKey].strictValue : keyMap.newValue;
                        isMapKey = true;
                    }
                });
                if (isMapKey) {
                    continue;
                }
            }

            if (!isExclude)
                resultModel[_key] = source[_key].strictValue;
        }
    }
    return resultModel;
};

export const builderModelToStrict: MapStrictForm = (
    target: any,
    source: any,
    mapper: MapExpression[] | null = null,
    keyMap: KeyMap[] | null = null,
    ...exclude: string[] | null
) => {
    const typedKeys = getKeys(source);

    for (const _key of typedKeys) {
        let isExclude: boolean = false;
        if (!isNullOrUndefined(exclude)) {
            exclude.forEach((ex) => {
                if (_key === ex) {
                    isExclude = true;
                    return;
                }
            });
        }

        switch (typeof target[_key]) {
            case 'bigint':
            case 'boolean':
            case 'number':
            case 'string':
            case 'symbol':
                target[_key] = source[_key];
                continue;
        }

        if (target[_key] instanceof StrictFormControl) {
            if (!isNullOrUndefined(mapper) && mapper.length > 0) {
                let isMap: boolean = false;
                mapper.forEach((map) => {
                    if (_key === map.sourceMapProperty) {
                        target[map.targetMapProperty].strictValue = map.mapObject;
                        isMap = true;
                    }
                });

                if (isMap) {
                    continue;
                }
            }

            if (!isNullOrUndefined(keyMap) && keyMap.length > 0) {
                let isMapKey: boolean = false;
                keyMap.forEach((key) => {
                    if (_key === key.sourceKey) {
                        target[key.targetKey].strictValue = isNullOrUndefined(key.newValue) ? source[key.sourceKey] : key.newValue;
                        isMapKey = true;
                    }
                });
                if (isMapKey) {
                    continue;
                }
            }

            if (!isExclude)
                target[_key].strictValue = source[_key];
        }
    }
};
