import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';


export class LifetimeCallListViewModel {

  userId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  offset: StrictFormControl<number> = new StrictFormControl<number>(0);
  count: StrictFormControl<number> = new StrictFormControl<number>(30);

}
