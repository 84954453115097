import {AbstractModelValidator} from '@koddington/ga-common';
import {MechanicEnablingRequestStatusViewModel} from '../../../models/mechanics/mechanic-enabling-request-status-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class MechanicEnablingRequestStatusValidator extends AbstractModelValidator<MechanicEnablingRequestStatusViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.newStatus).required();
  }
}
