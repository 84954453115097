<h3>Копирование сезона</h3>

<div class="row">
    <div class="col-2">
        Id сезона
    </div>
    <div class="col-2">
        Дата старта сезона
    </div>
    <div class="col-3">
        Переиспользовать лутбоксы
    </div>
</div>
<div class="row">
    <app-strict-input-number class="col-2"
                             placeholder="Id сезона"
                             [control]="copySettingsViewModel.seasonId"
                             (valueChange)="userManipulationSource.next()"
    ></app-strict-input-number>
    <strict-date-time-picker class="col-2"
                             placeholder="Дата старта сезона"
                             [control]="copySettingsViewModel.startDate"
    ></strict-date-time-picker>
    <app-strict-input-boolean class="col-3"
                              [control]="copySettingsViewModel.reuseLootBoxes"
    ></app-strict-input-boolean>
    <span>
        <button class="btn btn-primary mt-2"
                [disabled]="!canCopy"
                (click)="copy()">Начать копирование</button>
    </span>
</div>

<div *ngIf="tableData" class="row">
    <ga-table [data]="tableData"></ga-table>
</div>
