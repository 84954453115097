import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class AddFreeBetViewModel {
  userId = StrictFormControlBuilder.buildNullNumber();
  freeBetId = StrictFormControlBuilder.buildNullNumber();
  issuedFreebetId = StrictFormControlBuilder.buildNullNumber();
  summ = new StrictFormControl<number>(0);
  freeBetType = StrictFormControlBuilder.buildNullNumber();
  beginDate = StrictFormControlBuilder.buildNullDayjs();
  endDate = StrictFormControlBuilder.buildNullDayjs();
  typeForBonusClub = StrictFormControlBuilder.buildNullNumber();
}
