import {AbstractModelValidator} from '@koddington/ga-common';
import {UserMechanicsListViewModel} from '../../../models/mechanics/user-mechanics-list-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class UserMechanicsListValidator extends AbstractModelValidator<UserMechanicsListViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).min(1);
  }
}
