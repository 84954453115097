<h4>Создать btag для пользователя</h4>
<div class="row">
  <div>
    <label>Id btag</label>
    <app-strict-input-number [control]="viewModel.btagId"></app-strict-input-number>
  </div>
  <div>
    <label>Id пользователя</label>
    <app-strict-input-number [control]="viewModel.userId"></app-strict-input-number>
  </div>
  <div>
    <label>Дата изменения</label>
    <strict-date-time-picker [control]="viewModel.changeDate"></strict-date-time-picker>
  </div>
</div>
<div>
  <button class="btn btn-primary" (click)="send()">Отправить</button>
</div>
