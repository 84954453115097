<div *ngIf="isLoading()">
  <app-loading></app-loading>
</div>
<h5>Список медиа каналов</h5>
<div class="col-2">
  <label> Порядок даты изменений  </label>
  <app-enum-dropdown [ngClass]="{'disabled':idsStatus.hasStrictValue}"
                     [entityContainer]="dateStatus"
                     [strategy]="strategy"
                     [options]="options">
  </app-enum-dropdown>
</div>

<div class="col-2" >
  <label> Порядок id  </label>
  <app-enum-dropdown [ngClass]="{'disabled':dateStatus.hasStrictValue}"
                     [entityContainer]="idsStatus"
                     [strategy]="strategy"
                     [options]="options">
  </app-enum-dropdown>
  <div class="padding-top-and-bottom">
    <button class="btn btn-primary"  (click)="search()">Поиск</button>
  </div>
</div>
<div *ngIf="!isLoading()">
  <div>
    <table class="table">
      <tr class="table-info">
        <td class="col-1">
          Id
        </td>
        <td class="col-2">
          Название
        </td>
        <td class="col-3">
          Дата изменения
        </td>
        <td class="col-4">
          Тип
        </td>
      </tr>
      <tr *ngFor="let item of list()">
        <td class="col-1">
          {{item.channelId}}
        </td>
        <td class="col-2">
          {{item.name}}
        </td>
        <td class="col-3">
          {{item.changeDate | moscowDateTime}}
        </td>
        <td class="col-4">
          {{item.type | wlMockMediaIdentificationTypesTransform}}
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="!isLoading()">
  <ga-paging *ngIf="isPagingNeeded" (pageChangedEvent)="pageChange($event)" [pagedResult]="result.result"></ga-paging>
</div>
