import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {LifetimeCallEventsListComponent} from './lifetime-call-events-list/lifetime-call-events-list.component';
import {LifetimeCallEventsChangesComponent} from './lifetime-call-events-changes.component';
import {LifetimeCallEventsRoutes} from './lifetime-call-events.routes';
import {GaCommonModule} from '@koddington/ga-common';
import { EventsAreaCodegenAppModule } from '../../../../services/autogen/Events';


@NgModule({
  declarations: [
    LifetimeCallEventsListComponent,
    LifetimeCallEventsChangesComponent
  ],
  exports: [
    LifetimeCallEventsListComponent,
    LifetimeCallEventsChangesComponent
  ],
    imports: [
        CommonModule,
        SharedModule.forRoot(),
        RouterModule.forChild(LifetimeCallEventsRoutes),
        GaCommonModule,
        EventsAreaCodegenAppModule
    ]
})
export class LifetimeCallEventsModule { }
