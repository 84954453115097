import {AddMediaBtagViewModel} from '../add-media-btag-view-model';
import {AbstractModelValidator} from '@koddington/ga-common';

export class AddMediaBtagValidator extends AbstractModelValidator<AddMediaBtagViewModel> {
  constructor() {
    super();
    this.ruleForControl(input => input.btagId).min(1).requiredAndNotZero();
      this.ruleForControl(input => input.name).notEmptyString();
  }
}
