import { Routes } from '@angular/router';
import {CustomTournamentsLayoutComponent} from './components/custom-tournaments-layout/custom-tournaments-layout.component';
import {CustomTournamentsListComponent} from './components/custom-tournaments-list/custom-tournaments-list.component';
import {CustomTournamentTableListComponent} from './components/custom-tournament-table-list/custom-tournament-table-list.component';
import {CustomTournamentSequencesComponent} from './components/custom-tournament-sequences/custom-tournament-sequences.component';
import { CustomTournamentInvitationsComponent } from './components/custom-tournament-invitations/custom-tournament-invitations.component';

const ChildrenMockRoute: Routes = [
  {
    path: 'tournaments-data',
    component: CustomTournamentsListComponent
  },
  {
    path: 'tournaments-table',
    component: CustomTournamentTableListComponent
  },
  {
    path: 'tournaments-sequences',
    component: CustomTournamentSequencesComponent
  },
  {
    path: 'tournaments-invitations',
    component: CustomTournamentInvitationsComponent
  }
];

export const CustomTournamentsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'main',
        component: CustomTournamentsLayoutComponent,
        children: ChildrenMockRoute
      }
    ]
  }
];
