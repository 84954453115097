import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserMechanicDetailsComponent} from './user-mechanic-details/user-mechanic-details.component';
import {UserMechanicsListComponent} from './user-mechanics-list/user-mechanics-list.component';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ChangeBonusExpirationDateValidator} from './validators/change-bonus-expiration-date-validator';
import {ChangeMechanicsExpDateValidator} from './validators/change-mechanics-exp-date-validator';
import {UserMechanicsListValidator} from './validators/user-mechanics-list-validator';
import {MechanicsRoutes} from './mechanics.routes';
import {UserMechanicsProcessingSequencesComponent} from './user-mechanics-processing-sequences/user-mechanics-processing-sequences.component';
import {UserMechanicsEnablingRequestsComponent} from './user-mechanics-enabling-requests/user-mechanics-enabling-requests.component';
import {MechanicEnablingRequestStatusValidator} from './validators/mechanic-enabling-request-status-validator';
import {UserMechanicsProcessingErrorsComponent} from './user-mechanics-processing-errors/user-mechanics-processing-errors.component';
import {MechanicsLayoutComponent} from './mechanics-layout/mechanics-layout.component';
import {UserMechanicTriggeringRequestsComponent} from './user-mechanic-triggering-requests/user-mechanic-triggering-requests.component';
import {UserMechanicsBonusUsingRequestsComponent} from './user-mechanics-bonus-using-requests/user-mechanics-bonus-using-requests.component';
import {GaCommonModule} from '@koddington/ga-common';
import { UserMechanicsAreaCodegenAppModule } from '../../services/autogen/UserMechanics';


@NgModule({
  declarations: [
    UserMechanicDetailsComponent,
    UserMechanicsListComponent,
    UserMechanicsProcessingSequencesComponent,
    UserMechanicsEnablingRequestsComponent,
    UserMechanicsProcessingErrorsComponent,
    MechanicsLayoutComponent,
    UserMechanicTriggeringRequestsComponent,
    UserMechanicsBonusUsingRequestsComponent
  ],
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    RouterModule.forChild(MechanicsRoutes),
    ReactiveFormsModule,
    RouterModule,
    GaCommonModule,
    UserMechanicsAreaCodegenAppModule
  ],
  exports: [
    UserMechanicsListComponent,
    UserMechanicsProcessingSequencesComponent,
    UserMechanicsProcessingErrorsComponent,
    UserMechanicDetailsComponent,
    UserMechanicsEnablingRequestsComponent,
    MechanicsLayoutComponent
  ],
  providers: [
    ChangeBonusExpirationDateValidator,
    ChangeMechanicsExpDateValidator,
    UserMechanicsListValidator,
    MechanicEnablingRequestStatusValidator
  ]
})
export class MechanicsModule {
}
