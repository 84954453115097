import {Component, OnInit} from '@angular/core';
import { UserSegmentationService, WlSegmentationProcessesHistories } from 'src/app/services/autogen/Segmentation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {filter, first} from 'rxjs/operators';
import {GaMessagingService, isNullOrUndefined} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-last-segmentation-state',
  templateUrl: './last-segmentation-state.component.html',
  styleUrls: ['./last-segmentation-state.component.css']
})
export class LastSegmentationStateComponent implements OnInit {

  public lastState: WlSegmentationProcessesHistories;

  constructor(private readonly _segmentationService: UserSegmentationService,
              private readonly _messaging: GaMessagingService) {
  }

  public get stateClass(): string {
    if (isNullOrUndefined(this.lastState)) {
      return 'unknown';
    }

    if (isNullOrUndefined(this.lastState.completedAt)) {
      return 'in-process';
    }

    if (this.lastState.successful) {
      return 'completed';
    }

    return 'completed-error';
  }

  public get state(): string {
    if (isNullOrUndefined(this.lastState)) {
      return 'Неизвестно';
    }

    if (isNullOrUndefined(this.lastState.completedAt)) {
      return 'В процессе';
    }

    if (this.lastState.successful) {
      return 'Завершена';
    }

    return 'Завершена с ошибкой';
  }

  public ngOnInit(): void {
    this._segmentationService.getLastSegmentationState().pipe(
      first(),
      filter(u => !this._messaging.tryShowError(u)),
      untilDestroyed(this)
    ).subscribe((res) => {
      this.lastState = res.result;
    });
  }
}
