import { Component } from '@angular/core';

@Component({
  selector: 'app-daily-tasks-layout',
  templateUrl: './daily-tasks-layout.component.html',
  styleUrls: ['./daily-tasks-layout.component.css']
})
export class DailyTasksLayoutComponent {

}
