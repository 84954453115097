import {Component, OnInit} from '@angular/core';
import { CustomTournamentService } from 'src/app/services/autogen/CustomTournaments';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaMessagingService} from '@koddington/ga-common';
import {CustomTournamentTableCrudViewModel} from '../../../../models/custom-tournaments/custom-tournament-table-crud-view-model';
import { AddUserPlaceForm } from 'src/app/services/autogen/CustomTournaments';

@UntilDestroy()
@Component({
  selector: 'app-custom-tournaments-table-item-crud',
  templateUrl: './custom-tournaments-table-item-crud.component.html',
  styleUrls: ['./custom-tournaments-table-item-crud.component.scss']
})
export class CustomTournamentsTableItemCrudComponent implements OnInit {
  public readonly viewModel: CustomTournamentTableCrudViewModel = new CustomTournamentTableCrudViewModel();

  constructor(private readonly service: CustomTournamentService,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
  }

  public add(): void {
    const form = new AddUserPlaceForm();
    form.tournamentId = this.viewModel.tournamentId.strictValue;
    form.userId  = this.viewModel.userId .strictValue;
    form.tournamentPlace  = this.viewModel.tournamentPlace.strictValue;

    this.service
      .addUserPlace(form)
      .pipe(
        take(1),
        filter(u => !this.messaging.tryShowError(u)),
        untilDestroyed(this))
      .subscribe(() => this.messaging.showMessage('Место в турнире успешно добавлено'));
  }
}
