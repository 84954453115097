import {AbstractModelValidator, StrictError} from '@koddington/ga-common';
import {AddX50BetViewModel} from '../../../models/tours/add-x50-bet-view-model';
import { isNullOrUndefined } from '@koddington/ga-common';

export class AddX50BetValidator extends AbstractModelValidator<AddX50BetViewModel> {
  private allowedChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];

  constructor() {
    super();
    this.ruleForControl(u => u.betResultSum).min(0).required();
    this.ruleForControl(u => u.branches).min(1).required('Данные неверны или отсутствуют. ' +
      'Допускается целое число, указывающее на количество оставшихся благоприятных исходов для ставки');
    this.ruleForControl(u => u.betSum).min(0).requiredAndNotZero();
    this.ruleForControl(u => u.betId).min(1).required();
    this.ruleForControl(u => u.results).notEmptyString().minLength(11).maxLength(11)
      .customRule(fieldValue => {
        if (isNullOrUndefined(fieldValue)) {
          return null;
        }
        const charArray = fieldValue.split('');
        const notAllowedChars = charArray.filter(value => !this.allowedChars.includes(value));
        if (notAllowedChars.length > 0) {
          return new StrictError(StrictError.invalidValueErrorName, 'Строка содержит недопустимые символы. ' +
            'Допустимы цифры от 1 до 9 и буквы английского алфавита в заглавном регистре от A до F');
        }
        return null;
      });
    this.ruleForControl(u => u.betDate).required();
    this.ruleForControl(u => u.topPercent).min(0).max(100).required('Введено неверное значение. ' +
      'Данное поле указывает на процент вхождения пользователя в группу топ-игроков');
    this.ruleForControl(u => u.tourId).min(0).required();
    this.ruleForControl(u => u.userId).min(0).required();
  }
}
