<h1 style="padding: inherit">Обзвоненные пользователи</h1>

<div class="col-2 mt-3 mb-3">
  <a class="btn btn-primary" (click)="backClicked()">Назад</a>
</div>
<div *ngIf="loading" class="loading">
  <app-loading></app-loading>
</div>

<div class="container-fluid" *ngIf="!loading">
  <table class="table">
    <tr class="row table-info">
      <td class="col-1">
        UserId
      </td>
      <td class="col-1">
        Id Скрипта обзвона
      </td>
      <td class="col-1">
        Дата начала обзвона
      </td>
      <td class="col-1">
        Продолжительность обзвона (минуты)
      </td>
      <td class="col-1">
        Id механики
      </td>
      <td class="col-1">
        Id прикрепленного матча
      </td>
      <td class="col-1">
        Статус
      </td>
    </tr>

    <tr *ngFor="let item of items.results" class="row text-info">
      <td class="col-1">
        {{item.userId}}
      </td>
      <td class="col-1">
        {{item.callScriptId}}
      </td>
      <td class="col-1">
        {{item.startCallingDateTime | dateTime}}
      </td>
      <td class="col-1">
        {{item.usersCallListTimeMinutes}}
      </td>
      <td class="col-1">
        {{item.mechanicsConnectFactId}}
      </td>
      <td class="col-1">
        {{item.matchAssignedPlayerId}}
      </td>
      <td class="col-1">
        {{item.userCallState | wlUserCallStateTransform }}
      </td>
    </tr>
  </table>

  <ga-paging [pagedResult]="items" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
