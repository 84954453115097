import { Component, OnDestroy, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { EventsListStrictViewModel } from '../../../../models/events/events-list-strict-view-model';
import { UserStagesService } from '../../../../services/autogen/Users';
import { WlUserStagesLog } from 'src/app/services/autogen/Users';
import {GaPagedResult, GaPagingForm} from '@koddington/ga-common';

@Component({
  selector: 'app-users-stages',
  templateUrl: './users-stages.component.html',
  styleUrls: ['./users-stages.component.css']
})
export class UsersStagesComponent implements OnInit, OnDestroy {
  public logs: GaPagedResult<WlUserStagesLog>;
  public loading: boolean;
  public model: EventsListStrictViewModel = new EventsListStrictViewModel();

  constructor(private readonly service: UserStagesService) {
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnDestroy(): void {
  }

  public deserialize(value: string): string {
    return JSON.parse(value);
  }

  public pageChanged(form: GaPagingForm): void {
    this.load(form.offset);
  }

  load(offset = 0) {
    this.loading = true;
    const form = new GaPagingForm();
    form.count = 30;
    form.offset = offset;

    this.service.list(form).pipe(
      take(1)
    ).subscribe(res => {
      this.logs = res;
      this.loading = false;
    });
  }
}
