export const PUSH_PREFIX_URL_API = {
    get AndroidList() {
      return `/MockGCMPush/GetList`;
    },
    get GCMListErrorCodeByToken() {
      return `/MockGCMHandlersErrorDictionary/GetList`;
    },
    get GCMAddErrorCodeByToken() {
      return `/MockGCMHandlersErrorDictionary/AddErrorByToken`;
    },
    get GCMDeleteErrorById() {
      return `/MockGCMHandlersErrorDictionary/DeleteErrorCodeById`;
    },
    get ApnsListErrorCodeByToken() {
      return `/MockApnsHandlersErrorDictionary/GetList`;
    },
    get ApnsAddErrorCodeByToken() {
      return `/MockApnsHandlersErrorDictionary/AddErrorByToken`;
    },
    get ApnsDeleteErrorById() {
      return `/MockApnsHandlersErrorDictionary/DeleteErrorCodeById`;
    }
  };
