import {AbstractModelValidator} from '@koddington/ga-common';
import {Injectable} from '@angular/core';
import { MechanicTriggeringRequestStrictViewModel } from '../../../models/mechanics/mechanic-triggering-request-strict-view.model';

@Injectable({
  providedIn: 'root'
})
export class MechanicTriggeringRequestViewModelValidator extends AbstractModelValidator<MechanicTriggeringRequestStrictViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.newStatus).required();
  }
}
