import {Component, OnInit} from '@angular/core';
import {UserListViewModel} from '../../../../models/users/user-list-view-model';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {UserFreebetForm} from 'src/app/services/autogen/Users';
import { WlUserFreebet } from 'src/app/services/autogen/Users';
import {GaPagedResult, GaPagingForm} from '@koddington/ga-common';
import { UsersService } from 'src/app/services/autogen/Users';

@UntilDestroy()
@Component({
  selector: 'app-users-freebet-status',
  templateUrl: './users-freebet-status.component.html',
  styleUrls: ['./users-freebet-status.component.css']
})
export class UsersFreebetStatusComponent implements OnInit {

  public model: UserListViewModel = new UserListViewModel();
  public result: GaPagedResult<WlUserFreebet>;
  public freebets: WlUserFreebet[] = [];

  constructor(private readonly service: UsersService) {
  }

  ngOnInit(): void {
  }

  search(): void {
    const form = this.createForm();
    if (this.model.userId.hasStrictValue) {
      this.load(form);
    }
  }

  load(form: UserFreebetForm): void {
    this.service.userFreebetsList(form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.freebets = this.result.results;
      });
  }

  pageChanged(form: GaPagingForm): void {
    this.model.offset.strictValue = form.offset;
    this.load(this.createForm());
  }

  private createForm(): UserFreebetForm {
    const form = new UserFreebetForm();
    form.userId = this.model.userId.strictValue;
    form.count = this.model.count.strictValue;
    form.offset = this.model.offset.strictValue;

    return form;
  }
}
