import { BetPeriods } from 'src/app/services/autogen/Events';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class WlBetEventViewModel {
    eventId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    sportType: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    marketId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    championshipId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    gameId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    rate: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    betPeriod: StrictFormControl<BetPeriods> = StrictFormControlBuilder.buildNullNumber<BetPeriods>();
    firstTeamId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    secondTeamId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    totalScoreHandicap: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
}
