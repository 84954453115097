<div>
  <label class="mt-2">Привязка профиля {{model.platform.strictValue | wlGamePlatformsTransform}}</label>
  <div class="row">
    <div *ngIf="isRedirected" class="col-2">
      {{"Секрет"}}
    </div>
    <div *ngIf="!isRedirected" class="col-2">
      {{"User Id"}}
    </div>
    <div *ngIf="!isRedirected" class="col-2">
      {{"Платформа"}}
    </div>
  </div>
  <div class="mt-2 row">
    <app-strict-input-text *ngIf="isRedirected" class="col-2"
                             placeholder="Секрет"
                             [control]="model.secret">
    </app-strict-input-text>
    <app-strict-input-number *ngIf="!isRedirected" class="col-2"
                             placeholder="User Id"
                             [control]="model.userId">
    </app-strict-input-number>
    <app-enum-dropdown *ngIf="!isRedirected" class="col-2"
                       [strategy]="strategy"
                       [entityContainer]="model.platform"
                       [options]="options">
    </app-enum-dropdown>
    <app-strict-input-boolean-checkbox class="col-2"
      label="Доступ к статистике"
                               [control]="AccessGranted">
    </app-strict-input-boolean-checkbox>
  </div>
  <div class="row">
    <div *ngIf="isRedirectedTwitch" class="col-2">
      {{"Twitch Id"}}
    </div>
    <div *ngIf="isRedirectedSteam" class="col-2">
      {{"Steam Id"}}
    </div>
    <div class="col-2">
      {{"Никнейм пользователя "}}{{model.platform.strictValue | wlGamePlatformsTransform}}
    </div>
    <div class="col-3">
      {{"Ссылка на аватарку пользователя "}}{{model.platform.strictValue | wlGamePlatformsTransform}}
    </div>
  </div>
  <div class="mt-2 row">
    <app-strict-input-number *ngIf="isRedirectedTwitch" class="col-2"
                             placeholder="Twitch Id"
                             [control]="model.accountId">
    </app-strict-input-number>
    <app-strict-input-number *ngIf="isRedirectedSteam" class="col-2"
                             placeholder="Steam Id"
                             [control]="model.accountId">
    </app-strict-input-number>
    <app-strict-input-text class="col-2"
                           placeholder="Никнейм"
                           [control]="model.nickname">
    </app-strict-input-text>
    <app-strict-input-text class="col-2"
                           placeholder="Url аватарки"
                           [control]="model.avatarLink">
    </app-strict-input-text>
  </div>
  <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>

<app-platform-binding-events-list></app-platform-binding-events-list>
