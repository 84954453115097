import {Injectable} from '@angular/core';
import {BattlePassUserProfileBindingViewModel} from '../../../models/events/components-models/battle-pass-user-profile-binding-view-model';
import {AbstractModelValidator, StrictError} from '@koddington/ga-common';

@Injectable()
export class UserProfileBindingValidator extends AbstractModelValidator<BattlePassUserProfileBindingViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId)
      .customRule((fieldValue, model: BattlePassUserProfileBindingViewModel) => {
        if (!model.secret.hasStrictValue && !model.userId.hasStrictValue)
          return new StrictError('userIdError', 'Необходимо указать user id');

        return null;
      });

    this.ruleForControl(m => m.platform)
      .customRule((fieldValue, model: BattlePassUserProfileBindingViewModel) => {
        if (!model.secret.hasStrictValue && !model.platform.hasStrictValue)
          return new StrictError('platformError', 'Необходимо выбрать платформу для привязки');

        return null;
      });

    this.ruleForControl(u => u.accountId).requiredAndNotZero('Id аккаунта введён неверно');
    this.ruleForControl(u => u.nickname).notEmptyString('Необходимо заполнить никнейм пользователя');
    this.ruleForControl(u => u.avatarLink).notEmptyString('Ссылка на аватар пользователя не может быть пустой');
  }
}
