<form (submit)="onSave()" style="width: 500px">
  <h1 class="mt-2">Добавление игрока</h1>
  <div class="form-group">
    <label>Id</label>
    <app-strict-input-number [control]="this.model.id">
    </app-strict-input-number>
  </div>
  <div class="form-group">
    <label>Имя</label>
    <app-strict-input-text [control]="this.model.name">
    </app-strict-input-text>
  </div>
  <div class="form-group">
    <label>Любимая команда</label>
    <app-strict-autocomplete [control]="this.model.favouriteTeam"
                             [strategy]="teamsStrategy">
    </app-strict-autocomplete>
  </div>
  <button type="submit" class="btn btn-primary">Сохранить</button>
</form>

