import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { SportsStatsFilterForm } from 'src/app/services/autogen/Users';
import {UserSportStatsFilterViewModel} from './view-model/user-sport-stats-filter-view-model';

@Component({
  selector: 'app-user-sport-stats-filter',
  templateUrl: './user-sport-stats-filter.component.html',
  styleUrls: ['./user-sport-stats-filter.component.css']
})
export class UserSportStatsFilterComponent implements OnInit {

  @Output()
  public wlOnSubmit = new EventEmitter<SportsStatsFilterForm>();
  viewModel = new UserSportStatsFilterViewModel();

  constructor() {
  }

  ngOnInit(): void {
  }

  submit() {
    const form = new SportsStatsFilterForm();
    form.sportId = this.viewModel.sportId.strictValue;
    form.userId = this.viewModel.userId.strictValue;

    this.wlOnSubmit.emit(form);
  }
}
