<hr>
<div>
  <h3>Срабатывание механики</h3>
  <div>
    <div class="row mt-2">
      <div class="col-1">
        Id механики
      </div>
      <div class="col-2">
        Дата срабатывания
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <app-strict-input-number [control]="mechanicId" placeholder="Id механики">
        </app-strict-input-number>
      </div>
      <div class="col-2">
        <strict-date-time-picker [control]="mechanicTriggeredTime" placeholder="Дата срабатывания"></strict-date-time-picker>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="save()">Сохранить</button>
  </div>
</div>
