<div class="col-4">
  <h1>Пользователи</h1>

  <div class="row">
    <app-strict-input-number class="col-6" [control]="model.userId"
                             [placeholder]="'Id пользователя'"></app-strict-input-number>
    <span class="col-6">
    <button class="btn btn-primary" (click)="search()">Поиск</button>
    </span>
  </div>
  <table class="table mt-3">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Имя</th>
      <th scope="col">Телефон</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users">
      <th>{{user.userId}}</th>
      <td>{{user.name}}</td>
      <td>{{user.phone}}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
