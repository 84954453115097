import {Component, OnInit} from '@angular/core';
import { EventsService } from 'src/app/services/autogen/Events';
import { ActiveUserTriggerLog } from 'src/app/services/autogen/Triggers';
import { ActiveUserTriggersService } from 'src/app/services/autogen/Triggers';
import {ActivatedRoute} from '@angular/router';
import {ActiveTriggerHistoryParamModel} from '../../../models/triggers/active-trigger-history-param-model';
import {finalize, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlTriggerEvent } from 'src/app/services/autogen/Events';
import { isNullOrUndefined } from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-active-trigger-history',
  templateUrl: './active-trigger-history.component.html',
  styleUrls: ['./active-trigger-history.component.css']
})
export class ActiveTriggerHistoryComponent implements OnInit {

  private loading = false;
  public logs: ActiveUserTriggerLog[] = [];
  private events: WlTriggerEvent[] = [];
  private lastEvent: WlTriggerEvent;
  private readonly paramModel: ActiveTriggerHistoryParamModel = new ActiveTriggerHistoryParamModel();

  constructor(private readonly eventsService: EventsService,
              private readonly activeTriggerService: ActiveUserTriggersService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.initParamModel();
    this.loadLogs();
  }

  loadLogs(): void {
    this.activeTriggerService
      .activeTriggerHistory(this.paramModel.activeTriggerId, this.paramModel.userId, this.paramModel.userTriggerId)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
          this.logs = value.result;
        }, null,
        () => {
          const ids = this.logs.map(value => value.eventId);
          this.eventsService.getEventsByIds(ids).pipe(take(1), finalize(() => this.loading = false), untilDestroyed(this))
            .subscribe(value => this.events = value.result);
        });
  }

  initParamModel(): void {
    this.paramModel.activeTriggerId = Number(this.route.snapshot.queryParams['activeTriggerId']);
    this.paramModel.userId = this.route.snapshot.queryParams['userId'];
    this.paramModel.userTriggerId = this.route.snapshot.queryParams['userTriggerId'];
  }

  getEventById(id: number): WlTriggerEvent {
    if (this.events.length === 0) {
      return;
    }
    if (isNullOrUndefined(this.lastEvent) || id !== this.lastEvent.id) {
      this.lastEvent = this.events.find(value => value.id = id);
    }
    return this.lastEvent;
  }

  getSerializedEvent(event: WlTriggerEvent): string {
    return JSON.stringify(event);
  }

  get activeUserTriggerId(): number {
    return this.paramModel.activeTriggerId;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get isLogsExist(): boolean {
    return this.logs.length > 0;
  }
}
