import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import { WlPlatformsBindingEventsInternalModel } from 'src/app/services/autogen/BattlePass';
import {GaPagedResult, GaPagingForm} from '@koddington/ga-common';

@Component({
  selector: 'app-platform-binding-events-list',
  templateUrl: './platform-binding-events-list.component.html',
  styleUrls: ['./platform-binding-events-list.component.css']
})

export class PlatformBindingEventsListComponent implements OnInit {

  public result: GaPagedResult<WlPlatformsBindingEventsInternalModel>;

  constructor(private readonly _service: BattlePassService) {
  }

  ngOnInit(): void {
    this.load();
  }

  public eventAdded() {
    this.load();
  }

  public pageChanged(form: GaPagingForm): void {
    this.load(form.offset);
  }

  private load(offset: number = 0) {
    const paging = new GaPagingForm();
    paging.offset = offset;

    this._service.listEvent(paging).pipe(
      take(1)
    ).subscribe(res => this.result = res);
  }
}
