import {Component, OnInit} from '@angular/core';
import { UserLoginsService, UsersService } from 'src/app/services/autogen/Users';
import {UserListViewModel} from '../../../../models/users/user-list-view-model';
import {UserLoginsViewModel} from '../../../../models/users/user-logins-view-model';
import { WlUserLogins } from 'src/app/services/autogen/Users';
import { UserLoginsForm } from 'src/app/services/autogen/Users';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';
import {map, switchMap, take} from 'rxjs/operators';
import {GaMessagingService, GaPagedResult, isNullOrUndefined} from '@koddington/ga-common';
import {Observable} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-user-logins',
  templateUrl: './user-logins.component.html',
  styleUrls: ['./user-logins.component.css']
})

export class UserLoginsComponent implements OnInit {
  public model: UserListViewModel = new UserLoginsViewModel();
  public result: GaPagedResult<WlUserLogins>;

  constructor(private readonly messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly service: UsersService,
              private readonly loginsService: UserLoginsService) {
  }

  ngOnInit() {
    this.activeRoute.queryParams.pipe(
      map(value =>  this.initModel(value)),
      map(() => this.createForm()),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe(value => this.result = value);
  }

  public pageChanged(form: UserLoginsForm): void {
    this.model.count.strictValue = form.count;
    this.model.offset.strictValue = form.offset;

    this.navigateThisParam();
  }

  public search(): void {
    this.model.offset.strictValue = 0;
    this.navigateThisParam();
  }

  private load(form: UserLoginsForm): Observable<GaPagedResult<WlUserLogins>> {
    return this.loginsService.listUserLogins(form).pipe(
      take(1),
      untilDestroyed(this)
    );
  }

  private createForm(): UserLoginsForm {
    const form = new UserLoginsForm();
    form.userId = this.model.userId.strictValue;
    form.offset = this.model.offset.strictValue;
    form.count = this.model.count.strictValue;

    return form;
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : 0;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : 30;
    this.model.userId.strictValue = !isNullOrUndefined(params['userId']) ?
      params['userId'] : null;
  }

  private navigateThisParam(): void {
    const params: Params = {
      userId: this.model.userId.hasStrictValue ? this.model.userId.strictValue : null,
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : 30,
      tab: 'UserLogins'
    };

    this.navigation.search(this.activeRoute, params);
  }

}
