import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { GaIndexBasedPagingForm } from '@koddington/ga-common';
import { GaIndexBasedPagingDirections } from '@koddington/ga-common';
import {IndexBasedPagedResult} from '../../../../models/common/results/index-based-paged-result';
import {IIdItem} from '../../../../models/common/results/i-id-item';
import { WlIndexPagedResult } from '../../../../services/autogen/SmsGate';

@Component({
  selector: 'app-wl-index-paging',
  templateUrl: './wl-index-paging.component.html',
  styleUrls: ['./wl-index-paging.component.css']
})
export class WlIndexPagingComponent<TItem extends IIdItem, TFilter extends GaIndexBasedPagingForm> implements OnInit, OnDestroy {

  @Input()
  paging: IndexBasedPagedResult<TItem, TFilter> | WlIndexPagedResult<TItem, TFilter>;

  @Output()
  public pageChangedEvent = new EventEmitter<GaIndexBasedPagingForm>();

  constructor() {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }

  public prevPage(): void {
    const form = this.getPagingForm();
    form.direction = GaIndexBasedPagingDirections.Backward;
    form.index = this.paging.results.map(u => u.id).reduce((u, v) => Math.max(u, v));

    this.pageChangedEvent.emit(form);
  }

  public nextPage(): void {
    const form = this.getPagingForm();
    form.direction = GaIndexBasedPagingDirections.Forward;
    form.index = this.paging.results.map(u => u.id).reduce((u, v) => Math.min(u, v));

    this.pageChangedEvent.emit(form);
  }

  private getPagingForm(): GaIndexBasedPagingForm {
    const form = new GaIndexBasedPagingForm();
    form.count = this.paging.paging.count;

    return form;
  }
}
