import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {BattlePassRoutes} from './battle-pass-routes';
import {BattlePassLayoutComponent} from './components/battle-pass-layout/battle-pass-layout.component';
import {UserQuestsProgressComponent} from './components/user-quests-progress/user-quests-progress.component';
import {AddBattlePassQuestProgressComponent} from './components/add-battle-pass-quest-progress/add-battle-pass-quest-progress.component';
import {BattlePassSubscriptionsComponent} from './components/battle-pass-subscriptions/battle-pass-subscriptions.component';
import {BattlePassQuestsProgressComponent} from './components/battle-pass-quests-progress/battle-pass-quests-progress.component';
import {AddBattlePassQuestProgressValidator} from '../events/validators/add-battle-pass-quest-progress-validator';
import {
    BattlePassUserProfileBindingComponent
} from './components/battle-pass-user-profile-binding/battle-pass-user-profile-binding.component';
import {UserProfileBindingValidator} from '../events/validators/user-profile-binding-validator';
import {BattlePassUsersProfilesComponent} from './components/battle-pass-users-profiles/battle-pass-users-profiles.component';
import {PlatformBindingEventsListComponent} from './components/platform-binding-events-list/platform-binding-events-list.component';
import {BattlePassAccrualRewardListComponent} from './components/battle-pass-accrual-reward-list/battle-pass-accrual-reward-list.component';
import {BattlePassAccrualRewardItemComponent} from './components/battle-pass-accrual-reward-item/battle-pass-accrual-reward-item.component';
import {BattlePassFreeBetRequestsComponent} from './components/battle-pass-user-freebets/battle-pass-free-bet-requests.component';
import {GaCommonModule, GaPagingComponent} from '@koddington/ga-common';
import {BattlePassRewardDictionaryListComponent} from './components/battle-pass-reward-dictionary-list/battle-pass-reward-dictionary-list.component';
import {BattlePassFreeBetSequencesComponent} from './components/battle-pass-freebet-sequences/battle-pass-free-bet-sequences.component';
import { BattlePassLootBoxDictionaryListComponent } from './components/battle-pass-loot-box-dictionary-list/battle-pass-loot-box-dictionary-list.component';
import { BattlePassErpComponent } from './components/battle-pass-erp/battle-pass-erp.component';
import { BattlePassErpLevelsComponent } from './components/battle-pass-erp/battle-pass-erp-levels/battle-pass-erp-levels.component';
import { BattlePassSubscriptionItemComponent } from './components/battle-pass-subscription-item/battle-pass-subscription-item.component';
import { BattlePassAreaCodegenAppModule } from '../../services/autogen/BattlePass';
import { BattlePassSeasonCopyComponent } from './components/battle-pass-season-copy/battle-pass-season-copy.component';

@NgModule({
    declarations: [
        UserQuestsProgressComponent,
        BattlePassLayoutComponent,
        AddBattlePassQuestProgressComponent,
        BattlePassSubscriptionsComponent,
        BattlePassQuestsProgressComponent,
        BattlePassUserProfileBindingComponent,
        BattlePassUsersProfilesComponent,
        PlatformBindingEventsListComponent,
        BattlePassAccrualRewardListComponent,
        BattlePassAccrualRewardItemComponent,
        BattlePassRewardDictionaryListComponent,
        BattlePassFreeBetRequestsComponent,
        BattlePassFreeBetSequencesComponent,
        BattlePassLootBoxDictionaryListComponent,
        BattlePassErpComponent,
        BattlePassErpLevelsComponent,
        BattlePassSubscriptionItemComponent,
        BattlePassSeasonCopyComponent,
    ],
    imports: [
        ReactiveFormsModule,
        SharedModule,
        CommonModule,
        RouterModule.forChild(BattlePassRoutes),
        GaCommonModule,
        BattlePassAreaCodegenAppModule
    ],
    exports: [
        GaPagingComponent
    ],
    providers: [
        AddBattlePassQuestProgressValidator,
        UserProfileBindingValidator
    ]
})
export class BattlePassModule {
}
