import {Routes} from '@angular/router';
import {RateRaceLayoutComponent} from './components/rate-race-layout/rate-race-layout.component';
import {RateRaceExchangeSequencesComponent} from './components/rate-race-exchange-sequences/rate-race-exchange-sequences.component';
import {RateRaceExchangeRequestsComponent} from './components/rate-race-exchange-requests/rate-race-exchange-requests.component';
import {RateRaceUsersHistoriesComponent} from './components/rate-race-users-histories/rate-race-users-histories.component';
import { RateRaceResultsListComponent } from './components/rate-race-results-list/rate-race-results-list.component';
import { RateRaceResultsCrudComponent } from './components/rate-race-results-crud/rate-race-results-crud.component';

export const ChildrenMockRoute: Routes = [
  {
    path: 'exchange-sequences',
    component: RateRaceExchangeSequencesComponent
  },
  {
    path: 'exchange-requests/:id',
    component: RateRaceExchangeRequestsComponent,
  },
  {
    path: 'users-rate-race',
    component: RateRaceUsersHistoriesComponent,
  },
  {
    path: 'results',
    children: [
      {
        path: 'list',
        component: RateRaceResultsListComponent,
      },
      {
        path: 'create',
        component: RateRaceResultsCrudComponent
      },
      {
        path: 'update/:id',
        component: RateRaceResultsCrudComponent
      },
      {
        path: 'repeat/:id',
        component: RateRaceResultsCrudComponent
      },
    ]
  },
];

export const RateRaceRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'main',
        component: RateRaceLayoutComponent,
        children: ChildrenMockRoute
      }
    ]
  }
];

