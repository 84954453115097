<nav class="navbar navbar-expand-sm navbar-light bg-white mb-3 justify-content-lg-end">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link text-dark" [routerLinkActive]="['active']"
         [routerLink]="['/dashboard/control-panel/winline-api/states']">
        API Winline
      </a>
    </li>
  </ul>
</nav>
<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
