import {IDropdownStrategy} from './i-dropdown-strategy';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import { isNullOrUndefined } from '@koddington/ga-common';
import {WlSmsTrafficConversionCodes, WlSmsTrafficConversionCodesExtensions} from 'src/app/services/autogen/SmsGate';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SmsTrafficConversionCodesDropdownStrategy implements IDropdownStrategy<WlSmsTrafficConversionCodes> {
  public getEntities(): Observable<Array<WlSmsTrafficConversionCodes>> {
    return of(EnumExtensions.toArray(WlSmsTrafficConversionCodes));
  }

  public convert(model: WlSmsTrafficConversionCodes): DropdownItem<WlSmsTrafficConversionCodes> {
    if (isNullOrUndefined(model)) {
      return {
        entity: null,
        title: 'Не выбрано',
        id: ''
      };
    }

    return {
      entity: model,
      title: WlSmsTrafficConversionCodesExtensions.format(model),
      id: Number(model).toString()
    };
  }
}
