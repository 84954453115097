import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AddTemplateViewModel} from '../../../models/push/add-template-view-model';
import { TemplatesService } from 'src/app/services/autogen/PushGate';
import {AddTemplateValidator} from '../validators/add-template-validator';
import { AddOrUpdateTemplateForm } from 'src/app/services/autogen/PushGate';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {WlResult} from '../../../models/common/results/wl-result';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-push-template-add',
  templateUrl: './push-template-add.component.html',
  styleUrls: ['./push-template-add.component.css']
})
export class PushTemplateAddComponent implements OnInit {

  private result: WlResult<boolean>;
  private model: AddTemplateViewModel = new AddTemplateViewModel();

  @Output()
  private valueAdded: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private readonly service: TemplatesService,
              private readonly validator: AddTemplateValidator,
              private readonly messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new AddOrUpdateTemplateForm();
    form.templateCode = this.model.templateCode.strictValue;
    form.values = this.model.values.strictValue;

    this.service.addOrUpdate(form)
      .pipe(take(1), filter(value => !this.messaging.tryShowError(value)), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Значение успешно добавлено');
        this.valueAdded.emit(value.result);
      });
  }

  get viewModel(): AddTemplateViewModel {
    return this.model;
  }

  get templateCode(): StrictFormControl<number> {
    return this.viewModel.templateCode;
  }

  get values(): StrictFormControl<string> {
    return this.viewModel.values;
  }
}
