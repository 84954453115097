import { Injectable } from '@angular/core';
import { AbstractModelValidator } from '@koddington/ga-common';
import { AddUserCashbackViewModel } from '../../../models/events/components-models/add-user-cashback-view-model';

@Injectable()
export class AddUserCashbackValidator extends AbstractModelValidator<AddUserCashbackViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.userId).requiredAndNotZero().min(1);
        this.ruleForControl(m => m.categoryId).min(1);
        this.ruleForControl(m => m.selectDate).required();
    }
}
