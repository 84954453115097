import {Routes} from '@angular/router';
import {EventsLayoutComponent} from '../../events-layout/events-layout.component';
import {LifetimeCallEventsListComponent} from './lifetime-call-events-list/lifetime-call-events-list.component';
import {LifetimeCallEventsChangesComponent} from './lifetime-call-events-changes.component';


export const LifetimeCallEventsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: LifetimeCallEventsListComponent
      },
      {
        path: 'addEvent',
        component: LifetimeCallEventsChangesComponent
      }
    ]
  }
];
