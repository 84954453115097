import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {WlResult} from 'src/app/models/common/results/wl-result';
import {GaHttpWrapperService, GaPagedResult, isNullOrUndefined} from '@koddington/ga-common';
import {AddErrorByTokenForm} from '../components/push-mock-error-handle/models/push-mock-add-error-token-form';

@Injectable({
  providedIn: 'root'
})
export class MockPushSevice<T> {

  constructor(private readonly _http: GaHttpWrapperService) {
  }

  public getList(url: string, count: number, offset: number, token: string): Observable<GaPagedResult<T>> {
    let params = new HttpParams()
      .set('offset', offset.toString())
      .set('count', count.toString());
    if (!isNullOrUndefined(token)) {
      params = params.set('token', token.toString());
    }
    return this._http.get(url, params);
  }

  public addErrorByToken(url: string, paramForm: AddErrorByTokenForm): Observable<WlResult<boolean>> {
    return this._http.post(url, paramForm);

  }

  public deleteErrorById(url: string, id: number): Observable<WlResult<boolean>> {
    return this._http.post(url, id);
  }
}

