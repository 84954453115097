import {Component, OnDestroy, OnInit} from '@angular/core';
import { WlUserCallRecordApiModel } from 'src/app/services/autogen/CallCenter';
import {filter, finalize, first, take} from 'rxjs/operators';
import { UserCallRecordsService } from 'src/app/services/autogen/CallCenter';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import { WlUserCallState } from 'src/app/services/autogen/CallCenter';
import { UserCallResponseService } from 'src/app/services/autogen/CallCenter';

@Component({
  selector: 'app-user-calls-list',
  templateUrl: './record-user-call-list.component.html',
  styleUrls: ['./record-user-call-list.component.css']
})
export class RecordUserCallListComponent implements OnInit, OnDestroy {

  public loading = true;
  public items: WlUserCallRecordApiModel[] = [];

  constructor(private _location: Location,
              private readonly _api: UserCallRecordsService,
              private readonly  _responseService: UserCallResponseService,
              private readonly _route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.loading = true;
    this._api.list(this.initId).pipe(
      take(1),
      finalize(() => this.loading = false)
    ).subscribe(value => this.items = value.result);
  }

  ngOnDestroy(): void {
  }

  get initId(): number {
    return this._route.snapshot.params['id'];
  }

  backClicked() {
    this._location.back();
  }

  markAsCalled(id: number) {
      this._responseService.markUserAsNotified(id)
        .pipe(
          first(),
          filter(res => res)
        ).subscribe(res => this.load());
  }

  canMarkAsCompleted(userCallState: WlUserCallState): boolean {
    switch (userCallState) {
      case WlUserCallState.InCallQueue:
        return true;
        break;
      case WlUserCallState.IsCanceled:
      case WlUserCallState.CallCompleted:
        return false;
        break;
    }
  }
}
