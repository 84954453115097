import { AbstractModelValidator } from '@koddington/ga-common';
import { PickemErpViewModel } from '../view-models/pickem-erp.view-model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PickemErpValidator extends AbstractModelValidator<PickemErpViewModel> {
    constructor() {
        super();

        this.ruleForControl(u => u.userId)
            .required()
            .min(1);

        this.ruleForControl(u => u.promoId)
            .required()
            .min(1);
    }
}
