<div class="list-group list-group-flush">
  <div class="list-group-item list-group-item-action" style="border-top: none;">
    <div class="row">
      <div class="col-3">
        <label>Sms id:</label>
        <span class="long-description">
          {{viewModel.id}}
        </span>
      </div>
      <div class="col-2">
        <label>Аккаунт:</label>
        <span class="long-description">
          {{viewModel.account.login}}
        </span>
      </div>
      <div class="col-3">
        <label>Канал:</label>
        <span class="long-description">
          {{channels.format(viewModel.account.type)}}
        </span>
      </div>
      <div class="col-3">
        <label>Телефон:</label>
        <span class="long-description">
          {{viewModel.phone.phoneNumber}}
        </span>
      </div>
    </div>
  </div>
  <div class="list-group-item list-group-item-action" style="border-top: none;">
    <div class="row">
      <div class="col-3">
        <label>Дата отправки:</label>
        <span class="long-description">
          {{viewModel.createdAt | moscowDateTime}}
        </span>
      </div>
      <div class="col-3">
        <label>Статус отправки:</label>
        <span class="long-description">
          {{sentCodes.format(viewModel.sendingCode)}}
        </span>
      </div>
      <div class="col-3" *ngIf="canSetSmsTrafficConversion">
        <div class="form-group" style="display: inline-block;">
          <label>Статус конверсии смс траффик:</label>
          <app-enum-dropdown [entityContainer]="viewModel.smsTrafficConversionCode"
                             [strategy]="smsTrafficConversionCodeStrategy">
          </app-enum-dropdown>
        </div>
      </div>
      <div class="col-3" *ngIf="canSetMtsConversion">
        <div class="form-group" style="display: inline-block;">
          <label>Статус конверсии мтс коммуникатор:</label>
          <app-enum-dropdown [entityContainer]="viewModel.mtsCommunicatorConversionCode"
                             [strategy]="mtsCommunicatorConversionCodeStrategy">
          </app-enum-dropdown>
        </div>
      </div>
      <div class="col-3 float-right">
        <button class="btn btn-success" style="margin: 15px;" (click)="saveChanges()">
          Сохранить
        </button>
      </div>
    </div>
  </div>
  <div class="list-group-item list-group-item-action">
    <h5 class="mb-1">Текст: {{viewModel.text}}</h5>
  </div>
</div>
