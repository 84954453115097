import {Injectable} from '@angular/core';
import {AbstractModelValidator} from '@koddington/ga-common';
import {AddLifetimeCallEventViewModel} from '../events-components/lifetime-call-events-changes/models/add-lifetime-call-event-view-model';
import { CallOperationTypes } from 'src/app/services/autogen/Events';


@Injectable()
export class LifetimeCallEventsValidator extends AbstractModelValidator<AddLifetimeCallEventViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).required('Поле обязательно').min(1).maxLength(9);
    this.ruleForControl(m => m.eventChangeDate).required('Поле обязательно');
    this.ruleForControl(m => m.callOperation).required('Поле обязательно');

    this.ruleForControlIf(m => m.callResult,
      c => c.callOperation.strictValue === CallOperationTypes.SaveInCall ||
        c.callOperation.strictValue === CallOperationTypes.LeftByCall)
      .requiredAndNotZero('Поле обязательно');

    this.ruleForControl(m => m.callQueue).required('Поле обязательно');

  }
}
