import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserNotificationStatusViewModel} from '../../../../models/calls/user-notification-status-view-model';
import { UserCallResponseService } from 'src/app/services/autogen/CallCenter';
import {AddUserCallNotificationStatusValidator} from '../../validators/add-user-call-notification-status-validator';
import { AddUserCallNotificationStatusForm } from 'src/app/services/autogen/CallCenter';
import {filter, first} from 'rxjs/operators';
import {GaMessagingService} from '@koddington/ga-common';

@Component({
  selector: 'app-add-user-call-notification-status',
  templateUrl: './add-user-call-notification-status.component.html',
  styleUrls: ['./add-user-call-notification-status.component.css']
})
export class AddUserCallNotificationStatusComponent implements OnInit, OnDestroy {

  private readonly validator = new AddUserCallNotificationStatusValidator();
  public viewModel = new UserNotificationStatusViewModel();

  constructor(private readonly _service: UserCallResponseService,
              private readonly _messaging: GaMessagingService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  get isUnsubscribeAction(): boolean {
    return this.viewModel.isUnsubscribed.strictValue === true;
  }
  save(): void {
    const isValid = this.validator.validate(this.viewModel);
    if (isValid.length > 0) {
      this._messaging.showMessage('Ошибка валидации, ' + isValid.pop().message);
      return;
    }
      const form = new AddUserCallNotificationStatusForm();
      form.userId = this.viewModel.userId.strictValue;
      form.eventDate = this.viewModel.eventDate.strictValue;
      form.isUnsubscribe = this.viewModel.isUnsubscribed.strictValue;

      this._service.changeUserCallNotificationStatus(form).pipe(
        first(),
        filter(value => !this._messaging.tryShowError(value))
      ).subscribe(() => this._messaging.showMessage('Статус успешно изменён'));
  }
}
