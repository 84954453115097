<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/user-quests-progress']">
        Прогресс пользователя по заданиям
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/users-subscriptions']">
        Подписки пользователей
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/profiles-list']">
        Профили пользователей Battle Pass
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/user-profile-binding']">
        Привязка платформ
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/accrual-reward']">
        Выдача наград
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/reward-dictionary']">
        Справочник наград
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/loot-box-dictionary']">
        Справочник лутбоксов
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/free-bets-sequences']">
        Выдача фрибетов по БП
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/erp']">
        ERP
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main/season-copy']">
        Копирование сезона
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</div>
