import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {CountriesViewModel} from '../view-models/countries-view-model';
import {take} from 'rxjs/operators';
import { AddOrUpdateCountriesForm } from 'src/app/services/autogen/Dictionaries';
import { CountriesService } from 'src/app/services/autogen/Dictionaries';

@Component({
  selector: 'app-countries-update',
  templateUrl: './countries-update.component.html',
  styleUrls: ['./countries-update.component.css']
})
export class CountriesUpdateComponent implements OnInit, OnDestroy {

  @Output()
  public countiesChanged = new EventEmitter<void>();

  public loading = true;
  public viewModel = new CountriesViewModel();

  constructor(private readonly _service: CountriesService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  save() {
    const form = new AddOrUpdateCountriesForm();
    form.id = this.viewModel.id.value;
    form.nameRu = this.viewModel.nameRu.value;
    form.nameEn = this.viewModel.nameEn.value;

    this._service.addOrUpdate(form).pipe(
      take(1)
    ).subscribe(() => this.countiesChanged.emit());
  }
}
