import {Component, OnInit} from '@angular/core';
import { SportsStatisticsService } from 'src/app/services/autogen/Users';
import { SportsStatsFilterForm } from 'src/app/services/autogen/Users';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MockUserBetsForSportType } from 'src/app/services/autogen/Users';

@UntilDestroy()
@Component({
  selector: 'app-users-bets-for-sport-type',
  templateUrl: './users-bets-for-sport-type.component.html',
  styleUrls: ['./users-bets-for-sport-type.component.css']
})
export class UsersBetsForSportTypeComponent implements OnInit {

  bets: MockUserBetsForSportType[];

  constructor(private readonly _service: SportsStatisticsService) { }

  ngOnInit(): void {
    this.load(new SportsStatsFilterForm());
  }

  load(form: SportsStatsFilterForm) {
    this._service.getSportBets(form).pipe(
      untilDestroyed(this)
    ).subscribe(res => {
      this.bets = res.result;
      console.log(this.bets);
    });
  }
}
