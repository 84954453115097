import {Component, OnInit} from '@angular/core';
import {CouponViewModel} from '../../../../models/coupons/coupon-view-model';
import {DropdownOptions} from '../../../../models/common/enum-dropdown/dropdown-options';
import {CouponsDropdownStrategy} from '../../../shared/common/enum-dropdown/strategies/coupons.dropdown-strategy';
import { CouponsService } from 'src/app/services/autogen/Dictionaries';
import { AddCouponForm } from 'src/app/services/autogen/Dictionaries';
import {filter, first} from 'rxjs/operators';
import {AddCouponValidator} from '../validators/add-coupon-validator';
import { UpdateCouponForm } from 'src/app/services/autogen/Dictionaries';
import {UpdateCouponValidator} from '../validators/update-coupon-validator';
import {GaMessagingService} from '@koddington/ga-common';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit {

  public readonly couponModel: CouponViewModel = new CouponViewModel();
  private readonly _updateValidator: UpdateCouponValidator = new UpdateCouponValidator();

  constructor(private readonly dropdownStrategy: CouponsDropdownStrategy,
              private readonly _couponsService: CouponsService,
              private readonly _validator: AddCouponValidator,
              private readonly messaging: GaMessagingService) {

  }

  public get strategy(): CouponsDropdownStrategy {
    return this.dropdownStrategy;
  }

  ngOnInit(): void {
  }

  get dropDownOptions(): DropdownOptions {
    const options = new DropdownOptions();
    options.placeholder = 'Выберите тип';
    options.classname = 'coupon-type';
    return options;
  }

  public save(): void {
    if (this._validator.validate(this.couponModel).length === 0) {
      const form = new AddCouponForm();
      form.name = this.couponModel.name.strictValue;
      form.beginDate = this.couponModel.beginDate.strictValue.toISOString();
      form.daysUntilExpired = this.couponModel.daysUntilExpired.strictValue;
      form.type = this.couponModel.type.strictValue;
      this._couponsService.addCoupon(form)
        .pipe(first(),
          filter(value => !this.messaging.tryShowError(value)))
        .subscribe(() =>
          this.messaging.showMessage('Купон успешно добавлен'));
    }
  }

  public update(): void {
    if (this._updateValidator.validate(this.couponModel).length === 0) {
      const form = new UpdateCouponForm();
      form.couponId = this.couponModel.couponId.strictValue;
      form.expirationDate = this.couponModel.expirationDate.strictValue;
      this._couponsService.updateCoupon(form)
        .pipe(first(),
          filter(value => !this.messaging.tryShowError(value)))
        .subscribe(() =>
          this.messaging.showMessage('Купон успешно обновлён'));
    }
  }
}
