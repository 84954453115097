import {Component, OnInit} from '@angular/core';
import {AddMatchTeamValidator} from './models/add-match-team.validator';
import {AddMatchTeamViewModel} from './models/add-match-team.view-model';
import { AddMatchTeamForm } from 'src/app/services/autogen/Dictionaries';
import {filter, finalize, first} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Router} from '@angular/router';
import { MatchTeamsService } from 'src/app/services/autogen/Dictionaries';
import { FavouriteTeamsService } from 'src/app/services/autogen/Shared';
import {FavouriteTeamsStrategy} from '../../../../shared/common/strict-autocomplete/strategies/favourite-teams-strategy';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-add-match-team',
  templateUrl: './add-match-team.component.html',
  styleUrls: ['./add-match-team.component.css']
})
export class AddMatchTeamComponent implements OnInit {

  public loading = false;
  public model = new AddMatchTeamViewModel();
  public readonly teamsStrategy: FavouriteTeamsStrategy;

  constructor(private readonly _apiClient: MatchTeamsService,
              private readonly _messages: GaMessagingService,
              private readonly _validator: AddMatchTeamValidator,
              private readonly _router: Router,
              private readonly _apiService: FavouriteTeamsService) {
    this.teamsStrategy = new FavouriteTeamsStrategy(_apiService);
  }

  public ngOnInit(): void {
  }

  public onSave(): void {
    const errors = this._validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }

    const form = new AddMatchTeamForm();
    form.id = this.model.id.strictValue;
    form.name = this.model.name.strictValue;
    form.favouriteTeamId = this.model.favouriteTeam.strictValue.id;

    this.loading = true;
    this._apiClient.add(form).pipe(
      first(),
      filter(u => !this._messages.tryShowError(u)),
      finalize(() => this.loading = false),
      untilDestroyed(this)
    ).subscribe(() => {
      this._messages.showMessage('Команда добавлена');
      this._router.navigate(['/dashboard/dictionaries/teams/list']);
    });
  }
}
