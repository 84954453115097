import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Observable} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {take, finalize, map, switchMap} from 'rxjs/operators';
import {SearchNavigationService} from 'src/app/modules/shared/services/search-navigation.service';
import {MockPushHistoryListForm } from '../../../../services/autogen/PushGate';
import {PushListViewModel} from '../push-mock-list/models/push-mock-list.viewmodel';
import {PushHistoryAndroid} from './models/push-history-android';
import {MockPushGCMSevice} from '../../api-clients/push-mock-gcm-client';
import {formatBoolean, formatDateTime, GaPagedResult, GaPagingForm, GaTableData, isNullOrUndefined} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-push-mock-android-list',
  templateUrl: './push-mock-android-list.component.html',
  styleUrls: ['./push-mock-android-list.component.css']
})
export class PushMockAndroidListComponent implements OnInit {

  public result: PushHistoryAndroid[] = [];
  public pagedResult: GaPagedResult<PushHistoryAndroid> = new GaPagedResult<PushHistoryAndroid>();
  public loading: boolean;
  public model: PushListViewModel = new PushListViewModel();
  private defaultCount = 25;
  private defaultOffset = 0;

  tableData: GaTableData<PushHistoryAndroid>;

  constructor(private readonly _httpClient: MockPushGCMSevice,
              private readonly _navigation: SearchNavigationService,
              private readonly _activeRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this._activeRoute.queryParams.pipe(
      map((params) => this.initModel(params)),
      map(() => this.createForm()),
      switchMap((paging) => this.load(paging)),
      untilDestroyed(this)
    ).subscribe(data => {
      this.pagedResult = data;
      this.result = data.results;
      this.mapToTable();
    });
  }

  public pageChanged(form: GaPagingForm): void {
    this.model.offset.strictValue = form.offset;
    this.search();
  }

  private load(form: MockPushHistoryListForm): Observable<GaPagedResult<PushHistoryAndroid>> {
    this.loading = true;
    return this._httpClient.getList(form.count, form.offset, form.token).pipe(
      take(1),
      finalize(() => this.loading = false),
      untilDestroyed(this)
    );
  }

  private createForm(): MockPushHistoryListForm {
    const form = new MockPushHistoryListForm();
    form.offset = this.model.offset.hasStrictValue ? this.model.offset.strictValue : this.defaultOffset;
    form.count = this.model.count.hasStrictValue ? this.model.count.strictValue : this.defaultCount;
    form.token = this.model.token.hasStrictValue ? this.model.token.strictValue : null;
    return form;
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : null;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : null;
    this.model.token.strictValue = !isNullOrUndefined(params['token']) ?
      params['token'] : null;
  }

  public search(): void {
    const params: Params = {
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : this.defaultOffset,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : this.defaultCount,
      token: this.model.token.hasStrictValue ? this.model.token.strictValue : null,
    };
    this._navigation.search(this._activeRoute, params);
  }

  private mapToTable() {
    this.tableData = new GaTableData<PushHistoryAndroid>()
      .setData(this.result)
      .addSimpleColumn((elem) => elem.id, { title: 'Id' })
      .addSimpleColumn((elem) => elem.title, { title: 'Title' })
      .addSimpleColumn((elem) => elem.message, { title: 'Message' })
      .addSimpleColumn((elem) => elem.registrationId, { title: 'Registration id' })
      .addSimpleColumn((elem) => elem.userId, { title: 'User id' })
      .addSimpleColumn((elem) => elem.collapseKey, { title: 'Collapse key' })
      .addSimpleColumn((elem) => elem.attachment, { title: 'Attachment' })
      .addSimpleColumn((elem) => elem.deviceToken, { title: 'Device token' })
      .addSimpleColumn((elem) => formatBoolean(elem.externalLink), { title: 'External link' })
      .addSimpleColumn((elem) => formatBoolean(elem.isSilent), { title: 'IsSilent' })
      .addSimpleColumn((elem) => elem.url, { title: 'Url' })
      .addSimpleColumn((elem) => formatDateTime(elem.dateTimeCreate), { title: 'Date time created' })
      .addSimpleColumn((elem) => elem.templateBroadcastRequestId, { title: 'Request id' });
  }
}
