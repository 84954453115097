<h1>Получение жизненного цикла колл-центра</h1>
<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li (click)="onTabChange('LifetimeCallEventsListAllow')">Список обзвонов</li>
    <li (click)="onTabChange('LifeTimeCallEventsChangesAllow')">Добавление обзвона</li>
  </ul>
  <div>
    <div *ngIf="isLifetimeCallEventsListAllow">
     <app-lifetime-call-events-list></app-lifetime-call-events-list>
    </div>
    <div *ngIf="isLifetimeCallEventsChangesAllow">
      <app-lifetime-call-events-changes></app-lifetime-call-events-changes>
    </div>
  </div>
</div>
