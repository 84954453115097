<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/face-it/main/subscriptions']">
        Подписки
      </a>
    </li>
    <li class="nav-item battle-pass-li">
      <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/face-it/main/rewards']">
        Награды
      </a>
    </li>
  </ul>
  <router-outlet></router-outlet>
</div>
