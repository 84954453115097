<div>
  <h1>Cтавки на которые подписался пользователь</h1>
  <hr/>
  <div>
    <app-strict-input-number [control]="model.userId" [placeholder]="'Id пользователя'"></app-strict-input-number>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </div>
  <div *ngIf="result && result.results">
    <div class="row mt-3">
      <div class="col-1">id</div>
      <div class="col-1">UserId</div>
      <div class="col-1">BetId</div>
      <div class="col-1">MatchId</div>
      <div class="col-2">SubscribeDate</div>
      <div class="col-2">NotificationDate</div>
    </div>
    <hr>
    <div class="row" *ngFor="let item of result.results">
      <div class="col-1">
        {{item.id}}
      </div>
      <div class="col-1">
        {{item.userId}}
      </div>
      <div class="col-1">
        {{item.betId}}
      </div>
      <div class="col-1">
        {{item.matchId}}
      </div>
      <div class="col-2">
        {{item.subscribeDate | moscowDateTime}}
      </div>
      <div class="col-2">
        {{item.notificationDate| moscowDateTime }}
      </div>
    </div>
  </div>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
