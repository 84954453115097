import {Component, OnInit} from '@angular/core';
import { MediaService } from 'src/app/services/autogen/Shared';
import {filter, first, map} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {AddMediaBtagViewModel} from '../../../../models/media/view/add-media-btag-view-model';
import { AddMediaBtagForm } from 'src/app/services/autogen/Shared';
import {AddMediaBtagValidator} from '../../../../models/media/view/validators/add-media-btag-validator';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-media-add-btag',
  templateUrl: './media-add-btag.component.html',
  styleUrls: ['./media-add-btag.component.scss']
})
export class MediaAddBtagComponent implements OnInit {

  public viewModel: AddMediaBtagViewModel = new AddMediaBtagViewModel();
  public validator: AddMediaBtagValidator = new AddMediaBtagValidator();

  constructor(private _service: MediaService,
              private _messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  public send(): void {
    const errors = this.validator.validate(this.viewModel);

    if (errors.length > 0) {
      return;
    }

    const form = new AddMediaBtagForm();
    form.btagId = this.viewModel.btagId.strictValue;
    form.name = this.viewModel.name.strictValue;
    form.sourceId = this.viewModel.sourceId.strictValue;
    form.channelId = this.viewModel.channelId.strictValue;
    form.changeDate = this.viewModel.changeDate.strictValue;

    this._service.addBtag(form).pipe(
      first(),
      filter(value => !this._messaging.tryShowError(value)),
      map((value) => {
        if (value.result) {
          this._messaging.showMessage('Btag добавлен');
        } else {
          this._messaging.showMessage('Btag не добавлен, проверьте введённые данные');
        }
      }),
      untilDestroyed(this)
    ).subscribe();
  }
}
