import {IDropdownStrategy} from './i-dropdown-strategy';
import {WlMechanicsStates, WlMechanicsStatesExtensions} from 'src/app/services/autogen/UserMechanics';
import { isNullOrUndefined } from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MechanicStatesDropdownStrategy implements IDropdownStrategy<WlMechanicsStates> {

  convert(model: WlMechanicsStates): DropdownItem<WlMechanicsStates> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlMechanicsStates>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlMechanicsStatesExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlMechanicsStates>> {
    const types = EnumExtensions.toArray(WlMechanicsStates);
    return of(types);
  }

}

