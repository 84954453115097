import {Component, OnInit} from '@angular/core';
import {
    formatDateTime,
    GaPagedResult,
    GaPagingForm,
    GaTableData,
    isNullOrUndefined, StrictFormControl, StrictFormControlBuilder
} from '@koddington/ga-common';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';
import { CustomTournamentService } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentsTablePagingForm } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentTableModel } from 'src/app/services/autogen/CustomTournaments';

@UntilDestroy()
@Component({
  selector: 'app-custom-tournament-table-list',
  templateUrl: './custom-tournament-table-list.component.html'
})
export class CustomTournamentTableListComponent implements OnInit {
  public loading = true;
  public pagedResult: GaPagedResult<CustomTournamentTableModel>;
  public tableData: GaTableData<CustomTournamentTableModel>;
  private filter = new CustomTournamentsTablePagingForm();

  public tournamentId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public onlyActiveEntries?: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);

  constructor(private readonly service: CustomTournamentService,
              private readonly route: ActivatedRoute,
              private readonly navigation: SearchNavigationService) {
  }

  public ngOnInit(): void {
    this.route.queryParams.pipe(
      take(1)
    ).subscribe(u => this.init(u));
  }

  public load(): void {
    this.loading = true;

    this.service.listTournamentsPlaces(this.filter).pipe(
      take(1),
      untilDestroyed(this)
    ).subscribe(u => {
        this.pagedResult = u;
        this.mapToTable(u.results);

        this.loading = false;
      }
    );
  }

  public search(): void {
    this.filter.tournamentId = this.tournamentId.hasStrictValue ? this.tournamentId.strictValue : undefined;

    this.filter.onlyActiveEntries = this.onlyActiveEntries.hasStrictValue ? this.onlyActiveEntries.strictValue : false;

    this.navigateWithParameters();
  }

  public pageChanged(form: GaPagingForm): void {
    this.filter.offset = form.offset;
    this.filter.count = form.count;
    this.navigateWithParameters();
  }

  private mapToTable(result: CustomTournamentTableModel[]) {
    this.tableData = new GaTableData<CustomTournamentTableModel>()
      .setData(result)
      .addSimpleColumn((elem) => elem.id, { title: 'Id', widthSize: 60 })
      .addSimpleColumn((elem) => elem.tournamentId, { title: 'Id турнира', widthSize: 90 })
      .addSimpleColumn((elem) => elem.userId, { title: 'Id пользователя', widthSize: 120 })
      .addSimpleColumn((elem) => elem.tournamentPlace, { title: 'Место в турнирной таблице', widthSize: 190 })
      .addSimpleColumn((elem) => formatDateTime(elem.createdAt), { title: 'Дата создания', widthSize: 150 })
      .addSimpleColumn((elem) => formatDateTime(elem.deletedAt), { title: 'Дата удаления', widthSize: 150 });
  }

  private init(params: Params) {
    this.filter.offset = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
    this.filter.count = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
    this.filter.tournamentId = !isNullOrUndefined(params['tournamentId']) ? Number(params['tournamentId']) : undefined;
    this.filter.onlyActiveEntries = !isNullOrUndefined(params['onlyActiveEntries']) ? true : undefined;

    this.tournamentId.strictValue = this.filter.tournamentId;
    this.onlyActiveEntries.strictValue = this.filter.onlyActiveEntries;

    this.load();
  }

  private navigateWithParameters(): void {
    const params: Params = {
      offset: this.filter.offset,
      count: this.filter.count ? this.filter.count : 30,
    };

    if (!isNullOrUndefined(this.filter.tournamentId)) {
      params['tournamentId'] = this.filter.tournamentId;
    }

    if (this.filter.onlyActiveEntries) {
      params['onlyActiveEntries'] = true;
    }

    this.navigation.search(this.route, params);
  }
}
