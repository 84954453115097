<form (submit)="save()" style="width: 500px">
  <h1 class="mt-2">Обновление баланса</h1>
  <div class="form-group">
    <label>Id пользователя</label>
    <app-strict-input-number [control]="this.viewModel.userId">
    </app-strict-input-number>
  </div>
  <div class="form-group">
    <label>Баланс</label>
    <app-strict-input-number [control]="this.viewModel.amount">
    </app-strict-input-number>
  </div>
  <div class="form-group">
    <label>Мячи</label>
    <app-strict-input-number [control]="this.viewModel.balls">
    </app-strict-input-number>
  </div>
  <div class="form-group">
    <label>Баланс в игре</label>
    <app-strict-input-number [control]="this.viewModel.inGameBetsSum">
    </app-strict-input-number>
  </div>
  <button type="submit" class="btn btn-primary">Сохранить</button>
</form>

