<div>
  <h1>Статистика по SMS рассылкам</h1>
  <hr/>
  <div>
    <app-strict-input-number [control]="model.userId" [placeholder]="'Id пользователя'"></app-strict-input-number>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </div>
  <div *ngIf="result && result.results">
    <div class="row mt-3">
      <div class="col-1">id</div>
      <div class="col-1">UserId</div>
      <div class="col-1">Номер телефона</div>
      <div class="col-1">Канал отправки</div>
      <div class="col-1">Дата и время</div>
      <div class="col-1">Id шаблона</div>
      <div class="col-1">Код шаблона</div>
      <div class="col-1">Сообщение</div>
      <div class="col-1">Код ошибки</div>
      <div class="col-2">Ошибка</div>
    </div>
    <hr>
    <div class="row mt-3" *ngFor="let item of result.results">
      <div class="col-1">
        {{item.requestId}}
      </div>
      <div class="col-1">
        {{item.userId}}
      </div>
      <div class="col-1">
        {{item.phoneNumber}}
      </div>
      <div class="col-1">
        {{item.channel}}
      </div>
      <div class="col-1">
        {{item.utcSendTime | moscowDateTime}}
      </div>
      <div class="col-1">
        {{item.templateId}}
      </div>
      <div class="col-1">
        {{item.templateCode}}
      </div>
      <div class="col-1">
        {{item.message}}
      </div>
      <div class="col-1">
        {{item.errorCode}}
      </div>
      <div class="col-2">
        {{item.errorCode | wlSmsErrorCodesTransform}}
      </div>
    </div>
  </div>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
