import { Injectable } from '@angular/core';
import { UserViewModel } from '../../../models/users/user-view-model';
import {AbstractModelValidator, ValidationLevel} from '@koddington/ga-common';

@Injectable()
export class UpdateUserDataValidator extends AbstractModelValidator<UserViewModel> {
  constructor() {
    super();
    this.ruleForControl((m) => m.monetaryClass, ValidationLevel.OnSave).required();
    this.ruleForControl((m) => m.stage, ValidationLevel.OnSave).required();
    this.ruleForControl(m => m.phone, ValidationLevel.OnSave).required().minLength(3);
  }
}
