<h1>Список профилей пользователей</h1>

<div class="row">
  <app-strict-input-number class="col-6" [control]="model.userId"
                           [placeholder]="'Id пользователя'"></app-strict-input-number>
  <span>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </span>
</div>

<div class="row mb-3">
  <div class="col-1">
    Id пользователя
  </div>
  <div class="col-1">
    Steam Id
  </div>
  <div class="col-1">
    Twitch Id
  </div>
  <div class="col-1">
    Ссылка на аватарку Steam
  </div>
  <div class="col-1">
    Никнейм Steam
  </div>
  <div class="col-1">
    Ссылка на аватарку Twitch
  </div>
  <div class="col-1">
    Никнейм Twitch
  </div>
</div>
<div *ngFor="let result of results;let i = index">
  <div class="row">
    <div class="col-1">
      {{result.id}}
    </div>
    <div class="col-1">
      {{result.steamId}}
    </div>
    <div class="col-1">
      {{result.twitchId}}
    </div>
    <div class="col-1">
      {{result.avatarLinkSteam}}
    </div>
    <div class="col-1">
      {{result.nicknameSteam}}
    </div>
    <div class="col-1">
      {{result.avatarLinkTwitch}}
    </div>
    <div class="col-1">
      {{result.nicknameTwitch}}
    </div>
  </div>
</div>

<div *ngIf="pagedResult">
  <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>

