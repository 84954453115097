import {AbstractModelValidator} from '@koddington/ga-common';
import {FavoriteTeamViewModel} from '../../../models/events/components-models/favorite-team-view-model';

export class FavoriteTeamValidator extends AbstractModelValidator<FavoriteTeamViewModel> {
  constructor() {
    super();
    this.ruleForControl(u => u.userId).requiredAndNotZero().min(1);
    this.ruleForControl(u => u.favouriteTeam).required();
    this.ruleForControl(u => u.chooseDate).required();
  }
}
