import { Injectable } from "@angular/core";
import { IDropdownStrategy } from "./i-dropdown-strategy";
import { of, Observable } from "rxjs";
import { DropdownItem } from "src/app/models/common/enum-dropdown/dropdown-item";
import { GcmResponseStatus, GcmResponseStatusExtensions } from "src/app/modules/push-gate/enums/push-mock-gcm-response-enum";
import {EnumExtensions} from '@koddington/ga-common';

@Injectable({
    providedIn: 'root'
  })
  export class GcmResponseStatusDropdownStrategy implements IDropdownStrategy<GcmResponseStatus> {
    public getEntities(): Observable<Array<GcmResponseStatus>> {
      return of(EnumExtensions.toArray(GcmResponseStatus));
    }

    public convert(model: GcmResponseStatus): DropdownItem<GcmResponseStatus> {
      return {
        id: Number(model).toString(),
        title: GcmResponseStatusExtensions.toFriendlyString(model),
        entity: model
      };
    }
  }
