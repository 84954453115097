import { WlMockMediaIdentificationTypes } from 'src/app/services/autogen/Shared';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class AddChannelViewModel {
  channelId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  changeDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  type: StrictFormControl<WlMockMediaIdentificationTypes> = StrictFormControlBuilder.buildNullNumber<WlMockMediaIdentificationTypes>();
}
