import {MainLayoutComponent} from '../layouts/main-layout/main-layout.component';
import {Routes} from '@angular/router';
import {CallLayoutComponent} from './components/call-layout/call-layout.component';
import {RecordUserCallListComponent} from './components/record-user-call-list/record-user-call-list.component';

export const CallsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'call-center',
        component: CallLayoutComponent,
        children: []
      },
      {
        path: 'record-user-call-list/:id',
        component: RecordUserCallListComponent,
      },
    ]
  }
];
