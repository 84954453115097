import {Component, OnInit} from '@angular/core';
import {ApiStatesViewModel} from './models/api-states.view-model';
import {ApiStatesDropdownStrategy} from '../../../../shared/common/enum-dropdown/strategies/api-states.dropdown-strategy';
import { ApiStates } from 'src/app/services/autogen/ControlPanel';
import { WinlineApiStatesDashboardService } from 'src/app/services/autogen/ControlPanel';
import {filter, first, map, switchMap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {WlResult} from '../../../../../models/common/results/wl-result';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-winline-api-states',
  templateUrl: './winline-api-states.component.html',
  styleUrls: ['./winline-api-states.component.css']
})
export class WinlineApiStatesComponent implements OnInit {

  public apiStates = ApiStates;
  public viewModel: ApiStatesViewModel;

  public loading = true;

  constructor(public readonly dropdownStrategy: ApiStatesDropdownStrategy,
              private readonly _messages: GaMessagingService,
              private readonly _apiClient: WinlineApiStatesDashboardService) {
  }

  public ngOnInit(): void {
    this.reload().subscribe();
  }

  public onDocsAndSelfiesMethodChange(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this._apiClient.setDocsAndSelfiesMethodState(this.viewModel.docsAndSelfiesMethodState.strictValue).pipe(
      first(),
      filter(u => !this._messages.tryShowError(u)),
      map(u => u.result),
      switchMap(() => this.reload()),
      untilDestroyed(this)
    ).subscribe(() => {
      this._messages.showMessage('Изменения сохранены');
    });
  }

  public onBalanceByLoginsMethodChange(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this._apiClient.setBalanceByLoginsMethodState(this.viewModel.balanceByLoginsMethodState.strictValue).pipe(
      first(),
      filter(u => !this._messages.tryShowError(u)),
      map(u => u.result),
      switchMap(() => this.reload()),
      untilDestroyed(this)
    ).subscribe(() => {
      this._messages.showMessage('Изменения сохранены');
    });
  }

  public onClientsRegistrationsMethodChange(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;
    this._apiClient.setClientsRegistrationsMethodState(this.viewModel.clientsRegistrationsMethodState.strictValue).pipe(
      first(),
      filter(u => !this._messages.tryShowError(u)),
      switchMap(() => this.reload()),
      untilDestroyed(this)
    ).subscribe(() => {
      this._messages.showMessage('Изменения сохранены');
    });
  }

  public onMechanicEnablingChange(): void {
    this.set(this.viewModel.mechanicsEnablingQueueState.strictValue, (u) => this._apiClient.setMechanicEnablingQueueState(u));
  }

  public onMechanicTriggeringChange(): void {
    this.set(this.viewModel.mechanicsTriggeringQueueState.strictValue, (u) => this._apiClient.setMechanicTriggeringQueueState(u));
  }

  public onMechanicBonusUsingChange(): void {
    this.set(this.viewModel.mechanicsBonusUsingQueueState.strictValue, (u) => this._apiClient.setMechanicBonusUsingQueueState(u));
  }

  public onBetsChange(): void {
    this.set(this.viewModel.betsState.strictValue, (u) => this._apiClient.setBetsState(u));
  }

  public onMediaBtagsMethodChange(): void {
    this.set(this.viewModel.mediaBtagsMethodState.strictValue, (u) => this._apiClient.setMediaBtagsMethodState(u));
  }

  public onMediaPromoIdsMethodChange(): void {
    this.set(this.viewModel.mediaPromoIdsMethodState.strictValue, (u) => this._apiClient.setMediaPromoIdsMethodState(u));
  }

  public onMediaSourcesMethodChange(): void {
    this.set(this.viewModel.mediaSourcesMethodState.strictValue, (u) => this._apiClient.setMediaSourcesMethodState(u));
  }

  public onPushInfoChange(): void {
    this.set(this.viewModel.pushInfoState.strictValue, (u) => this._apiClient.setPushInfoState(u));
  }

  public onUniQueueChange(): void {
    this.set(this.viewModel.uniQueueForBroadcastState.strictValue, (u) => this._apiClient.setUniQueueForBroadcastState(u));
  }

  public onUniQueueBroadcastCsvDownloadState(): void {
    this.set(this.viewModel.uniQueueBroadcastCsvDownloadState.strictValue, (u) => {
      return this._apiClient.setUniBroadcastCsvDownloadState(u);
    });
  }

  public onMediaChannelsMethodChange(): void {
    this.set(this.viewModel.mediaChannelsMethodState.strictValue, (u) => this._apiClient.setMediaChannelsMethodState(u));
  }

  public onMediaUserBtagsMethodChange(): void {
    this.set(this.viewModel.mediaUserBtagsMethodState.strictValue, (u) => this._apiClient.setMediaUserBtagsMethodState(u));
  }

  public onBattlePassFreeBetQueueChange(): void {
    this.set(this.viewModel.battlePassFreeBetQueueState.strictValue, (u) => this._apiClient.setBattlePassFreeBetQueueState(u));
  }

  public onLogoServiceChange(): void {
    this.set(this.viewModel.logoServiceState.strictValue, (u) => this._apiClient.setLogoServiceState(u));
  }

  public onCustomTournamentChange(): void {
    this.set(this.viewModel.customTournamentState.strictValue, (u) => this._apiClient.setCustomTournamentState(u));
  }

  public onRateRaceExchangeQueueChange(): void {
    this.set(this.viewModel.rateRaceExchangeQueueState.strictValue, (u) => this._apiClient.setRateRaceExchangeQueueState(u));
  }

  public onUserRateRaceQueueChange(): void {
    this.set(this.viewModel.userRateRaceQueueState.strictValue, (u) => this._apiClient.setUserRateRaceQueueState(u));
  }

  public onRateRaceResultsQueueChange(): void {
    this.set(this.viewModel.rateRaceResultsQueueState.strictValue, (u) => this._apiClient.setRateRaceResultsQueueState(u));
  }
    public onCashbackBonusQueueChange(): void {
        this.set(this.viewModel.cashbackBonusState.strictValue, (u) => this._apiClient.setCashbackBonusState(u));
    }

  private set(newVal: ApiStates, update: (apiState: ApiStates) => Observable<WlResult<boolean>>): void {
    if (this.loading) {
      return;
    }

    this.loading = true;
    update(newVal).pipe(
      first(),
      filter(u => !this._messages.tryShowError(u)),
      switchMap(() => this.reload()),
      untilDestroyed(this)
    ).subscribe(() => {
      this._messages.showMessage('Изменения сохранены');
    });
  }

  private reload(): Observable<void> {
    this.loading = true;
    return this._apiClient.getCurrentStates().pipe(
      first(),
      filter(u => !this._messages.tryShowError(u)),
      map(u => u.result),
      map(u => {
        this.viewModel = new ApiStatesViewModel();

        this.viewModel.docsAndSelfiesMethodState.strictValue = u.docsAndSelfiesMethodState;
        this.viewModel.balanceByLoginsMethodState.strictValue = u.balanceByLoginsMethodState;
        this.viewModel.clientsRegistrationsMethodState.strictValue = u.clientsRegistrationsMethodState;
        this.viewModel.mechanicsEnablingQueueState.strictValue = u.mechanicsEnablingQueueState;
        this.viewModel.mechanicsTriggeringQueueState.strictValue = u.mechanicsTriggeringQueueState;
        this.viewModel.mechanicsBonusUsingQueueState.strictValue = u.mechanicsBonusUsingQueueState;
        this.viewModel.betsState.strictValue = u.betsState;
        this.viewModel.mediaBtagsMethodState.strictValue = u.mediaBtagsMethodState;
        this.viewModel.mediaPromoIdsMethodState.strictValue = u.mediaPromoIdsMethodState;
        this.viewModel.mediaSourcesMethodState.strictValue = u.mediaSourcesMethodState;
        this.viewModel.mediaChannelsMethodState.strictValue = u.mediaChannelsMethodState;
        this.viewModel.mediaUserBtagsMethodState.strictValue = u.mediaUserBtagsMethodState;
        this.viewModel.uniQueueBroadcastCsvDownloadState.strictValue = u.uniBroadcastCsvDownload;
        this.viewModel.uniQueueForBroadcastState.strictValue = u.uniQueueBroadcastsQueueState;
        this.viewModel.pushInfoState.strictValue = u.pushInfoState;
        this.viewModel.battlePassFreeBetQueueState.strictValue = u.battlePassFreeBetQueueState;
        this.viewModel.logoServiceState.strictValue = u.logoServiceState;
        this.viewModel.customTournamentState.strictValue = u.customTournamentState;
        this.viewModel.rateRaceExchangeQueueState.strictValue = u.rateRaceExchangeQueueState;
        this.viewModel.userRateRaceQueueState.strictValue = u.usersRateRaceQueueState;
        this.viewModel.rateRaceResultsQueueState.strictValue = u.rateRaceResultsQueueState;
        this.viewModel.cashbackBonusState.strictValue = u.cashbackBonusState;

        this.loading = false;
      }),
      untilDestroyed(this)
    );
  }
}
