import {Pipe, PipeTransform} from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';
import DayjsTimezone from 'dayjs/plugin/timezone';
import dayjs, { Dayjs } from 'dayjs';
dayjs.extend(DayjsTimezone);

@Pipe({
  name: 'moscowDateTime'
})
export class MoscowDateTimePipe implements PipeTransform {

  transform(value: Dayjs, ...args: unknown[]): string {
    if (isNullOrUndefined(value)) {
      return '';
    }
    return dayjs(value).tz('Europe/Moscow').format('DD.MM.YYYY HH:mm:ss');
  }
}
