import {Routes} from '@angular/router';
import {MediaLayoutComponent} from './media-layout/media-layout.component';

export const MediaRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list'
          },
          {
            path: 'layout',
            component: MediaLayoutComponent
          },
        ]
      }
    ]
  }
];
