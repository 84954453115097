import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {filter, finalize, first} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {CreateAccountViewModel} from './forms/create-account.view-model';
import {CreateFormValidator} from './forms/create-form.validator';
import { MockSmsAccountsService } from 'src/app/services/autogen/SmsGate';
import { CreateSmsAccountForm } from 'src/app/services/autogen/SmsGate';
import {SmsChannelsTypesDropdownStrategy} from '../../../../shared/common/enum-dropdown/strategies/sms-channels-types.dropdown-strategy';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-create-sms-account',
  templateUrl: './create-sms-account.component.html',
  styleUrls: ['./create-sms-account.component.css']
})
export class CreateSmsAccountComponent implements OnInit {

  @Output()
  public accountCreated = new EventEmitter<void>();

  public loading = true;
  public viewModel = new CreateAccountViewModel();

  constructor(public readonly dropdownStrategy: SmsChannelsTypesDropdownStrategy,
              private readonly _apiClient: MockSmsAccountsService,
              private readonly _validator: CreateFormValidator,
              private readonly _messages: GaMessagingService) {
  }

  public ngOnInit(): void {
  }

  public save(): void {
    const errors = this._validator.validate(this.viewModel);
    if (errors.length > 0) {
      return;
    }

    const form = new CreateSmsAccountForm();
    form.login = this.viewModel.login.strictValue;
    form.password = this.viewModel.password.strictValue;
    form.type = this.viewModel.type.strictValue;

    this.loading = true;
    this._apiClient.addOrUpdateAccount(form)
      .pipe(
        first(),
        filter(u => !this._messages.tryShowError(u)),
        finalize(() => this.loading = false),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this._messages.showMessage('Аккаунт создан');
        this.accountCreated.emit();
      });
  }

}
