import {Component, OnInit} from '@angular/core';
import { ProcessingErrorsViewModel, ProcessingRequestsErrorsFilter } from 'src/app/services/autogen/UserMechanics';
import {UserMechanicsProcessingErrorsViewModel} from '../../../models/mechanics/user-mechanics-processing-errors-view-model';
import { UserMechanicsLogsService } from 'src/app/services/autogen/UserMechanics';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dayjs } from 'dayjs';
import {StrictFormControl} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-user-mechanics-processing-errors',
  templateUrl: './user-mechanics-processing-errors.component.html',
  styleUrls: ['./user-mechanics-processing-errors.component.css']
})
export class UserMechanicsProcessingErrorsComponent implements OnInit {

  private result: ProcessingErrorsViewModel = new ProcessingErrorsViewModel();
  private readonly model: UserMechanicsProcessingErrorsViewModel = new UserMechanicsProcessingErrorsViewModel();

  constructor(private readonly logsService: UserMechanicsLogsService) {
  }

  ngOnInit(): void {
  }

  search(): void {
    this.logsService.processingRequestsErrors(new ProcessingRequestsErrorsFilter({
          start: this.model.start.strictValue,
          end: this.model.end.strictValue
        }))
        .pipe(take(1), untilDestroyed(this))
        .subscribe(value => this.result = value);
  }

  get startDate(): StrictFormControl<Dayjs> {
    return this.model.start;
  }

  get endDate(): StrictFormControl<Dayjs> {
    return this.model.end;
  }

  get errorsResult(): ProcessingErrorsViewModel {
    return this.result;
  }
}
