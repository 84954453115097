import {Component, OnInit} from '@angular/core';
import {WlResult} from '../../../../models/common/results/wl-result';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import {DropdownOptions} from '../../../../models/common/enum-dropdown/dropdown-options';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {AddLifetimeCallEventViewModel} from './models/add-lifetime-call-event-view-model';
import {LifetimeCallEventsValidator} from '../../validators/lifetime-call-events-validator';
import { CallOperationTypes } from 'src/app/services/autogen/Events';
import { CallResultsTypes } from 'src/app/services/autogen/Events';
import { CallQueueTypes } from 'src/app/services/autogen/Events';
import {CallOperationTypesStrategy} from '../../../shared/common/enum-dropdown/strategies/call-operation-types-strategy';
import {CallQueueTypesStrategy} from '../../../shared/common/enum-dropdown/strategies/call-queue-types-strategy';
import {CallResultsTypesStrategy} from '../../../shared/common/enum-dropdown/strategies/call-results-types-strategy';
import { AddCallEventsForm, EventsService } from 'src/app/services/autogen/Events';


@UntilDestroy()
@Component({
  selector: 'app-lifetime-call-events-changes',
  templateUrl: './lifetime-call-events-changes.component.html',
  styleUrls: ['./lifetime-call-events-changes.component.css']
})
export class LifetimeCallEventsChangesComponent implements OnInit {

  private result: WlResult<boolean>;
  private readonly model: AddLifetimeCallEventViewModel = new AddLifetimeCallEventViewModel();
  private readonly _validator: LifetimeCallEventsValidator = new LifetimeCallEventsValidator();

  constructor(private readonly service: EventsService,
              private readonly _messaging: GaMessagingService,
              public readonly _callOperationTypesStrategy: CallOperationTypesStrategy,
              public readonly _callQueueTypesStrategy: CallQueueTypesStrategy,
              public readonly _callResultsTypesStrategy: CallResultsTypesStrategy) {
  }

  ngOnInit(): void {
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get eventChangeDate(): StrictFormControl<Dayjs> {
    return this.model.eventChangeDate;
  }

  get callOperation(): StrictFormControl<CallOperationTypes> {
    return this.model.callOperation;
  }

  get callResult(): StrictFormControl<CallResultsTypes> {
    return this.model.callResult;
  }

  get callQueue(): StrictFormControl<CallQueueTypes> {
    return this.model.callQueue;
  }

  public isSaveInCallOrLeftByCall(): boolean {
    return this.model.callOperation.strictValue === CallOperationTypes.SaveInCall ||
      this.model.callOperation.strictValue === CallOperationTypes.LeftByCall;
  }

  get options(): DropdownOptions {
    const dropdownOptions = new DropdownOptions();
    dropdownOptions.placeholder = 'Не выбрано';

    return dropdownOptions;
  }
  save(): void {

    const errors = this._validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }

    const form = new AddCallEventsForm();

    form.userId = this.userId.strictValue;
    form.eventChangeDate = this.eventChangeDate.strictValue;
    form.callOperation = this.callOperation.strictValue;
    form.callResult = this.callResult.hasStrictValue ? this.callResult.strictValue : null;
    form.callQueue = this.callQueue.strictValue;

    this.service.addLifetimeCallEvents(form)
      .pipe(
        take(1),
        filter(u => !this._messaging.tryShowError(u)),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.result = value;
        this._messaging.showMessage('Событие обзвона добавлено');
      });
  }

}
