import {Injectable} from '@angular/core';
import {AbstractModelValidator} from '@koddington/ga-common';
import {AddScoreUpdateViewModel} from '../../../models/events/components-models/add-score-update-view-model';

@Injectable()

export class AddScoreUpdateValidator extends AbstractModelValidator<AddScoreUpdateViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).requiredAndNotZero().min(1);
    this.ruleForControl(m => m.score).min(-3).max(7);
    this.ruleForControl(m => m.operationDate).required();
  }
}
