<div>
  <label class="mt-2">Ректировать награды</label>
  <div class="row mt-3">
    <div class="col-2">
      <app-strict-input-text [title]="'Название'" [control]="viewModel.name"></app-strict-input-text>
    </div>
    <div class="col-2">
      <app-strict-input-text [title]="'Описание'" [control]="viewModel.description"></app-strict-input-text>
    </div>
    <div class="col-2">
      <app-strict-input-number [title]="'Рыночная стоимость'" [control]="viewModel.marketPrice"></app-strict-input-number>
    </div>
    <div class="col-2">
      <app-strict-input-text [title]="'Имя ассета в Steam'" [control]="viewModel.hashName"></app-strict-input-text>
    </div>
    <div class="col-2">
      <app-strict-input-text [title]="'Ссылка на изображение'" [control]="viewModel.imageUrl"></app-strict-input-text>
    </div>
    <div class="col-1">
      <app-strict-input-text [title]="'Игра'" [control]="viewModel.game"></app-strict-input-text>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-1">
      <app-strict-input-text [title]="'Тип предмета'" [control]="viewModel.type"></app-strict-input-text>
    </div>
    <div class="col-2">
      <app-strict-input-text [title]="'Группа предметов'" [control]="viewModel.group"></app-strict-input-text>
    </div>
    <div class="col-2">
      <app-strict-input-text [title]="'Категория предмета'" [control]="viewModel.category"></app-strict-input-text>
    </div>
    <div class="col-2">
      <app-strict-input-text [title]="'Редкость предмета'" [control]="viewModel.scarcity"></app-strict-input-text>
    </div>
    <div class="col-2">
      <app-strict-input-number [title]="'Редкость предмета от 1 до 10'" [control]="viewModel.scarValue"></app-strict-input-number>
    </div>
    <div class="col-2">
      <app-strict-input-text [title]="'Качество предмета'" [control]="viewModel.quality"></app-strict-input-text>
    </div>
    <div class="col-2">
      <button class="btn btn-primary mt-2" (click)="send()">Отправить обновление</button>
    </div>
  </div>
</div>
<hr>
<div>
  <label class="mt-2">Справочник наград</label>
  <table *ngIf="!!result" class="table table-hover mt-3">
    <thead>
    <tr class="row table-info">
      <td class="col-1">
        Id
      </td>
      <td class="col-3">
        Название
      </td>
      <td class="col-3">
        Описание
      </td>
      <td class="col-4">
        Результат, текст ошибки
      </td>
      <td class="col-1">
      </td>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let elem of result.results" class="row" style="cursor: pointer;">
      <td class="col-1">
        {{elem.id}}
      </td>
      <td class="col-3">
        {{elem.name}}
      </td>
      <td class="col-3">
        {{elem.description}}
      </td>
      <td class="col-4">
        {{elem.resultChange}}, {{elem.errorText}}
      </td>
      <td class="col-1">
        <button class="btn btn-primary mt-2" (click)="change(elem.id)">Редактировать</button>
      </td>
    </tr>
    </tbody>
  </table>
  <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
