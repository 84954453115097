<label class="mt-2">Создать тур X50</label>
<div>
  <div class="row mt-2">
    <div class="col-3">
      Id(Последний Id тура - {{viewModel.lastTourId.strictValue}})
    </div>
    <div class="col-3">
      Название
    </div>
    <div class="col-2">
      Дата первого события
    </div>
    <div class="col-2">
      Статус тура
    </div>
    <div class="col-2">
      Исходы тура
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <app-strict-input-number [control]="viewModel.id" [placeholder]="'Id тура'">
      </app-strict-input-number>
    </div>
    <div class="col-3">
      <app-strict-input-text [control]="viewModel.name" [placeholder]="'Имя тура'">
      </app-strict-input-text>
    </div>
    <div class="col-2">
      <strict-date-time-picker [control]="viewModel.startDate"></strict-date-time-picker>
    </div>
    <div class="col-2">
      <app-enum-dropdown [strategy]="strategy" [entityContainer]="viewModel.status">
      </app-enum-dropdown>
    </div>
    <div class="col-2">
      <app-strict-input-text [control]="viewModel.results" [placeholder]="'Результат'">
      </app-strict-input-text>
    </div>
  </div>
  <div class="row mx-1">
    <span class="col-3 mt-2">
     <button class="btn btn-primary" (click)="save()">Сохранить</button>
     <button class="btn btn-secondary mx-1 " (click)="loadLastTourId()">Обновить Id</button>
    </span>
  </div>
</div>
