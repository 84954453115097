import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { GaCommonModule } from '@koddington/ga-common';
import {CustomTournamentsRoutes} from './custom-tournaments.routes';
import {CustomTournamentsLayoutComponent} from './components/custom-tournaments-layout/custom-tournaments-layout.component';
import {CustomTournamentsItemCrudComponent} from './components/custom-tournaments-item-crud/custom-tournaments-item-crud.component';
import {CustomTournamentsListComponent} from './components/custom-tournaments-list/custom-tournaments-list.component';
import {
  CustomTournamentsTableItemCrudComponent
} from './components/custom-tournaments-table-item-crud/custom-tournaments-table-item-crud.component';
import {CustomTournamentTableListComponent} from './components/custom-tournament-table-list/custom-tournament-table-list.component';
import {CustomTournamentSequencesComponent} from './components/custom-tournament-sequences/custom-tournament-sequences.component';
import { CustomTournamentInvitationsComponent } from './components/custom-tournament-invitations/custom-tournament-invitations.component';


@NgModule({
  declarations: [
    CustomTournamentsListComponent,
    CustomTournamentsItemCrudComponent,
    CustomTournamentTableListComponent,
    CustomTournamentsTableItemCrudComponent,
    CustomTournamentSequencesComponent,
    CustomTournamentsLayoutComponent,
    CustomTournamentInvitationsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(CustomTournamentsRoutes),
    SharedModule,
    GaCommonModule,
  ]
})
export class CustomTournamentsModule { }
