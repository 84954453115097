import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class WlBattlePassChangeRewardViewModel {
  public id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public description: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public marketPrice: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public hashName: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public imageUrl: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public game: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public type: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public group: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public category: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public scarcity: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  public scarValue: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public quality: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
}
