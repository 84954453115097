import { Routes } from '@angular/router';
import { RateUsLayoutComponent } from './rate-us-layout/rate-us-layout.component';
import { RateUsFeedbackListComponent } from './rate-us-feedback-list/rate-us-feedback-list.component';

export const ChildrenMockRoute: Routes = [
    {
        path: 'feedbacks',
        component: RateUsFeedbackListComponent
    }
];

export const RateUsRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: 'main',
                component: RateUsLayoutComponent,
                children: ChildrenMockRoute
            }
        ]
    }
];

