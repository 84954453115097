import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaceItLayoutComponent } from './face-it-layout/face-it-layout.component';
import { FaceItSubscriptionsComponent } from './face-it-subscriptions/face-it-subscriptions.component';
import {RouterModule} from '@angular/router';
import {GaCommonModule} from '@koddington/ga-common';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {FaceItRoutes} from './face-it.routes';
import { FaceItAreaCodegenAppModule } from '../../services/autogen/FaceIt';
import {FaceItRewardsComponent} from './face-it-rewards/face-it-rewards.component';

@NgModule({
  declarations: [
    FaceItLayoutComponent,
    FaceItSubscriptionsComponent,
    FaceItRewardsComponent
  ],
  exports: [
    FaceItLayoutComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(FaceItRoutes),
    SharedModule,
    FaceItAreaCodegenAppModule,
    GaCommonModule
  ],
})
export class FaceItModule { }
