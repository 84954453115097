import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GaCommonModule } from '@koddington/ga-common';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RateUsLayoutComponent } from './rate-us-layout/rate-us-layout.component';
import { RateUsRoutes } from './rate-us.routes';
import { RateUsFeedbackListComponent } from './rate-us-feedback-list/rate-us-feedback-list.component';

@NgModule({
    declarations: [
        RateUsLayoutComponent,
        RateUsFeedbackListComponent
    ],
    exports: [
        RateUsLayoutComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule.forChild(RateUsRoutes),
        SharedModule,
        GaCommonModule
    ],
})
export class RateUsModule {
}
