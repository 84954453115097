import { AbstractModelValidator } from '@koddington/ga-common';
import { WlSeasonCopySettingsViewModel } from '../models/wl-season-copy-settings.view-model';

export class WlSeasonCopyValidator extends AbstractModelValidator<WlSeasonCopySettingsViewModel> {
    constructor() {
        super();

        this.ruleForControl(u => u.seasonId)
            .required('Id сезона обязателен')
            .min(1);
    }
}
