import {Routes} from '@angular/router';
import {FaceItSubscriptionsComponent} from './face-it-subscriptions/face-it-subscriptions.component';
import {FaceItLayoutComponent} from './face-it-layout/face-it-layout.component';
import {FaceItRewardsComponent} from './face-it-rewards/face-it-rewards.component';

export const ChildrenMockRoute: Routes = [
  {
    path: 'subscriptions',
    component: FaceItSubscriptionsComponent
  },
  {
    path: 'rewards',
    component: FaceItRewardsComponent
  }
];

export const FaceItRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'main',
        component: FaceItLayoutComponent,
        children: ChildrenMockRoute
      }
    ]
  }
];

