import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {RouterModule} from '@angular/router';
import { DictionariesLayoutComponent } from './dictionaries-layout/dictionaries-layout.component';
import { ControlPanelLayoutComponent } from './control-panel-layout/control-panel-layout.component';
import { SmsGateLayoutComponent } from './sms-gate-layout/sms-gate-layout.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    DictionariesLayoutComponent,
    ControlPanelLayoutComponent,
    SmsGateLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    MainLayoutComponent
  ]
})
export class LayoutsModule {
}
