import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class AddMediaBtagViewModel {
  btagId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  sourceId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  channelId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  changeDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
}
