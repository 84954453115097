import {AbstractModelValidator, StrictError} from '@koddington/ga-common';
import {AddX50TourViewModel} from '../../../models/tours/add-x50-tour-view-model';
import { isNullOrUndefined } from '@koddington/ga-common';

export class AddX50TourValidator extends AbstractModelValidator<AddX50TourViewModel> {
  private readonly allowedChars = ['1', '2', '3', '4'];
  constructor() {
    super();
    this.ruleForControl(u => u.id)
      .requiredAndNotZero()
      .min(1);
    this.ruleForControl(u => u.name).notEmptyString();
    this.ruleForControl(u => u.results).notEmptyString().minLength(11).maxLength(11)
      .customRule(fieldValue => {
        if (isNullOrUndefined(fieldValue)) {
          return null;
        }
        const charArray = fieldValue.toString().split('');
        const notAllowedChars = charArray.filter(value => !this.allowedChars.includes(value));
        if (notAllowedChars.length > 0) {
          return new StrictError(StrictError.invalidValueErrorName, 'Недопустимое значение. Допускаются цифры от 1 до 4 включительно');
        }
        return null;
      });
    this.ruleForControl(u => u.startDate).required();
    this.ruleForControl(u => u.status).required();
  }
}
