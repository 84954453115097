import {AbstractModelValidator} from '@koddington/ga-common';
import {AddBalanceViewModel} from '../../../models/events/components-models/add-balance-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class AddBalanceValidator extends AbstractModelValidator<AddBalanceViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.balance).required();
    this.ruleForControl(m => m.bonusBalls).required().min(1);
    this.ruleForControl(m => m.userId).requiredAndNotZero();
  }
}
