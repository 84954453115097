import { Injectable } from '@angular/core';
import { AbstractModelValidator } from '@koddington/ga-common';
import { BetCalculationRedeemViewModel } from '../../../models/events/components-models/bet-calculation-redeem-view-model';

@Injectable()
export class AddRedeemedBetValidator extends AbstractModelValidator<BetCalculationRedeemViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.betRedeemedRate)
            .required('Поле обязательно')
            .maxLength(9);
    }
}
