import {Component, OnInit} from '@angular/core';
import {AddXFiveBetViewModel} from '../../../../models/events/components-models/add-x-five-bet-view-model';
import {WlResult} from '../../../../models/common/results/wl-result';
import { EventsService } from 'src/app/services/autogen/Events';
import {AddXFiveBetValidator} from '../../validators/add-x-five-bet-validator';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { AddXfiveBetForm } from 'src/app/services/autogen/Events';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-add-x-five-bet',
  templateUrl: './add-x-five-bet.component.html',
  styleUrls: ['./add-x-five-bet.component.css']
})
export class AddXFiveBetComponent implements OnInit {

  private readonly model: AddXFiveBetViewModel = new AddXFiveBetViewModel();
  private result: WlResult<boolean>;

  constructor(private readonly service: EventsService,
              private readonly validator: AddXFiveBetValidator,
              private readonly messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get couponId(): StrictFormControl<number> {
    return this.model.couponId;
  }

  get date(): StrictFormControl<Dayjs> {
    return this.model.date;
  }

  add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new AddXfiveBetForm();
    form.couponId = this.couponId.strictValue;
    form.date = this.date.strictValue;
    form.userId = this.userId.strictValue;
    this.service.xfiveBetAdd(form).pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Купон X5 успешно добавлен');
      });
  }
}
