import {Injectable} from '@angular/core';
import {AbstractModelValidator} from '@koddington/ga-common';
import {AddMatchTeamViewModel} from './add-match-team.view-model';

@Injectable({
  providedIn: 'root'
})
export class AddMatchTeamValidator extends AbstractModelValidator<AddMatchTeamViewModel> {
  constructor() {
    super();

    this.ruleForControl(u => u.id)
      .required()
      .min(0);

    this.ruleForControl(u => u.name)
      .required()
      .minLength(1);
  }
}
