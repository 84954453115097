import { AbstractModelValidator } from '@koddington/ga-common';
import { MockRateRaceResultPlaceViewModel } from '../../../models/rate-race/mock-rate-race-results-crud-view-model';
import { StrictValidationRules } from '../../shared/validation-rules/strict-validation-rules';

export class RateRaceResultsPlacesValidator extends AbstractModelValidator<MockRateRaceResultPlaceViewModel> {
    constructor() {
        super();

        this.ruleForControl(u => u.place)
            .required()
            .min(1, 'Юзер не может оказаться на месте ниже нуля')
            .customRule(u => StrictValidationRules.isInteger(u));

        this.ruleForControl(u => u.userId)
            .required()
            .customRule(u => StrictValidationRules.isInteger(u));

        this.ruleForControl(u => u.drop)
            .required()
            .min(0, 'Дроп не может быть ниже нуля')
            .customRule(u => StrictValidationRules.isInteger(u));
    }
}
