import {Component, OnInit} from '@angular/core';
import {AddX50BetViewModel} from '../../../models/tours/add-x50-bet-view-model';
import { AddXfiftyBetForm, EventsService } from 'src/app/services/autogen/Events';
import {AddX50BetValidator} from '../validators/add-x50-bet-validator';
import {filter} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-add-x50-bet',
  templateUrl: './add-x50-bet.component.html',
  styleUrls: ['./add-x50-bet.component.css']
})
export class AddX50BetComponent implements OnInit {

  viewModel = new AddX50BetViewModel();
  validator = new AddX50BetValidator();
  constructor(private readonly toursService: EventsService,
              private readonly messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  save(): void {
    if (!this.validator.isValid(this.viewModel)) {
      return;
    }
    const form = new AddXfiftyBetForm();
    form.betDate = this.viewModel.betDate.strictValue;
    form.betSum = this.viewModel.betSum.strictValue;
    form.betResultSum = this.viewModel.betResultSum.strictValue;
    form.branches = this.viewModel.branches.strictValue;
    form.results = this.viewModel.results.strictValue;
    form.userId = this.viewModel.userId.strictValue;
    form.tourId = this.viewModel.tourId.strictValue;
    form.betId = this.viewModel.betId.strictValue;
    form.topPercent = this.viewModel.topPercent.strictValue;

    this.toursService.addXfiftyBet(form).pipe(
      filter(value => !this.messaging.tryShowError(value)), untilDestroyed(this))
      .subscribe(() => this.messaging.showMessage('Ставка успешно добавлена'));
  }
}
