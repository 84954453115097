import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-battle-pass-layout',
  templateUrl: './battle-pass-layout.component.html',
  styleUrls: ['./battle-pass-layout.component.css']
})
export class BattlePassLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
