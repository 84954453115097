<div class="row">
  <label class="col-1 bt-search-token">Apns Error</label>
</div>
<div class="row">
  <label class="col-1 bt-search-token">Input token:</label>
  <div class="col-4">
    <app-strict-input-text [control]="tokenInput"></app-strict-input-text>
  </div>
  <label class="col-1 bt-search-token">Input error code:</label>
  <div class="col-3">
    <app-enum-dropdown [entityContainer]="apnsResponseStatus" [strategy]="_strategy"
                       [options]="options"></app-enum-dropdown>
  </div>
  <div class="col-2">
    <button class="btn btn-primary push-mock-save" (click)="save()">Сохранить</button>
  </div>
</div>

<div class="container-fluid" *ngIf="!loading">
  <ga-table *ngIf="!!tableData" [data]="tableData"></ga-table>
  <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>


