import {Component, OnInit} from '@angular/core';
import { GaMessagingService, GaPagedResult, GaPagingForm, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { SearchLootBoxDictionaryForm, WlBattlePassLootBoxListModel } from 'src/app/services/autogen/BattlePass';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import {WlBattlePassLootBoxViewModel} from '../../../../models/battle-pass/wl-battle-pass-loot-box-view-model';
import {WlBattlePassLootBoxContentViewModel} from '../../../../models/battle-pass/wl-battle-pass-loot-box-content-view-model';
import { ChangeLootBoxDictionaryForm } from 'src/app/services/autogen/BattlePass';
import { ContentLootBoxDictionaryForm } from 'src/app/services/autogen/BattlePass';
import { filter } from 'rxjs/operators';
import {WlBattlePassRewardsStrategy} from '../../../shared/common/enum-dropdown/strategies/wl-battle-pass-rewards-strategy';
import {DropdownOptions} from '../../../../models/common/enum-dropdown/dropdown-options';

@UntilDestroy()
@Component({
  selector: 'app-battle-pass-loot-box-dictionary-list',
  templateUrl: './battle-pass-loot-box-dictionary-list.component.html',
  styleUrls: ['./battle-pass-loot-box-dictionary-list.component.css']
})
export class BattlePassLootBoxDictionaryListComponent implements OnInit {

  public result: GaPagedResult<WlBattlePassLootBoxListModel>;
  public viewModel: WlBattlePassLootBoxViewModel;
  public searchLootBoxId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public rewardStrategy: WlBattlePassRewardsStrategy;

  constructor(private readonly _battlePassService: BattlePassService,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit() {
    this.rewardStrategy = new WlBattlePassRewardsStrategy(this._battlePassService);

    this.load();
    this.emptyLootBox();
  }

  public change(lootBoxId: number): void {
    const lootBox = this.result.results
      .filter((value) => value.id === lootBoxId)[0];

    const editModel = new WlBattlePassLootBoxViewModel();

    editModel.Id.strictValue = lootBox.id;

    editModel.rewards.strictValue = lootBox.rewards.map((value) => {
      const mapped = new WlBattlePassLootBoxContentViewModel();

      mapped.reward.strictValue = value.reward;
      mapped.probability.strictValue = value.probability;

      return mapped;
    });

    this.viewModel = editModel;
  }

  public send(): void {
    const form = this.createForm();
    this._battlePassService.changeLootBox(form).pipe(
      filter(u => !this.messaging.tryShowError(u)),
      untilDestroyed(this))
      .subscribe(u => this.messaging.showMessage('Изменение отправленно'));
  }

  public pageChanged(form: GaPagingForm): void {
    this.load(form.offset);
  }

  public search(): void {
      this.load(0);
  }

  public add(): void {
    const content = new WlBattlePassLootBoxContentViewModel();

    this.viewModel.rewards.strictValue.push(content);
  }

  public delete(index: number): void {
    if (this.viewModel.rewards.strictValue.length === 1) {
      return;
    }

    this.viewModel.rewards.strictValue = this.viewModel.rewards.strictValue
      .filter((u, k) => k !== index);
  }

  private load(offset: number = 0): void {
    const form = new SearchLootBoxDictionaryForm();
    form.offset = offset;
    form.lootBoxId = this.searchLootBoxId.getStrictValueOrDefault(null);

    this._battlePassService.lootBoxList(form)
      .pipe(untilDestroyed(this))
      .subscribe(res => this.result = res);
  }

  private emptyLootBox(): void {
    const emptyModel = new WlBattlePassLootBoxViewModel();

    emptyModel.rewards.strictValue.push(new WlBattlePassLootBoxContentViewModel());
    this.viewModel = emptyModel;
  }

  private createForm(): ChangeLootBoxDictionaryForm {
    const form = new ChangeLootBoxDictionaryForm();

    form.id = this.viewModel.Id.strictValue;
    form.rewards = this.viewModel.rewards.strictValue.map((value) => {
      const mapped = new ContentLootBoxDictionaryForm();

      mapped.rewardId = value.reward.strictValue.id;
      mapped.probability = value.probability.strictValue;

      return mapped;
    });

    return form;
  }

  get dropDownOptions(): DropdownOptions {
    const options = new DropdownOptions();
    options.placeholder = 'Выберите награду';
    return options;
  }
}
