<div>
  <h1>Токены игроков (CRM)</h1>
  <div class="row">
    <div class="col-9">
      <div class="row">
        <div class="col-2">
          <app-strict-input-number [control]="userIdForNewToken" [placeholder]="'Id пользователя'">
          </app-strict-input-number>
        </div>
        <div class="col-3">
          <app-strict-input-text [control]="gamblerToken" [placeholder]="'Токен'">
          </app-strict-input-text>
        </div>
        <div class="col-3">
          <strict-date-time-picker
            placeholder="Дата окончания срока"
            [control]="tokenExpirationDate">
          </strict-date-time-picker>
        </div>
        <div class="col-auto">
          <button class="btn btn-secondary my-1 mr-2" (click)="generateRandomData()">Рандом</button>
          <button (click)="generateNewToken()" class="btn btn-primary my-1">Отправить токен</button>
        </div>
        <hr/>
      </div>
    </div>
    <div class="col-3">
      <div class="row">
        <div class="col-6">
          <app-strict-input-number [control]="userIdFilter" [placeholder]="'Id пользователя'">
          </app-strict-input-number>
        </div>
        <div class="col-6">
          <button (click)="search()" class="ml-4 btn btn-primary">Найти</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="result && result.results">
    <div class="row mt-3 font-weight-bold">
      <div class="col-1">User id</div>
      <div class="col-5">Токен</div>
      <div class="col-2">Дата окончания срока</div>
      <div class="col-3"></div>
    </div>
    <hr>
    <div class="row mt-3" *ngFor="let item of result.results">
      <div class="col-1">
        {{item.userId}}
      </div>
      <div class="col-5">
        {{item.token}}
      </div>
      <div class="col-2">
        {{item.expirationDate | moscowDateTime}}
      </div>
      <div class="col-3">
        <button (click)="removeToken(item.token)" class="btn btn-primary">Отозвать токен</button>
      </div>
    </div>
  </div>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
