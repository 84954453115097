<div>
  <div>
    <div class="row">
      <app-media-add-source class="col-6"></app-media-add-source>
      <app-media-add-channel class="col-6"></app-media-add-channel>
    </div>
    <hr>
  </div>
  <div>
    <div class="row">
      <app-media-add-btag class="col-6"></app-media-add-btag>
      <app-media-add-promo class="col-6"></app-media-add-promo>
    </div>
    <hr>
  </div>
  <div>
    <app-media-add-user-btag class="col-6"></app-media-add-user-btag>
  </div>
  <div>
    <app-client-registration></app-client-registration>
  </div>
</div>
