<h3>Пуш конверсия по видам спорта</h3>
<app-user-sport-stats-filter (wlOnSubmit)="load($event)"></app-user-sport-stats-filter>
<ng-container *ngIf="!!conversions">
  <div class="row">
    <div class="col-2">
      Пользователь
    </div>
    <div class="col-2">
      Вид спорта
    </div>
    <div class="col-4">
      Дата
    </div>
    <div class="col-2">
      Количество
    </div>
  </div>

  <div class="row" *ngFor="let elem of conversions">
    <div class="col-2">
      {{elem.userId}}
    </div>
    <div class="col-2">
      {{elem.sportType}}
    </div>
    <div class="col-4">
      {{elem.eventDate | dateOnly }}
    </div>
    <div class="col-2">
      {{elem.count}}
    </div>
  </div>
</ng-container>
