import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UpdateSecurityGroupViewModel} from '../../../security-groups/components/update-security-group-form/models/update-security-group.view-model';
import {SportTypeViewModel} from '../view-models/sport-type-view-model';
import { SportTypesService } from 'src/app/services/autogen/Dictionaries';
import { AddUpdateSportTypeForm } from 'src/app/services/autogen/Dictionaries';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-sport-type-update',
  templateUrl: './sport-type-update.component.html',
  styleUrls: ['./sport-type-update.component.css']
})
export class SportTypeUpdateComponent implements OnInit {

  @Output()
  public sportTypeChanged = new EventEmitter<void>();

  public loading = true;
  public viewModel = new SportTypeViewModel();

  constructor(private readonly _service: SportTypesService) { }

  ngOnInit(): void {
  }

  save() {
    const form = new AddUpdateSportTypeForm();
    form.id = this.viewModel.id.value;
    form.name = this.viewModel.name.value;

    this._service.addUpdateSportType(form).pipe(
      take(1)
    ).subscribe(() => this.sportTypeChanged.emit());
  }
}
