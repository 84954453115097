<app-loading *ngIf="loading"></app-loading>
<div class="row" *ngIf="!loading">
  <div class="col-12">
    <h1 class="mt-2">Список телефонов зарегистрированых на мок сервере</h1>
    <div class="form-group">
      <label>Номер</label>
      <app-strict-input-text [control]="this.currentFilter.phoneNumber">
      </app-strict-input-text>
    </div>
    <app-wl-index-paging [paging]="list" (pageChangedEvent)="onPageChanged($event)">
    </app-wl-index-paging>
    <app-phones-list-item *ngFor="let item of list.results" [phone]="item"></app-phones-list-item>
    <app-wl-index-paging [paging]="list" (pageChangedEvent)="onPageChanged($event)">
    </app-wl-index-paging>
  </div>
</div>
