import {Component, OnDestroy, OnInit} from '@angular/core';
import { CountriesService } from 'src/app/services/autogen/Dictionaries';
import {finalize, take} from 'rxjs/operators';
import { WlMockCountriesModel } from 'src/app/services/autogen/Dictionaries';

@Component({
  selector: 'app-countries-list',
  templateUrl: './countries-list.component.html',
  styleUrls: ['./countries-list.component.css']
})
export class CountriesListComponent implements OnInit, OnDestroy {

  loading: boolean;
  countries: WlMockCountriesModel[];

  constructor(private readonly _service: CountriesService) { }

  ngOnInit(): void {
    this.reload();
  }

  ngOnDestroy() {
  }

  reload() {
    this.loading = true;

    this._service.list().pipe(
      take(1),
      finalize(() => this.loading = false)
    ).subscribe(res => this.countries = res.result);
  }
}
