import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MediaRoutes} from './media.routes';
import {SharedModule} from '../shared/shared.module';
import {MediaLayoutComponent} from './media-layout/media-layout.component';
import {MediaSourceListComponent} from './media-lists/media-source-list/media-source-list.component';
import {MediaChannelListComponent} from './media-lists/media-channel-list/media-channel-list.component';
import {MediaAddChannelComponent} from './media-actions/media-add-channel/media-add-channel.component';
import {MediaActionsComponent} from './media-actions/media-actions';
import {MediaAddSourceComponent} from './media-actions/media-add-source/media-add-source.component';
import {MediaAddBtagComponent} from './media-actions/media-add-btag/media-add-btag.component';
import {MediaAddPromoComponent} from './media-actions/media-add-promo/media-add-promo.component';
import {MediaBtagListComponent} from './media-lists/media-btag-list/media-btag-list.component';
import {MediaPromoListComponent} from './media-lists/media-promo-list/media-promo-list.component';
import {MediaControlComponent} from './media-layout/media-control/media-control.component';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {EventsModule} from '../events/events.module';
import {MediaAddUserBtagComponent} from './media-actions/media-add-user-btag/media-add-user-btag.component';
import {GaCommonModule} from '@koddington/ga-common';


@NgModule({
  declarations: [
    MediaActionsComponent,
    MediaLayoutComponent,
    MediaSourceListComponent,
    MediaChannelListComponent,
    MediaAddChannelComponent,
    MediaAddSourceComponent,
    MediaAddBtagComponent,
    MediaAddPromoComponent,
    MediaBtagListComponent,
    MediaPromoListComponent,
    MediaControlComponent,
    MediaAddUserBtagComponent
  ],
  exports: [
    MediaActionsComponent,
    MediaLayoutComponent,
    MediaSourceListComponent,
    MediaChannelListComponent,
    MediaAddChannelComponent,
    MediaAddSourceComponent,
    MediaAddBtagComponent,
    MediaAddPromoComponent,
    MediaBtagListComponent,
    MediaPromoListComponent,
    MediaControlComponent,
    MediaAddUserBtagComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(MediaRoutes),
    NgxDaterangepickerMd.forRoot(),
    SharedModule,
    EventsModule,
    GaCommonModule
  ],
  providers: []
})
export class MediaModule {
}
