<div class="row">
  <div class="col-5">
    <app-strict-input-number [control]="viewModel.userId" placeholder="Id пользователя"></app-strict-input-number>
  </div>
  <div class="col-5">
    <app-strict-input-number [control]="viewModel.sportId" placeholder="Id вида спорта"></app-strict-input-number>
  </div>
  <div class="col-2">
    <button class="btn btn-primary" (click)="submit()">Поиск</button>
  </div>
</div>
