import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SportMatchesEventsListComponent} from './sport-matches-events/sport-matches-events-list/sport-matches-events-list.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {EventsNotificationsRoutes} from './events-notifications.routes';
import { SportMatchesEventsAddComponent } from './sport-matches-events/sport-matches-events-add/sport-matches-events-add.component';
import {GaCommonModule} from '@koddington/ga-common';
import { EventNotificationsAreaCodegenAppModule } from '../../services/autogen/EventNotifications';



@NgModule({
  declarations: [
    SportMatchesEventsListComponent,
    SportMatchesEventsAddComponent
  ],
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    RouterModule.forChild(EventsNotificationsRoutes),
    GaCommonModule,
    EventNotificationsAreaCodegenAppModule
  ]
})
export class EventsNotificationsModule { }
