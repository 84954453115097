<div>
  <h4>
    Ставки X50
  </h4>
</div>
<div>
  <app-x50-bets-search (betsList)="resultsHandler($event)" (loading)="loadingStatus($event)" [offset]="offset">
  </app-x50-bets-search>
</div>
<div>
  <div *ngIf="loading">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!loading">
    <table class="table">
      <tr class="row table-info">
        <td class="col-1">
          Id
        </td>
        <td class="col-2">
          Id пользователя
        </td>
        <td class="col-1">
          Id тура
        </td>
        <td class="col-1">
          Id ставки
        </td>
        <td class="col-1">
          Дата ставки
        </td>
        <td class="col-2">
          Сумма ставки
        </td>
        <td class="col-2">
          Выплата
        </td>
        <td class="col-1">
          Результат
        </td>
        <td class="col-1">
          Топ X%
        </td>
      </tr>
      <tr class="row" *ngFor="let bet of result.results">
        <td class="col-1">
          {{bet.id}}
        </td>
        <td class="col-2">
          {{bet.userId}}
        </td>
        <td class="col-1">
          {{bet.tourId}}
        </td>
        <td class="col-1">
          {{bet.betId}}
        </td>
        <td class="col-1">
          {{bet.betDate | moscowDateTime}}
        </td>
        <td class="col-2">
          {{bet.betSum}}
        </td>
        <td class="col-2">
          {{bet.betResultSum}}
        </td>
        <td class="col-1">
          {{bet.result}}
        </td>
        <td class="col-1">
          {{bet.topPercent}}
        </td>
      </tr>
    </table>
  </div>
</div>
<div *ngIf="!loading">
  <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
