import { Component, OnInit } from '@angular/core';
import { GaPagedResult, GaPagingForm } from '@koddington/ga-common';
import { BattlePassFreeBetsService, WlBattlePassFreeBetSequenceViewModel } from 'src/app/services/autogen/BattlePass';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
    selector: 'app-battle-pass-free-bet-sequences',
    templateUrl: './battle-pass-free-bet-sequences.component.html',
    styleUrls: ['./battle-pass-free-bet-sequences.component.css']
})
export class BattlePassFreeBetSequencesComponent implements OnInit {
    public loading = true;
    public pagedResult: GaPagedResult<WlBattlePassFreeBetSequenceViewModel>;

    private filter = new GaPagingForm();

    constructor(private readonly service: BattlePassFreeBetsService,
                private readonly router: Router) {
    }

    public ngOnInit(): void {
        this.load();
    }

    public navigateToProcessingRequest(id: number): void {
        this.router.navigate(['/dashboard/battle-pass/main/free-bets-requests', id]);
    }

    private load(): void {
        this.loading = true;

        this.service.listSequences(this.filter).pipe(
            take(1),
            untilDestroyed(this)
        ).subscribe(u => {
                this.pagedResult = u;
                this.filter = u.paging;

                this.loading = false;
            }
        );
    }

    public pageChanged(form: GaPagingForm): void {
        this.filter = form;
        this.load();
    }
}
