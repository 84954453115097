import {AbstractModelValidator} from '@koddington/ga-common';
import {Injectable} from '@angular/core';
import {UsersWithoutLoginsViewModel} from '../../../models/users/users-without-logins-view-model';
import {UserListViewModel} from '../../../models/users/user-list-view-model';

@Injectable()
export class UsersWithoutLoginsValidator  extends AbstractModelValidator<UserListViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).maxLength(9);
  }
}
