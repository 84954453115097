import { AbstractModelValidator } from '@koddington/ga-common';
import { BetCalculationLineViewModel } from '../../../models/events/components-models/bet-calculation-line-view-model';
import { StrictValidationRules } from '../../shared/validation-rules/strict-validation-rules';
import isDigit = StrictValidationRules.isDigit;

export class BetCalculationLineValidator  extends AbstractModelValidator<BetCalculationLineViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.id)
            .required('Поле обязательно');


        this.ruleForControl(m => m.lineRate)
            .required('Введите коэффициент линии')
            .min(0.01, 'Коэффициент должен быть больше 0')
            .customRule(u => isDigit(u));
    }
}
