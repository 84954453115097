import {Component, OnInit} from '@angular/core';
import { WlUser } from 'src/app/services/autogen/Users';
import { UsersService } from 'src/app/services/autogen/Users';
import {debounceTime, distinctUntilChanged, take, tap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UsersListByIdForm } from 'src/app/services/autogen/Users';
import {UserListViewModel} from '../../../../models/users/user-list-view-model';
import {GaPagedResult} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {
  public model: UserListViewModel = new UserListViewModel();
  public users: WlUser[] = [];
  public result: GaPagedResult<WlUser>;

  ngOnInit(): void {
    this.model.userId.strictValueSource.pipe(
      tap(),
      debounceTime(300),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(() => {
      this.model.offset.strictValue = 0;
      this.search();
    });
  }

  constructor(private readonly service: UsersService) {
  }

  createForm(): UsersListByIdForm {
    const form = new UsersListByIdForm();
    form.userId = this.model.userId.strictValue;
    form.offset = this.model.offset.strictValue;
    form.count = this.model.count.strictValue;

    return form;
  }
  load(form: UsersListByIdForm): void {

    this.service.list(form)
      .pipe(take(1),
            untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.users = this.result.results;
      });
  }

  userAdded(result: boolean): void {
    if (result) {
      const form = this.createForm();
      this.load(form);
    }
  }
  pageChanged(form: UsersListByIdForm): void {
    this.load(form);
  }
  search(): void {
    const form = this.createForm();

    this.load(form);
  }
}
