<h1>Профили пользователей с сегментами не по умолчанию</h1>
<div>
  <div *ngIf="isLoading">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!isLoading">
    <table class="table">
      <tr class="row table-info">
        <td class="col-4">Id пользователя</td>
        <td class="col-4">Время синхронизации</td>
      </tr>
      <tr *ngFor="let profile of profiles" class="d-flex">
        <td class="col-4">
          {{profile.userId}}
        </td>
        <td class="col-4">
          {{profile.syncDate}}
        </td>
      </tr>
    </table>
    <div>
      <ga-paging (pageChangedEvent)="changePage($event)" [pagedResult]="currentResult"></ga-paging>
    </div>
  </div>
</div>
