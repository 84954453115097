import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class UserSportStatsFilterViewModel {
  userId: StrictFormControl<number>;
  sportId: StrictFormControl<number>;

  constructor() {
    this.userId = StrictFormControlBuilder.buildNullNumber();
    this.sportId = StrictFormControlBuilder.buildNullNumber();
  }
}
