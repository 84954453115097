import {CreateAccountViewModel} from './create-account.view-model';
import {Injectable} from '@angular/core';
import {AbstractModelValidator} from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class CreateFormValidator extends AbstractModelValidator<CreateAccountViewModel>{
  constructor() {
    super();

    this.ruleForControl(u => u.login).required();
    this.ruleForControl(u => u.password).required();
    this.ruleForControl(u => u.type).required();
  }
}
