import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-face-it-layout',
  templateUrl: './face-it-layout.component.html',
  styleUrls: ['./face-it-layout.component.scss']
})
export class FaceItLayoutComponent implements OnInit, OnDestroy {
  constructor() {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }
}
