import { IDropdownStrategy } from './i-dropdown-strategy';
import { Observable, of } from 'rxjs';
import { DropdownItem } from '../../../../../models/common/enum-dropdown/dropdown-item';
import { EnumExtensions, isNullOrUndefined } from '@koddington/ga-common';
import {WlTournamentType, WlTournamentTypeExtensions} from 'src/app/services/autogen/CustomTournaments';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WlCustomTournamentTypesStrategy implements IDropdownStrategy<WlTournamentType> {
  convert(model: WlTournamentType): DropdownItem<WlTournamentType> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlTournamentType>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlTournamentTypeExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlTournamentType>> {
    const types = EnumExtensions.toArray(WlTournamentType);
    return of(types);
  }
}
