import {Component, OnInit} from '@angular/core';
import { WlMatchTeam } from 'src/app/services/autogen/Dictionaries';
import {GaMessagingService, GaPagedResult, GaPagingForm} from '@koddington/ga-common';
import { MatchTeamsService } from 'src/app/services/autogen/Dictionaries';
import {filter, finalize, first, map} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-match-teams-list',
  templateUrl: './match-teams-list.component.html',
  styleUrls: ['./match-teams-list.component.css']
})
export class MatchTeamsListComponent implements OnInit {

  public loading = true;
  public model: GaPagedResult<WlMatchTeam>;

  private readonly pageSize = 30;

  constructor(private readonly _apiClient: MatchTeamsService,
              private readonly _messages: GaMessagingService) {
  }

  public ngOnInit(): void {
    this.loadList(0);
  }

  public onPageChanged(form: GaPagingForm): void {
    this.loadList(form.offset);
  }

  public onDeleteClick(id: number): void {
    this._apiClient.delete(id).pipe(
      first(),
      filter(u => !this._messages.tryShowError(u)),
      untilDestroyed(this)
    ).subscribe(() => {
      this._messages.showMessage('Команда удалена');
      this.loadList(0);
    });
  }

  private loadList(offset: number): void {
    const filterForm = new GaPagingForm();
    filterForm.offset = offset;
    filterForm.count = this.pageSize;

    this.loading = true;
    this._apiClient.list(filterForm)
      .pipe(
        first(),
        filter(u => !this._messages.tryShowError(u)),
        map(u => u.result),
        finalize(() => this.loading = false),
        untilDestroyed(this)
      )
      .subscribe((u) => {
        this.model = u;
      });
  }
}
