import { Component, OnInit } from '@angular/core';
import {FavouriteNationalTeamViewModel} from '../../../../models/events/components-models/FavouriteNationalTeamViewModel';
import { EventsService } from 'src/app/services/autogen/Events';
import {filter, first, map} from 'rxjs/operators';
import { FavouriteNationalTeamChangeForm } from 'src/app/services/autogen/Events';
import {GaMessagingService, GaPanelGridConfig, GaTitlePositions} from '@koddington/ga-common';

@Component({
  selector: 'app-add-favourite-national-team-changes',
  templateUrl: './add-favourite-national-team-changes.component.html',
  styleUrls: ['./add-favourite-national-team-changes.component.css']
})
export class AddFavouriteNationalTeamChangesComponent implements OnInit {

  viewModel = new FavouriteNationalTeamViewModel();
  config = new GaPanelGridConfig();

  constructor(private _service: EventsService,
              private _messaging: GaMessagingService) { }

  ngOnInit(): void {
    this.buildGrid();
  }

  public changeTeam(): void {
    const form = new FavouriteNationalTeamChangeForm();
    form.teamId = this.viewModel.teamId.strictValue;
    form.userId = this.viewModel.userId.strictValue;
    form.changeDate = this.viewModel.changeDate.strictValue;

    this._service.favoriteNationalTeamChange(form).pipe(
      first(),
      filter(value => !this._messaging.tryShowError(value)),
      map(value => this._messaging.showMessage('Любимая сборная успешна изменена'))
    ).subscribe();
  }

  private buildGrid() {
    this.config.columnsCount = 5;
    this.config.titlePositions = GaTitlePositions.Top;
    this.config.addNumbers(this.viewModel.userId, 'Id пользователя')
      .addNumbers(this.viewModel.teamId, 'Id команды')
      .addDateTime(this.viewModel.changeDate, 'Дата смены');
  }
}
