import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class AddSportMatchEventViewModel {
  matchId = StrictFormControlBuilder.buildNullNumber();
  type = StrictFormControlBuilder.buildNullNumber();
  subtype = StrictFormControlBuilder.buildNullNumber();
  eventDate = StrictFormControlBuilder.buildNullDayjs();
  sportId = StrictFormControlBuilder.buildNullNumber();
  sportName = StrictFormControlBuilder.buildNullString();
  countryId = StrictFormControlBuilder.buildNullNumber();
  countryName = StrictFormControlBuilder.buildNullString();
  championshipId = StrictFormControlBuilder.buildNullNumber();
  championshipName = StrictFormControlBuilder.buildNullString();
  date = StrictFormControlBuilder.buildNullDayjs();
  firstPlayer = StrictFormControlBuilder.buildNullString();
  secondPlayer = StrictFormControlBuilder.buildNullString();
  currentScore = StrictFormControlBuilder.buildNullString();
  currentPeriodScore = StrictFormControlBuilder.buildNullString();
  eventId = StrictFormControlBuilder.buildNullString();
  rabbitSentDate = StrictFormControlBuilder.buildNullDayjs();
  winner = new StrictFormControl<number>(0);
}
