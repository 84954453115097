import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-rate-race-layout',
  templateUrl: './rate-race-layout.component.html',
  styleUrls: ['./rate-race-layout.component.scss']
})
export class RateRaceLayoutComponent implements OnInit, OnDestroy {
  constructor() {
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
  }
}
