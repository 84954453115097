import {Component, OnDestroy, OnInit} from '@angular/core';
import { MediaService } from 'src/app/services/autogen/Shared';
import {filter, first, map} from 'rxjs/operators';
import { GaMessagingService, StrictError, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

@Component({
  selector: 'app-media-control',
  templateUrl: './media-control.component.html',
  styleUrls: ['./media-control.component.css']
})
export class MediaControlComponent implements OnInit, OnDestroy {

  public userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  constructor(private _service: MediaService,
              private _messaging: GaMessagingService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  drop(): void {
    if (!this.userId.hasStrictValue || this.userId.strictValue < 1) {
      this.userId.addStrictErrors([new StrictError('invalidUserId', 'Неверное значение поля')]);
      return;
    }

    this._service.dropUserInfo(this.userId.strictValue).pipe(
      first(),
      filter(value => !this._messaging.tryShowError(value)),
      map(value => this._messaging.showMessage('Данные пользователя сброшены'))
    ).subscribe();
  }
}
