<form (submit)="save()" style="width: 500px">
  <h1 class="mt-2">Обновление стран</h1>
  <div class="form-group">
    <label>id</label>
    <app-strict-input-number [control]="this.viewModel.id">
    </app-strict-input-number>
  </div>
  <div class="form-group">
    <label>Название на русском</label>
    <app-strict-input-text [control]="this.viewModel.nameRu">
    </app-strict-input-text>
  </div>
  <div class="form-group">
    <label>Название на английском</label>
    <app-strict-input-text [control]="this.viewModel.nameEn">
    </app-strict-input-text>
  </div>
  <button type="submit" class="btn btn-primary">Сохранить</button>
</form>
