import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MockLogoServiceImagesViewModel } from 'src/app/services/autogen/LogoService';
import { LogoServiceService } from 'src/app/services/autogen/LogoService';
import { MockLogoServicePagingForm } from 'src/app/services/autogen/LogoService';
import {
    formatDateTime, GaPagedResult, GaPagingForm,
    GaTableCellTemplate, GaTableData, isNullOrUndefined
} from '@koddington/ga-common';
import { MockLogoServiceEntityTypesExtensions } from 'src/app/services/autogen/LogoService';
import { GaMessagingService } from '@koddington/ga-common';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';

@UntilDestroy()
@Component({
    selector: 'app-logo-service-items-list',
    templateUrl: './logo-service-items-list.component.html'
})
export class LogoServiceItemsListComponent implements OnInit {
    @ViewChild('itemActions') itemActions: TemplateRef<any>;
    public loading = true;
    public pagedResult: GaPagedResult<MockLogoServiceImagesViewModel>;
    public tableData: GaTableData<MockLogoServiceImagesViewModel>;

    private filter = new MockLogoServicePagingForm();

    constructor(private readonly service: LogoServiceService,
                private readonly router: Router,
                private readonly route: ActivatedRoute,
                private readonly messaging: GaMessagingService,
                private readonly navigation: SearchNavigationService) {
    }

    public ngOnInit(): void {
        this.route.queryParams.pipe(
            take(1)
        ).subscribe(u => this.init(u));
    }

    private load(): void {
        this.loading = true;

        this.service.list(this.filter).pipe(
            take(1),
            untilDestroyed(this)
        ).subscribe(u => {
                this.pagedResult = u;
                this.mapToTable(u.results);

                this.loading = false;
            }
        );
    }

    public showImage(item: MockLogoServiceImagesViewModel): void {
        this.service.urlForItemGet(item.type, item.itemId).pipe(
            take(1),
            filter(u => !this.messaging.tryShowError(u))
        ).subscribe(u => window.open(u.result, '_blank'));
    }

    public editImage(id: number): void {
        this.router.navigate(['dashboard/logo-service/main/update', id]);
    }

    public asLogoServiceItem(rawItem: any): MockLogoServiceImagesViewModel {
        return rawItem as MockLogoServiceImagesViewModel;
    }

    public pageChanged(form: GaPagingForm): void {
        this.filter.offset = form.offset;
        this.filter.count = form.count;
        this.navigateWithParameters();
    }

    private mapToTable(result: MockLogoServiceImagesViewModel[]) {
        this.tableData = new GaTableData<MockLogoServiceImagesViewModel>()
            .setData(result)
            .addSimpleColumn((elem) => elem.id, { title: 'Id', widthSize: 60 })
            .addSimpleColumn((elem) => MockLogoServiceEntityTypesExtensions.format(elem.type), { title: 'Тип сущности', widthSize: 200 })
            .addSimpleColumn((elem) => elem.itemId, {title: 'Id сущности'})
            .addSimpleColumn(elem => elem.imageUrl, { title: 'Url', widthSize: 720 })
            .addSimpleColumn((elem) => formatDateTime(elem.crmUpdateTime), { title: 'Обновлено CRM', widthSize: 130 })
            .addSimpleColumn((elem) => formatDateTime(elem.mockUpdateTime), { title: 'Обновлено Mock', widthSize: 130 })
            .addTemplateColumn(new GaTableCellTemplate(this.itemActions, (elem) => {
                return {
                    item: elem
                };
            }), {title: 'Действия', widthSize: 400 });
    }

    private init(params: Params) {
        this.filter.offset = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.filter.count = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
        if (!isNullOrUndefined(params['matchId'])) {
            this.filter.itemId = Number(params['itemId']);
        }

        if (!isNullOrUndefined(params['entityType'])) {
            this.filter.entityType = Number(params['entityType']);
        }

        this.load();
    }

    private navigateWithParameters(): void {
        const params: Params = {
            offset: this.filter.offset,
            count: this.filter.count ? this.filter.count : 30,
            itemId: this.filter.itemId,
            entityType: this.filter.entityType
        };

        this.navigation.search(this.route, params);
    }
}
