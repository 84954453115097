import {Component, OnInit} from '@angular/core';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import {TriggerMechanicViewModel} from '../models/trigger-mechanic-view-model';
import {filter, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsService, TriggerEnabledMechanicForm } from 'src/app/services/autogen/Events';
import {TriggerEnabledMechanicValidator} from '../../../validators/trigger-enabled-mechanic-validator';
import { Dayjs } from 'dayjs';


@UntilDestroy()
@Component({
  selector: 'app-trigger-enabled-user-mechanic',
  templateUrl: './trigger-enabled-user-mechanic.component.html',
  styleUrls: ['./trigger-enabled-user-mechanic.component.css']
})
export class TriggerEnabledUserMechanicsComponent implements OnInit {

  private readonly model: TriggerMechanicViewModel = new TriggerMechanicViewModel();
  private readonly _validator: TriggerEnabledMechanicValidator = new TriggerEnabledMechanicValidator();

  constructor(private readonly _service: EventsService,
              private readonly _messaging: GaMessagingService,
              ) {
  }

  ngOnInit(): void {
  }

  get mechanicId(): StrictFormControl<number> {
    return this.model.mechanicId;
  }
  get mechanicTriggeredTime(): StrictFormControl<Dayjs> {
    return this.model.mechanicTriggeredTime;
  }

  public save(): void {
    const errors = this._validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new TriggerEnabledMechanicForm();
    form.mechanicId = this.mechanicId.strictValue;
    form.mechanicTriggeredTime = this.mechanicTriggeredTime.strictValue;

    this._service.triggerEnabledMechanicForUser(form)
      .pipe(
        take(1),
        filter(value => !this._messaging.tryShowError(value)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this._messaging.showMessage('Механика сработала');
      });
  }
}
