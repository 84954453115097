import { IDropdownStrategy } from './i-dropdown-strategy';
import { WlTournamentPrizeFundTypes, WlTournamentPrizeFundTypesExtensions } from '../../../../../services/autogen/CustomTournaments';
import { DropdownItem } from '../../../../../models/common/enum-dropdown/dropdown-item';
import { EnumExtensions, isNullOrUndefined } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WlTournamentPrizeFundTypesStrategy implements IDropdownStrategy<WlTournamentPrizeFundTypes> {
    convert(model: WlTournamentPrizeFundTypes): DropdownItem<WlTournamentPrizeFundTypes> {
        if (isNullOrUndefined(model)) {
            return undefined;
        }
        const item = new DropdownItem<WlTournamentPrizeFundTypes>();
        item.id = model.toString();
        item.entity = model;
        item.title = WlTournamentPrizeFundTypesExtensions.format(model);

        return item;
    }

    getEntities(): Observable<Array<WlTournamentPrizeFundTypes>> {
        const types = EnumExtensions.toArray(WlTournamentPrizeFundTypes);
        return of(types);
    }
}
