import {IDropdownStrategy} from './i-dropdown-strategy';
import {
  WinlineBonusUsingStatus,
  WinlineBonusUsingStatusExtensions
} from 'src/app/services/autogen/UserMechanics';
import {EnumExtensions} from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Injectable} from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MechanicBonusUsingRequestStatusDropdownStrategy implements IDropdownStrategy<WinlineBonusUsingStatus> {
  public getEntities(): Observable<Array<WinlineBonusUsingStatus>> {
    return of(EnumExtensions.toArray(WinlineBonusUsingStatus));
  }

  public convert(model: WinlineBonusUsingStatus): DropdownItem<WinlineBonusUsingStatus> {
    if (isNullOrUndefined(model)) {
      return {
        entity: null,
        title: 'Не выбрано',
        id: ''
      };
    }

    return {
      entity: model,
      title: WinlineBonusUsingStatusExtensions.format(model),
      id: Number(model).toString()
    };
  }
}
