<h1 style="padding: inherit">Последовательности</h1>
<div *ngIf="loading" class="loading">
  <app-loading></app-loading>
</div>

<div class="container-fluid" *ngIf="!loading">

  <table class="table">
    <tr class="row table-info">
      <td class="col-1">
        Id
      </td>
      <td class="col-1">
        Id задачи
      </td>
      <td class="col-2">
        Дата создания последовательности
      </td>
      <td class="col-2">
        Дата начала последовательности
      </td>
      <td class="col-2">
        Дата окончания последовательности
      </td>
      <td class="col-2">
        Тип последовательности
      </td>
    </tr>

    <tr *ngFor="let sequence of model.results" class="row text-info">
      <td class="col-1">
        <a [routerLink]="[listRoute, sequence.id]"> {{sequence.id}}</a>
      </td>
      <td class="col-1">
        {{sequence.jobId}}
      </td>
      <td class="col-2">
        {{sequence.createTime | dateTime}}
      </td>
      <td class="col-2">
        {{sequence.executionStartTime | dateTime}}
      </td>
      <td class="col-2">
        {{sequence.executionEndTime | dateTime}}
      </td>
      <td class="col-2">
        {{sequence.sequencesType | wlCallSequencesTypeTransform}}
      </td>
    </tr>
  </table>

  <ga-paging [pagedResult]="model" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
