<label class="mt-2">Ставка купона x5</label>
<div>
  <div class="row mt-2">
    <div class="col-3">
      Id пользователя
    </div>
    <div class="col-3">
      Дата
    </div>
    <div class="col-3">
      ID купона
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <app-strict-input-number [control]="userId" [placeholder]="'Id пользователя'">
      </app-strict-input-number>
    </div>
    <div class="col-3">
      <strict-date-time-picker [control]="date"></strict-date-time-picker>
    </div>
    <div class="col-3">
      <app-strict-input-number [control]="couponId" placeholder="Id купона">
      </app-strict-input-number>
    </div>
  </div>
  <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
