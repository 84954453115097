<h1>Запросы на выдачу фрибетов по БП для последовательности {{sequenceId}}</h1>
<table class="table mt-3">
    <tr class="d-flex">
        <td class="col-1">
            Id
        </td>
        <td class="col-1">
            ID пользователя
        </td>
        <td class="col-1">
            ID операции
        </td>
        <td class="col-1">
            ID фрибета WL
        </td>
        <td class="col-1">
            Сумма
        </td>
        <td class="col-2">
            Дата последней обработки
        </td>
        <td class="col-1">
            ID выданного фрибета
        </td>
        <td class="col-2">
            Статус
        </td>
        <td class="col-2">
            <app-enum-dropdown [strategy]="strategy"
                               [options]="dropDownOptions"
                               [entityContainer]="allStatesControl"></app-enum-dropdown>
            <button class="btn btn-primary" (click)="setStatusForAll()">Сохранить для всех</button>
        </td>
    </tr>

    <tr *ngFor="let result of results" class="d-flex">
        <td class="col-1">
            {{result.request.id}}
        </td>
        <td class="col-1">
            {{result.request.userId}}
        </td>
        <td class="col-1">
            {{result.request.operationId}}
        </td>
        <td class="col-1">
            {{result.request.winlineFreeBetId}}
        </td>
        <td class="col-1">
            {{result.request.amount}}
        </td>
        <td class="col-2">
            {{result.request.updatedAt | dateTime}}
        </td>
        <td class="col-1">
            {{result.request.freeBetId}}
        </td>
        <td class="col-2">
            <app-enum-dropdown [options]="dropDownOptions"
                               [strategy]="strategy"
                               [entityContainer]="result.newStatus"></app-enum-dropdown>
            <button class="btn btn-primary" (click)="saveNewStatus(result)">Сохранить</button>
        </td>
    </tr>
</table>
