import {IDropdownStrategy} from './i-dropdown-strategy';
import {WlRewardExternalResponseTypes, WlRewardExternalResponseTypesExtensions} from 'src/app/services/autogen/BattlePass';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Observable, of} from 'rxjs';
import {EnumExtensions, isNullOrUndefined} from '@koddington/ga-common';

export class WlRewardExternalResponseTypesStrategy implements IDropdownStrategy<WlRewardExternalResponseTypes> {

  convert(model: WlRewardExternalResponseTypes): DropdownItem<WlRewardExternalResponseTypes> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlRewardExternalResponseTypes>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlRewardExternalResponseTypesExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlRewardExternalResponseTypes>> {
    const types = EnumExtensions.toArray(WlRewardExternalResponseTypes);
    return of(types);
  }
}
