import {Component, OnDestroy, OnInit} from '@angular/core';
import { GaMessagingService, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { UsersService } from 'src/app/services/autogen/Users';
import {filter, first, map} from 'rxjs/operators';

@Component({
  selector: 'app-user-video-verification',
  templateUrl: './user-video-verification.component.html',
  styleUrls: ['./user-video-verification.component.css']
})
export class UserVideoVerificationComponent implements OnInit, OnDestroy {

  public userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();

  constructor(private _usersService: UsersService,
              private _messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }


  reset(): void {
    if (!this.userId.hasStrictValue) {
      return;
    }
    this._usersService.resetUserVerificationAttempts(this.userId.strictValue).pipe(
      first(),
      filter(value => !this._messaging.tryShowError(value)),
      map(() => this._messaging.showMessage('Попытки видеоверификации пользователя обнулены')))
      .subscribe();
  }
}
