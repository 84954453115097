<h1 style="padding: inherit">Список причин для обзвона</h1>
<div *ngIf="loading" class="loading">
  <app-loading></app-loading>
</div>

<div class="container-fluid" *ngIf="!loading">

  <table class="table">
    <tr class="row table-info">
      <div class="col-1">
        Id пользователя
      </div>
      <div class="col-1">
        Id цепочки
      </div>
      <div class="col-1">
        Id механики
      </div>
      <div class="col-1">
        Id скрипта
      </div>
      <div class="col-2">
        Источник обзвона
      </div>
      <div class="col-2">
        Время добавления
      </div>
      <div class="col-2">
        Время удаления
      </div>
      <div class="col-1">
        Удален
      </div>
    </tr>

    <tr *ngFor="let reason of model?.results" class="row text-info">
      <div class="col-1">
        {{reason.userId}}
      </div>
      <div class="col-1">
        {{reason.userTriggerId}}
      </div>
      <div class="col-1">
        {{reason.mechanicConnectFactId}}
      </div>
      <div class="col-1">
        {{reason.callScriptExternalId}}
      </div>
      <div class="col-2">
        {{broadcastSourceTypeToString(reason.sourceType)}}
      </div>
      <div class="col-2">
        {{reason.createdTime | dateTime}}
      </div>
      <div class="col-2">
        {{reason.deletedTime | dateTime}}
      </div>
      <div class="col-1">
        {{reason.isDeleted}}
      </div>
    </tr>
  </table>

  <ga-paging [pagedResult]="model" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
