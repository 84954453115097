<form (submit)="save()" style="width: 500px">
  <h1 class="mt-2">Обновление группы СБ</h1>
  <div class="form-group">
    <label>Id Winline (внешний)</label>
    <app-strict-input-number [control]="this.viewModel.id">
    </app-strict-input-number>
  </div>
  <div class="form-group">
    <label>Имя</label>
    <app-strict-input-text [control]="this.viewModel.name">
    </app-strict-input-text>
  </div>
  <div class="form-group">
    <label>Хорошая</label>
    <app-strict-input-boolean [control]="this.viewModel.isGood">
    </app-strict-input-boolean>
  </div>
  <div class="form-group">
    <label>Тестовая</label>
    <app-strict-input-boolean [control]="this.viewModel.isTest">
    </app-strict-input-boolean>
  </div>
  <button type="submit" class="btn btn-primary">Сохранить</button>
</form>

