import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {finalize, take} from 'rxjs/operators';
import { WlCallUserReasons } from 'src/app/services/autogen/CallCenter';
import { CallReasonsService } from 'src/app/services/autogen/CallCenter';
import {WlBroadcastSourceTypeExtensions, WlBroadcastSourceType } from '../../../../services/autogen/CallCenter';
import {Observable} from 'rxjs';
import {WlResult} from '../../../../models/common/results/wl-result';
import {GaPagedResult, GaPagingForm, StrictFormControl} from '@koddington/ga-common';

@Component({
  selector: 'app-call-reasons-list',
  templateUrl: './call-reasons-list.component.html',
  styleUrls: ['./call-reasons-list.component.css']
})
export class CallReasonsListComponent implements OnInit, OnDestroy {

  @Input()
  public isHistory: boolean;
  @Input()
  public offset: StrictFormControl<number> = new StrictFormControl<number>(0);

  public loading: boolean;
  private model: GaPagedResult<WlCallUserReasons>;
  private query: Observable<WlResult<GaPagedResult<WlCallUserReasons>>>;

  constructor(private readonly service: CallReasonsService) {
  }

  public ngOnDestroy(): void {
  }

  public ngOnInit(): void {
    this.load(this.offset.strictValue);
  }

  public load(offset: number): void {
    this.loading = true;
    const form = new GaPagingForm();
    form.offset = offset;

    if (this.isHistory) {
      this.query = this.service.history(form);
    } else {
      this.query = this.service.actualList(form);
    }

    this.query.pipe(
      take(1),
      finalize(() => this.loading = false)
    ).subscribe(value => this.model = value.result);
  }

  public pageChanged(form: GaPagingForm): void {
    this.offset.strictValue = form.offset;
  }

  public broadcastSourceTypeToString(sourceType: WlBroadcastSourceType) {
    return WlBroadcastSourceTypeExtensions.format(sourceType);
  }
}

