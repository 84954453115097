<h3>Очередь запросов на обработку механик (100 - подключение механики, 101 - срабатывание механики, 102 - использование
  бонуса)</h3>
<div *ngIf="loading">
  <app-loading></app-loading>
</div>
<div *ngIf="!loading">
  <table class="table table-hover mt-3">
    <thead>
    <tr class="row table-info">
      <td class="col-1">
        Id
      </td>
      <td class="col-1">
        Id запроса пуш сервера
      </td>
      <td class="col-1">
        Дата запроса
      </td>
      <td class="col-2">
        Обработан винлайном
      </td>
      <td class="col-2">
        Обработан пуш сервером
      </td>
      <td class="col-2">
        Номер очереди
      </td>
    </tr>
    </thead>

    <tbody>
    <tr (click)="navigateToProcessingRequest(sequence.id, sequence.queueNumber)"
        *ngFor="let sequence of resultModel.results" class="row" style="cursor: pointer;">
      <td class="col-1">
        {{sequence.id}}
      </td>
      <td class="col-1">
        {{sequence.jobRequestId}}
      </td>
      <td class="col-1">
        {{sequence.creationDate | dateTime}}
      </td>
      <td class="col-2">
        {{sequence.processedByWinline}}
      </td>
      <td class="col-2">
        {{sequence.processedByPushServer}}
      </td>
      <td class="col-2">
        {{sequence.queueNumber}}
      </td>
    </tr>
    </tbody>
  </table>
  <ga-paging [pagedResult]="resultModel" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
