import {Routes} from '@angular/router';
import {BroadcastsStartComponent} from '../broadcasts/broadcasts-start/broadcasts-start.component';
import {BroadcastsLayoutComponent} from '../broadcasts/broadcasts-layout/broadcasts-layout.component';
import {SportMatchesEventsListComponent} from './sport-matches-events/sport-matches-events-list/sport-matches-events-list.component';

export const EventsNotificationsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'sport-matches-events',
        component: SportMatchesEventsListComponent
      }
    ]
  }
];
