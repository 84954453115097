import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class UserMechanicsNavigationService {

  constructor(private readonly router: Router) {
  }

  navigateToMechanicsEnablingRequest(sequenceId: number): void {
    this.router.navigate(['/dashboard/mechanics/enabling-requests', sequenceId]);
  }

  navigateToTriggeringRequest(sequenceId: number): void {
    this.router.navigate(['/dashboard/mechanics/triggering-requests', sequenceId]);
  }

  navigateToBonusUsingRequest(sequenceId: number): void {
    this.router.navigate(['/dashboard/mechanics/bonus-using-requests', sequenceId]);
  }
}
