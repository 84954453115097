<form (submit)="save()" style="width: 500px">
    <h1 class="mt-2">Обновление маркетов</h1>
    <div class="form-group">
        <label>id</label>
        <app-strict-input-number [control]="this.viewModel.id">
        </app-strict-input-number>
    </div>
    <div class="form-group">
        <label>Название</label>
        <app-strict-input-text [control]="this.viewModel.name">
        </app-strict-input-text>
    </div>
    <div class="form-group">
        <label>Удалён?</label>
        <app-strict-input-boolean [control]="this.viewModel.isDeleted">
        </app-strict-input-boolean>
    </div>
    <button type="submit" class="btn btn-primary">Сохранить</button>
</form>
