import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
    selector: 'app-rate-us-layout',
    templateUrl: './rate-us-layout.component.html'
})
export class RateUsLayoutComponent implements OnInit, OnDestroy {
    constructor() {
    }

    public ngOnInit(): void {
    }

    public ngOnDestroy(): void {
    }
}
