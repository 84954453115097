<label class="mt-2">Выдача наград пользователю</label>
<div class="row">
  <div class="col-2">
    User Id
  </div>
  <div class="col-2">
    Id награды
  </div>
  <div class="col-2">
    Payload
  </div>
</div>
<div class="mt-2 row">
  <app-strict-input-number class="col-2" placeholder="User Id"
                           [control]="accrueRewardForm.userId">
  </app-strict-input-number>
  <app-strict-input-number class="col-2" placeholder="id награды"
                           [control]="accrueRewardForm.rewardId">
  </app-strict-input-number>
  <app-strict-input-text class="col-2" placeholder="Payload"
                         [control]="accrueRewardForm.rewardPayload">
  </app-strict-input-text>
  <app-strict-input-number class="col-2" placeholder="PartnerId"
                         [control]="accrueRewardForm.partnerId">
  </app-strict-input-number>
  <div class="col-2">
    <button class="btn btn-primary mb-3" (click)="create()">Сохранить</button>
  </div>
</div>
<div *ngIf="loading">
  <app-loading></app-loading>
</div>
<div class="row mt-2">
  <app-strict-input-number class="col-3"
                           placeholder="User Id"
                           [control]="pagingViewModel.userId"
                           [title]="'UserId'">
  </app-strict-input-number>
  <app-strict-input-number class="col-3"
                           placeholder="Id награды"
                           [control]="pagingViewModel.rewardId"
                           [title]="'Id награды'">
  </app-strict-input-number>
  <div class="col-2 mt-4">
    <button class="btn btn-primary mb-3" (click)="search()">Поиск</button>
  </div>
</div>
<div *ngIf="!loading">
  <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
  <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
