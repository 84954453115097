<div>
  <label class="mt-2">Анулировать фрибет</label>
  <div>
    <div class="row mt-2">
      <div class="col-2">
        Id выданного фрибета
      </div>
      <div class="col-2 timeline-marker">
        Дата операции
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <app-strict-input-number [control]="issuedFreebetId">
        </app-strict-input-number>
      </div>
      <div class="col-2">
        <strict-date-time-picker [control]="operationDate"></strict-date-time-picker>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="reset()">Сохранить</button>
  </div>
</div>
