import {Routes} from '@angular/router';
import {CouponComponent} from './coupon/coupon.component';
import {CouponsListComponent} from './coupons-list/coupons-list.component';

export const CouponsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list'
          },
          {
            path: 'settings',
            component: CouponComponent
          },
          {
            path: 'list',
            component: CouponsListComponent
          }
        ]
      }
    ]
  }
];
