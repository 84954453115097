<input type="text"
       class="form-control my-1"
       [formControl]="control"
       [class.is-invalid]="control.shouldDisplayErrors | async"
       [placeholder]="placeholder" />
<div class="invalid-feedback">
  <li *ngFor="let error of this.control.strictErrors | async">
    {{error.message}}
  </li>
</div>

