import {Component, OnInit} from '@angular/core';
import { SportMatchesEventsService } from 'src/app/services/autogen/EventNotifications';
import { MockSportMatchesEvent } from 'src/app/services/autogen/EventNotifications';
import {take} from 'rxjs/operators';
import {GaPagedResult, GaPagingForm} from '@koddington/ga-common';

@Component({
  selector: 'app-sport-matches-events-list',
  templateUrl: './sport-matches-events-list.component.html',
  styleUrls: ['./sport-matches-events-list.component.css']
})
export class SportMatchesEventsListComponent implements OnInit {
  public result: GaPagedResult<MockSportMatchesEvent>;

  constructor(private readonly _service: SportMatchesEventsService) {
  }

  ngOnInit(): void {
    this.load();
  }

  public eventAdded() {
    this.load();
  }

  public pageChanged(form: GaPagingForm): void {
    this.load(form.offset);
  }

  private load(offset: number = 0) {
    const paging = new GaPagingForm();
    paging.offset = offset;

    this._service.list(paging).pipe(
      take(1)
    ).subscribe(res => this.result = res);
  }
}
