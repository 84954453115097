import { Component } from '@angular/core';
import {DocumentVerificationsService } from 'src/app/services/autogen/Users';
import { CountDocVerificationErrorsModel } from 'src/app/services/autogen/Users';
import {filter, take} from 'rxjs/operators';
import {UserListViewModel} from '../../../../models/users/user-list-view-model';
import {GaMessagingService} from '@koddington/ga-common';

@Component({
  selector: 'app-document-verification-errors',
  templateUrl: './document-verification-errors.component.html',
  styleUrls: ['./document-verification-errors.component.css']
})
export class DocumentVerificationErrorsComponent {
  model: UserListViewModel = new UserListViewModel();
  errors: CountDocVerificationErrorsModel;

  constructor(private readonly _service: DocumentVerificationsService
  , private readonly _messages: GaMessagingService) { }

  search(userId: number) {
    this._service.getForUser(userId).pipe(
      take(1),
      filter(res => !this._messages.tryShowError(res))
    ).subscribe(res => this.errors = res.result);
  }
}
