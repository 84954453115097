import {AbstractModelValidator} from '@koddington/ga-common';
import {ChangeMechExpDateViewModel} from '../../../models/mechanics/change-mech-exp-date-view-model';
import {Injectable} from '@angular/core';

@Injectable()
export class ChangeMechanicsExpDateValidator extends AbstractModelValidator<ChangeMechExpDateViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.newDate).required();
  }
}
