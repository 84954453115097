import {AbstractModelValidator} from '@koddington/ga-common';
import {CupisChangesEventViewModel} from '../../../models/events/components-models/cupis-changes-event-view.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CupisChangesValidator extends AbstractModelValidator<CupisChangesEventViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.userId).requiredAndNotZero();
    this.ruleForControl(m => m.state).min(0);
    this.ruleForControl(m => m.eventDate).required();
    this.ruleForControl(m => m.registrationStateChangedAt).required();
    this.ruleForControl(m => m.hasSnils).min(0).max(1);
    this.ruleForControl(m => m.withdrawalApprovedState).min(0).max(4);
  }
}
