import { DropdownItem, EnumExtensions, IDropdownStrategy, isNullOrUndefined } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import { MockLogoServiceEntityTypes, MockLogoServiceEntityTypesExtensions } from '../../../../../services/autogen/LogoService';

export class MockLogoServiceEntityTypesStrategy implements IDropdownStrategy<MockLogoServiceEntityTypes> {
    getEntities(): Observable<Array<MockLogoServiceEntityTypes>> {
        const types = EnumExtensions.toArray(MockLogoServiceEntityTypes);
        return of(types);
    }

    map(model: MockLogoServiceEntityTypes): DropdownItem<MockLogoServiceEntityTypes> {
        if (isNullOrUndefined(model)) {
            return {
                entity: null,
                title: 'Не выбрано',
                id: ''
            };
        }

        return {
            entity: model,
            title: MockLogoServiceEntityTypesExtensions.format(model),
            id: Number(model).toString()
        };
    }
}
