import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { BetCalculationRedeemViewModel } from './bet-calculation-redeem-view-model';
import { MockBetCalculationForm } from '../../../services/autogen/Events';
import { BetCalculationLineViewModel } from './bet-calculation-line-view-model';
import { Dayjs } from 'dayjs';

export class BetCalculationViewModel {
    public betId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public eventDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public calculationAmount: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public isWin: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public ballsCount: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public isRedeem: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public isReturn: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public redemptionInfo: BetCalculationRedeemViewModel = new BetCalculationRedeemViewModel();
    public expressLines = new StrictFormControl<BetCalculationLineViewModel[]>([]);
    public expressRate: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();

    public hasBet = false;

    public toForm(): MockBetCalculationForm {
        const form = new MockBetCalculationForm();
        form.betId = this.betId.strictValue;
        form.eventDate = this.eventDate.strictValue;
        form.calculationAmount = this.calculationAmount.strictValue;
        form.isWin = this.isWin.strictValue;
        form.ballsCount = this.ballsCount.strictValue;
        form.isRedeem = this.isRedeem.strictValue ? 1 : 0;

        if (this.isRedeem.strictValue) {
            form.redemptionInfo = this.redemptionInfo.toForm();
        }

        if (this.isReturn.strictValue) {
            form.expressLines = this.expressLines.strictValue.map(u => u.toForm());
        }

        form.expressRate = this.expressRate.strictValue;

        return form;
    }
}
