<h1>Колл центр</h1>

<div class="col-2 mt-3 mb-3">
  <a class="btn btn-warning" (click)="clearHistory()">Очистить историю обзвонов</a>
</div>

<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li (click)="onTabChange('AddUserCallHistory')">Задачи на обзвон пользователей</li>
    <li (click)="onTabChange('RemoveUserCallQueueHistory')">Задачи на отмену обзвона пользователей</li>
    <li (click)="onTabChange('ResponseUserQueueHistory')">Обзвоненные пользователи</li>
    <li (click)="onTabChange('CallReasons')">Актуальные причины для обзвона</li>
    <li (click)="onTabChange('CallReasonsHistory')">История причин для обзвона</li>
    <li (click)="onTabChange('CallEvents')">События колл-центра</li>
  </ul>
  <div>
    <div *ngIf="isAddUserCallHistory">
      <app-call-sequences [sequencesType]="sequencesType.AddUserInCallQueue"
                          [offset]="offset">
      </app-call-sequences>
    </div>
    <div *ngIf="isRemoveUserCallHistory">
      <app-remove-call-sequences [offset]="offset">
      </app-remove-call-sequences>
    </div>
    <div *ngIf="isResponseUserCallHistory">
      <app-response-user-call-list [offset]="offset">
      </app-response-user-call-list>
    </div>
    <div *ngIf="isCallReasons">
      <app-call-reasons-list [offset]="offset"
                             [isHistory]="false">
      </app-call-reasons-list>
    </div>
    <div *ngIf="isCallReasonsHistory">
      <app-call-reasons-list [offset]="offset"
                             [isHistory]="true">
      </app-call-reasons-list>
    </div>
    <div *ngIf="isCallEvents">
      <app-add-user-call-notification-status>
      </app-add-user-call-notification-status>
    </div>
  </div>
</div>
