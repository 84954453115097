import {Component, OnInit} from '@angular/core';
import { IssueAuthTokenForm, WlFilteredPagedResult } from 'src/app/services/autogen/Shared';
import { UsersListByIdForm } from 'src/app/services/autogen/Users';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import dayjs, { Dayjs } from 'dayjs';
import { MockGamblerAuthTokens } from 'src/app/services/autogen/Shared';
import {GamblerAuthTokensService } from '../../../../services/autogen/Shared';
import {v4 as uuidv4} from 'uuid';
import { GaMessagingService, GaPagingForm, isNullOrUndefined, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';


@UntilDestroy()
@Component({
  selector: 'app-gambler-auth-tokens',
  templateUrl: './gambler-auth-tokens.component.html'
})
export class GamblerAuthTokensComponent implements OnInit {

  public userIdFilter = StrictFormControlBuilder.buildNullNumber();
  public userIdForNewToken = StrictFormControlBuilder.buildNullNumber();
  public gamblerToken = StrictFormControlBuilder.buildNullString();
  public tokenExpirationDate = StrictFormControlBuilder.buildNullDayjs();
  public result: WlFilteredPagedResult<MockGamblerAuthTokens, UsersListByIdForm>;

  constructor(private readonly _messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly _apiClient: GamblerAuthTokensService) {
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.pipe(
      map(value => this.parseFilterModel(value)),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe();
  }

  public removeToken(token: string): void {
    this._apiClient.revokeToken(token).pipe(
      first(),
      switchMap(() => this.load(this.result.paging))
    ).subscribe(u => {
      this._messaging.showMessage(`Токен удален`);
    });
  }

  public generateRandomData(): void {
    this.gamblerToken.strictValue = uuidv4();
    this.tokenExpirationDate.strictValue = dayjs().add(1, 'M');
  }

  public generateNewToken(): void {
    if (!this.userIdForNewToken.hasStrictValue ||
      !this.gamblerToken.hasStrictValue ||
      !this.tokenExpirationDate.hasStrictValue) {
      this._messaging.showMessage(`Форма добавления заполнена неправильно`);
    }
    const form = new IssueAuthTokenForm();
    form.userId = this.userIdForNewToken.strictValue;
    form.token = this.gamblerToken.strictValue;
    form.expirationDate = this.tokenExpirationDate.strictValue;

    this._apiClient.issueToken(form).pipe(
      first(),
      filter(u => !this._messaging.tryShowError(u)),
      switchMap((u) => this.load(this.result.paging).pipe(
        map(() => u)
      ))
    ).subscribe(u => {
      this.userIdForNewToken.strictValue = null;
      this.gamblerToken.strictValue = null;
      this.tokenExpirationDate.strictValue = null;
      this.userIdForNewToken.strictValue = null;
      this._messaging.showMessage(`Токен добавлен`);
    });
  }

  public pageChanged(form: GaPagingForm): void {
    this.navigateWithParams(form.offset, this.userIdFilter.getStrictValueOrDefault(null));
  }

  public search(): void {
    this.navigateWithParams(0, this.userIdFilter.getStrictValueOrDefault(null));
  }

  private load(form: UsersListByIdForm): Observable<WlFilteredPagedResult<MockGamblerAuthTokens, UsersListByIdForm>> {
    return this._apiClient.getList(form).pipe(
      first(),
      untilDestroyed(this),
      tap(u => this.result = u)
    );
  }

  private parseFilterModel(params: Params): UsersListByIdForm {
    const form = new UsersListByIdForm();

    this.userIdFilter.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
    form.userId = this.userIdFilter.strictValue;

    form.offset = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
    form.count = !isNullOrUndefined(params['count']) ? Number(params['count']) : 15;

    return form;
  }

  private navigateWithParams(offset?: number, userId?: number, count?: number): void {
    const params: Params = {
      userId: userId,
      offset: offset,
      count: count,
      tab: 'GamblerAuthTokens'
    };

    this.navigation.search(this.activeRoute, params);
  }
}
