<div>
    <h4>{{ pageCaption }}</h4>
    <div class="row">
        <div>
            <label>Тип предмета</label>
            <app-strict-dropdown class="wl-logo-service-input"
                                 [options]="dropDownOptions"
                                 [control]="viewModel.entityType"
                                 [strategy]="strategy"
                                 [shouldShowErrorList]="true"
            ></app-strict-dropdown>
        </div>
        <div>
            <label>Id предмета</label>
            <app-strict-input-number [control]="viewModel.itemId"></app-strict-input-number>
        </div>
        <div>
            <label>Ссылка на изображение</label>
            <app-strict-input-textarea [control]="viewModel.imageUrl"></app-strict-input-textarea>
        </div>
    </div>
    <div>
        <button class="btn btn-primary" (click)="save()">Сохранить</button>
    </div>
</div>
