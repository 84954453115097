import {Component, OnInit} from '@angular/core';
import {GaPagedResult, GaPagingForm} from '@koddington/ga-common';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomTournamentService } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentSequenceModel } from 'src/app/services/autogen/CustomTournaments';

@UntilDestroy()
@Component({
  selector: 'app-custom-tournament-sequences',
  templateUrl: './custom-tournament-sequences.component.html',
  styleUrls: ['./custom-tournament-sequences.component.scss']
})
export class CustomTournamentSequencesComponent implements OnInit {
  public loading = true;
  public pagedResult: GaPagedResult<CustomTournamentSequenceModel>;
  private filter = new GaPagingForm();

  constructor(private readonly service: CustomTournamentService) {
  }

  public ngOnInit(): void {
    this.load();
  }

  public pageChanged(form: GaPagingForm): void {
    this.filter = form;
    this.load();
  }

  private load(): void {
    this.loading = true;

    this.service.listSequences(this.filter).pipe(
      take(1),
      untilDestroyed(this)
    ).subscribe(u => {
        this.pagedResult = u;
        this.filter = u.paging;

        this.loading = false;
      }
    );
  }
}
