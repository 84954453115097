import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WlXfiftyBetsAutocompleteViewModel} from '../../../models/tours/wl-xfifty-bets-autocomplete-view-model';
import { WlFilteredPagedResult } from 'src/app/services/autogen/Shared';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {finalize, first, map} from 'rxjs/operators';
import { ToursService, WlXfiftyBetInternalModel, WlXFiftyBetsSearchForm } from 'src/app/services/autogen/Events';
import {ActivatedRoute, Params} from '@angular/router';
import {GaPagingForm, StrictFormControl} from '@koddington/ga-common';
import {DateOrderDropdownStrategy} from '../../shared/common/enum-dropdown/strategies/date-order.dropdown-strategy';
import { isNullOrUndefined } from '@koddington/ga-common';
import {DropdownOptions} from '../../../models/common/enum-dropdown/dropdown-options';
import { DateOrder } from 'src/app/services/autogen/Shared';

@UntilDestroy()
@Component({
  selector: 'app-x50-bets-search',
  templateUrl: './x50-bets-search.component.html',
  styleUrls: ['./x50-bets-search.component.css']
})
export class X50BetsSearchComponent implements OnInit {

  private userIds: string[] = [];
  public readonly viewModel = new WlXfiftyBetsAutocompleteViewModel();

  @Input()
  offset: StrictFormControl<number>;
  @Output()
  betsList: EventEmitter<WlFilteredPagedResult<WlXfiftyBetInternalModel, GaPagingForm>> = new EventEmitter<WlFilteredPagedResult<WlXfiftyBetInternalModel, GaPagingForm>>();
  @Output()
  loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private x50BetsService: ToursService,
  public readonly dateOrderStrategy: DateOrderDropdownStrategy,
  private readonly activeRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.buildModel();
    this.activeRoute.queryParams.pipe(
      map(value => this.initModel(value)),
      map(() => this.load())
    ).subscribe();
  }

  get userIdsForAutocomplete(): string[] {
    if (!this.viewModel.userId.hasStrictValue) {
      return [];
    }

    return this.userIds
      .filter(value => value
        .startsWith(this.viewModel.userId.strictValue.toString()));
  }

  initModel(params: Params): void {
    this.viewModel.dateOrder.strictValue = !isNullOrUndefined(params['dateOrder']) ?
      Number(params['dateOrder']) : null;
    this.viewModel.offset.strictValueSource.pipe(
      map(() => this.load()),
      untilDestroyed(this)
    ).subscribe();

  }
  buildModel(): void {
    this.viewModel.offset = this.offset;
  }

  get dateStatus(): StrictFormControl<DateOrder> {
    return this.viewModel.dateOrder;
  }
  get options(): DropdownOptions {
    const dropdownOptions = new DropdownOptions();
    dropdownOptions.placeholder = 'Не указан';

    return dropdownOptions;
  }

  userIdSelected(id: number): void {
    this.viewModel.userId.strictValue = id;
    this.viewModel.offset.strictValue = 0;
  }

  search(): void {
    this.viewModel.offset.strictValue = 0;

  }
  load(): void {
    this.loading.emit(true);

    const form = new WlXFiftyBetsSearchForm();
    form.userId = this.viewModel.userId.strictValue;
    form.count = this.viewModel.count.strictValue;
    form.offset = this.viewModel.offset.strictValue;
    form.dateOrder = this.viewModel.dateOrder.strictValue;

    this.x50BetsService.x50BetsList(form).pipe(
      first(),
      finalize(() => this.loading.emit(false)),
      untilDestroyed(this)
    ).subscribe(value => {
      this.userIds = this.userIds
        .concat(value.results
            .map(u => u.userId.toString())
            .filter((u) => this.userIds
              .indexOf(u) === -1)
            .filter((elem, index, array) => index === array.indexOf(elem))
        );
      this.betsList.emit(value);
    });
  }
}
