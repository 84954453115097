import {Component, OnInit} from '@angular/core';
import { EventsService } from 'src/app/services/autogen/Events';
import {ClientRegistrationViewModel} from '../../../../models/events/components-models/client-registration-view-model';
import {ClientRegistrationValidator} from '../../validators/client-registration-validator';
import {take} from 'rxjs/operators';
import { ClientRegistrationForm } from 'src/app/services/autogen/Events';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {SecurityGroupsStrategy} from '../../../shared/common/strict-autocomplete/strategies/security-groups-strategy';
import { SecurityGroupsService } from 'src/app/services/autogen/Dictionaries';
import {GaMessagingService} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-client-registration',
  templateUrl: './client-registration.component.html',
  styleUrls: ['./client-registration.component.css']
})
export class ClientRegistrationComponent implements OnInit {

  public readonly model: ClientRegistrationViewModel = new ClientRegistrationViewModel();
  public readonly securityGroupStrategy: SecurityGroupsStrategy;

  constructor(private readonly eventService: EventsService,
              private readonly validator: ClientRegistrationValidator,
              private readonly messaging: GaMessagingService,
              private readonly _apiService: SecurityGroupsService) {
    this.securityGroupStrategy = new SecurityGroupsStrategy(_apiService);
  }

  ngOnInit(): void {
  }

  add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length === 0) {
      const form = new ClientRegistrationForm();
      form.registrationDate = this.model.registrationDate.strictValue;
      form.registrationSource = this.model.registrationSource.strictValue;
      form.registrationState = this.model.registrationState.strictValue;
      form.registrationUrl = this.model.registrationUrl.strictValue;
      form.userId = this.model.userId.strictValue;
      form.name = this.model.name.strictValue;
      form.securityGroup = this.model.groupId.strictValue.id;
      form.phoneNumber = this.model.phoneNumber.strictValue;
      form.email = this.model.email.strictValue;
      form.emailRejecting = this.model.emailRejecting.strictValue;
      form.emailRejectingDate = this.model.emailRejectingDate.strictValue;
      form.smsRejecting = this.model.smsRejecting.strictValue;
      form.smsRejectingDate = this.model.smsRejectingDate.strictValue;
      form.birthday = this.model.birthday.strictValue;
      form.promoId = this.model.promoId.strictValue;

      this.eventService
        .clientsRegistrationAddEvent(form)
        .pipe(
          take(1),
          untilDestroyed(this)
        )
        .subscribe(value => this.messaging.showMessage('Регистрация пользователя успешно выполнена'));
    }
  }
}
