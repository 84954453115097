<h3>Список изображений в лого-сервисе</h3>
<div *ngIf="loading">
    <app-loading></app-loading>
</div>
<div *ngIf="!loading">
    <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
    <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>

<ng-template #itemActions let-rawItem="item">
    <ng-template [ngIf]="asLogoServiceItem(rawItem)" let-item="ngIf">
        <button class="btn btn-primary" (click)="showImage(item)">Открыть</button>
        <button class="btn btn-primary mx-2" (click)="editImage(item.id)">Редактировать</button>
    </ng-template>
</ng-template>
