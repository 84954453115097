<app-loading *ngIf="loading"></app-loading>

<div class="row border mt-2">
  <div class="col-2">
    <label><b>Телефон:</b></label>
    <label>{{phone.phoneNumber}}</label>
    <br/>
    <label><b>Id телефона:</b></label>
    <label>{{phone.id}}</label>
  </div>
  <div class="col-2">
    <div class="form-group">
      <label>Код отправки через смс траффик</label>
      <app-enum-dropdown [strategy]="smsGateErrorStrategy"
                         [entityContainer]="viewModel.defaultSmsTrafficSendingCode">
      </app-enum-dropdown>
    </div>
  </div>
  <div class="col-3">
    <div class="form-group">
      <label>Код отправки через мтс</label>
      <app-enum-dropdown [strategy]="smsGateErrorStrategy" [entityContainer]="viewModel.defaultMtsSendingCode">
      </app-enum-dropdown>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <label>Код конверсии через смс траффик</label>
      <app-enum-dropdown [strategy]="smsTrafficConversionCodeStrategy"
                         [entityContainer]="viewModel.defaultSmsTrafficConversionCode">
      </app-enum-dropdown>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <label>Код конверсии через мтс </label>
      <app-enum-dropdown [strategy]="mtsCommunicatorConversionCodeStrategy"
                         [entityContainer]="viewModel.defaultMtsCommunicatorConversionCode">
      </app-enum-dropdown>
    </div>
  </div>
  <div class="col-1">
    <button type="submit" class="btn btn-primary mt-4" (click)="saveChanges()">Сохранить</button>
  </div>
</div>
