<div class="tabs container-fluid">
    <ul class="container-fluid">
        <li class="nav-item daily-tasks-li">
            <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/daily-tasks/main/erp']">
                ERP "Фрибет каждый день"
            </a>
        </li>
        <li class="nav-item daily-tasks-li">
            <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/daily-tasks/main/pickem-erp']">
                ERP "Pickem"
            </a>
        </li>
    </ul>
    <router-outlet></router-outlet>
</div>
