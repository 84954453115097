import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { WlMatchTeam } from 'src/app/services/autogen/Dictionaries';
import { Dayjs } from 'dayjs';

export class AddSportMatchViewModel {
  matchId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  championshipId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  category: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  matchDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  firstPlayer: StrictFormControl<WlMatchTeam> = StrictFormControlBuilder.buildNullObject(WlMatchTeam);
  secondPlayer: StrictFormControl<WlMatchTeam> = StrictFormControlBuilder.buildNullObject(WlMatchTeam);
  sportId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
}
