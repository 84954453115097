<div>
  <h3>Перемещение пользователя по турнирнирной таблице</h3>
  <div class="row">
    <div class="col-1">
      {{"Id пользователя"}}
    </div>
    <div class="col-1">
      {{"Id турнира"}}
    </div>
    <div class="col-2">
      {{"Место в турнирной таблице"}}
    </div>
  </div>
  <div class="mt-2 row">
    <app-strict-input-number class="col-1"
                             [control]="viewModel.userId">
    </app-strict-input-number>
    <app-strict-input-number class="col-1"
                             [control]="viewModel.tournamentId">
    </app-strict-input-number>
    <app-strict-input-number class="col-2"
                             [control]="viewModel.tournamentPlace">
    </app-strict-input-number>
  </div>
  <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
