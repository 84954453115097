import {Component, OnInit} from '@angular/core';
import {
  formatDateTime,
  GaMessagingService,
  GaPagedResult,
  GaPagingForm,
  GaTableData,
  isNullOrUndefined
} from '@koddington/ga-common';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';
import { CustomTournamentService, WlTournamentPrizeFundTypesExtensions } from 'src/app/services/autogen/CustomTournaments';
import { CustomTournamentModel } from 'src/app/services/autogen/CustomTournaments';
import {WlTournamentTypeExtensions} from 'src/app/services/autogen/CustomTournaments';

@UntilDestroy()
@Component({
  selector: 'app-custom-tournaments-list',
  templateUrl: './custom-tournaments-list.component.html'
})
export class CustomTournamentsListComponent implements OnInit {
  public loading = true;
  public pagedResult: GaPagedResult<CustomTournamentModel>;
  public tableData: GaTableData<CustomTournamentModel>;
  private filter = new GaPagingForm();

  constructor(private readonly service: CustomTournamentService,
              private readonly route: ActivatedRoute,
              private readonly messaging: GaMessagingService,
              private readonly navigation: SearchNavigationService) {
  }

  public ngOnInit(): void {
    this.route.queryParams.pipe(
      take(1)
    ).subscribe(u => this.init(u));
  }

  public pageChanged(form: GaPagingForm): void {
    this.filter.offset = form.offset;
    this.filter.count = form.count;
    this.navigateWithParameters();
  }

  private load(): void {
    this.loading = true;

    this.service.listTournaments(this.filter).pipe(
      take(1),
      untilDestroyed(this)
    ).subscribe(u => {
        this.pagedResult = u;
        this.filter = u.paging;
        this.mapToTable(u.results);

        this.loading = false;
      }
    );
  }

  private mapToTable(result: CustomTournamentModel[]) {
    this.tableData = new GaTableData<CustomTournamentModel>()
      .setData(result)
      .addSimpleColumn((elem) => elem.id, { title: 'Id', widthSize: 60 })
      .addSimpleColumn((elem) => elem.tournamentId, { title: 'Id турнира', widthSize: 90 })
      .addSimpleColumn((elem) => elem.name, { title: 'Наименование', widthSize: 290 })
      .addSimpleColumn((elem) => WlTournamentTypeExtensions.format(elem.tournamentType), { title: 'Тип сущности', widthSize: 300 })
      .addSimpleColumn((elem) => elem.isActive ? 'Да' : 'Нет', { title: 'Активен', widthSize: 80 })
      .addSimpleColumn((elem) => elem.prizeFund, { title: 'Призовой фонд', widthSize: 120 })
      .addSimpleColumn((elem) => WlTournamentPrizeFundTypesExtensions.format(elem.prizeFundType), { title: 'Тип призового фонда', widthSize: 160 })
      .addSimpleColumn((elem) => elem.winnersCount, { title: 'Количество призёров', widthSize: 170 })
      .addSimpleColumn((elem) => elem.usersCount, { title: 'Количество участников', widthSize: 170 })
      .addSimpleColumn((elem) => formatDateTime(elem.registrationDate), { title: 'Дата регистрации', widthSize: 130 })
      .addSimpleColumn((elem) => formatDateTime(elem.startDate), { title: 'Дата начала', widthSize: 120 })
      .addSimpleColumn((elem) => formatDateTime(elem.endDate), { title: 'Дата завершения', widthSize: 130 });
  }

  private init(params: Params) {
    this.filter.offset = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
    this.filter.count = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;

    this.load();
  }

  private navigateWithParameters(): void {
    const params: Params = {
      offset: this.filter.offset,
      count: this.filter.count ? this.filter.count : 30
    };

    this.navigation.search(this.route, params);
  }
}
