<header>
    <nav class="navbar navbar-expand-sm navbar-light bg-white border-bottom ml-3">
        <div class="row">
            <a class="navbar-brand" [routerLink]="['/dashboard']">Wl.MockServer.Web</a>
            <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
                <ul class="navbar-nav flex-grow-1">
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/push-gate/main']">
                            Push gate
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/sms-gate']">
                            Sms gate
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/dictionaries']">
                            Справочники
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/control-panel']">
                            Панель управления
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/users/main']">
                            Пользователи
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']"
                           [routerLink]="['/dashboard/users/pushDevices']">
                            Пользователи без логина
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLink]="['active']" [routerLink]="['/dashboard/broadcasts/layout']">
                            Рассылки
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/users/addData']">
                            Добавление контактной информации
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/events/addEvent']">
                            Контроль событий пользователя
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/events/list']">
                            Список событий пользователей
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/mechanics']">
                            Механики
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/triggers/list']">
                            Активные триггеры
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/triggers/actions']">
                            Триггеры (прочее)
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']"
                           [routerLink]="['/dashboard/segments/userData']">
                            Сегментация
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']"
                           [routerLink]="['/dashboard/calls/call-center']">
                            Колл центр
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']"
                           [routerLink]="['/dashboard/lifetime-call']">
                            ПЖЦ КЦ
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']"
                           [routerLink]="['/dashboard/events-notifications/sport-matches-events']">
                            События в спортивных матчах
                        </a>
                    </li>
                </ul>
            </div>
            <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse">
                <ul class="navbar-nav flex-grow-1">
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/battle-pass/main']">
                            Battle Pass
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/logo-service/main']">
                            Лого-сервис
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/custom-tournaments/main']">
                            Кастомные турниры
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/rate-race/main']">
                            Rate Race
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/face-it/main']">
                            Face it
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/rate-us/main']">
                            Rate Us
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/daily-tasks/main']">
                            Daily Tasks
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
<div class="container-fluid">
    <router-outlet></router-outlet>
</div>
