import {Routes} from '@angular/router';
import {MainLayoutComponent} from '../layouts/main-layout/main-layout.component';
import {UsersListComponent} from './components/users-list/users-list.component';
import {UsersAddDataComponent} from './components/users-add-data/users-add-data.component';
import {UsersWithoutLoginsComponent} from './components/users-without-logins/users-without-logins.component';
import {UsersLayoutComponent} from './components/layout/users-layout/users-layout.component';
import {UserLoginsComponent} from './components/user-logins/user-logins.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UsersStagesComponent } from './components/users-stages/users-stages.component';

export const UsersRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'main',
        component: UsersLayoutComponent
      },
      {
        path: 'list',
        component: UsersListComponent
      },
      {
        path: 'addData',
        component: UsersAddDataComponent
      },
      {
        path: 'pushDevices',
        component: UsersWithoutLoginsComponent
      },
      {
        path: 'userLogins',
        component: UserLoginsComponent
      },
      {
        path: 'usersStages',
        component: UsersStagesComponent
      },
      {
        path: 'userDetails/:id',
        component: UserDetailsComponent,
      }
    ]
  }
];
