import {Component, OnInit} from '@angular/core';
import { WlBetsResultsSubscriptionsForm } from 'src/app/services/autogen/EventNotifications';
import { WlBetsResultsSubscriptions } from 'src/app/services/autogen/EventNotifications';
import {BetsResultsSubscriptionsViewModel} from '../../../models/events-notifications/bets-results-subscriptions-view-model';
import { EventsNotificationsService } from 'src/app/services/autogen/EventNotifications';
import { map, switchMap, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {ActivatedRoute, Params} from '@angular/router';
import {GaMessagingService, GaPagedResult, isNullOrUndefined} from '@koddington/ga-common';
import {SearchNavigationService} from '../../shared/services/search-navigation.service';
import {Observable} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-bets-results-subscriptions-list',
  templateUrl: './bets-results-subscriptions-list.component.html',
  styleUrls: ['./bets-results-subscriptions-list.component.css']
})
export class BetsResultsSubscriptionsListComponent implements OnInit {

  public model: BetsResultsSubscriptionsViewModel = new BetsResultsSubscriptionsViewModel();
  public result: GaPagedResult<WlBetsResultsSubscriptions>;

  constructor(private readonly messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly service: EventsNotificationsService) {
  }

  ngOnInit() {

    this.activeRoute.queryParams.pipe(
      map(value =>  this.initModel(value)),
      map(() => this.createForm()),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe(value => this.result = value);
  }

  public search(): void {
    this.model.offset.strictValue = 0;
    this.navigateWithParameters();
  }

  public pageChanged(form: WlBetsResultsSubscriptionsForm): void {
    this.model.count.strictValue = form.count;
    this.model.offset.strictValue = form.offset;

    this.navigateWithParameters();
  }

  private load(form: WlBetsResultsSubscriptionsForm): Observable<GaPagedResult<WlBetsResultsSubscriptions>> {
    return this.service.list(form).pipe(
      take(1),
      untilDestroyed(this)
    );
  }

  private createForm(): WlBetsResultsSubscriptionsForm {
    const form = new WlBetsResultsSubscriptionsForm();
    form.userId = this.model.userId.strictValue;
    form.offset = this.model.offset.strictValue;
    form.count = this.model.count.strictValue;
    return form;
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : 0;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : 30;
    this.model.userId.strictValue = !isNullOrUndefined(params['userId']) ?
      params['userId'] : null;
  }

  private navigateWithParameters(): void {
    const params: Params = {
      userId: this.model.userId.hasStrictValue ? this.model.userId.strictValue : null,
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : 30,
      tab: 'BetsResultsSubscriptions'
    };

  this.navigation.search(this.activeRoute, params);
  }



}
