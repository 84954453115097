import {Component, OnInit} from '@angular/core';
import {XFiveResultViewModel} from '../../../../models/events/components-models/x-five-result-view-model';
import { EventsService } from 'src/app/services/autogen/Events';
import {XFiveResultValidator} from '../../validators/x-five-result-validator';
import {WlResult} from '../../../../models/common/results/wl-result';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { XfiveResultForm } from 'src/app/services/autogen/Events';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNullOrUndefined } from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-x-five-result',
  templateUrl: './x-five-result.component.html',
  styleUrls: ['./x-five-result.component.css']
})
export class XFiveResultComponent implements OnInit {

  private readonly model: XFiveResultViewModel = new XFiveResultViewModel();
  private result: WlResult<boolean>;

  constructor(private readonly service: EventsService,
              private readonly validator: XFiveResultValidator,
              private readonly messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get winsCount(): StrictFormControl<number> {
    return this.model.winsCount;
  }

  get userDate(): StrictFormControl<Dayjs> {
    return this.model.userDate;
  }

  get loyaltyLevel(): StrictFormControl<number> {
    return this.model.loyaltyLevel;
  }

  add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new XfiveResultForm();
    form.loyaltyLevel = this.loyaltyLevel.strictValue;
    form.userDate = this.userDate.strictValue;
    form.userId = this.userId.strictValue;
    form.winsCount = this.winsCount.strictValue;
    this.service.xfiveResultAdd(form).pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.messaging.showMessage('Расчёта купона X5 успешно выполнен');
      });
  }

  isError(): string {
    if (isNullOrUndefined(this.result)) {
      return;
    }
    return this.result.isCorrect ? 'alert alert-success' : 'alert alert-danger';
  }
}
