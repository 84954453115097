import {UpdateBalanceViewModel} from '../models/update-balance.view-model';
import {Injectable} from '@angular/core';
import {AbstractModelValidator} from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class UpdateFormValidator extends AbstractModelValidator<UpdateBalanceViewModel> {
  constructor() {
    super();

    this.ruleForControl(u => u.userId)
      .required()
      .min(0);

    this.ruleForControl(u => u.amount)
      .required();

    this.ruleForControl(u => u.balls)
      .required()
      .min(0);

    this.ruleForControl(u => u.inGameBetsSum)
      .required()
      .min(0);
  }
}
