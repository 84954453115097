<div class="tabs container-fluid">
    <ul class="container-fluid">
        <li class="nav-item battle-pass-li">
            <a class="nav-link text-dark" [routerLinkActive]="['active']" [routerLink]="['/dashboard/rate-us/main/feedbacks']">
                Отзывы пользователей
            </a>
        </li>
    </ul>
    <router-outlet></router-outlet>
</div>
