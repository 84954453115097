import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import { SecurityGroupsService } from 'src/app/services/autogen/Dictionaries';
import { WlMockSecurityGroup } from 'src/app/services/autogen/Dictionaries';
import { isNullOrUndefined } from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {IAutocompleteItem, IAutocompleteStrategy, IDropdownStrategy} from '@koddington/ga-common';

export class SecurityGroupsStrategy implements IAutocompleteStrategy<WlMockSecurityGroup>, IDropdownStrategy<WlMockSecurityGroup>  {
  private _termSource = new BehaviorSubject<string>('');
  private _source = new BehaviorSubject<WlMockSecurityGroup[]>([]);

  constructor(private _apiService: SecurityGroupsService) {
    this._termSource
      .pipe(
        switchMap((term) => {
          return this._apiService.getFilterSecurityGroup(term).pipe(
            filter(v => v.isCorrect),
            map(result => result.result)
          );
        })
      ).subscribe((u) => this._source.next(u));
  }

  public convert(model: WlMockSecurityGroup): IAutocompleteItem<WlMockSecurityGroup> {
    return {
      entity: model,
      title: model.name,
      key: model.id.toString()
    };
  }

  public getSource(): Observable<Array<WlMockSecurityGroup>> {
    return this._source.asObservable();
  }

  public updateSource(term: string): void {
    this._termSource.next(term);
  }

  public getEntities(): Observable<Array<WlMockSecurityGroup>> {
    return this._apiService.getFilterSecurityGroup('').pipe(
      map(u => {
        if (isNullOrUndefined(u) || !u.isCorrect) {
          return [];
        }

        return u.result;
      })
    );
  }

  public map(model: WlMockSecurityGroup): DropdownItem<WlMockSecurityGroup> {
    if (isNullOrUndefined(model)) {
      return {
        entity: model,
        title: '',
        id: null
      };
    }

    return {
      entity: model,
      title: model.name,
      id: model.id.toString()
    };
  }

}

