import {Component, OnInit} from '@angular/core';
import { MockBroadcastingTemplate } from 'src/app/services/autogen/PushGate';
import { TemplatesService } from 'src/app/services/autogen/PushGate';
import {finalize, take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlFilteredPagedResult } from 'src/app/services/autogen/Shared';
import {GaPagingForm} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-push-templates-list',
  templateUrl: './push-templates-list.component.html',
  styleUrls: ['./push-templates-list.component.css']
})
export class PushTemplatesListComponent implements OnInit {

  private pageOffset: number;
  private isLoading: boolean;
  private result: WlFilteredPagedResult<MockBroadcastingTemplate, GaPagingForm>;

  constructor(private readonly service: TemplatesService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.pageOffset = 0;
    this.load();
  }

  load(): void {
    this.service.list(this.pageOffset)
      .pipe(take(1),
        untilDestroyed(this),
        finalize(() => this.isLoading = false))
      .subscribe(value => this.result = value);
  }

  valueAdded(result: boolean): void {
    if (result) {
      this.load();
    }
  }

  pageChanged(form: GaPagingForm): void {
    this.pageOffset = form.offset;
    this.load();
  }

  get getResult(): WlFilteredPagedResult<MockBroadcastingTemplate, GaPagingForm> {
    return this.result;
  }

  get models(): MockBroadcastingTemplate[] {
    return this.result.results;
  }

  get pagingNeeded(): boolean {
    return this.result.total >= 30;
  }

  get IsLoading(): boolean {
    return this.isLoading;
  }
}
