export enum ApnsResponseStatus {
    Unknown = -1,
    Success = 0,
    BadCollapseId = 1,
    BadDeviceToken = 2,
    BadExpirationDate = 3,
    BadMessageId = 4,
    BadPriority = 5,
    BadTopic = 6,
    DeviceTokenNotForTopic = 7,
    DuplicateHeaders = 8,
    IdleTimeout = 9,
    InvalidPushType = 10,
    MissingDeviceToken = 11,
    MissingTopic = 12,
    PayloadEmpty = 13,
    TopicDisallowed = 14,
    BadCertificate = 15,
    BadCertificateEnvironment = 16,
    ExpiredProviderToken = 17,
    Forbidden = 18,
    InvalidProviderToken = 19,
    MissingProviderToken = 20,
    BadPath = 21,
    MethodNotAllowed = 22,
    Unregistered = 23,
    PayloadTooLarge = 24,
    TooManyProviderTokenUpdates = 25,
    TooManyRequests = 26,
    InternalServerError = 27,
    ServiceUnavailable = 28,
    Shutdown = 29,
  }
  
  
  export namespace ApnsResponseStatusExtensions {
    export function toFriendlyString(value: ApnsResponseStatus) {
      switch (value) {
        case ApnsResponseStatus.Unknown:
          return 'Unknown';
        case ApnsResponseStatus.Success:
          return 'Success';
        case ApnsResponseStatus.BadCollapseId:
            return 'Bad collapse id';
        case ApnsResponseStatus.BadDeviceToken:
            return 'Bad device token';
        case ApnsResponseStatus.BadExpirationDate:
            return 'Bad expiration date';
        case ApnsResponseStatus.BadMessageId:
            return 'Bad message id';
        case ApnsResponseStatus.BadPriority:
            return 'Bad priority';
        case ApnsResponseStatus.BadTopic:
            return 'Bad topic';
        case ApnsResponseStatus.DeviceTokenNotForTopic:
            return 'Device token not for topic';
        case ApnsResponseStatus.DuplicateHeaders:
            return 'Duplicate headers';
        case ApnsResponseStatus.IdleTimeout:
            return 'Idle timeout';
        case ApnsResponseStatus.InvalidPushType:
            return 'Invalid push type';
        case ApnsResponseStatus.MissingDeviceToken:
            return 'Missing device token';
        case ApnsResponseStatus.MissingTopic:
            return 'Missing topic';
        case ApnsResponseStatus.PayloadEmpty:
            return 'Payload empty';
        case ApnsResponseStatus.TopicDisallowed:
            return 'Topic disallowed';
        case ApnsResponseStatus.BadCertificate:
            return 'Bad certificate';
        case ApnsResponseStatus.BadCertificateEnvironment:
            return 'Bad certificate environment';
        case ApnsResponseStatus.ExpiredProviderToken:
            return 'Expired provider token';
        case ApnsResponseStatus.Forbidden:
            return 'Forbidden';
        case ApnsResponseStatus.InvalidProviderToken:
            return 'Invalid provider token';
        case ApnsResponseStatus.MissingProviderToken:
            return 'Missing provider token';
        case ApnsResponseStatus.BadPath:
            return 'Bad path';
        case ApnsResponseStatus.MethodNotAllowed:
            return 'Method not allowed';
        case ApnsResponseStatus.Unregistered:
            return 'Unregistered';
        case ApnsResponseStatus.PayloadTooLarge:
            return 'Payload too large';
        case ApnsResponseStatus.TooManyProviderTokenUpdates:
            return 'Too many provider token updates';
        case ApnsResponseStatus.TooManyRequests:
            return 'Too many requests';
        case ApnsResponseStatus.InternalServerError:
            return 'Internal Server Error';
        case ApnsResponseStatus.ServiceUnavailable:
            return 'Service unavailable';
        case ApnsResponseStatus.Shutdown:
            return 'Shutdown';
        default:
          return '';
      }
    }
  }