<div>
  <h1>Статистика по Push рассылкам</h1>
  <hr/>
  <div>
    <app-strict-input-number [control]="model.userId" [placeholder]="'Id пользователя'"></app-strict-input-number>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </div>
  <div *ngIf="result && result.results">
    <div class="row mt-3">
      <div class="col-1">id</div>
      <div class="col-1">UserId</div>
      <div class="col-1">Устройство</div>
      <div class="col-2">Дата</div>
      <div class="col-2">Токен</div>
      <div class="col-1">Код ошибки</div>
      <div class="col-2">Ошибка</div>
    </div>
    <hr>
    <div class="row mt-3" *ngFor="let item of result.results">
      <div class="col-1">
        {{item.notificationSendRequestId}}
      </div>
      <div class="col-1">
        {{item.userId}}
      </div>
      <div class="col-1">
        {{item.deviceKind | wlDeviceKindsTransform}}
      </div>
      <div class="col-2">
        {{item.eventDate | date:"dd.MM.YYYY"}}
      </div>
      <div class="col-2">
        {{item.deviceToken}}
      </div>
      <div class="col-1">
        {{item.errorCode}}
      </div>
      <div class="col-2">
        {{item.errorCode | pushErrorCodesTransform}}
      </div>

    </div>
  </div>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
