<app-loading *ngIf="loading"></app-loading>
<div class="row" *ngIf="!loading">
  <div class="col-12">
    <h1 class="mt-2">Отправленные смс</h1>
    <div class="form-group">
      <label>Телефон</label>
      <app-strict-input-text [control]="this.currentFilter.phoneNumber">
      </app-strict-input-text>
    </div>
    <app-wl-index-paging [paging]="list" (pageChangedEvent)="onPageChanged($event)">
    </app-wl-index-paging>
    <div class="row mt-2" *ngIf="!loading">
      <div class="col-12 сropping-line">
        <mat-card class="mt-2 color-red" style="background-color: #BEE5EB; padding: 3px;" *ngFor="let item of list.results">
          <app-sent-message-info [sms]="item"></app-sent-message-info>
        </mat-card>
      </div>
    </div>
    <app-wl-index-paging [paging]="list" (pageChangedEvent)="onPageChanged($event)">
    </app-wl-index-paging>
  </div>
</div>
