<div>
  <h3>Начисленные механики пользователей</h3>
  <div>
    <app-strict-input-number [control]="model.mechanicId" [placeholder]="'Id механики'"></app-strict-input-number>
    <app-strict-input-number [control]="model.userId" [placeholder]="'Id пользователя'"></app-strict-input-number>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </div>
  <div *ngIf="result && result.results">
    <div class="row mt-3">
      <div class="col-1">Id механики</div>
      <div class="col-1">Id пользователя</div>
      <div class="col-1">Идентификатор причины выдачи</div>
      <div class="col-1">Тип механики</div>
      <div class="col-1">Срок ожидания депозита</div>
      <div class="col-1">Id фрибета</div>
      <div class="col-2">Сумма депозита для срабатывания механики</div>
      <div class="col-1">Сумма фрибета</div>
      <div class="col-1">Срок жизни фрибета (дни)</div>
      <div class="col-1">Дата подключения</div>
      <div class="col-1">Дата срабатывания</div>

    </div>
    <hr>
    <div class="row" *ngFor="let item of result.results">
      <div class="col-1">
        {{item.mechanicId}}
      </div>
      <div class="col-1">
        {{item.userId}}
      </div>
      <div class="col-1">
        {{item.mechanicEnabledReasonId | wlMechanicEnabledReasonsTransform}}
      </div>
      <div class="col-1">
        {{item.mechanicType | wlSegmentationMechanicsTypesTransform}}
      </div>
      <div class="col-1">
        {{item.depositWaitingDate | moscowDateTime}}
      </div>
      <div class="col-1">
        {{item.freebetId }}
      </div>
      <div class="col-2">
        {{item.sumForMechanicTriggering}}
      </div>
      <div class="col-1">
        {{item.freebetSum}}
      </div>
      <div class="col-1">
        {{item.freebetLifetimeDays}}
      </div>
      <div class="col-1">
        {{item.enabledAt | moscowDateTime}}
      </div>
      <div class="col-1">
        {{item.triggeredAt | moscowDateTime}}
      </div>
    </div>
  </div>
  <div *ngIf="result">
    <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
  </div>
</div>
