import { WlRewardExternalResponseTypes } from 'src/app/services/autogen/BattlePass';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { WlBattlePassRewardResponseErrorCodes } from '../../../enums/wl-battle-pass-reward-response-error-codes';

export class BattlePassRequestAccrualRewardViewModel {
    public id: number;
    public requestId: number;
    public tradeLinkUrl: string;
    public itemId: number;
    public hashName: string;
    public operation: string;
    public status: StrictFormControl<WlRewardExternalResponseTypes> = StrictFormControlBuilder.buildNullNumber<WlRewardExternalResponseTypes>();
    public errorCode: StrictFormControl<WlBattlePassRewardResponseErrorCodes> = StrictFormControlBuilder.buildNullNumber<WlBattlePassRewardResponseErrorCodes>();
    public comment: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
}
