<h1>История состояний активной цепочки {{this.activeUserTriggerId}}</h1>
<div *ngIf="isLoading">
  <app-loading></app-loading>
</div>
<div *ngIf="!isLoading && isLogsExist">
  <table class="table mt-3">
    <tr class="d-flex">
      <td class="col-1">
        ID рассылки
      </td>
      <td class="col-1">
        ID пользователя
      </td>
      <td class="col-1">
        ID события
      </td>
      <td class="col-2">
        Имя события
      </td>
      <td class="col-5">
        Тело события
      </td>
      <td class="col-2">
        Тип события
      </td>
    </tr>
    <tr *ngFor="let log of logs" class="d-flex">
      <td class="col-1">
        {{log.userTriggerId}}
      </td>
      <td class="col-1">
        {{log.userId}}
      </td>
      <td class="col-1">
        {{this.getEventById(log.eventId).id}}
      </td>
      <td class="col-2">
        {{this.getEventById(log.eventId).groupName}}
      </td>
      <td class="col-5">
        <pre class="container-xl event-body">{{this.getSerializedEvent(this.getEventById(log.eventId))}}</pre>
      </td>
      <td class="col-2">
        {{log.eventType | wlActiveUserTriggersStatesTransform}}
      </td>
    </tr>
  </table>
</div>
