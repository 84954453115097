import {AbstractModelValidator} from '@koddington/ga-common';
import {UserNotificationStatusViewModel} from '../../../models/calls/user-notification-status-view-model';

export class AddUserCallNotificationStatusValidator extends AbstractModelValidator<UserNotificationStatusViewModel> {
  constructor() {
    super();
    this.ruleForControl(u => u.isUnsubscribed).required();
    this.ruleForControl(u => u.userId).required().min(1);
    this.ruleForControl(u => u.eventDate).required();
  }
}
