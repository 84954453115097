import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {DropdownItem, IAutocompleteItem, IAutocompleteStrategy, IDropdownStrategy, isNullOrUndefined} from '@koddington/ga-common';
import { WlFavouriteTeam } from 'src/app/services/autogen/Shared';
import { FavouriteTeamsService } from 'src/app/services/autogen/Shared';

export class FavouriteTeamsStrategy implements IAutocompleteStrategy<WlFavouriteTeam>, IDropdownStrategy<WlFavouriteTeam>  {
  private _termSource = new BehaviorSubject<string>('');
  private _source = new BehaviorSubject<WlFavouriteTeam[]>([]);

  constructor(private _apiService: FavouriteTeamsService) {
    this._termSource
      .pipe(
        switchMap((term) => {
          return this._apiService.list(term).pipe(
            filter(v => v.isCorrect),
            map(result => result.result)
          );
        })
      ).subscribe((u) => this._source.next(u));
  }

  public convert(model: WlFavouriteTeam): IAutocompleteItem<WlFavouriteTeam> {
    return {
      entity: model,
      title: model.name,
      key: model.id.toString()
    };
  }

  public getSource(): Observable<Array<WlFavouriteTeam>> {
    return this._source.asObservable();
  }

  public updateSource(term: string): void {
    this._termSource.next(term);
  }

  public getEntities(): Observable<Array<WlFavouriteTeam>> {
    return this._apiService.list('').pipe(
      map(u => {
        if (isNullOrUndefined(u) || !u.isCorrect) {
          return [];
        }

        return u.result;
      })
    );
  }

  public map(model: WlFavouriteTeam): DropdownItem<WlFavouriteTeam> {
    if (isNullOrUndefined(model)) {
      return {
        entity: model,
        title: '',
        id: null
      };
    }

    return {
      entity: model,
      title: model.name,
      id: model.id.toString()
    };
  }

}

