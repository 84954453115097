import {Component, OnInit} from '@angular/core';
import { MockSportsPushConversion } from 'src/app/services/autogen/Users';
import { SportsStatisticsService } from 'src/app/services/autogen/Users';
import { SportsStatsFilterForm } from 'src/app/services/autogen/Users';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-user-push-conversion-by-sport-type',
  templateUrl: './user-push-conversion-by-sport-type.component.html',
  styleUrls: ['./user-push-conversion-by-sport-type.component.css']
})
export class UserPushConversionBySportTypeComponent implements OnInit {

  conversions: MockSportsPushConversion[];

  constructor(private readonly _service: SportsStatisticsService) { }

  ngOnInit(): void {
    this.load(new SportsStatsFilterForm());
  }

  load(form: SportsStatsFilterForm) {
    this._service.getSportPushConversion(form).pipe(
      untilDestroyed(this)
    ).subscribe(res => this.conversions = res.result);
  }
}
