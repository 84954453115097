export enum WlBattlePassQuestSubscriptionStates {
    Ok = 0,
    Error = 1,
    InvalidModel = 2,
    WrongPartnerTaskId = 3,
    CsProfileClosed = 4,
    DotaProfileClosed = 5,
    SteamProfileClosed = 6,
}

export namespace WlBattlePassQuestSubscriptionStatesExtensions {
    export function toRabbitMessage(state: WlBattlePassQuestSubscriptionStates): string {
        switch (state) {
            case WlBattlePassQuestSubscriptionStates.Ok:
                return 'OK';
            case WlBattlePassQuestSubscriptionStates.Error:
                return 'Error';
            case WlBattlePassQuestSubscriptionStates.InvalidModel:
                return 'InvalidModel';
            case WlBattlePassQuestSubscriptionStates.WrongPartnerTaskId:
                return 'WrongPartner_task_id';
            case WlBattlePassQuestSubscriptionStates.CsProfileClosed:
                return 'cs_profile_closed';
            case WlBattlePassQuestSubscriptionStates.DotaProfileClosed:
                return 'dota_profile_closed';
            case WlBattlePassQuestSubscriptionStates.SteamProfileClosed:
                return 'steam_profile_closed';
        }
    }

    export function toFriendlyString(state: WlBattlePassQuestSubscriptionStates): string {
        switch (state) {
            case WlBattlePassQuestSubscriptionStates.Ok:
                return 'OK';
            case WlBattlePassQuestSubscriptionStates.Error:
                return 'CRM Error';
            case WlBattlePassQuestSubscriptionStates.InvalidModel:
                return 'InvalidModel';
            case WlBattlePassQuestSubscriptionStates.WrongPartnerTaskId:
                return 'WrongPartnerTaskId';
            case WlBattlePassQuestSubscriptionStates.CsProfileClosed:
                return 'Закрыт профиль игрока (CS)';
            case WlBattlePassQuestSubscriptionStates.DotaProfileClosed:
                return 'Выключена настройка «Общедоступная история матчей»';
          case WlBattlePassQuestSubscriptionStates.SteamProfileClosed:
                return 'Закрыт профиль Steam';
        }
    }
}
