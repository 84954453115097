import { Component, OnInit } from '@angular/core';
import { SportTypesService } from 'src/app/services/autogen/Dictionaries';
import { WlSportType } from 'src/app/services/autogen/Dictionaries';
import {finalize, take} from 'rxjs/operators';

@Component({
  selector: 'app-sport-types-list',
  templateUrl: './sport-types-list.component.html',
  styleUrls: ['./sport-types-list.component.css']
})
export class SportTypesListComponent implements OnInit {

  loading: boolean;
  sportTypes: WlSportType[];
  constructor(private readonly _service: SportTypesService) { }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.loading = true;

    this._service.list().pipe(
      take(1),
      finalize(() => this.loading = false)
    ).subscribe(res => this.sportTypes = res.result);
  }
}
