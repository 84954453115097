<h1>События</h1>
<div class="tabs container-fluid">
    <ul class="container-fluid">
        <li (click)="onTabChange('RegEvents')">Регистрация/Иденификация пользователя</li>
        <li (click)="onTabChange('BetEvents')">Ставки</li>
        <li (click)="onTabChange('FinEvents')">Финансовые операции</li>
        <li (click)="onTabChange('X5Events')">Тур X5</li>
        <li (click)="onTabChange('Tours')">Туры</li>
        <li (click)="onTabChange('FreeBetEvents')">Фрибеты и др. бонусы</li>
        <li (click)="onTabChange('SportMatches')">Спортивные матчи</li>
        <li (click)="onTabChange('Score')">Score</li>
        <li (click)="onTabChange('Cashback')">Cashback</li>
        <li (click)="onTabChange('CashbackGuide')">Справочник категорий Cashback</li>
        <li (click)="onTabChange('CashbackBonus')">Пользователи, не забравшие бонус Cashback</li>
    </ul>
    <div>
        <div *ngIf="isClientRegAllow">
            <app-add-cupis-changes></app-add-cupis-changes>
            <hr>
            <app-add-registration-state-changes></app-add-registration-state-changes>
            <hr>
            <app-client-registration></app-client-registration>
            <hr>
            <app-add-selfie-and-doc></app-add-selfie-and-doc>
            <hr>
            <app-add-docs-and-selfie-verification-result></app-add-docs-and-selfie-verification-result>
            <hr/>
            <app-add-complete-identification></app-add-complete-identification>
            <hr>
            <app-add-sms-operations></app-add-sms-operations>
            <hr>
            <app-client-information></app-client-information>
            <hr>
        </div>
        <div *ngIf="isBetOperationsAllow" class="bet-operations">
            <app-add-bet></app-add-bet>
            <app-bet-calculation></app-bet-calculation>
            <app-bet-express-line-calculation></app-bet-express-line-calculation>
        </div>
        <div *ngIf="isFinEventsAllow">
            <app-add-finance-operation></app-add-finance-operation>
        </div>
        <div *ngIf="isX5EventsAllow" class="x5-bet-operations">
            <app-x-five-result></app-x-five-result>
            <app-add-x-five-bet></app-add-x-five-bet>
        </div>
        <div *ngIf="isToursAllow">
            <app-tours-layout></app-tours-layout>
        </div>
        <div *ngIf="isFreeBetEventsAllow">
            <app-add-free-bet></app-add-free-bet>
            <app-reset-freebet></app-reset-freebet>
        </div>
        <div *ngIf="isSportMatchesAllow">
            <app-sport-matches></app-sport-matches>
        </div>
        <div *ngIf="isScore">
            <app-add-user-score-update></app-add-user-score-update>
        </div>
        <div *ngIf="isCashback">
            <app-add-user-cashback></app-add-user-cashback>
        </div>
        <div *ngIf="isCashbackGuide">
            <app-add-guide-cashback></app-add-guide-cashback>
        </div>
        <div *ngIf="isCashbackBonus">
            <app-add-cashback-bonus></app-add-cashback-bonus>
        </div>
    </div>
</div>
