import {
  BattlePassRequestAccrualRewardViewModel
} from '../../../models/events/components-models/battle-pass-request-accrual-reward-view-model';
import {AbstractModelValidator} from '@koddington/ga-common';

export class BattlePassAccrualRewardValidator extends AbstractModelValidator<BattlePassRequestAccrualRewardViewModel> {
  constructor() {
    super();
    this.ruleForControl(m => m.status).required();
  }
}
