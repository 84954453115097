import { Component, OnInit } from '@angular/core';
import { WlBattlePassRequestAccrualRewardModel } from 'src/app/services/autogen/BattlePass';
import { BattlePassService } from 'src/app/services/autogen/BattlePass';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GaPagedResult, GaPagingForm, isNullOrUndefined, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { ActivatedRoute, Params } from '@angular/router';
import { RewardsRequestsPagingForm } from 'src/app/services/autogen/BattlePass';
import { SearchNavigationService } from '../../../shared/services/search-navigation.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-battle-pass-accrual-reward-list',
    templateUrl: './battle-pass-accrual-reward-list.component.html',
    styleUrls: ['./battle-pass-accrual-reward-list.component.css']
})
export class BattlePassAccrualRewardListComponent implements OnInit {

    public result: GaPagedResult<WlBattlePassRequestAccrualRewardModel>;
    public pagingForm: RewardsRequestsPagingForm;
    public requestIdControl = StrictFormControlBuilder.buildNullNumber();

    constructor(private readonly battlePassService: BattlePassService,
                private readonly activeRoute: ActivatedRoute,
                private readonly navigation: SearchNavigationService) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public search(): void {
        this.pagingForm.offset = 0;
        this.pagingForm.requestId = this.requestIdControl.strictValue;

        this.navigate();
    }

    public pageChanged(form: GaPagingForm): void {
        this.pagingForm.offset = form.offset;

        this.navigate();
    }

    private init(): void {
        this.activeRoute.queryParams.pipe(
            map(value => this.createPagingForm(value)),
            switchMap(_ => this.load()),
            untilDestroyed(this)
        ).subscribe(value => {
            this.result = value;
        });
    }

    private load(): Observable<GaPagedResult<WlBattlePassRequestAccrualRewardModel>> {
        return this.battlePassService.listRequests(this.pagingForm).pipe(untilDestroyed(this));
    }

    private createPagingForm(params: Params): void {
        this.pagingForm = new RewardsRequestsPagingForm();

        this.pagingForm.offset = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
        this.pagingForm.count = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
        this.pagingForm.requestId = !isNullOrUndefined(params['requestId']) ? Number(params['requestId']) : null;

        this.requestIdControl.strictValue = this.pagingForm.requestId;
    }

    private navigate(): void {
        const params: Params = {
            requestId: this.pagingForm.requestId,
            offset: this.pagingForm.offset ?? 0,
            count: this.pagingForm.count ?? 30
        };

        this.navigation.search(this.activeRoute, params);
    }
}
