import {Component, OnInit} from '@angular/core';
import { WlFilteredPagedResult } from 'src/app/services/autogen/Shared';
import { WlXfiftyTourInternalModel } from 'src/app/services/autogen/Events';
import {filter, finalize} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GaPagingForm} from '@koddington/ga-common';
import { ToursService } from '../../../services/autogen/Events';

@UntilDestroy()
@Component({
  selector: 'app-x50-tours-list',
  templateUrl: './x50-tours-list.component.html',
  styleUrls: ['./x50-tours-list.component.css']
})
export class X50ToursListComponent implements OnInit {

  result: WlFilteredPagedResult<WlXfiftyTourInternalModel, GaPagingForm>;
  loading = false;
  private offset: number;
  constructor(private readonly toursService: ToursService) { }

  ngOnInit(): void {
    this.offset = 0;
    this.load();
  }

  get tours(): WlXfiftyTourInternalModel[] {
    return this.result.results;
  }
  pageChanged(form: GaPagingForm): void {
    this.offset = form.offset;
    this.load();
  }

  load(): void {
    this.loading = true;
    this.toursService.x50ToursList(new GaPagingForm(30, this.offset)).pipe(
      filter(value => value.results.length > 0),
      finalize(() => this.loading = false),
      untilDestroyed(this)
    ).subscribe(value => {
      this.result = value;
    });
  }
}
