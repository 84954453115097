import { WlXfiftyStates } from 'src/app/services/autogen/Events';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class AddX50TourViewModel {
  id: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  lastTourId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  name: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
  startDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  status: StrictFormControl<WlXfiftyStates> = StrictFormControlBuilder.buildNullNumber<WlXfiftyStates>();
  results: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
}
