import { Injectable } from '@angular/core';
import { AddBattlePassQuestProgressViewModel } from '../../../models/events/components-models/add-battle-pass-quest-progress-view-model';
import { AbstractModelValidator } from '@koddington/ga-common';
import { StrictValidationRules } from '../../shared/validation-rules/strict-validation-rules';

@Injectable()
export class AddBattlePassQuestProgressValidator extends AbstractModelValidator<AddBattlePassQuestProgressViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.subscriptionId)
            .required('Введите Id подписки!')
            .min(1, 'Id подписки не должен быть меньше 1')
            .customRule(u => StrictValidationRules.isInteger(u));

        this.ruleForControl(m => m.currentProgress)
            .min(0, 'Прогресс не должен быть меньше 0')
            .customRule(u => StrictValidationRules.isInteger(u));
    }
}
