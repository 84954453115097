import { Component, OnInit } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { GaMessagingService, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import {BattlePassFreeBetsService} from 'src/app/services/autogen/BattlePass';
import {
    WlBattlePassFreeBetRequestEditableViewModel
} from '../../../../models/battle-pass/wl-battle-pass-free-bet-request-editable-view-model';
import { WlBattlePassFreeBetRequestStatus } from 'src/app/services/autogen/BattlePass';
import { ActivatedRoute } from '@angular/router';
import {
    WlBattlePassFreeBetRequestStatusDropdownStrategy
} from '../../../shared/common/enum-dropdown/strategies/wl-battle-pass-free-bet-request-status-dropdown-strategy';
import { DropdownOptions } from '../../../../models/common/enum-dropdown/dropdown-options';

@Component({
    selector: 'app-battle-pass-free-bet-requests',
    templateUrl: './battle-pass-free-bet-requests.component.html',
    styleUrls: ['./battle-pass-free-bet-requests.component.css']
})

export class BattlePassFreeBetRequestsComponent implements OnInit {

    public results: WlBattlePassFreeBetRequestEditableViewModel[];
    public strategy = new WlBattlePassFreeBetRequestStatusDropdownStrategy();
    public allStatesControl = StrictFormControlBuilder.buildNullNumber<WlBattlePassFreeBetRequestStatus>();

    public sequenceId: number;

    constructor(private readonly service: BattlePassFreeBetsService,
                private readonly route: ActivatedRoute,
                private readonly messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.init();
    }

    public saveNewStatus(result: WlBattlePassFreeBetRequestEditableViewModel): void {
        this.service.setStatusForRequest(result.request.id, result.newStatus.strictValue).pipe(
            take(1),
            filter(u => !this.messaging.tryShowError(u))
        ).subscribe(_ => {
            this.messaging.showMessage('Статус изменён');
            this.load();
        });
    }

    public setStatusForAll(): void {
        this.service.setStatusForAllRequests(this.sequenceId, this.allStatesControl.strictValue).pipe(
            take(1),
            filter(u => !this.messaging.tryShowError(u))
        ).subscribe(_ => {
            this.messaging.showMessage('Статус изменён');
            this.load();
        });
    }

    private init(): void {
        const routeParams = this.route.snapshot.paramMap;
        this.sequenceId = Number(routeParams.get('id'));
        this.load();
    }

    private load() {
        this.service.listRequests(this.sequenceId).pipe(
            take(1)
        ).subscribe(u => this.results = u.map(v => {
            const model = new WlBattlePassFreeBetRequestEditableViewModel();
            model.request = v;
            model.newStatus.strictValue = v.processingStatus;

            return model;
        }));
    }

    get dropDownOptions(): DropdownOptions {
        const options = new DropdownOptions();
        options.placeholder = 'Выберите тип';
        options.classname = 'enabling-request-type';
        return options;
    }
}
