import {Component, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-mechanics-layout',
  templateUrl: './mechanics-layout.component.html',
  styleUrls: ['./mechanics-layout.component.css']
})
export class MechanicsLayoutComponent implements OnInit, OnDestroy {
  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
