import {Component, OnInit} from '@angular/core';
import { WlFilteredPagedResult } from 'src/app/services/autogen/Shared';
import { WlActiveUserTrigger } from 'src/app/services/autogen/Triggers';
import { ActiveUserTriggersFilterForm } from 'src/app/services/autogen/Triggers';
import {ActiveUserTriggerListViewModel} from '../../../models/triggers/active-user-trigger-list-view-model';
import { ActiveUserTriggersService } from 'src/app/services/autogen/Triggers';
import {ActiveUserTriggersListValidator} from '../validators/active-user-triggers-list-validator';
import {finalize, take} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import { isNullOrUndefined } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {StrictFormControl} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-active-user-triggers-list-for-user',
  templateUrl: './active-user-triggers-list-for-user.component.html',
  styleUrls: ['./active-user-triggers-list-for-user.component.css']
})
export class ActiveUserTriggersListForUserComponent implements OnInit {

  private loading = false;
  private model: ActiveUserTriggerListViewModel = new ActiveUserTriggerListViewModel();
  private result: WlFilteredPagedResult<WlActiveUserTrigger, ActiveUserTriggersFilterForm>;

  constructor(private readonly service: ActiveUserTriggersService,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly validator: ActiveUserTriggersListValidator) {
  }

  get resultModel(): WlFilteredPagedResult<WlActiveUserTrigger, ActiveUserTriggersFilterForm> {
    return this.result;
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  public ngOnInit(): void {
    this.route.queryParams.pipe(
      untilDestroyed(this)
    ).subscribe((params) => {
      const userId = this.route.snapshot.queryParams['userId'];
      if (!isNullOrUndefined(userId)) {
        this.userId.strictValue = Number(userId);
      }

      this.load(0, 30);
    });

  }

  load(offset: number, count: number): void {
    this.loading = true;
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new ActiveUserTriggersFilterForm();
    form.userId = this.userId.hasStrictValue ? this.userId.strictValue : 0;
    form.count = count;
    form.offset = offset;

    this.service.listForUser(form).pipe(take(1), finalize(() => this.loading = false))
      .subscribe(value => this.result = value);
  }

  findByUserId(): void {
    this.router.navigate(['/dashboard/triggers/list'], {
      queryParams: {
        userId: this.userId.strictValue
      }
    });
  }

}
