import { Injectable } from '@angular/core';
import { AbstractModelValidator } from '@koddington/ga-common';
import { AddCashbackBonusViewModel } from '../models/add-cashback-bonus-view-model';


@Injectable()
export class AddCashbackBonusValidator extends AbstractModelValidator<AddCashbackBonusViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.userId).requiredAndNotZero().min(1);
    }
}
