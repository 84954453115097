import { Component, Input, OnInit } from '@angular/core';
import { WlErpBattlePassLevelApiModel } from 'src/app/services/autogen/BattlePass';
import { KeyValue } from '@angular/common';

@Component({
    selector: 'app-battle-pass-erp-levels[levels]',
    templateUrl: './battle-pass-erp-levels.component.html',
    styleUrls: ['./battle-pass-erp-levels.component.scss']
})
export class BattlePassErpLevelsComponent implements OnInit {
    @Input()
    levels: WlErpBattlePassLevelApiModel[];

    public levelsText = new Map<number, {text: string, show: boolean}>();

    constructor() {
    }

    public ngOnInit(): void {
        this.init();
    }

    private init(): void {
        this.levels.forEach(u => {
            const text = JSON.stringify(u, null, 2);
            this.levelsText.set(u.id, {text: text, show: false});
        });
    }

    public clickText(elem: KeyValue<number, { text: string; show: boolean }>) {
        return elem.value.show ? 'Скрыть' : 'Показать';
    }

    public onClick(elem: KeyValue<number, { text: string; show: boolean }>) {
        elem.value.show = !elem.value.show;
    }
}
