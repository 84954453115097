<h1>Список наград пользователя</h1>

<div class="row">
  <app-strict-input-number class="col-6" [control]="model.userId"
                           [placeholder]="'Id пользователя'"></app-strict-input-number>
  <span class="col-6">
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </span>
</div>

<div class="row mb-3">
  <div class="col-1">
    id
  </div>
  <div class="col-1">
    Сумма награды
  </div>
  <div class="col-1">
    Тип награды
  </div>
  <div class="col-1">
    Уровень бонус клуба
  </div>
  <div class="col-1">
    Дата начисления
  </div>
  <div class="col-2">
    Обновить дату начисления
  </div>
</div>
<div *ngFor="let bonusClubReward of bonusClubRewards;let i = index">
  <div class="row">
    <div class="col-1">
      {{bonusClubReward.id}}
    </div>
    <div class="col-1">
      {{bonusClubReward.rewardAmount}}
    </div>
    <div class="col-1">
      {{bonusClubReward.rewardType | wlBonusClubRewardTypesTransform}}
    </div>
    <div class="col-1">
      {{bonusClubReward.bonusClubLevel | wlBonusClubLevelTransform}}
    </div>
    <div class="col-1">
      {{bonusClubReward.issueDate | dateTime}}
    </div>
    <div class="col-2">
      <strict-date-time-picker
        placeholder="Новая дата подтверждения"
        [control] = "getControl(bonusClubReward.id)">
      </strict-date-time-picker>
    </div>
    <div class="col-1">
      <button class="btn btn-primary mt-2" (click)="update(bonusClubReward.id)">Обновить</button>
    </div>
    <div class="col-1">
      <button class="btn btn-warning mt-2" (click)="remove(bonusClubReward.id)">Удалить</button>
    </div>
  </div>
</div>

<div *ngIf="result">
  <ga-paging [pagedResult]="result" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>

