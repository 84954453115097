<div>
  <h4>Создать promo</h4>
  <div class="row">
    <div>
      <label>Id promo</label>
      <app-strict-input-number [control]="viewModel.promoId"></app-strict-input-number>
    </div>
    <div>
      <label>Название promo</label>
      <app-strict-input-text [control]="viewModel.name"></app-strict-input-text>
    </div>
    <div>
      <label>Id источника</label>
      <app-strict-input-number [control]="viewModel.sourceId"></app-strict-input-number>
    </div>
    <div>
      <label>Id канала</label>
      <app-strict-input-number [control]="viewModel.channelId"></app-strict-input-number>
    </div>
    <div>
      <label>Дата изменения</label>
      <strict-date-time-picker [control]="viewModel.changeDate"></strict-date-time-picker>
    </div>
  </div>
  <div>
    <button class="btn btn-primary" (click)="send()">Отправить</button>
  </div>
</div>
