import { Component } from '@angular/core';
import { BetExpressLineViewModel } from 'src/app/models/events/components-models/bet-express-line-calculation-view-model';
import { EventsService } from 'src/app/services/autogen/Events';
import { ExpressLineCalculationForm } from 'src/app/services/autogen/Events';
import { builderStrictToModel } from 'src/app/modules/shared/common/operation/builder-operation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GaMessagingService } from '@koddington/ga-common';
import { ExpressLineRateCalculationValidator } from '../../validators/express-line-rate-validator';

@UntilDestroy()
@Component({
    selector: 'app-bet-express-line-calculation',
    templateUrl: './bet-express-line-calculation.component.html',
    styleUrls: ['./bet-express-line-calculation.component.scss'],
})
export class BetExpressLineCalculation {
    public viewModel: BetExpressLineViewModel = new BetExpressLineViewModel();

    constructor(
        private readonly _service: EventsService,
        private readonly _messaging: GaMessagingService,
        private readonly _validator: ExpressLineRateCalculationValidator
    ) {
    }

    public save(): void {
        const errorValidate = this._validator.validate(this.viewModel);
        if (errorValidate.length > 0) {
            return;
        }
        const form = builderStrictToModel(ExpressLineCalculationForm, this.viewModel);
        this._service.clientExpressLineCalculationAdd(form)
            .pipe(untilDestroyed(this))
            .subscribe((response) => {
                if (response.isCorrect && response.result) {
                    this._messaging.showMessage(
                        'Запись целевого события в экспрессе создана'
                    );
                }
            });
    }

    public defaultFill(): void {
        this.viewModel.balls.strictValue = this.generateNumberByInterval(0.001, 0.9, true);
        this.viewModel.expressRate.strictValue = this.generateNumberByInterval(0.1, 10, true);
        this.viewModel.betId.strictValue = this.generateNumberByInterval(100, 100000);
        this.viewModel.userId.strictValue = this.generateNumberByInterval(100, 100000);
        this.viewModel.lineId.strictValue = this.generateNumberByInterval(100, 100000);
        this.viewModel.eventId.strictValue = this.generateNumberByInterval(100, 100000);
        this.viewModel.winAmount.strictValue = this.generateNumberByInterval(100, 100000, true);
        this.viewModel.purchase.strictValue = this.generateNumberByInterval(0, 1);
        this.viewModel.betSum.strictValue = this.generateNumberByInterval(1, 1000);
        this.viewModel.lineRate.strictValue = this.generateNumberByInterval(0, 5.5, true);
    }

    private generateNumberByInterval(min: number, max: number, isFloat: boolean = false): number {
        if (isFloat) {
            const result = (Math.random() * (max - min)).toFixed(2);
            return this.roundTwoChar(result);
        }
        return Math.floor(Math.random() * (max - min + 1));
    }

    private roundTwoChar(strNumber: string): number {
        return Number(strNumber);
    }
}
