<h1>Список подписок пользователей</h1>

<div class="row">
    <app-strict-input-number class="col-6" [control]="model.userId"
                             [placeholder]="'Id пользователя'"></app-strict-input-number>
    <span>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </span>
</div>

<div class="row mb-3">
    <div class="col-1">
        Id пользователя
    </div>
    <div class="col-1">
        Id подписки
    </div>
    <div class="col-1">
        Задание
    </div>
    <div class="col-1">
        Текущий прогресс
    </div>
    <div class="col-1">
        Дата подписки
    </div>
    <div class="col-1">
        Дата завершения задания
    </div>
    <div class="col-1">
        Завершено вручную
    </div>
    <div class="col-2">
        Код статуса
    </div>
    <div class="col-1">
        Статус
    </div>
    <div class="col-2">
        Сообщение
    </div>
</div>
<div *ngFor="let result of results;let i = index">
    <app-battle-pass-subscription-item [item]="result"></app-battle-pass-subscription-item>
</div>

<div *ngIf="pagedResult">
    <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>

