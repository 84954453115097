import {Component, OnInit} from '@angular/core';
import { EventsService } from 'src/app/services/autogen/Events';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CupisChangesForm } from 'src/app/services/autogen/Events';
import {CupisChangesEventViewModel} from '../../../../models/events/components-models/cupis-changes-event-view.model';
import {CupisChangesValidator} from '../../validators/cupis-changes-validator.service';
import {GaMessagingService, GaPanelGridConfig, GaTitlePositions} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-add-cupis-changes',
  templateUrl: './add-cupis-changes.component.html',
  styleUrls: ['./add-cupis-changes.component.css']
})
export class AddCupisChangesComponent implements OnInit {

  public readonly model = new CupisChangesEventViewModel();
  config = new GaPanelGridConfig();

  constructor(private readonly _apiClient: EventsService,
              private readonly validator: CupisChangesValidator,
              private readonly messaging: GaMessagingService) {
  }

  public ngOnInit(): void {
    this.buildGrid();
  }

  public add(): void {
    const errors = this.validator.validate(this.model);

    if (errors.length !== 0) {
      return;
    }

    const form = new CupisChangesForm();
    form.state = this.model.state.strictValue;
    form.userId = this.model.userId.strictValue;
    form.operationDate = this.model.eventDate.strictValue;
    form.registrationStateChangedAt = this.model.registrationStateChangedAt.strictValue;
    form.withdrawalApprovedState = this.model.withdrawalApprovedState.strictValue;
    form.hasSnils = this.model.hasSnils.strictValue;
    this._apiClient
      .addCupisChanges(form)
      .pipe(
        take(1),
        untilDestroyed(this)
      )
      .subscribe(value => this.messaging.showMessage('Событие добавлено'));
  }

  private buildGrid() {
    this.config.columnsCount = 5;
    this.config.titlePositions = GaTitlePositions.Top;
    this.config.addNumbers(this.model.userId, 'Id пользователя')
      .addDateTime(this.model.eventDate, 'Дата события')
      .addNumbers(this.model.state, 'Статус регистрации (1)')
      .addDateTime(this.model.registrationStateChangedAt, 'Дата смены статуса регистрации (2)')
      .addNumbers(this.model.withdrawalApprovedState, 'Статус \'вывод одобрен\' (3)')
      .addNumbers(this.model.hasSnils, 'Есть СНИЛС (4)');
  }
}
