<h1>Пользователи</h1>
<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li [class.active]="isUserAddTab" (click)="onTabChange('UserAddTab')">Добавить пользователя</li>
    <li [class.active]="isUserListTab" (click)="onTabChange('UserList')">Просмотр профилей пользователей CRM</li>
    <li [class.active]="isFreeBetStatusTab" (click)="onTabChange('FreeBetStatus')">Просмотр статуса фрибета
      пользователя
    </li>
    <li [class.active]="isBonusClubRewardsTab" (click)="onTabChange('BonusClubRewards')">Просмотр наград пользователя
    </li>
    <li [class.active]="isUserLogins" (click)="onTabChange('UserLogins')">Зарегистрированные
      пользователи
    </li>
    <li [class.active]="isResetResultBetCurrentMonthTab" (click)="onTabChange('ResetResultBetCurrentMonth')">Сброс результатов ставок</li>
    <li [class.active]="isDocsVerificationErrorsTab" (click)="onTabChange('DocsVerificationErrors')">Ошибки загрузки
      документов
    </li>
    <li [class.active]="isSportsStatsTab" (click)="onTabChange('SportsStats')">Статистика по видам спорта</li>
    <li [class.active]="isStageHistoryTab" (click)="onTabChange('StageHistory')">История сегментации</li>
    <li [class.active]="isUserVideo" (click)="onTabChange('VideoAttempt')">Видеоверификация</li>
    <li [class.active]="isGamblerTokens" (click)="onTabChange('GamblerAuthTokens')">Токены игроков</li>
  </ul>

  <div>
    <div *ngIf="isUserAddTab">
      <app-users-list></app-users-list>
    </div>
    <div *ngIf="isUserListTab">
      <app-users-test-profiles-list></app-users-test-profiles-list>
    </div>
    <div *ngIf="isFreeBetStatusTab">
      <app-users-freebet-status></app-users-freebet-status>
    </div>
    <div *ngIf="isBonusClubRewardsTab">
      <app-user-bonus-club-rewards></app-user-bonus-club-rewards>
    </div>
    <div *ngIf="isResetResultBetCurrentMonthTab">
      <app-users-reset-result-bet></app-users-reset-result-bet>
    </div>
    <div *ngIf="isUserLogins">
      <app-user-logins></app-user-logins>
    </div>
    <div *ngIf="isDocsVerificationErrorsTab">
      <app-document-verification-errors></app-document-verification-errors>
    </div>
    <div *ngIf="isSportsStatsTab">
      <app-users-sport-type-statistics></app-users-sport-type-statistics>
    </div>
    <div *ngIf="isStageHistoryTab">
      <app-users-stages></app-users-stages>
    </div>
    <div *ngIf="isUserVideo">
      <app-user-video-verification></app-user-video-verification>
    </div>
    <div *ngIf="isGamblerTokens">
      <app-gambler-auth-tokens></app-gambler-auth-tokens>
    </div>
  </div>
</div>
