<h1 style="padding: inherit">Стадии</h1>
<div *ngIf="loading" class="loading">
  <app-loading></app-loading>
</div>
<div class="container-fluid" *ngIf="!loading">
  <table class="table">
    <tr class="row table-info">
      <td class="col-1">
        Id
      </td>
      <td class="col-1">
        Id пользователя
      </td>
      <td class="col-2">
        Дата события
      </td>
      <td class="col-3">
        Тип события
      </td>
      <td class="col-5">
        Данные
      </td>
    </tr>

    <tr *ngFor="let log of logs.results" class="row">
      <td class="col-1">
        {{log.id}}
      </td>
      <td class="col-1">
        {{log.userId}}
      </td>
      <td class="col-2">
        {{log.createDate | moscowDateTime}}
      </td>
      <td class="col-3">
        {{log.historyType | wlStageHistoryTypesTransform}}
      </td>
      <td class="col-5">
        <pre class="wl-payloads">{{deserialize(log.payload) | json}}</pre>
      </td>
    </tr>
  </table>
  <ga-paging [pagedResult]="logs" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>
