import { WlMockSecurityGroup } from 'src/app/services/autogen/Dictionaries';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class ClientRegistrationViewModel {
  public readonly userId = StrictFormControlBuilder.buildNullNumber();
  public readonly registrationDate = StrictFormControlBuilder.buildNullDayjs();
  public readonly name = StrictFormControlBuilder.buildNullString();
  public readonly groupId = StrictFormControlBuilder.buildNullObject(WlMockSecurityGroup);
  public readonly phoneNumber = StrictFormControlBuilder.buildNullString();
  public readonly email = StrictFormControlBuilder.buildNullString();
  public readonly registrationSource = StrictFormControlBuilder.buildNullNumber();
  public readonly registrationUrl = StrictFormControlBuilder.buildNullString();
  public readonly registrationState = StrictFormControlBuilder.buildNullNumber();
  public readonly emailRejecting = new StrictFormControl<boolean>(false);
  public readonly smsRejecting = new StrictFormControl<boolean>(false);
  public readonly emailRejectingDate = StrictFormControlBuilder.buildNullDayjs();
  public readonly smsRejectingDate = StrictFormControlBuilder.buildNullDayjs();
  public readonly birthday = StrictFormControlBuilder.buildNullDayjs();
  public readonly promoId = StrictFormControlBuilder.buildNullNumber();
}
