import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { WlMechanicEnabledReasons } from 'src/app/services/autogen/Segmentation';
import { WlSegmentationMechanicsTypes } from 'src/app/services/autogen/Events';
import { Dayjs } from 'dayjs';

export class AddMechanicViewModel {
  userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  mechanicEnabledReason: StrictFormControl<WlMechanicEnabledReasons> = StrictFormControlBuilder.buildNullNumber<WlMechanicEnabledReasons>();
  mechanicType: StrictFormControl<WlSegmentationMechanicsTypes> = StrictFormControlBuilder.buildNullNumber<WlSegmentationMechanicsTypes>();
  depositWaitingDate?: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  freebetId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  sumForMechanicTriggering?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  freebetLifetimeDays?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  freebetSum?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();

}
