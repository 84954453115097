<h1>Рассылки</h1>
<div class="tabs container-fluid">
  <ul class="container-fluid">
    <li (click)="onTabChange('Push')">Шаблоны</li>
    <li (click)="onTabChange('Broadcasts')">Запуск рассылки</li>
    <li (click)="onTabChange('BetsResultsSubscriptions')">Cтавки пользователя</li>
    <li (click)="onTabChange('SmsBroadcastingStatistics')">Статистика SMS рассылки</li>
    <li (click)="onTabChange('PushBroadcastingStatistics')">Статистика Push рассылки</li>
  </ul>
  <div>
    <div *ngIf="isPushAllow">
      <app-push-templates-list></app-push-templates-list>
    </div>
    <div *ngIf="isBroadcastsAllow" class="bet-operations">
      <app-broadcasts-start></app-broadcasts-start>
    </div>
    <div *ngIf="isBetsResultsSubscriptionsAllow">
      <app-bets-results-subscriptions-list></app-bets-results-subscriptions-list>
    </div>
    <div *ngIf="isSmsBroadcastingStatisticsAllow">
      <app-sms-broadcasting-statistics-list></app-sms-broadcasting-statistics-list>
    </div>
    <div *ngIf="isPushBroadcastingStatisticsAllow">
      <app-push-broadcasting-statistics-list></app-push-broadcasting-statistics-list>
    </div>
  </div>
</div>
