import {Routes} from '@angular/router';
import {MechanicsLayoutComponent} from './mechanics-layout/mechanics-layout.component';
import {UserMechanicsListComponent} from './user-mechanics-list/user-mechanics-list.component';
import {UserMechanicsEnablingRequestsComponent} from './user-mechanics-enabling-requests/user-mechanics-enabling-requests.component';
import {UserMechanicsProcessingSequencesComponent} from './user-mechanics-processing-sequences/user-mechanics-processing-sequences.component';
import {UserMechanicsProcessingErrorsComponent} from './user-mechanics-processing-errors/user-mechanics-processing-errors.component';
import {UserMechanicDetailsComponent} from './user-mechanic-details/user-mechanic-details.component';
import {UserMechanicTriggeringRequestsComponent} from './user-mechanic-triggering-requests/user-mechanic-triggering-requests.component';
// tslint:disable-next-line:max-line-length
import {UserMechanicsBonusUsingRequestsComponent} from './user-mechanics-bonus-using-requests/user-mechanics-bonus-using-requests.component';

export const MechanicsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: MechanicsLayoutComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'list'
          },
          {
            path: 'list',
            component: UserMechanicsListComponent,
          },
          {
            path: 'processing-requests',
            component: UserMechanicsProcessingSequencesComponent,
          },
          {
            path: 'processing-errors',
            component: UserMechanicsProcessingErrorsComponent,
          },
          {
            path: 'user-mechanic-details/:id',
            component: UserMechanicDetailsComponent,
          },
          {
            path: 'enabling-requests/:id',
            component: UserMechanicsEnablingRequestsComponent,
          },
          {
            path: 'triggering-requests/:id',
            component: UserMechanicTriggeringRequestsComponent,
          },
          {
            path: 'bonus-using-requests/:id',
            component: UserMechanicsBonusUsingRequestsComponent,
          }
        ]
      }
    ]
  }
];
