import { CallQueueTypes } from 'src/app/services/autogen/Events';
import { CallOperationTypes } from 'src/app/services/autogen/Events';
import { CallResultsTypes } from 'src/app/services/autogen/Events';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';


export class AddLifetimeCallEventViewModel {

  userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  eventChangeDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  callOperation: StrictFormControl<CallOperationTypes> = StrictFormControlBuilder.buildNullNumber<CallOperationTypes>();
  callResult?: StrictFormControl<CallResultsTypes> = StrictFormControlBuilder.buildNullNumber<CallResultsTypes>();
  callQueue: StrictFormControl<CallQueueTypes> = StrictFormControlBuilder.buildNullNumber<CallQueueTypes>();

}
