import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {CallsRoutes} from './calls.routes';
import {CallLayoutComponent} from './components/call-layout/call-layout.component';
import {ResponseUserCallListComponent} from './components/response-user-call-list/response-user-call-list.component';
import {CallSequencesComponent} from './components/sequences/call-sequences.component';
import {RecordUserCallListComponent} from './components/record-user-call-list/record-user-call-list.component';
import {RemoveCallSequencesComponent} from './components/remove-sequences/remove-call-sequences.component';
import {CallReasonsListComponent} from './components/call-reasons/call-reasons-list.component';
import {EventsModule} from '../events/events.module';
import {
    LifetimeCallEventsLayoutComponent
} from '../events/events-components/lifetime-call-events-changes/lifetime-call-events-layout/lifetime-call-events-layout.component';
import {LifetimeCallEventsModule} from '../events/events-components/lifetime-call-events-changes/lifetime-call-events.module';
import {GaCommonModule, GaPagingComponent} from '@koddington/ga-common';
import { CallCenterAreaCodegenAppModule } from '../../services/autogen/CallCenter';

@NgModule({
    declarations: [
        CallLayoutComponent,
        ResponseUserCallListComponent,
        CallSequencesComponent,
        RecordUserCallListComponent,
        RemoveCallSequencesComponent,
        CallReasonsListComponent,
        LifetimeCallEventsLayoutComponent
    ],
    exports: [
        LifetimeCallEventsLayoutComponent,
        GaPagingComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule.forChild(CallsRoutes),
        EventsModule,
        LifetimeCallEventsModule,
        GaCommonModule,
        CallCenterAreaCodegenAppModule
    ]
})
export class CallsModule {
}
