import {Component, OnInit} from '@angular/core';
import {AddDocAndSelfieViewModel} from '../../../../models/events/components-models/add-doc-and-selfie-view-model';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import { Dayjs } from 'dayjs';
import { EventsService } from 'src/app/services/autogen/Events';
import {AddSelfieAndDocValidator} from '../../validators/add-selfie-and-doc-validator';
import { AddDocAndSelfieForm } from 'src/app/services/autogen/Events';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-add-selfie-and-doc',
  templateUrl: './add-selfie-and-doc.component.html',
  styleUrls: ['./add-selfie-and-doc.component.css']
})
export class AddSelfieAndDocComponent implements OnInit {

  private model: AddDocAndSelfieViewModel = new AddDocAndSelfieViewModel();

  constructor(private readonly service: EventsService,
              private readonly validator: AddSelfieAndDocValidator,
              private readonly messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new AddDocAndSelfieForm();
    form.userId = this.model.userId.strictValue;
    form.selfieDate = this.model.selfieDate.strictValue;
    form.documentLoadingDate = this.model.documentLoadingDate.strictValue;

    this.service.selfieAndDocAddEvent(form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => this.messaging.showMessage('Фотография и документ успешно добавленны'));
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get selfieDate(): StrictFormControl<Dayjs> {
    return this.model.selfieDate;
  }

  get docDate(): StrictFormControl<Dayjs> {
    return this.model.documentLoadingDate;
  }
}
