<div>
  <h5>Добавить источник</h5>
</div>
<div class="row">
  <div>
    <label>Id источника</label>
    <app-strict-input-number [control]="viewModel.sourceId" placeholder="Id источника"></app-strict-input-number>
  </div>
  <div>
    <label>Имя источника</label>
    <app-strict-input-text [control]="viewModel.name" placeholder="Имя источника"></app-strict-input-text>
  </div>
  <div>
    <label>Дата создания</label>
    <strict-date-time-picker [control]="viewModel.changeDate" placeholder="Укажите дату"></strict-date-time-picker>
  </div>
</div>
<div>
  <button class="btn btn-primary" (click)="send()">Сохранить</button>
</div>
