<div>
  <h5>Добавить канал</h5>
</div>
<div class="row">
  <div>
    <label>Id канала</label>
    <app-strict-input-number [control]="viewModel.channelId" placeholder="Id канала"></app-strict-input-number>
  </div>
  <div>
    <label>Имя канала</label>
    <app-strict-input-text [control]="viewModel.name" placeholder="Имя канала"></app-strict-input-text>
  </div>
  <div>
    <label>Дата создания</label>
    <strict-date-time-picker [control]="viewModel.changeDate" placeholder="Укажите дату"></strict-date-time-picker>
  </div>
</div>
<div>
  <button class="btn btn-primary" (click)="send()">Сохранить</button>
</div>
