import {Component, OnInit} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, first, take, tap} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlUser } from 'src/app/services/autogen/Users';
import { TestUsersDataService } from 'src/app/services/autogen/Users';
import { UsersListByIdForm } from 'src/app/services/autogen/Users';
import {UserListViewModel} from '../../../../models/users/user-list-view-model';
import {GaPagedResult} from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-users-test-profiles-list',
  templateUrl: './users-test-profiles-list.component.html',
  styleUrls: ['./users-test-profiles-list.component.css']
})
export class UsersTestProfilesListComponent implements OnInit {

  public model: UserListViewModel = new UserListViewModel();
  public result: GaPagedResult<WlUser>;
  public users: WlUser[] = [];

  constructor(private readonly service: TestUsersDataService) {
  }

  ngOnInit(): void {
    this.model.userId.strictValueSource.pipe(
      tap(),
      debounceTime(300),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(() => {
      this.model.offset.strictValue = 0;
      this.search();
    });
  }

  search(): void {
    const form = this.createForm();
    this.load(form);
  }

  load(form: UsersListByIdForm): void {
    this.service.list(form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => {
        this.result = value;
        this.users = this.result.results;
      });
  }

  createForm(): UsersListByIdForm {
    const form = new UsersListByIdForm();
    form.userId = this.model.userId.strictValue;
    form.count = this.model.count.strictValue;
    form.offset = this.model.offset.strictValue;

    return form;
  }

  pageChanged(form: UsersListByIdForm): void {
    this.load(form);
  }
}
