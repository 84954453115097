import {Component, OnInit} from '@angular/core';
import { UsersService, WlPushDevices } from 'src/app/services/autogen/Users';
import {map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {UsersWithoutLoginsViewModel} from '../../../../models/users/users-without-logins-view-model';
import { UsersWithoutLoginsForm } from 'src/app/services/autogen/Users';
import {UsersWithoutLoginsValidator} from '../../validators/users-without-logins-validator';
import { GaMessagingService, GaPagedResult, GaPagingForm, isNullOrUndefined } from '@koddington/ga-common';

@UntilDestroy()
@Component({
  selector: 'app-users-without-logins',
  templateUrl: './users-without-logins.component.html',
  styleUrls: ['./users-without-logins.component.css']
})

export class UsersWithoutLoginsComponent implements OnInit {
  public model: UsersWithoutLoginsViewModel = new UsersWithoutLoginsViewModel();
  public result: GaPagedResult<WlPushDevices>;

  constructor(private readonly messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly validator: UsersWithoutLoginsValidator,
              private readonly service: UsersService) {
  }

  ngOnInit() {
    this.activeRoute.queryParams.pipe(
      map(value =>  this.initModel(value)),
      map(() => this.createForm()),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe(value => this.result = value);
  }

  public pageChanged(form: GaPagingForm): void {
    this.model.count.strictValue = form.count;
    this.model.offset.strictValue = form.offset;

    this.navigateThisParam();
  }

  public search(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    this.model.offset.strictValue = 0;
    this.navigateThisParam();
  }

  private load(form: UsersWithoutLoginsForm): Observable<GaPagedResult<WlPushDevices>> {
    return this.service.getDevicesWithoutLogins(form).pipe(
      untilDestroyed(this)
    );
  }

  private createForm(): UsersWithoutLoginsForm {
    const form = new UsersWithoutLoginsForm();
    form.userId = this.model.userId.strictValue;
    form.deviceToken = this.model.deviceToken.strictValue;
    form.offset = this.model.offset.strictValue;
    form.count = this.model.count.strictValue;

    return form;
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : 0;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : 30;
    this.model.userId.strictValue = !isNullOrUndefined(params['userId']) ?
      params['userId'] : null;
    this.model.deviceToken.strictValue = !isNullOrUndefined(params['deviceToken']) ?
      params['deviceToken'] : null;
  }

  private navigateThisParam(): void {
    const params: Params = {
      userId: this.model.userId.getStrictValueOrDefault(undefined),
      deviceToken: this.model.deviceToken.getStrictValueOrDefault(undefined),
      offset: this.model.offset.getStrictValueOrDefault(0),
      count: this.model.count.getStrictValueOrDefault(30),
    };

    this.navigation.search(this.activeRoute, params);
  }
}
