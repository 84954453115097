<h3>Профиль пользователя "Фрибет каждый день"</h3>

<div class="row">
    <app-strict-input-number class="col-2"
                             placeholder="Id пользователя"
                             [control]="userId"

    ></app-strict-input-number>
    <span>
        <button class="btn btn-primary mt-2" (click)="load()">Загрузить</button>
    </span>
</div>
<div *ngIf="tableData" class="row">
    <ga-table [data]="tableData"></ga-table>
</div>

<ng-template #daysShow let-days="days">
    <app-daily-tasks-erp-days [days]="days"></app-daily-tasks-erp-days>
</ng-template>
