import {Routes} from '@angular/router';
import {UserSegmentationDataComponent} from './components/user-segmentation-data/user-segmentation-data.component';
import {MainLayoutComponent} from '../layouts/main-layout/main-layout.component';
import {UserSyncProfilesComponent} from './components/user-sync-profiles/user-sync-profiles.component';
import {SegmentationLayoutComponent} from './components/new-segmenatation/segmentation-layout/segmentation-layout.component';

export const SegmentsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'userData',
        component: UserSegmentationDataComponent
      },
      {
        path: 'userProfiles',
        component: UserSyncProfilesComponent
      },
      {
        path: 'addSegmentation',
        component: SegmentationLayoutComponent
      }
    ]
  }
];
