<app-broadcasts-pausing></app-broadcasts-pausing>
<h1>Добавить рассылку</h1>
<div class="broadcast-start col-4">
    <app-strict-input-boolean class="form-group"
                              [control]="model.excludingMode"
                              label="Исключающий список"
                              (userSelect)="userManipulationSource.next()"
    ></app-strict-input-boolean>
    <app-strict-input-number class="form-group"
                             [control]="model.templateCode"
                             title="Код шаблона"
                             placeholder="Код шаблона"
                             (valueChange)="userManipulationSource.next()"
    ></app-strict-input-number>
    <div class="form-group">
        <label>Файл со списком</label>
        <div class="custom-file">
            <input type="file" class="custom-file-input" #fileInput id="customFileLang" (change)="onFileChange($event)" lang="ru">
            <label class="custom-file-label" for="customFileLang">{{ model.file?.strictValue?.name }}</label>
        </div>
        <div class="errors-list">
      <span class="error-item" *ngFor="let error of model.file.strictErrors | async">
          {{ error.message }}
      </span>
        </div>
    </div>
    <strict-date-time-picker class="form-group"
                             [control]="model.date"
                             (valueChanged)="userManipulationSource.next()"
    ></strict-date-time-picker>
    <button class="btn btn-primary mt-2"
            [disabled]="!valid"
            (click)="save()"
    >
        Сохранить
    </button>
</div>
