import {Component, OnInit} from '@angular/core';
import { map, switchMap, take, } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MechanicsSegmentationService } from 'src/app/services/autogen/Segmentation';
import {MechanicsListViewModel} from '../models/mechanics-list-view-model';
import { WlSegmentationMechanic } from 'src/app/services/autogen/Segmentation';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../../shared/services/search-navigation.service';
import {Observable} from 'rxjs';
import {GaMessagingService, GaPagedResult, isNullOrUndefined} from '@koddington/ga-common';
import { MechanicsSegmentationFilterForm } from 'src/app/services/autogen/Segmentation';


@UntilDestroy()
@Component({
  selector: 'app-mechanics-list',
  templateUrl: './mechanics-list.component.html',
  styleUrls: ['./mechanics-list.component.css']
})
export class MechanicsListComponent implements OnInit {

  public model: MechanicsListViewModel = new MechanicsListViewModel();
  public result: GaPagedResult<WlSegmentationMechanic>;

  constructor(private readonly messaging: GaMessagingService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService,
              private readonly service: MechanicsSegmentationService) {
  }

  ngOnInit() {

    this.activeRoute.queryParams.pipe(
      map(value =>  this.initModel(value)),
      map(() => this.createForm()),
      switchMap(value => this.load(value)),
      untilDestroyed(this)
    ).subscribe(value => this.result = value);
  }

  public search(): void {
    this.model.offset.strictValue = 0;
    this.navigateWithParameters();
  }

  public pageChanged(form: MechanicsSegmentationFilterForm): void {
    this.model.count.strictValue = form.count;
    this.model.offset.strictValue = form.offset;

    this.navigateWithParameters();
  }

  private load(form: MechanicsSegmentationFilterForm): Observable<GaPagedResult<WlSegmentationMechanic>> {
    return this.service.list(form).pipe(
      take(1),
      untilDestroyed(this)
    );
  }

  private createForm(): MechanicsSegmentationFilterForm {
    const form = new MechanicsSegmentationFilterForm();
    form.mechanicId = this.model.mechanicId.strictValue;
    form.userId = this.model.userId.strictValue;
    form.offset = this.model.offset.strictValue;
    form.count = this.model.count.strictValue;
    return form;
  }

  private initModel(params: Params): void {
    this.model.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : 0;
    this.model.count.strictValue = !isNullOrUndefined(params['count']) ?
      Number(params['count']) : 30;
    this.model.userId.strictValue = !isNullOrUndefined(params['userId']) ?
      params['userId'] : null;
    this.model.mechanicId.strictValue = !isNullOrUndefined(params['mechanicId']) ?
      params['mechanicId'] : null;
  }

  private navigateWithParameters(): void {
    const params: Params = {
      tab: 'MechanicsList',
      mechanicId: this.model.mechanicId.hasStrictValue ? this.model.mechanicId.strictValue : null,
      userId: this.model.userId.hasStrictValue ? this.model.userId.strictValue : null,
      offset: this.model.offset.hasStrictValue ? this.model.offset.strictValue : 0,
      count: this.model.count.hasStrictValue ? this.model.count.strictValue : 30
    };

    this.navigation.search(this.activeRoute, params);
  }



}
