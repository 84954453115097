import {AddMediaPromoViewModel} from '../add-media-promo-view-model';
import {AbstractModelValidator} from '@koddington/ga-common';

export class AddMediaPromoValidator extends AbstractModelValidator<AddMediaPromoViewModel> {
  constructor() {
    super();
    this.ruleForControl(input => input.promoId).min(1).requiredAndNotZero();
      this.ruleForControl(input => input.name).notEmptyString();
  }
}

