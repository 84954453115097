import {Component, OnInit} from '@angular/core';
import { EventsService } from 'src/app/services/autogen/Events';
import {ChannelChangeViewModel} from '../../../../models/events/components-models/channel-change-view-model';
import {GaMessagingService, StrictFormControl} from '@koddington/ga-common';
import {ChannelChangeValidator} from '../../validators/channel-change-validator';
import { ChannelChangeForm } from 'src/app/services/autogen/Events';
import {take} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dayjs } from 'dayjs';

@UntilDestroy()
@Component({
  selector: 'app-add-sms-operations',
  templateUrl: './add-sms-operations.component.html',
  styleUrls: ['./add-sms-operations.component.css']
})
export class AddSmsOperationsComponent implements OnInit {

  private model: ChannelChangeViewModel = new ChannelChangeViewModel();

  constructor(private readonly service: EventsService,
              private readonly validator: ChannelChangeValidator,
              private readonly messaging: GaMessagingService) {
  }

  ngOnInit(): void {
  }

  add(): void {
    const errors = this.validator.validate(this.model);
    if (errors.length > 0) {
      return;
    }
    const form = new ChannelChangeForm();
    form.userId = this.model.userId.strictValue;
    form.emailRestricted = this.model.emailRestricted.strictValue;
    form.smsRestricted = this.model.smsRestricted.strictValue;
    form.emailRestrictedDate = this.model.emailRestrictedDate.strictValue;
    form.smsRestrictedDate = this.model.smsRestrictedDate.strictValue;

    this.service.addChannelChange(form)
      .pipe(take(1), untilDestroyed(this))
      .subscribe(value => this.messaging.showMessage('Канал успешно изменён'));
  }

  get userId(): StrictFormControl<number> {
    return this.model.userId;
  }

  get email(): StrictFormControl<number> {
    return this.model.emailRestricted;
  }

  get sms(): StrictFormControl<number> {
    return this.model.smsRestricted;
  }
  get smsDate(): StrictFormControl<Dayjs> {
    return this.model.smsRestrictedDate;
  }
  get emailDate(): StrictFormControl<Dayjs> {
    return this.model.emailRestrictedDate;
  }
}
