import {Component, OnInit} from '@angular/core';
import {StrictFormControl} from '@koddington/ga-common';
import {take, tap} from 'rxjs/operators';
import { isNullOrUndefined } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlCallSequencesType } from 'src/app/services/autogen/CallCenter';
import { CallSequencesService } from 'src/app/services/autogen/CallCenter';
import {ActivatedRoute, Params} from '@angular/router';
import {SearchNavigationService} from '../../../shared/services/search-navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-call-layout',
  templateUrl: './call-layout.component.html',
  styleUrls: ['./call-layout.component.css']
})

export class CallLayoutComponent implements OnInit {

  public sequencesType: typeof WlCallSequencesType = WlCallSequencesType;
  protected offset: StrictFormControl<number> = new StrictFormControl<number>(0);
  private tabsControl: StrictFormControl<string> = new StrictFormControl<string>('');

  constructor(private readonly  _api: CallSequencesService,
              private readonly activeRoute: ActivatedRoute,
              private readonly navigation: SearchNavigationService) {
  }

    get isCallEvents(): boolean {
    return this.tab.strictValue === 'CallEvents';
  }

  get tab(): StrictFormControl<string> {
    return this.tabsControl;
  }

  get isAddUserCallHistory(): boolean {
    return this.tab.strictValue === 'AddUserCallHistory';
  }

  get isRemoveUserCallHistory(): boolean {
    return this.tab.strictValue === 'RemoveUserCallQueueHistory';
  }

  get isResponseUserCallHistory(): boolean {
    return this.tab.strictValue === 'ResponseUserQueueHistory';
  }

  get isCallReasons(): boolean {
    return this.tab.strictValue === 'CallReasons';
  }

  get isCallReasonsHistory(): boolean {
    return this.tab.strictValue === 'CallReasonsHistory';
  }

  public ngOnInit(): void {
    this.activeRoute.queryParams.pipe(
      tap(value => this.initModel(value)),
      take(1)
    ).subscribe();

    this.offset.strictValueSource
      .pipe(untilDestroyed(this))
      .subscribe(() => this.navigateThisParam());
  }

  public onTabChange(tabName: string): void {
    this.tab.strictValue = tabName;
    this.offset.strictValue = 0;
  }

  public clearHistory() {
    this._api.clearHistory()
      .pipe(take(1)).subscribe();
  }

  private initModel(params: Params): void {
    this.offset.strictValue = !isNullOrUndefined(params['offset']) ?
      Number(params['offset']) : 0;
    this.tabsControl.strictValue = !isNullOrUndefined(params['tabsControl']) ?
      params['tabsControl'] : '';
  }

  private navigateThisParam(): void {
    const params: Params = {
      offset: this.offset.hasStrictValue ? this.offset.strictValue : 0,
      tabsControl: this.tabsControl.hasStrictValue ? this.tabsControl.strictValue : '',
    };

    this.navigation.search(this.activeRoute, params);
  }
}

